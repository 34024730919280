<!-- 页头组件 -->
<template>
  <div class="page-header">
    <img src="@/assets/common/back.png" alt="back" @click="onBack" class="back-btn" />

    <span class="title" v-if="!!props.title">{{ t(props.title) }}</span>

    <img
      v-if="!!props.history"
      src="@/assets/common/app_bar_transaction_history.png"
      alt="history"
      @click="onHistory"
      class="home-btn" />
    <img
      v-else-if="!!props.back"
      src="@/assets/common/icon_jogo.png"
      alt="home"
      @click="onHome"
      class="home-btn" />
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const store = useStore();
const props = defineProps({
  title: String,
  back: Function,
  history: Function,
});

const { t } = useI18n();

const onBack = () => {
  props.back()
}

const onHome = () => {
  store.commit("setCurrentView", "casino");
}

const onHistory = () => {
  props.history()
}
</script>

<style lang="scss">
.page-header {
  background-color: black;
  box-sizing: border-box;
  padding: 0 0.5rem;
  width: 100%;
  height: 4.375rem;
  @include flex(row, space-between, center);
  position: sticky;
  top: 0;
  z-index: 99;

  .back-btn {
    height: 2.1875rem;
    padding: 1.09375rem;
  }

  .title {
    font-size: 1.625rem;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .home-btn {
    height: 2.1875rem;
    padding: 1.09375rem;
  }
}
</style>