/**
 * 商城逻辑模块
 */

import { netApi } from "@/api";
import { GFunc } from "@/lib/GFunc";

class _ShopCtrl_ {
    hadEventInit = false;       // 模块是否已经初始化事件监听

    constructor() {
        GFunc.log("ShopCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("ShopCtrl initCtrl");
    }

    initModel() {
        GFunc.log("ShopCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
        // TODO 待处理
    }

    // 下单二次弹窗确认 您确定购买该商品？
    async tryRechargeOrder(cfg, callFunc, failFunc) {
        GFunc.log("try recharge order")
        const reqParams = {
            // userid: UserCtrl.getInstance().userid,
            // payId: cfg.id,
            payCost: cfg.costVip,
            // payType: cfg.payType,
            tick: cfg.tick,
            type: cfg.type,
        }
        try {
            const data = await netApi.rechargeOrder(reqParams);
            callFunc && callFunc(data);
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    // 奖金钱包兑换
    async tryWalletExchange(cfg, callFunc, failFunc) {
        GFunc.log("try wallet exchange")
        const reqParams = {
            "money": cfg.money
        }
        try {
            const data = await netApi.walletExchange(reqParams);
            callFunc && callFunc(data);
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }
}

const ShopCtrl = new _ShopCtrl_();

export { ShopCtrl }

// DEBUG
window.ShopCtrl = ShopCtrl;
