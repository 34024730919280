/**
 * VIP逻辑模块
 */

import { KGEvent } from "@/api/KGEvent";
import eventBus from "@/eventBus";
import { GFunc } from "@/lib/GFunc";
import store from "@/store";
import { computed } from "vue";
import { UserCtrl } from "./UserCtrl";
import { DataIns } from "@/lib/DataIns";
import { CfgAnalyze } from "@/lib/CfgAnalyze";
import { EEventType } from "@/lib/Consts";
import { netApi } from "@/api";
import { EventCtrl } from "./EventCtrl";

// eslint-disable-next-line no-unused-vars
const userInfo = computed(() => store.state.userInfo);

class _VipCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听
    vipData = null;         // Vip数据 {list: [{}, {}, ...]}

    constructor() {
        GFunc.log("VipCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("VipCtrl initCtrl");
    }

    initModel() {
        GFunc.log("VipCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
        // TODO 待处理
    }

    // 初始化Vip数据
    initData() {
        this.vipData = null;
    }

    // Vip模块是否可用
    checkModuleEnable() {
        return true;
    }

    // 获取Vip数据列表
    getVipDataByCache(callFunc, failFunc) {
        if (!this.checkModuleEnable()) {
            callFunc && callFunc();
            return;
        }
        if (!this.vipData) {
            this.getVipDataList(callFunc, failFunc);
        } else {
            this.getExistVipDataList(callFunc);
        }
        // 每次都请求数据
        // this.getVipDataList(callFunc, failFunc);
    }

    // 获取Vip数据列表
    /*static*/ getVipDataList(callFunc, failFunc) {
        if (!this.checkModuleEnable()) {
            callFunc && callFunc();
            return;
        }
        let self = this;
        this.reqVipListData(() => {
            self.getExistVipDataList(callFunc);
        }, () => {
            failFunc && failFunc();
        });
    }

    // 获取已获取的Vip数据
    /*static*/ getExistVipDataList(callFunc) {
        let vipData = this.checkModuleEnable() ? this.vipData : {};
        // 过滤Vip0
        // let list = DataIns.checkarray(vipData.list);
        // for (let idx = list.length -1; idx >= 0; idx--) {
        //     if (list[idx].level == 0) {
        //         list.splice(idx, 1);
        //     }
        // }
        // console.error("list:", list)
        callFunc && callFunc(vipData);
        eventBus.emit(KGEvent.EVENT_VIP_DATA_RECEIVE, vipData);
    }

    // 根据Vip等级获取Vip数据
    getVipDataByLv(lv) {
        let vipData = {};
        if (this.checkModuleEnable() && this.vipData) {
            let vipList = DataIns.checkarray(this.vipData.list);
            for (let idx = vipList.length - 1; idx >= 0; idx--) {
                let lvData = vipList[idx];
                if (lv >= lvData.level) {
                    vipData = lvData;
                    break;
                }
            }
        }
        return vipData;
    }

    // 判断是否Vip最高等级
    isMaxVipLv(lv) {
        let isMax = true;
        if (this.checkModuleEnable() && this.vipData) {
            let vipList = DataIns.checkarray(this.vipData.list);
            for (let idx = vipList.length - 1; idx >= 0; idx--) {
                let lvData = vipList[idx];
                if (lv < lvData.level) {
                    isMax = false
                    break;
                }
            }
        }
        return isMax;
    }

    // 获取下个Vip等级
    getVipNextLv(lv) {
        let vipList = DataIns.checkarray(this.vipData.list);
        let maxLv = vipList[vipList.length - 1].level;
        return lv >= maxLv ? maxLv : DataIns.checkstring(lv + 1);
    }

    // 获取等级奖励是否领取
    hadVipLvBonus(lv) {
        let vipAwards = UserCtrl.getVipAwards();
        let bit = Math.pow(2, lv);
        return (vipAwards & bit) == bit;
    }

    // 更新对应等级奖励已领取
    /*static*/ updateVipLvBonus(lv) {
        let vipAwards = UserCtrl.getVipAwards();
        let bit = Math.pow(2, lv);
        UserCtrl.setVipAwards(vipAwards | bit);
    }

    // 返回自己的Vip等级
    /*static*/ checkOwnerVipLvUp() {
        if (!this.checkModuleEnable() || !this.vipData || !UserCtrl.getIsLogin()) {
            return;
        }
        let ownerVip = {};
        let totalRecharge = UserCtrl.getTotalRecharge();
        let playmoney = UserCtrl.getPlaylMoney();
        let vipData = this.checkModuleEnable() ? this.vipData : {};
        let vipList = DataIns.checkarray(vipData.list);
        for (let idx = vipList.length - 1; idx >= 0; idx--) {
            let lvData = vipList[idx];
            if (totalRecharge >= lvData.totalrecharge && playmoney >= lvData.playmoney) {
                ownerVip = lvData;
                break;
            }
        }
        if (UserCtrl.getVipLevel() !== DataIns.checkint(ownerVip.level)) {
            UserCtrl.setVipLevel(DataIns.checkint(ownerVip.level));
        }
        eventBus.emit(KGEvent.EVENT_VIP_LV_UPDATE, ownerVip);
    }

    /**
     * 获取Vip等级充值和打码进度
     * @param isNextLv 是否为获取下个Vip等级
     * @returns
     */
    getVipProgress(isNextLv) {
        let totalRecharge = UserCtrl.getTotalRecharge().totalRecharge;
        let playMoney = UserCtrl.getPlaylMoney().playMoney;
        let curLv = UserCtrl.getVipLevel();
        let newLv = isNextLv && !this.isMaxVipLv(curLv) ? curLv + 1 : curLv;
        let nextVipData = this.getVipDataByLv(newLv);
        let nextTotalRecharge = DataIns.checkint(nextVipData.totalrecharge);
        let nextPlayMoney = DataIns.checkint(nextVipData.playmoney);
        let rechargeProgress = nextTotalRecharge != 0 ? Math.min(totalRecharge / nextTotalRecharge, 1.0) : 1.0;
        let playMoneyProgress = nextPlayMoney != 0 ? Math.min(playMoney / nextPlayMoney, 1.0) : 1.0;
        return [rechargeProgress, playMoneyProgress];
    }

    // 请求Vip数据
    /*static*/ async reqVipListData(callFunc, failFunc) {
        if (!this.checkModuleEnable()) {
            callFunc && callFunc();
            return;
        }
        let self = this;
        let reqSucc = (data) => {
            // let vipList = DataIns.checkarray(DataIns.checktable(data).list);
            // vipList = vipList.sort((a, b) => {
            //     let lvA = a.level;
            //     let lvB = b.level;
            //     return lvA - lvB;
            // })
            data = DataIns.checktable(data);
            self.vipData = data;

            let hasVip0 = false;
            for (let key in data.list) {
                if (data.list[key].level == 0) {
                    hasVip0 = true;
                    break;
                }
            }

            // vip0特殊处理，没有配置vip0的补上
            if (!hasVip0) {
                let vip0 = {
                    "body": "",             // 签到奖励配置 {"1":5,"2":8,...."7":10}
                    "id": 0,                // vip索引
                    "level": 0,             // vip等级
                    "maxdailywithdrawal": 0,// 每日最高可提款金额
                    "maxwithdrawal": 0,     // 最大提款金额
                    "minwithdrawal": 0,     // 最低提款金额
                    "playmoney": 0,         // 打码金额
                    "rate": 0,              // 手续费率 5.5 表示5.5%
                    "sendmoney": 0,         // 升级赠送金额
                    "totalrecharge": 0,     // 累计充值金额
                    "vipname": "vip0",      // VIP等级名称
                    "withdrawalcount": 0,   // 每日提款次数
                    "hide": true,           // 特殊标记，这种情况不显示
                };
                self.vipData.list.unshift(vip0);
            }

            this.checkOwnerVipLvUp();
            callFunc && callFunc();
        }
        let proVer = CfgAnalyze.getProductPackVer();
        if (proVer === 2 || EventCtrl.checkEventEnable(EEventType.EEvent_Vip)) {
            await EventCtrl.getEventDataByCache(EEventType.EEvent_Vip, (data) => {
                reqSucc(data);
            }, () => {
                failFunc && failFunc();
            });
        } else {
            try {
                const data = await netApi.getVipConf({});
                reqSucc(data);
            } catch (e) {
                failFunc && failFunc();
            } finally {
                /** */
            }
        }
    }

    // 领取VIP奖励
    async reqVipAward(reqParams, callFunc, failFunc) {
        if (!this.checkModuleEnable()) { return; }
        try {
            await netApi.getVipAward(reqParams);
            this.updateVipLvBonus(reqParams.level);
            let disTip = DataIns.checkbool(reqParams.disTip);
            !disTip && eventBus.emit("message", {
                type: "success",
                message: `TID_REWARD_SUCCESS`
            });
            callFunc && callFunc();
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }
}

const VipCtrl = new _VipCtrl_();

export { VipCtrl }

// DEBUG
window.VipCtrl = VipCtrl;
