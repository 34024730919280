/**
 * 公共方法
 */

import { decode } from "js-base64";
import { Consts } from "./Consts";
import { DateIns } from "./DateIns";
import { CfgAnalyze, SwitchModule } from "./CfgAnalyze";
import { DeviceMgr } from "./DeviceMgr";
import { DataIns } from "./DataIns";
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';

// import Game from "../../i18n/Game";
// import { EPackType } from "../enum/Enum";

const GFunc = {
    /**
     * 随机生成字符串
     * @param {*} len
     */
    getRandStr(len) {
        const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const maxPos = chars.length;
        let str = "";
        len = len || 5;
        for (let i = 0; i < len; i++) {
            str += chars[Math.floor(Math.random() * maxPos)];
        }
        return str;
    },

    /**
     * 生成一个用不重复的ID
     * 随机数前置 + 时间戳 + 36进制
     * @param len
     */
    genNonDuplicateID(len) {
        let sIdx = 2;
        return Number(Math.random().toString().slice(sIdx, len + sIdx) + Date.now()).toString(36);
    },

    /**
     * 得到两个数之间的整数值
     * @param {*} minV
     * @param {*} maxV
     * @param {*} hadMax 是否包含最大值 true|false 默认 false
     * @returns hadMax == true 返回 [min, max] 否则 返回 [min, max)
     */
    getRandom(minV, maxV, hadMax) {
        // hadMax = hadMax != undefined ? hadMax : false;
        // // 通过最大值减去最小值然后加1得到取值的范围可能值的总数
        // // 例如取2到10之间的整数，10-2 = 8
        // let dev = maxV - minV;
        // // 如果是包含最大值，需要+1
        // if (hadMax) {
        //     dev += 1;
        // }
        // // 然后通过随机数乘以刚才得到的值，
        // // 例如：Math.random() * 8，由于得到的是小于1的随机数，所以随机最大值0.99*8得到的数始终小于8
        // // 然后使用floor方法向下取整得到的数最大值就是7，然后再加上最小值
        // return Math.floor(Math.random() * dev + minV);
        return Math.floor(this.getRandomOrig(minV, maxV, hadMax));
    },

    getRandomOrig(minV, maxV, hadMax) {
        hadMax = hadMax != undefined ? hadMax : false;
        // 通过最大值减去最小值然后加1得到取值的范围可能值的总数
        // 例如取2到10之间的整数，10-2 = 8
        let dev = maxV - minV;
        // 如果是包含最大值，需要+1
        if (hadMax) {
            dev += 1;
        }
        // 然后通过随机数乘以刚才得到的值，
        // 例如：Math.random() * 8，由于得到的是小于1的随机数，所以随机最大值0.99*8得到的数始终小于8
        // 然后使用floor方法向下取整得到的数最大值就是7，然后再加上最小值
        let value = this.toFixedFloat(Math.random() * dev + minV);
        // rich.log("get random between:" + value);
        return value;
    },

    getType(obj) {
        const toString = Object.prototype.toString;
        return typeMap[toString.call(obj)];
    },

    /**
     * 深度拷贝数据
     * @param data
     * @returns
     */
    deepClone(data) {
        let type = DataIns.getType(data);
        let obj;
        if (type === 'array') {
            obj = [];
        } else if (type === 'object') {
            obj = {};
        } else {
            return data;
        }
        if (type === 'array') {
            for (let i = 0, len = data.length; i < len; i++) {
                let tmpData = data === data[i] ? data : this.deepClone(data[i])
                obj.push(tmpData)
            }
        } else if (type === 'object') {
            for (let key in data) {
                obj[key] = data === data[key] ? data : this.deepClone(data[key])
            }
        }
        return obj;
    },

    /**
     * 是否只有数字或英文
     * @param {*} value
     * @param {*} spChars
     */
    checkNumOrLetter(value, spChars) {
        let regex = spChars ? /^[0-9a-zA-Z.@$!%*#_~?&^]{1,}$/ : /^[0-9a-zA-Z]{1,}$/;
        return regex.test(value);
    },

    /**
     * 匹配出除英文字母或数字或指定特殊字符之外的字符，并去重
     * @param value
     * @returns
     */
    getNonNumOrLetter(value, spChars) {
        // 匹配除英文字母、数字、指定特殊字符、全半角空格之外的字符
        // 匹配全角空格（\u3000）、半角空格（\xA0）和普通空格（\s）
        let regex = spChars ? /[^0-9a-zA-Z.@$!%*#_~?&^\u3000\xA0\s]/g : /[^0-9a-zA-Z\u3000\xA0\s]/g;
        // 使用 match 方法返回匹配到的字符数组，如果没有匹配到则返回空数组
        let arr = value.match(regex) || [];
        // 去重字符
        let uniqueChars = Array.from(new Set(arr));
        // 确保有空格时，空格放在最前面
        // let sortedChars = uniqueChars.sort((a, b) => (a === ' ' ? -1 : b === ' ' ? 1 : 0));
        // 匹配全角空格（\u3000）、半角空格（\xA0）和普通空格（\s）
        let hasSpace = /[\u3000\xA0\s]/.test(value);
        hasSpace && uniqueChars.unshift(" ");
        return uniqueChars;
    },

    /**
     * 空格转文字显示 包括全角或半角空格
     * @param value
     */
    replaceSpaces(value) {
        // 匹配全角空格（\u3000）、半角空格（\xA0）和普通空格（\s）
        let regex = /[\u3000\xA0\s]/g;
        // let spaceChar = Game.LocalizeUtil.getLangStr(`TID_LB_SPACES`);
        let spaceChar = " ";
        let result = value.replace(regex, spaceChar);
        return result;
    },

    // 文本长度补全
    appendStrToLen(value, len, char) {
        len = len != undefined ? len : 1;
        char = char || "0";
        const vLen = value.toString().length;
        let res = "";
        if (vLen < len) {
            for (let i = 0; i < len - vLen; i++) {
                res += char;
            }
        }
        return res + value;
    },

    /**
     * 验证账号长度是否合法
     * @param str
     * @returns
     */
    validUsernameLen(str) {
        str = DataIns.checkstring(str).trim();
        let len = str.length;
        return len >= 4 && len <= 20;
    },

    /**
     * 验证账号字符类型是否合法
     * @param {string} str
     * @returns {Boolean}
     */
    validUsernameCharType(str) {
        str = DataIns.checkstring(str).trim();
        return this.checkNumOrLetter(str);
    },

    /**
     * 验证密码长度是否合法
     * @param {string} str
     * @returns {Boolean}
     */
    validPasswordLen(str) {
        str = DataIns.checkstring(str).trim();
        let len = str.length;
        return len >= 6 && len <= 16;
    },

    /**
     * 验证密码字符类型是否合法
     * @param {string} str
     * @returns {Boolean}
     */
    validPasswordCharType(str) {
        str = DataIns.checkstring(str).trim();
        return this.checkNumOrLetter(str, true);
    },

    /**
     * 验证密码是否一致
     * @param {string} pwd
     * @param {string} confirm
     * @returns {Boolean}
     */
    validPasswordConfirm(pwd, confirm) {
        pwd = DataIns.checkstring(pwd).trim();
        confirm = DataIns.checkstring(confirm).trim();
        // console.log("validPasswordConfirm pwd:" + pwd + ", pwdCon:" + pwdCon)
        return pwd === confirm && this.validPasswordLen(pwd) && this.validPasswordCharType(pwd);
    },

    // // 检测账号是否合法
    // checkAccount(acc) {
    //     // const acc = this.input_account.string.trim();
    //     let errstr = "";
    //     if (acc == "") {
    //         // 请输入账号
    //         errstr = `TID_NOT_INPUT_ACCOUNT_NAME`;
    //     }
    //     else if (!GFunc.validUsernameLen(acc)) {
    //         // 账号必需8-16位
    //         errstr = `TID_ERROR_INPUT_ACCOUNT_NAME`;
    //     }
    //     // else if (!GFunc.validUsernameCharType(acc)) {
    //     //     // 账号字符类型不是英文或数字
    //     //     errstr = `TID_ERROR_INPUT_ACCOUNT_NAME_CHAR`;
    //     // }
    //     // 账号字符类型不是英文或数字，提示对应字符
    //     let invalidArr = GFunc.getNonNumOrLetter(acc);
    //     if (invalidArr.length > 0) {
    //         let invalid = Game.LocalizeUtil.getLangStr(`TID_ERROR_INPUT_ACCOUNT_NAME_UN_INCLUDE`);
    //         let valid = Game.LocalizeUtil.getLangStr(`TID_ERROR_INPUT_ACCOUNT_NAME_CHAR`);
    //         let invalidStr = GFunc.replaceSpaces(invalidArr.join(","));
    //         errstr = `${invalid}${invalidStr}. ${valid}`;
    //     }
    //     const valid = errstr == "";
    //     if (!valid) {
    //         rich.alert.show(errstr);
    //     }
    //     return valid;
    // },

    // // 检测密码是否合法
    // checkPwd(pwd, errMsg) {
    //     let errstr = "";
    //     if (!pwd) {
    //         // 请输入密码
    //         errstr = `TID_NOT_INPUT_PWD`
    //         if (errMsg && DataIns.checkstring(errMsg[0])) {
    //             errstr = DataIns.checkstring(errMsg[0]);
    //         }
    //     }
    //     else if (!DataIns.validPasswordLen(pwd)) {
    //         // 密码必需6-16位
    //         errstr = `TID_ERROR_INPUT_PWD`;
    //         if (errMsg && DataIns.checkstring(errMsg[1])) {
    //             errstr = DataIns.checkstring(errMsg[1]);
    //         }
    //     }
    //     // 密码字符类型不是英文或数字或指定特殊字符，提示对应字符
    //     let invalidArr = GFunc.getNonNumOrLetter(pwd, true);
    //     if (invalidArr.length > 0) {
    //         let invalidStr = GFunc.replaceSpaces(invalidArr.join(","));
    //         errstr = `${Game.LocalizeUtil.getLangStr('TID_ERROR_INPUT_PWD_NAME_UN_INCLUDE')}${invalidStr}`;
    //     }
    //     const valid = errstr == "";
    //     if (!valid) {
    //         rich.alert.show(errstr);
    //     }
    //     return valid;
    // },

    // // 检测确认密码是否合法
    // checkConfirmPwd(pwd, confirmPwd) {
    //     if (!GFunc.validPasswordConfirm(pwd, confirmPwd)) {
    //         rich.alert.show(`TID_ERROR_INPUT_PWD_CONFIRM`);
    //         return false;
    //     }
    //     return true;
    // },

    /**
     * 验证钱包地址
     * len == 34 || 0x41 || 41
     * eg: TL2h5SV6meWC3aPcfC7wLxsJHXyjehR4w7
     * @param addr
     */
    checkUsdtAddr(addr) {
        addr = DataIns.checkstring(addr).trim().toLowerCase();
        let len = addr.length;
        if (len == 0) {
            return 0;
        }
        if (len >= 34) {
            if (len == 34) {
                return 1;
            }
            else {
                let ret = /^(0x41|41)/.test(addr);
                return ret ? 1 : -1;
            }
        }
        return -1;
    },

    // 按规则截取并替换字符串
    fixedLen(str, rp, len) {
        str = DataIns.checkstring(str).trim();
        let total = str.length;
        if (total > len) {
            str = `${str.slice(0, len)}${rp}${str.slice(total - 3, total)}`;
        }
        else {
            let low = Math.min(Math.floor(total * 0.5), 21);
            str = `${str.slice(0, low)}${rp}${str.slice(total - 3, total)}`;
        }
        return str;
    },

    // 截取字符串指定长度字符
    getFixedLen(str, len, midPos) {
        str = DataIns.checkstring(str).trim();
        len = len || 12;
        let total = str.length;
        if (total > len) {
            if (!midPos) {
                str = `${str.slice(0, len)}...`;
            }
            else {
                str = `${str.slice(0, len - 3)}****${str.slice(total - 3, total)}`
            }
        }
        return str
    },

    getFixedLenEx(str, len) {
        str = DataIns.checkstring(str).trim();
        len = len || 12;
        let total = str.length;
        if (total > len) {
            str = `${str.slice(0, len - 3)}****${str.slice(total - 3, total)}`;
        }
        else {
            let low = Math.min(Math.floor(total * 0.5), 21);
            str = `${str.slice(0, low)}****${str.slice(total - 3, total)}`;
        }
        return str
    },

    // // 获取头像路径
    // getAvatarPath(avatarIdx) {
    //     let path = "";
    //     if (SwitchModule.checkPlayerHeadSwitch()) {
    //         avatarIdx = DataIns.checkint(avatarIdx) ? avatarIdx : "1";
    //         path = `bundleHome/res/images/heads/head${avatarIdx}`;
    //     }
    //     return path;
    // },

    // 获取Loading中logo资源路径
    getLoadingLogoPath() {
        let path = `resources/res/loading/logo`;
        return path;
    },

    // 获取logo资源路径
    getLogoPath() {
        let path = `bundleHome/res/images/logos/logo2`;
        return path;
    },

    // 获取热门游戏资源路径
    getHotGameImgPath(key) {
        return `bundleHome/res/images/games/${key}`;
    },

    // 获取随机头像ID
    getRandAvatarIdx() {
        return DataIns.checkstring(Math.ceil(Math.random() * Consts.userIconAmount));
    },

    // 获取货币符号
    getCurrencySymbol() {
        return CfgAnalyze.getCurrencyType();
    },

    // 获取货币简写
    getCurrencyShortName() {
        return CfgAnalyze.getCurrencyShortName();
    },

    // 获取包产品类型
    getProductType() {
        return CfgAnalyze.getProductType();
    },

    // 获取手机号
    // 印度手机号：10位，再加上国家码91，共12位
    // 尼日利亚手机号：10位，再加上区位码234，共13位
    getRealPhone(phone) {
        let realPhone = DataIns.checkstring(phone);
        realPhone = DataIns.checkstring(realPhone.trim().replace(/ /g, ""));
        // if (realPhone) {
        //     let packtype = CfgAnalyze.getPackType();
        //     switch (packtype) {
        //         case EPackType.NONE:
        //             (realPhone.length == 10) && (realPhone = `234${realPhone}`);
        //             break;
        //         default:
        //             (realPhone.length == 10) && (realPhone = `91${realPhone}`);
        //             break;
        //     }
        // }
        return realPhone;
    },

    /**
     * 获取手机号位数
     * 巴西有国际电话代码 55 和两位数的区号，电话号码长度为八位或九位。
     * 一些地区在 2006 年之前使用七位数，这意味着您可能仍然会找到一些旧电话号码，
     * 除非您添加另一个数字，否则这些号码将无法使用。
     * （大多数情况下，尝试在开头添加 2 或 3，或者如果它是一个以 6 到 9 开头的八位数字，请尝试在开头添加 9）。
     * 2～5开头的八位号码为陆线，6～9位开头的八位或九位号码为手机。
     * 
     * 越南 限制为 9-11位
     */
    checkRealPhoneLen(phone, language) {
        let len = phone.length;
        if (language === 'vn') {
            return len >= 9 && len <= 11;
        }
        return len == 10 || len == 11;
    },

    // 获取版本号
    // app版本名 + H5版本号 + 包类型 + 马甲包标识
    getVersionName() {
        let appver = Consts.baseVersion;
        let ver = window["buildInfo"] && window["buildInfo"].buildVersion;
        let packtype = CfgAnalyze.getPackType();
        let packvest = CfgAnalyze.isVestPack() ? ".M" : "";
        return `v${packtype}.${appver}.${ver}${packvest}`;
    },

    /**
     * 跳转浏览器打开链接
     * @param url
     * @param safe 是否安全打开页面
     * @returns
     */
    openUrl(url, safe, tip) {
        url = DataIns.checkstring(url);
        if (!url) {
            return;
        }
        // 因为safari浏览器有一些安全策略，禁止在回调函数中执行window.open方法，以防页面不断弹出窗口。
        // 目前主要用于支付的二次弹窗
        if (safe && DeviceMgr.isIOSOrMac() && DeviceMgr.isSafariBrowser()) {
            Modal.info({
                icon: null,
                centered: true,
                okButtonProps: {
                    style: {
                        width: '100%',
                        height: '3.125rem',
                        fontSize: '1rem',
                        fontWeight: '700',
                    },
                },
                content: createVNode(
                    'div',
                    {
                        style: 'color: #fff;font-size: 1.5rem;font-weight: blod',
                    },
                    tip,
                ),
                onOk() {
                    window.open(url, '_blank');
                },
            });
        }
        else {
            window.open(url, '_blank');
        }
    },

    // 判断是否是快速点击
    isFastClick(sender, spaceTime) {
        let curClickTime = DateIns.getCurDateTime();
        spaceTime = spaceTime || 1000;
        if (sender._lastClickTime && (curClickTime - sender._lastClickTime < spaceTime)) {
            return true;
        }
        sender._lastClickTime = curClickTime;
        return false;
    },

    // 强制保留x位小数
    // 2.006 -> 2
    toFixedFloat(value, decimal) {
        decimal = decimal != undefined ? decimal : 2;
        let base = Math.pow(10, decimal);
        return DataIns.checknumber((Math.floor(DataIns.checknumber(value) * base) / base).toFixed(decimal));
    },

    // 强制保留x位小数
    // 2.006 -> "2.00"
    toFixedFloatForce(value, decimal) {
        decimal = decimal != undefined ? decimal : 2;
        let base = Math.pow(10, decimal);
        return (Math.floor(DataIns.checknumber(value) * base) / base).toFixed(decimal);
    },

    // 数字增加千位符，支持小数、负数
    // 使用slice 不断截取，不断分割
    toThousands(num, fixed) {
        let result = '';
        num = DataIns.checknumber(num);
        const symbol = Number(num) < 0 ? '-' : '';
        const numArr = num.toString().split(/[.]/);
        let value = numArr[0];
        let decimal = numArr[1] ? '.' + numArr[1] : '';
        if (decimal) {
            decimal = GFunc.toFixedFloat(decimal);
            const dArr = decimal.toString().split(/[.]/);
            let dto = dArr[1];
            if (dto) {
                // 保存2位小数
                dto = fixed && dto.length === 1 && DataIns.checkint(dto) < 10 ? `${DataIns.checkint(dto)}0` : dto;
            }
            decimal = dto ? '.' + dto : '';
        }
        else if (fixed) {
            decimal = '.00';
        }
        value = String(Math.abs(Number(value)));
        while (value.length > 3) {
            result = ',' + value.slice(-3) + result;
            value = value.slice(0, value.length - 3);
        }
        if (value) {
            result = value + result;
        }
        return this.toThousandsForPtBrl(symbol + result + decimal);
    },

    // 转为巴西数字格式
    // 123456.78 => 123.456,78
    // 123,456.78 => 123.456,78
    toThousandsForPtBrl(num) {
        num = `${num}`.replace(/[.]/g, "@");
        num = num.replace(/[,]/g, ".");
        num = num.replace(/[@]/g, ",");
        return num;
    },

    /**
     * 货币转换，不四舍五入并保留两位小数，规则
     * <1000 按原始值
     * >=1000(千) 显示 K
     * >= 1000000(百万) 显示 M
     * >= 1000000000(十亿) 显示 B
     * @param {*} num       需转换的数值
     * @param {*} useCommas 是否显示千位符
     * @returns
     */
    formatNumber(num, useCommas = false) {
        num = DataIns.checknumber(num);
        // 处理正负号
        const sign = Math.sign(num) < 0 ? '-' : '';
        num = Math.abs(num);
        // 忽略四舍五入
        function toFixedWithoutRounding(value, decimals) {
            const factor = Math.pow(10, decimals);
            return Math.floor(value * factor) / factor;
        }
        // 增加千位符
        function formatWithCommas(value, decimals = 2) {
            return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: decimals });
        }

        // 如果需要千位符，使用 formatWithCommas，否则直接保留小数
        function formatValue(value, decimals = 2) {
            return useCommas ? formatWithCommas(value, decimals) : value.toFixed(decimals);
        }

        if (num < 1000) {
            return sign + formatValue(toFixedWithoutRounding(num, 2));
        } else if (num >= 1000 && num < 1000000) {
            return sign + formatValue(toFixedWithoutRounding(num / 1000, 2)) + 'K';
        } else if (num >= 1000000 && num < 1000000000) {
            return sign + formatValue(toFixedWithoutRounding(num / 1000000, 2), 3) + 'M';
        } else {
            return sign + formatValue(toFixedWithoutRounding(num / 1000000000, 2), 3) + 'B';
        }
    },

    // 查找数组中某个字符出现的次数
    findCharAmountIn(arr, char) {
        arr = DataIns.checkarray(arr);
        let index = arr.indexOf(char);
        let num = 0;
        while (index !== -1) {
            num++
            index = arr.indexOf(char, index + 1);
        }
        return num;
    },

    // 过滤掉非数字字符
    // /\D/g 是一个匹配非数字字符的正则表达式。
    // replace 函数用于将匹配到的非数字字符替换为空字符串，从而得到纯数字的字符串。
    filterDigits(inputString) {
        const digitsOnly = inputString.replace(/\D/g, '');
        return digitsOnly;
    },

    // window.location对象属性获取
    // 为解决iframe获取父级属性跨域问题
    getWinLocation(key) {
        let str = window["_location_"] && window["_location_"][key] || "";
        !str && (str = window.location[key]);
        return str;
    },

    // 获取Web地址参数值
    getQueryString(name) {
        let regex = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = this.getWinLocation("search").slice(1).match(regex);
        // console.error("regex:", regex, r)
        return r != null ? decodeURI(r[2]) : "";
    },

    // 获取真实的链接
    getRealLocationHref(datas, filter) {
        let href = this.getWinLocation("origin");
        // href = "https://xxx.com";
        let domains = [ // "xxx.com"
        ];
        if (domains.length > 0) {
            let nIdx = -1;  // 是否在列表中
            for (let i = 0; i < domains.length; i++) {
                let idx = href.indexOf(domains[i]);
                if (idx != -1) {
                    nIdx = i;
                    break;
                }
            }
            // console.error(nIdx)
            if (nIdx == -1) {
                let idx = Math.floor(Math.random() * domains.length);
                let domain = domains[idx];
                href = `https://${domain}`;
            }
        }
        let search = this.getWinLocation("search");
        let searchArr = search ? search.split(/[?&]/) : [];
        // console.error("searchArr:", searchArr)
        // 过滤
        if (searchArr.length > 0 && filter) {
            for (let idx = searchArr.length - 1; idx >= 0; idx--) {
                let sa = searchArr[idx];
                let r = sa.match(/(.*)=(.*)/);
                let key = DataIns.checkstring(r && decodeURI(r[1]));
                if (!key || filter.indexOf(key) != -1) {
                    searchArr.splice(idx, 1);
                }
            }
        }
        search = "";
        if (searchArr.length > 0 && datas) {
            // 参数去重
            for (let key in datas) {
                for (let idx = searchArr.length - 1; idx >= 0; idx--) {
                    let sa = searchArr[idx];
                    let r = sa.match(/(.*)=(.*)/);
                    // console.error("sa:", r);
                    if (r && decodeURI(r[1]) && decodeURI(r[1]) == key) {
                        searchArr.splice(idx, 1);
                    }
                }
            }
            search = searchArr.length > 0 ? "?" : "";
            if (searchArr.length > 0) {
                for (let idx = 0; idx < searchArr.length; idx++) {
                    let sa = searchArr[idx];
                    sa && (search += `${sa}&`);
                }
                search = search.slice(0, search.length - 1);
            }
        }
        if (href) {
            href += `/`;
        }
        if (search) {
            href += `${search}`;
        }
        return href;
    },

    // 获取拼接参数后的web地址
    getInviteLinkEx(datas, filter) {
        // let href = this.getWinLocation("href");
        let href = this.getRealLocationHref(datas, filter);
        if (datas && DataIns.checktable(datas)) {
            let str = "";
            for (let key in datas) {
                str += `${key}=${datas[key]}&`;
            }
            str = str.slice(0, str.length - 1);
            let idx = href.indexOf("?");
            href += `${idx == -1 ? '?' : '&'}${str}`;
        }
        // console.error("href:", href)
        return href;
    },

    // 检测是否显示下载按钮
    checkDownloadShow() {
        return GFunc.checkValidIosMobileConfig() || GFunc.checkValidInviteLink();
    },

    // 是否支持 iOS mobile config
    checkValidIosMobileConfig() {
        if (!SwitchModule.checkDownloadAppSwitch()) {
            return false;
        }
        if (!DeviceMgr.isIOSOrMac()) {
            return false;
        }
        if (!CfgAnalyze.getIosMobileConfigFilePath()) {
            return false;
        }
        return true;
    },

    // 是否支持 android invite link
    checkValidInviteLink() {
        let ignore = () => {
            let ignore = false;
            return ignore;
        }
        if (!SwitchModule.checkDownloadAppSwitch()) {
            return false;
        }
        if (!DeviceMgr.isAndroid() && !ignore()) {
            return false;
        }
        if (!CfgAnalyze.getInviteLink()) {
            return false;
        }
        return true;
    },

    // // 是否支持PC
    // checkPCEnv() {
    //     return SwitchModule.checkPCEnvSwitch() && DeviceMgr.isPCEnv();
    // },

    // // 检查设备注册账号是否达到上限
    // checkDeviceAccountLimit() {
    //     let limit = DeviceMgr.getDeviceAccountLimit();
    //     let limitCfg = CfgAnalyze.getDeviceAccountLimit();
    //     let bLimit = limitCfg && limit >= limitCfg;
    //     bLimit && rich.alert.show(`TID_ERROR_THE_SAME_DEVICE`);
    //     return bLimit;
    // },

    // // 获取PC右侧区域缩放系数
    // getPCScale(origWidth) {
    //     let uiProfileWidth = 245 * Consts.pcScaleBaseLeft;
    //     let uiLeftPullWidth = 20;
    //     let scale = 1;
    //     if (origWidth) {
    //         let spareWidth = cc.winSize.width - uiProfileWidth - uiLeftPullWidth * 2;
    //         if (spareWidth < origWidth) {
    //             scale = spareWidth / origWidth;
    //         }
    //     }
    //     return scale;
    // },

    // 解码API回调数据
    printDecodeApiResponse(resp) {
        let response = DataIns.checkstring(decode(resp));
        let rData = decode(response.slice(14));
        console.error('rData:', JSON.parse(rData));
    },

    // 解码API请求数据
    getDecodeApiRequest(req) {
        let request = req.slice(7);
        let rData = decode(request);
        return rData;
    },
    printDecodeApiRequest(req) {
        console.error('rData:', JSON.parse(this.getDecodeApiRequest(req)));
    },

    // 解码配置
    printDecodeCfg(cfg) {
        let rData = JSON.parse(decode(decode(cfg).slice(31)));
        console.error('rData:', rData);
    },

    // 打印日志
    log: function (...l) {
        !CfgAnalyze.isLogHide() && console.log("[log]" + (new Date().Format("yyyy/MM/dd hh:mm:ss")) + " ", ...l);
    },
};

const typeMap = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
};

export { GFunc };

// DEBUG
window.GFunc = GFunc;
