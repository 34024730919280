<template>
  <div class="vip">
    <PageHeader title="VIP" :back="onBack" />
    <div class="vip-info">
      <div class="vip-info-bg">
        <div class="vip-info-vipbg">
          <span class="vip-info-viplv">{{ UserCtrl.getVipLevel() }}</span>
        </div>
      </div>
      <span class="vip-info-desc">{{ t("TID_VIP_UPGRADE_NEXT_TITLE", {value1: `${nextVip?.level}`}) }}</span>
      <div class="vip-info-line"></div>
      <div class="vip-info-container">
        <div class="vip-info-container-item">
          <span class="vip-info-container-item-title">{{ t("TID_VIP_RECHARGE_AMOUNT_TITLE") }}</span>
          <br />
          <div class="vip-info-container-item-content">
            <span class="vip-info-container-item-content-left">₫{{ GFunc.formatNumber(UserCtrl.getTotalRecharge())
            }}</span>
            <span class="vip-info-container-item-content-right">{{
              GFunc.formatNumber(Math.min(nextVip?.totalrecharge, UserCtrl.getTotalRecharge())) +
              '/' + GFunc.formatNumber(nextVip?.totalrecharge)
            }}</span>
          </div>
          <a-progress :percent="UserCtrl.getTotalRecharge() / nextVip?.totalrecharge * 100" />
        </div>
        <div class="vip-info-container-line"></div>
        <div class="vip-info-container-item">
          <span class="vip-info-container-item-title">{{ t("TID_VIP_CODING_VOLUME_TITLE") }}</span>
          <br />
          <div class="vip-info-container-item-content">
            <span class="vip-info-container-item-content-left">₫{{ GFunc.formatNumber(UserCtrl.getPlaylMoney()) }}</span>
            <span class="vip-info-container-item-content-right">{{
              GFunc.formatNumber(Math.min(nextVip?.playmoney, UserCtrl.getPlaylMoney())) +
              '/' + GFunc.formatNumber(nextVip?.playmoney)
            }}</span>
          </div>
          <a-progress :percent="(UserCtrl.getPlaylMoney() / nextVip?.playmoney).toFixed(2) * 100" />
        </div>
      </div>
    </div>
    <div class="vip-list">
      <div class="vip-list-tip">{{ t("TID_VIP_RECOMPENSAS_TIP") }}</div>
      <div class="vip-list-title">
        <span style="font-size: 1.5rem;font-weight: bold;width: 30%;">VIP</span>
        <div style="width: 0.0625rem;height: 3.125rem;background-color: #8c8c8c;"></div>
        <span style="font-size: 1.5rem;font-weight: bold;width: 40%;">{{ t("TID_VIP_RECOMPENSAS") }}</span>
        <div style="width: 0.0625rem;height: 3.125rem;background-color: #8c8c8c;"></div>
        <span style="font-size: 1.5rem;font-weight: bold;width: 30%;"> </span>
      </div>
      <div class="vip-list-content" v-for="(item, k) in vipList" :key="k" :style="{
        backgroundColor: k % 2 === 0 ? '#000' : '#222627',
        color: item.level === UserCtrl.getVipLevel() ? '#e71e62' : '#fff'
      }">
        <span style="font-size: 1.5rem;font-weight: bold;width: 30%;">{{ item.level }}</span>
        <div style="width: 0.0625rem;height: 3.125rem;background-color: #8c8c8c;"></div>
        <span style="font-size: 1.5rem;font-weight: bold;width: 40%;">₫{{ GFunc.formatNumber(item.sendmoney) }}</span>
        <div style="width: 0.0625rem;height: 3.125rem;background-color: #8c8c8c;"></div>
        <div style="width: 30%;">
          <div v-if="UserCtrl.getVipLevel() >= item.level && VipCtrl.hadVipLvBonus(item.level)" :style="{
            color: '#7f7f7f'
          }">{{ t("TID_PROMOTE_AWARD_GET") }}</div>
          <div v-else :style="{
            backgroundColor: UserCtrl.getVipLevel() >= item.level ? '#e71e62' : '#383838',
            borderRadius: '.5rem',
            color: '#fff',
            padding: '.5rem 0',
            width: '70%',
            marginLeft: '15%'
          }" @click="UserCtrl.getVipLevel() >= item.level && getVipReward(item.level)">Receber</div>
        </div>
      </div>
    </div>
    <div class="vip-page">
      <div class="vip-page-item" v-for="(item, k) in vipList" :key="k">
        <div class="vip-page-item-container">
          <div class="vip-page-item-left">
            <span>{{ t("TID_VIP_LIST_WITHDRAWAL_PRIVILEGE_TITLE") }}</span>
            <span>{{ t("TID_VIP_LIST_WITHDRAW_MIN_AMOUNT", {value1: `${item.withdrawalcount}`}) }}</span>
            <span>{{ t("TID_VIP_LIST_WITHDRAWAL_AMOUNT_LIMIT", {value1: `₫${GFunc.formatNumber(item.maxdailywithdrawal)}`}) }}</span>
            <span>{{ t("TID_VIP_LIST_WITHDRAWAL_FEE", {value1: `${item.rate}`}) }}</span>
            <!-- <span>Numero de restantes: {{ item.withdrawalcount }}vezesfdia Valor unico de retirada on-line:
              {{ GFunc.formatNumber(item.maxwithdrawal) }}Taxa de retirada: {{ item.rate }}%</span>
            <br /> -->
            <!-- <span>Como aumentar o nivel de Vip:</span> -->
            <br />
            <span>{{ t("TID_VIP_LIST_UPGRADE_CONDITION_TITLE") }}</span>
          </div>
          <div class="vip-page-item-right">v{{ item.level }}</div>
        </div>
        <div class="vip-page-item-bottom">
          <div class="vip-page-item-bottom-item">
            <span>₫{{ GFunc.formatNumber(item.totalrecharge) }}</span>
            <span>{{ t("TID_VIP_RECHARGE_AMOUNT_TITLE") }}</span>
          </div>
          <div class="vip-page-item-bottom-item">
            <span>₫{{ GFunc.formatNumber(item.playmoney) }}</span>
            <span>{{ t("TID_VIP_CODING_VOLUME_TITLE") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vip-desc">
      <!-- <div>Primeiro deposito de ₫50-100 apos o registro para obter bonus de 20%, limitado a uma unica vez</div>
      <div>Deposito >= 100 para obter bnus de 8%, receba tres vezes ao dia</div> -->
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import PageHeader from "@/components/PageHeader";
import { VipCtrl } from "@/ctrl/VipCtrl";
import { UserCtrl } from "@/ctrl/UserCtrl";
import { GFunc } from "@/lib/GFunc"
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const vipList = ref([]);
const nextVip = computed(() => {
  let idx = vipList.value.findIndex(({ level }) => level === UserCtrl.getVipLevel());
  if (idx + 1 < vipList.value.length) {
    idx += 1;
  }
  return vipList.value[idx];
})

onMounted(async () => {
  VipCtrl.getVipDataByCache((data) => {
    vipList.value = data.list.filter(({ level }) => level > 0);
  })
});

const getVipReward = async (level) => {
  await VipCtrl.reqVipAward({ level });
  VipCtrl.updateVipLvBonus(level);
  message.success(t("TID_LB_CLAIM_SUCCESSFULLY") + ` ₫${GFunc.formatNumber(VipCtrl.getVipDataByLv(level).sendmoney)}`)
}

const onBack = () => {
  store.commit("goBack");
};
</script>

<style lang="scss" scoped>
.vip {
  @include flex(column, center, center);
  gap: 1rem;

  &-info {
    @include flex(column, center, center);
    max-width: 600px;
    width: 100%;
    background: linear-gradient(#ea804c, #ffb04b);

    &-bg {
      @include flex(column, center, center);
      background-image: url('@/assets/VIP/vip_bg1.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 34.9666666667rem;
      height: 7.8666666667rem;
      padding-top: 2rem;
    }

    &-vipbg {
      @include flex(column, center, center);
      background-image: url('@/assets/VIP/vip_bg.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 8.9333333333rem;
      height: 7.8666666667rem;
      position: relative;
      top: -.75rem;
    }

    &-viplv {
      font-size: 2.6875rem;
      color: #fff000;
      text-shadow: #e4463b 0.1875rem 0 0, #e4463b 0 0.1875rem 0, #e4463b -0.1875rem 0 0, #e4463b 0 -0.1875rem 0;
    }

    &-desc {
      font-size: 1.625rem;
    }

    &-line {
      background-color: #fff000;
      width: calc(100% - 2rem);
      height: 0.0625rem;
    }

    &-container {
      width: 100%;
      @include flex(row, center, center);
      gap: 1rem;
      box-sizing: border-box;
      padding: 0 1rem;

      &-item {
        @include flex(column, center, center);
        width: calc((100% - 0.0625rem) / 2);

        &-title {
          color: #a64c19;
          font-size: 1.1875rem;
        }

        &-content {
          @include flex(row, center, center);
          width: 100%;

          &-left {
            font-size: 1.5rem;
          }

          &-right {
            flex-grow: 1;
            text-align: right;
          }
        }
      }

      &-line {
        background-color: #fff000;
        width: 0.0625rem;
        height: 6.25rem;
      }
    }
  }

  &-list {
    @include flex(column, center, center);
    max-width: 600px;
    width: 100%;
    background-color: #000;
    padding-bottom: 1rem;

    &-tip {
      font-size: 1.625rem;
      font-weight: bold;
      width: 100%;
      box-sizing: border-box;
      padding: 1rem 1rem .2rem;
    }

    &-title {
      @include flex(row, center, center);
      width: 100%;
      text-align: center;
      background-color: #222627;
    }

    &-content {
      @include flex(row, center, center);
      width: 100%;
      text-align: center;
    }
  }

  &-page {
    @include flex(row, flex-start, center);
    box-sizing: border-box;
    padding: 0 1rem;
    gap: .5rem;
    max-width: 600px;
    width: 100%;
    overflow-x: auto; // Allow horizontal scrolling

    &-item {
      @include flex(column, center, center);
      width: 31.25rem;
      height: 16.875rem;
      background-color: #000;
      border-radius: .5rem;
      box-sizing: border-box;
      padding: 1rem 0;
      gap: .5rem;
      flex-shrink: 0;

      &-container {
        @include flex(row, space-between, center);
        box-sizing: border-box;
        padding: 0 1rem;
        gap: 1rem;
        width: 94%;
      }

      &-left {
        @include flex(column, center, flex-start);
        color: #fff;
      }

      &-right {
        color: #e71e62;
        font-size: 2rem;
        font-weight: bold;
      }

      &-bottom {
        @include flex(row, center, center);
        width: 100%;
        gap: 1rem;

        &-item {
          @include flex(column, center, center);
          background-color: #222627;
          border-radius: .5rem;
          width: calc(50% - 1.5rem);
          box-sizing: border-box;
          padding: 1rem 0;
        }
      }
    }
  }

  &-desc {
    color: #757575;
    box-sizing: border-box;
    padding: 0 1rem 2rem;
  }
}
</style>