<!-- 加载动画 -->
 <template>
  <div class="overlay">
    <div class="loading-splash">
      <img src="@/assets/common/loading.gif" alt="splash" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, onUnmounted } from "vue";

const toggleBodyScroll = (disableScroll) => {
  if (disableScroll) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
};

onMounted(() => {
  toggleBodyScroll(true);
});

onUnmounted(() => {
  toggleBodyScroll(false);
});
</script>
<style lang="scss" scoped>
.overlay {
  position: fixed; /* Fixed position ensures it covers the entire viewport */
  top: 0; /* Start from the very top of the page */
  left: 0; /* Start from the very left of the page */
  right: 0; /* Stretch to the right edge of the page */
  bottom: 0; /* Stretch to the bottom edge of the page */
  background-color: rgba(0, 0, 0, 0.44); /* Semi-transparent black background */
  z-index: 1000; /* High z-index to ensure it sits on top of other elements */
  display: flex; /* Enables you to center content within the overlay */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  .loading-splash {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    @include flex(row, center, center);
    background-color: #000;
    box-shadow: rgba(190, 190, 190, 0.35) 0px 5px 15px;
    img {
      object-fit: cover;
      width: 80%;
      height: auto;
    }
  }
}
</style>
