<template>
  <section class="change-avatar-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_AVATARS") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        class="back-btn"
        style="opacity: 0"
      />
    </div>

    <div class="change-avatar-inner">
      <div class="display-avatar-section">
        <img
          src="@/assets/profile/profile_1.png"
          alt="avatar"
          class="user-avatar"
          v-if="!userInfo.avatar_url && !selectedAvatar?.image_url"
        />

        <img
          v-if="!userInfo.avatar_url && selectedAvatar?.image_url"
          :src="selectedAvatar?.image_url"
          alt="avatar"
          class="user-avatar"
        />

        <div v-if="userInfo.avatar_url">
          <img
            :src="userInfo.avatar_url"
            alt="avatar"
            class="user-avatar"
            v-if="!selectedAvatar"
          />
          <img
            :src="selectedAvatar?.image_url"
            alt="avatar"
            class="user-avatar"
            v-else
          />
        </div>
      </div>

      <div class="avatar-list-section">
        <img
          v-for="(item, key) in dataList"
          :src="item.image_url"
          :key="key"
          @click="selectedAvatar = item"
          :class="{ 'avatar-selected': selectedAvatar?.id === item.id }"
        />
      </div>
    </div>

    <div class="save-btn-section">
      <div
        :class="`save-btn ${!selectedAvatar && 'save-btn-disabled'}`"
        @click="uploadAvatar"
      >
        {{ t("TID_LB_SAVE") }}
      </div>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import { message } from "ant-design-vue";

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const requestLoading = ref(false);
const dataList = ref([]);
const selectedAvatar = ref(null);

const userInfo = computed(() => store.state.userInfo);

const onBack = () => {
  store.commit("goBack");
};

const fetchData = async () => {
  try {
    requestLoading.value = true;

    const res = await reqApi.getAvatarList({
      language: locale.value,
      version: "3.6.2",
    });
    if (res) {
      dataList.value = res;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const uploadAvatar = async () => {
  if (selectedAvatar.value) {
    try {
      requestLoading.value = true;
      const res = await reqApi.saveAvatar({
        language: locale.value,
        id: selectedAvatar.value.id,
        version: "3.6.2",
      });
      if (res) {
        const userRes = await reqApi.getMemberProfile({
          language: locale.value,
          version: "3.6.2",
        });
        if (userRes) {
          store.commit("setUserInfo", userRes);
        }
        selectedAvatar.value = null;
        message.success(t("TID_LB_UPDATED"));
      }
    } catch (e) {
      console.log(e);
    } finally {
      requestLoading.value = false;
    }
  }
};

onMounted(() => {
  fetchData();
});
</script>

<style lang="scss">
.change-avatar-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    position: sticky;
    background-color: #000;
    top: 0;
    @include flex(row, space-between, center);
    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .change-avatar-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 430px;
    @include flex(column, center, center);
    .display-avatar-section {
      width: 100%;
      box-sizing: border-box;
      position: sticky;
      top: 51px;
      @include flex(row, center, center);
      padding-bottom: 2rem;
      background-color: #000;

      img {
        border-radius: 50%;
        overflow: hidden;
        width: 150px;
        height: auto;
      }
    }

    .avatar-selected {
      border: 4px solid #ffeb02 !important;
    }
    .avatar-list-section {
      @include flex(row, flex-start, center);
      flex-wrap: wrap;
      gap: 0.5rem;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem;
      padding-bottom: 10rem;
      &::-webkit-scrollbar {
        display: none;
      }
      /* Optional: Hide scrollbar for other browsers */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      img {
        flex: 0 0 calc((100% - 2 * 0.5rem) / 3);
        max-width: calc((100% - 2 * 0.5rem) / 3);
        cursor: pointer;
        border-radius: 10px;
        box-sizing: border-box;
        height: auto;
        border-radius: 50%;
        overflow: hidden;
        border: 4px solid #000;
      }
    }
  }
  .save-btn-section {
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    padding: 1rem 0;
    @include flex(row, center, center);
    background-color: #000;
    .save-btn {
      background-color: #ffeb02;
      font-weight: bold;
      color: #000;
      border-radius: 20px;
      width: 90%;
      padding: 0.8rem 0;
      max-width: 430px;
      @include flex(row, center, center);
      &-disabled {
        background-color: #9e9e9e;
        color: #fff;
      }
    }
  }
}
</style>
