/**
 * 网络协议映射配置
 */

import { CfgAnalyze } from "@/lib/CfgAnalyze";
import { DataIns } from "@/lib/DataIns";

/**
 * 协议头
 */
const ProtocolsHeaders = {
    "1": {
        "X-NONCE": "random",
        "X-TS": "timestamp",
        "X-UID": "playerid",
        "X-SIGN": "sign",
    },
    "2": {
        "X-TS": "tt",
        "X-UID": "id",
        "X-NONCE": "rand",
        "X-SIGN": "key",
    },
}

/**
 * 协议第一个路由
 */
const ProtocolsFirstMap = {
    "1": {
        "auth": "account/authorize",
        "user": "player/behavior",
        "wallte": "bank/finance",
        "vip": "member/upgradevip",
        "active": "daily/rewards",
    },
    "2": {
        "auth": "request/access",
        "user": "account/effect",
        "wallte": "purse/operate",
        "vip": "grade/rise",
        "active": "movable/distribute",
    },
}

/**
 * 协议最后一个路由
 */
const ProtocolsLastMap = {
    "1": {
        "login": "enter",
        "reg": "register",
        "getCustomer": "kefu",
        "userType": "AccountFormat",
        "game": "sporting",
        "gameNew": "sporting2",
        "banner": "carousel",
        "logout": "quit",
        "attr": "attributes",
        "getCollect": "getfocus",
        "setCollect": "setfocus",
        "index": "home",
        "setPass": "dopassword",
        "userInfo": "playerinfo",
        "team": "squad",
        "team2": "squadNew",
        "teamInfo": "squadInfo",
        "enterGame": "gotoGame",
        "enterGame2": "gotoNewGame",
        "betStats": "playStatus",
        "betDaily": "playDaily",
        "betRecord": "playOrder",
        "refresh": "uptoken",
        "regPullStats": "recruit",
        "getRegInviteAward": "recruitAward",
        "regPullRecord": "recruitOrder",
        "receive": "getReceive",
        "getShortToken": "littleName",
        "rechargeMoneyRecord": "paymentHistory",
        "mailboxAward": "envelope",
        "trade": "topay",
        "pix": "gopix",
        "withdrawal": "towithdraw",
        "withdrawal2": "towithdrawNew",
        "withdrawalOrder": "withdrawRecord",
        "balance": "remaining",
        "record": "records",
        "rechargeOrder": "payorder",
        "setWpass": "setWithdrawalPass",
        "record2": "recordNew",
        "exchange": "change",
        "conf": "config",
        "award": "bonus",
        "signinIndex": "task",
        "signinAward": "taskAward",
    },
    "2": {
        "login": "joins",
        "reg": "created",
        "getCustomer": "service",
        "userType": "checktype",
        "game": "palyinfo",
        "gameNew": "palyinfoToNew",
        "banner": "scroll",
        "logout": "out",
        "attr": "information",
        "getCollect": "picklist",
        "setCollect": "topick",
        "index": "homePage",
        "setPass": "addPwd",
        "userInfo": "Info",
        "team": "group",
        "team2": "newgroup",
        "teamInfo": "groupData",
        "enterGame": "enter",
        "enterGame2": "entertwo",
        "betStats": "state",
        "betDaily": "dailyPlay",
        "betRecord": "gameOrder",
        "refresh": "update",
        "regPullStats": "signUpStatus",
        "getRegInviteAward": "signUpBonus",
        "regPullRecord": "signOrder",
        "receive": "receiveBack",
        "getShortToken": "short",
        "rechargeMoneyRecord": "payAllRecord",
        "mailboxAward": "boxBonus",
        "trade": "thirdPay",
        "pix": "pixInfo",
        "withdrawal": "withdra",
        "withdrawal2": "getwithdra",
        "withdrawalOrder": "withdraRecord",
        "balance": "getBalance",
        "record": "recordList",
        "rechargeOrder": "tradeOrder",
        "setWpass": "addWithdrawalPass",
        "record2": "recordNews",
        "exchange": "conversion",
        "conf": "getConf",
        "award": "prize",
        "signinIndex": "mission",
        "signinAward": "missionAward",
    },
}

const NetProtocols = {
    /**
     * 获取协议头映射值
     * @param hKey 原始协议Key
     * @returns
     */
    getRealProtocolsHeaderKey(hKey) {
        let routeVer = CfgAnalyze.getApiRouteVer();
        let real = DataIns.checkstring(DataIns.checktable(ProtocolsHeaders[routeVer])[hKey]);
        return real || hKey;
    },

    /**
     * 获取协议第一个路由映射
     * @param pName 原始路由名称
     * @returns
     */
    getRealProtocolsFirstName(pName) {
        let routeVer = CfgAnalyze.getApiRouteVer();
        let real = DataIns.checkstring(DataIns.checktable(ProtocolsFirstMap[routeVer])[pName]);
        return real || pName;
    },

    /**
     * 获取协议最后一个路由映射
     * @param pName 原始路由名称
     * @returns
     */
    getRealProtocolsLastName(pName) {
        let routeVer = CfgAnalyze.getApiRouteVer();
        let real = DataIns.checkstring(DataIns.checktable(ProtocolsLastMap[routeVer])[pName]);
        return real || pName;
    },
}

export { NetProtocols };
