<template>
  <section class="change-password-container">
    <PageHeader :back="onBack" :title="changePwdType === 0 ? t('TID_LB_CHANGE_PASSWORD') : t('TID_LB_WITHDRAWAL_PWD_TITLE')" />

    <div class="change-password-inner">
      <a-form class="login-form" layout="vertical" style="width: 100%">
        <a-form-item name="password" v-if="!newsetPwd">
          <a-input :type="!passwordVisible ? 'password' : 'text'" v-model:value="password"
            :placeholder="t('TID_LB_CURRENT_PASSWORD_HINT')" class="inut">
            <!-- <template #prefix>
              <LockOutlined style="color: #8e8d8a; margin-right: 1rem" />
            </template> -->
            <template #suffix>
              <img src="@/assets/common/eye_off.png" style="width: auto;height: 30px;" v-if="!passwordVisible"
                @click="togglePasswordVisible" />
              <img src="@/assets/common/eye_on.png" style="width: auto;height: 30px;" v-else
                @click="togglePasswordVisible" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item name="new-password">
          <a-input :type="!newPasswordVisible ? 'password' : 'text'" v-model:value="newPassword"
            :placeholder="t('TID_INPUT_NEW_PWD_PLACEHOLDER')" class="inut">
            <!-- <template #prefix>
              <LockOutlined style="color: #8e8d8a; margin-right: 1rem" />
            </template> -->
            <template #suffix>
              <img src="@/assets/common/eye_off.png" style="width: auto;height: 30px;" v-if="!newPasswordVisible"
                @click="toggleNewPasswordVisible" />
              <img src="@/assets/common/eye_on.png" style="width: auto;height: 30px;" v-else
                @click="toggleNewPasswordVisible" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item name="confirm-password">
          <a-input :type="!confirmPasswordVisible ? 'password' : 'text'" v-model:value="confirmPassword"
            :placeholder="t('TID_INPUT_PWD_CONFIRM_HINT')" class="inut">
            <!-- <template #prefix>
              <LockOutlined style="color: #8e8d8a; margin-right: 1rem" />
            </template> -->
            <template #suffix>
              <img src="@/assets/common/eye_off.png" style="width: auto;height: 30px;" v-if="!confirmPasswordVisible"
                @click="toggleConfirmPasswordVisible" />
              <img src="@/assets/common/eye_on.png" style="width: auto;height: 30px;" v-else
                @click="toggleConfirmPasswordVisible" />
            </template>
          </a-input>
        </a-form-item>

        <a-button type="primary" class="change-password-btn" @click="onSubmit">
          {{ t("TID_LB_CHANGE") }}
        </a-button>
      </a-form>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
import { reqApi, netApi } from "@/api";
import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";
import { UserCtrl } from "@/ctrl/UserCtrl";

const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const store = useStore();

const changePwdType = ref(store.state.changePwdType);
const newsetPwd = computed(() => {
  return changePwdType.value === 1 && UserCtrl.getWalletPassword() === ''
})

const password = ref("");
const newPassword = ref("");
const confirmPassword = ref("");

const passwordVisible = ref(false);
const newPasswordVisible = ref(false);
const confirmPasswordVisible = ref(false);

const requestLoading = ref(false);

const togglePasswordVisible = () => {
  passwordVisible.value = !passwordVisible.value;
};

const toggleNewPasswordVisible = () => {
  newPasswordVisible.value = !newPasswordVisible.value;
};

const toggleConfirmPasswordVisible = () => {
  confirmPasswordVisible.value = !confirmPasswordVisible.value;
};

const onBack = () => {
  store.commit("goBack");
};

const onSubmit = async () => {
  if (
    // password.value === "" ||
    newPassword.value === "" ||
    confirmPassword.value === ""
  ) {
    message.error(t("TID_LB_PLEASE_FILL_IN_ALL_THE_FIELDS"));
    return;
  }

  if (newPassword.value !== confirmPassword.value) {
    message.error(t("TID_LB_NEW_PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH"));
    return;
  }

  try {
    if (changePwdType.value === 0) {
      requestLoading.value = true;
      const res = await reqApi.changePassword({
        confirm_password: confirmPassword.value,
        current_password: password.value,
        new_password: newPassword.value,
        language: locale.value,
        version: "3.6.2",
      });
      password.value = "";
      newPassword.value = "";
      confirmPassword.value = "";
      if (res) {
        message.success(t("TID_LB_PASSWORD_HAS_BEEN_CHANGED"));
      }
    }

    if (changePwdType.value === 1) {
      await netApi.setWidthrawalPwd({
        oldpassword: password.value,
        password: newPassword.value,
        nextpassword: confirmPassword.value,
      })
      message.success(t("TID_SET_PWD_SUCCESS"));
      onBack();
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};
</script>

<style lang="scss">
.change-password-container {
  @include flex(column, center, center);
  box-sizing: border-box;

  .change-password-inner {
    width: 100%;
    box-sizing: border-box;
    @include flex(column, center, center);
    padding: 2rem 1rem;
    max-width: 600px;

    .change-password-btn {
      background: linear-gradient(#ff2f75, #8e1541);
      width: 100%;
      padding: 1.8rem 0;
      font-weight: bold;
      border-radius: 5px;
      border-bottom: 1px solid #8e1541 !important;
      color: #fff !important;
      font-size: 1.5rem;
    }

    .inut {
      background-color: #383838;
      width: 100%;

      .ant-input {
        background-color: #383838;
        font-size: 1.5rem !important;
      }
    }
  }
}
</style>
