/**
 * 公告数据及逻辑模块
 */

import { netApi } from "@/api";
import { KGEvent } from "@/api/KGEvent";
import eventBus from "@/eventBus";
import { DataIns } from "@/lib/DataIns";
import { GFunc } from "@/lib/GFunc";

class _NoticeCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听
    noticeData = null;      // 轮播图及公告数据

    constructor() {
        GFunc.log("NoticeCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("NoticeCtrl initCtrl");
    }

    initModel() {
        GFunc.log("NoticeCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
    }

    // 初始化代理数据
    initData() {
        this.noticeData = null;
    }

    /**
     * 是否有公告数据
     * @param type
     * @returns
     */
    hasNoticeData(type) {
        if (!DataIns.checkint(type)) {
            // console.error("Check notice data error, no type!");
            return false;
        }
        if (!this.noticeData) {
            return false;
        }
        let cfg = DataIns.checkarray(DataIns.checktable(DataIns.checktable(this.noticeData).result)[type]);
        return cfg.length > 0;
    }

    /**
     * 获取活动公告数据，包括：轮播图及弹窗公告
     * @param type      类型，参考 ENoticeType
     * @param callFunc
     */
    getNoticeDataByCache(type, callFunc, failFunc) {
        if (!this.noticeData) {
            this.reqNoticeData(type, callFunc, failFunc);
        } else {
            this.getNoticeData(type, callFunc, failFunc);
        }
    }

    /* static */ async reqNoticeData(type, callFunc, failFunc) {
        let self = this;
        let reqParams = {};
        try {
            const data = await netApi.getActivityConf(reqParams);
            self.noticeData = data;
            self.getNoticeData(type, callFunc, failFunc);
        } catch (error) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    /* static */ getNoticeData(type, callFunc, failFunc) {
        if (!DataIns.checkint(type)) {
            // console.error("Get notice data error, no type!");
            failFunc && failFunc();
            return;
        }
        let cfg = DataIns.checkarray(DataIns.checktable(DataIns.checktable(this.noticeData).result)[type]);
        callFunc && callFunc(cfg);
        // rich.eventManager.sendEvent(KGEvent.EVENT_NOTICE_CONFIG_DATA_RECEIVE, {type, cfg});
        eventBus.emit(KGEvent.EVENT_NOTICE_CONFIG_DATA_RECEIVE, {type, cfg});
    }

}

const NoticeCtrl = new _NoticeCtrl_();

export { NoticeCtrl }

// DEBUG
window.NoticeCtrl = NoticeCtrl;
