<template>
  <div class="user-info-banner">
    <div class="top-section">
      <div class="menu">
        <img src="@/assets/common/btn_manu.png" @click="openMenu" />
      </div>
      <div class="logo">
        <img src="@/assets/common/app_icon.png" alt="logo" />
      </div>
      <div class="right">
        <div class="wallet" @click="updateUserInfo">
          <div class="wallet-inner">
            <div class="balance">
              <span v-if="userInfo">
                <span class="currency">₫</span>
                {{ GFunc.formatNumber(userInfo?.currentmoney) }}
              </span>

              <span v-else> <span class="currency">₫</span>0.00 </span>
            </div>

            <div style="width: 0;height: 0;">
              <div :style="{
                width: '3rem',
                height: '3rem',
                position: 'absolute',
                transform: 'translate(-12%, -50%)',
              }"></div>
            </div>
            <div v-if="!requestLoading">
              <img class="refresh" src="@/assets/common/btn_shua.png" alt="refresh"/>
            </div>
            <a-spin class="refresh" v-else style="color: #ffeb02" size="small" />
          </div>

        </div>

        <div class="deposit" @click="goTo('deposit-withdraw')">
          {{ t("TID_LB_DEPOSIT") }}
        </div>
      </div>
    </div>
  </div>

  <LeftMenu :open="menuOpen" :close="() => (menuOpen = false)" />
</template>
<script setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { netApi } from "@/api/index";
import { useStore } from "vuex";
import LeftMenu from "@/components/LeftMenu";
import { GFunc } from "@/lib/GFunc";

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const menuOpen = ref(false);
const requestLoading = ref(false);
const store = useStore();
const userInfo = computed(() => store.state.userInfo);

const goTo = (page) => {
  if (page === "deposit-withdraw") {
    store.commit("setDepositOrWithdraw", "deposit");
  }
  store.commit("setCurrentView", page);
};

const updateUserInfo = async () => {
  try {
    requestLoading.value = true;
    const resp = await netApi.getUserInfo()
    // console.log(resp)
    store.commit("setUserInfo", resp.player);
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const openMenu = () => {
  menuOpen.value = true;
};
</script>

<style lang="scss" scoped>
.user-info-banner {
  color: #fff;
  padding: 0.5rem 0.5rem;
  position: sticky;
  top: 0;
  /*3.5rem;*/
  z-index: 999;
  background-color: #121212;

  .top-section {
    @include flex(row, space-between, center);
    gap: .5rem;
    height: 2.8rem;

    @media (max-width: 600px) {
      height: 4.4rem;
    }

    .menu img {
      height: 1.7rem;
      display: flex;
      justify-content: center;

      @media (max-width: 600px) {
        height: 2.2rem;
      }
    }

    .logo {
      flex-grow: 1;
      float: left;

      img {
        height: 1.6rem;
        display: flex;
        justify-content: center;

        @media (max-width: 600px) {
          height: 1.9rem;
        }
      }
    }

    .right {
      @include flex(row, center, center);
      width: max-content;
      height: 2.25rem;
      gap: 1rem;
      font-size: 1.2rem;

      @media (max-width: 600px) {
        font-size: 1.625rem;
      }

      .wallet {
        @include flex(row, center, center);
        box-sizing: border-box;
        border: 0.125rem solid #e71e62;
        border-radius: 2rem;
        background: #500e25;
        cursor: pointer;
        padding: 0 1rem;

        @media (max-width: 600px) {
          height: 3.25rem;
        }

        &-inner {
          @include flex(row, center, center);
          gap: 0.5rem;

          .refresh {
            width: 1.2rem;
            margin: auto;
            vertical-align: middle;
          }
        }
      }

      .deposit {
        @include flex(row, center, center);
        box-sizing: border-box;
        color: #fff;
        border-radius: 2rem;
        font-weight: 700;
        background: #e71e62;
        cursor: pointer;
        padding: 0rem 1.5rem;

        @media (max-width: 600px) {
          height: 3.25rem;
        }
      }
    }
  }
}</style>
