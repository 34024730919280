/**
 * 状态管理 - 数据存储共享仓库
 * 不能直接修改 state 的值 或 直接调用 mutations 的处理函数
 * 如修改 apiBaseUrl 的值时，需使用 store.commit("setApiBaseUrl", "xxx")
 */

import { UserStore } from "@/lib/UserStore";
import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage, // localStorage | sessionStorage | localforage
  key: "u_cc_data"
});

const store = createStore({
  state: {
    currentView: "casino",
    previousView: "",
    token: "",
    loginMode: 0,
    userAccount: "",
    userPwd: "",
    localLastUser: "",
    userInfo: "",
    currentGame: "",
    depositOrWithdraw: "deposit",
    favouriteGames: [],
    selectedGameCategory: null,
    selectedGamePlatform: null,
    selectedProvider: null,
    affiliateInfo: "",
    appInfo: "",
    rememberMe: false,
    spinValue: 0,
    isAudioPlaying: false,
    audio: "",
    historyStack: [],
    // 浏览器默认语言
    language: window.navigator.language.split("-").shift(),
    prevApiBaseUrl: UserStore.getBaseApiUrl(),
    apiBaseUrl: UserStore.getBaseApiUrl(),
    pixtype: 0,
    rpCodesInfo: {},
    // ... other state properties
    rechargeCommissionConf: {},
    setCommissionRate: {},
    setPlatBaseConf: {},
    changePwdType: 0,
  },
  // store 存储，必须是同步函数，只处理同步事务。
  mutations: {
    setApiBaseUrl(state, newApi) {
      state.apiBaseUrl = newApi;
    },
    setPrevApiBaseUrl(state, newApi) {
      state.prevApiBaseUrl = newApi;
    },
    setCurrentView(state, newView) {
      if (state.currentView !== newView) {
        state.historyStack.push(state.currentView);
      }
      state.currentView = newView;
    },
    // Mutation to navigate back using the history stack
    goBack(state) {
      if (state.historyStack.length > 0) {
        state.currentView = state.historyStack.pop();
      }
    },
    // Optional mutation to clear the history stack if needed
    clearHistoryStack(state) {
      state.historyStack = [];
    },
    setPreviousView(state, newView) {
      state.previousView = newView;
    },
    setToken(state, newToken) {
      state.token = newToken;
    },
    setLoginMode(state, mode) {
      state.loginMode = mode;
    },
    setUserAccount(state, account) {
      state.userAccount = account;
    },
    setUserPwd(state, pwd) {
      state.userPwd = pwd;
    },
    setLocalLastUser(state, lastuser) {
      state.localLastUser = lastuser;
    },
    setUserInfo(state, newInfo) {
      state.userInfo = newInfo;
    },
    setCurrentGame(state, newGame) {
      state.currentGame = newGame;
    },
    setDepositOrWithdraw(state, newAction) {
      state.depositOrWithdraw = newAction;
    },
    setFavouriteGames(state, newGame) {
      state.favouriteGames = newGame;
    },
    setSelectedGameCategory(state, newCategory) {
      state.selectedGameCategory = newCategory;
    },
    setSelectedGamePlatform(state, newPlatform) {
      state.selectedGamePlatform = newPlatform;
    },
    setSelectedProvider(state, newProvider) {
      state.selectedProvider = newProvider;
    },
    setAffiliateInfo(state, newAffiliateInfo) {
      state.affiliateInfo = newAffiliateInfo;
    },
    setAppInfo(state, newAppInfo) {
      state.appInfo = newAppInfo;
    },
    setRememberMe(state, newRemember) {
      state.rememberMe = newRemember;
    },
    setSpinValue(state, newSpinValue) {
      state.spinValue = newSpinValue;
    },
    setAudioPlaying(state, newAudioPlaying) {
      state.isAudioPlaying = newAudioPlaying;
    },
    setAudio(state, newAudio) {
      state.audio = newAudio;
    },
    setLanguage(state, newLanguage) {
      state.language = newLanguage;
    },
    setPixType(state, type) {
      state.pixtype = type;
    },
    setRPCodes(state, rpCodes) {
      state.rpCodesInfo = rpCodes;
    },
    // ... other mutations
    setRechargeCommissionConf(state, rechargeCommissionConf) {
      state.rechargeCommissionConf = rechargeCommissionConf;
    },
    setCommissionRate(state, commissionRate) {
      state.commissionRate = commissionRate;
    },
    setPlatBaseConf(state, platBaseConf) {
      state.platBaseConf = platBaseConf;
    },
    setChangedPwdType(state, changePwdType) {
      state.changePwdType = changePwdType;
    },
  },
  getters: {
    // ... other getters
  },
  // 提交的是 mutation，而不是直接变更状态。可以包含任意异步操作。
  actions: {
    // ... other actions
  },
  plugins: [vuexLocal.plugin],
});

export default store;
