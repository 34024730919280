<template>
  <div class="login-register-container">
    <PageHeader :back="onBack" :title="selectedView === 'login' ? 'TID_LOGIN_TITLE' : 'TID_REGISTER_TITLE'" />
    <div class="login-register-inner">
      <a-form class="login-form" layout="vertical" :form="form" @submit="handleSubmit">
        <!-- 账号 -->
        <!-- <a-form-item :label="t('TID_INPUT_PHONE')" name="account"> -->
        <a-form-item name="account">
          <a-input type="text" :value="accountName" @input="handleAccountNameInput"
            :placeholder="t('TID_INPUT_USERNAME_PLACEHOLDER')">
            <template #suffix>
            </template>
          </a-input>
        </a-form-item>

        <!-- 手机号 -->
        <!-- <a-form-item :label="t('TID_INPUT_PHONE')" name="phone"> -->
        <a-form-item name="phone" v-if="selectedView === 'register'">
          <div :style="{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }">
            <a-dropdown>
              <div :style="{
                backgroundColor: '#383838',
                borderRadius: '6px',
                height: '3.125rem',
                color: '#fff',
                padding: '0 1rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                lineHeight: '3.125rem',
              }">{{ phoneCode }}</div>

              <template #overlay v-if="false">
                <a-menu style="backgroundColor: #383838;">
                  <a-menu-item v-for="(v, k) in phoneCodes" :key="k" @click="() => _phoneCode = k">
                    <a href="javascript:;" style="color: #fff;">{{ v }}</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <a-input type="text" :value="phoneNumber" @input="handlePhoneNumberInput"
              :placeholder="t('TID_INPUT_PHONE_NUMBER_HINT')" :maxlength="10">
              <template #prefix>
              </template>
            </a-input>
          </div>
        </a-form-item>

        <!-- 密码 -->
        <!-- <a-form-item :label="t('TID_INPUT_PWD')" name="password"> -->
        <a-form-item name="password">
          <a-input :type="!passwordVisible ? 'password' : 'text'" :value="password" @input="handlePasswordInput"
            placeholder="********">
            <template #suffix>
              <img src="@/assets/common/eye_off.png" style="width: auto; height: 1.875rem;" v-if="!passwordVisible"
                @click="togglePasswordVisible" />
              <img src="@/assets/common/eye_on.png" style="width: auto; height: 1.875rem;" v-else
                @click="togglePasswordVisible" />
            </template>
          </a-input>
        </a-form-item>

        <!-- 确认密码 -->
        <!-- <a-form-item :label="t('TID_INPUT_PWD_CONFIRM')" -->
        <a-form-item name="password" v-if="selectedView === 'register'">
          <a-input :type="!confirmPasswordVisible ? 'password' : 'text'" :value="confirmPassword"
            @input="(e) => (confirmPassword = e.target.value)" placeholder="********">
            <template #suffix>
              <img src="@/assets/common/eye_off.png" style="width: auto;height: 1.875rem;" v-if="!confirmPasswordVisible"
                @click="toggleConfirmPasswordVisible" />
              <img src="@/assets/common/eye_on.png" style="width: auto;height: 1.875rem;" v-else
                @click="toggleConfirmPasswordVisible" />
            </template>
          </a-input>
        </a-form-item>

        <!-- 邀请码 -->
        <a-form-item name="invite" v-if="selectedView === 'register'">
          <a-input type="text" :value="inviteCode" @input="handleInviteCodeInput"
            :placeholder="t('TID_INPUT_INVITE_CODE_PLACEHOLDER')">
            <template #suffix>
            </template>
          </a-input>
        </a-form-item>

        <!-- 记住我 -->
        <a-form-item v-if="selectedView === 'login'">
          <a-checkbox :autofocus="false" @change="rememberMe = !rememberMe" :checked="rememberMe">
            <span style="color: #ccc; font-size: 1.2rem;">{{ t("TID_RREMEMBER_ME") }}</span>
          </a-checkbox>
        </a-form-item>

        <!-- <a-form-item
          :label="t('TID_LB_REFERRAL_CODE') + ' (' + t('TID_LB_OPTIONAL') + ')'"
          v-if="selectedView === 'register'"
          style="width: 100%; margin-bottom: 3rem"
        >
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            :conditionalClass="['one', 'two', 'three', 'four', 'five']"
            separator=""
            inputType="letter-numeric"
            :num-inputs="5"
            @on-complete="handleOnComplete"
            v-model:value="referralCode"
          />
        </a-form-item> -->
        <a-form-item>
          <a-button type="primary" class="submit-btn" @click="handleSubmit" v-if="selectedView === 'login'">
            {{ t("TID_LOGIN_TITLE") }}
          </a-button>

          <a-button type="primary" class="submit-btn" @click="handleSubmit" v-else>
            {{ t("TID_REGISTER_BTN") }}
          </a-button>
        </a-form-item>
      </a-form>

      <div class="login-register-btns">
        <div style="width: 100%;" v-if="selectedView === 'register'">
          <p style="text-align: center; cursor: pointer; font-size: 1.2rem;" @click="selectedView = 'login'">{{
            t("TID_LOGIN_TITLE") }}</p>
        </div>
        <div style="width: 100%; display: flex;" v-else>
          <!-- <p
            style="width: 50%; text-align: left; cursor: pointer; font-size: 1.2rem;"
            @click="selectedView = 'login'"
            >{{ t("TID_LB_CHANGE_PASSWORD") }}</p> -->
          <p style="width: 100%; text-align: center; cursor: pointer; font-size: 1.2rem;"
            @click="selectedView = 'register'">{{ t("TID_REGISTER_TITLE") }}</p>
        </div>
      </div>
    </div>
  </div>

  <Loading v-if="requestLoading" />
</template>
<script setup>
import { ref, reactive, computed, toRefs, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { netApi } from "@/api";
import { useStore } from "vuex";
import UAParser from "ua-parser-js";
import { message } from "ant-design-vue";
// import VOtpInput from "vue3-otp-input";
import Loading from "@/components/Loading";
import { UserStore } from "@/lib/UserStore";
import PageHeader from "@/components/PageHeader";
import { ELoginType } from "@/lib/Consts";
import eventBus from "@/eventBus";
import { GFunc } from "@/lib/GFunc";
import { CfgAnalyze } from "@/lib/CfgAnalyze";

const store = useStore();

const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const route = useRoute();

const phoneCodes = ref({
  vn: '+84',
});
const _phoneCode = ref(store.state.language);
const phoneCode = computed(() => {
  return phoneCodes.value[_phoneCode.value];
});
if (!phoneCodes.value[_phoneCode.value]) {
  _phoneCode.value = 'vn';
}

const form = ref(null);
const requestLoading = ref(false);
const passwordVisible = ref(false);
const confirmPasswordVisible = ref(false);
const selectedView = ref("login");

const rememberMe = ref(store.state.rememberMe);
const formData = reactive({
  accountName: "",
  phoneNumber: "",
  password: "",
  confirmPassword: "",
  inviteCode: "",
});

const { accountName, phoneNumber, password, confirmPassword, inviteCode } = toRefs(formData);

accountName.value = selectedView.value === "login" && rememberMe.value ? store.state.userAccount : accountName.value;
password.value = selectedView.value === "login" && rememberMe.value ? store.state.userPwd : password.value;
watch(selectedView, () => {
  accountName.value = selectedView.value === "login" && rememberMe.value ? store.state.userAccount : "";
  password.value = selectedView.value === "login" && rememberMe.value ? store.state.userPwd : "";
})
// const otpInput = ref(null);
// const referralCode = ref("");

const apiBaseUrl = computed(() => store.state.apiBaseUrl);
// const prevApiBaseUrl = computed(() => store.state.prevApiBaseUrl);

const parser = new UAParser();
const result = parser.getResult();

const togglePasswordVisible = () => {
  passwordVisible.value = !passwordVisible.value;
};

const toggleConfirmPasswordVisible = () => {
  confirmPasswordVisible.value = !confirmPasswordVisible.value;
};

// const handleOnComplete = () => {
//   if (otpInput.value) {
//     const lastInput = otpInput.value.$el.querySelector(
//       ".otp-input.five.is-complete"
//     );
//     if (lastInput) {
//       lastInput.blur(); // This removes focus from the last input
//     }
//   }
// };

const doLogin = async () => {
  try {
    requestLoading.value = true;
    const res = await netApi.userLogin({
      username: accountName.value.toString(),
      password: password.value.toString()
    })

    if (res.token) {
      const {
        rechargeCommissionConf, // 充值返佣配置
        commissionRate,         // 代理佣金返佣比例
        platBaseConf,           //
        player,                 // 用戶信息
        token
      } = res;

      store.commit("setRememberMe", rememberMe.value);
      store.commit("setToken", token);
      store.commit("setUserInfo", player);
      store.commit("setRechargeCommissionConf", rechargeCommissionConf);
      store.commit("setCommissionRate", commissionRate);
      store.commit("setPlatBaseConf", platBaseConf);

      message.success(t("TID_LOGIN_SUCCESS"));
      store.commit("goBack");
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const doRegister = async () => {
  try {
    requestLoading.value = true;
    const res = await netApi.userRegister({
      username: accountName.value.toString(),
      password: password.value.toString(),
      confirmPassword: confirmPassword.value.toString(),
      uuid: inviteCode.value.toString(),
      avatar: GFunc.getRandAvatarIdx(),
      phone: _getRealPhone(true),
      language: locale.value,
      device_info: result.ua,
    });
    if (res) {
      message.success(t("TID_REGISTER_SUCCESS"));
      doLogin();
    }
  } catch (e) {
    /* */
  } finally {
    requestLoading.value = false;
  }
};

const handleSubmit = async (e) => {
  if (requestLoading.value) {
    return;
  }
  e.preventDefault();

  // On submit validation
  if (selectedView.value === "login") {
    // if (accountName.value === "" || password.value === "") {
    //   message.error(t("TID_INPUT_PHONE_PASSWORD_EMPTY"));
    //   return;
    // }
    if (accountName.value === "") {
      message.error(t("TID_NOT_INPUT_ACCOUNT_NAME"));
      return;
    }
    // 账号必需8-16位
    if (!GFunc.validUsernameLen(accountName.value)) {
      message.error(t("TID_ERROR_INPUT_ACCOUNT_NAME"));
      return;
    }
    // 账号字符类型不是英文或数字，提示对应字符
    let invalidArr = GFunc.getNonNumOrLetter(accountName.value);
    if (invalidArr.length > 0) {
      let invalidStr = GFunc.replaceSpaces(invalidArr.join(","));
      message.error(t("TID_ERROR_INPUT_ACCOUNT_NAME_UN_INCLUDE") + invalidStr);
      return;
    }
    if (password.value === "") {
      message.error(t("TID_NOT_INPUT_PWD"));
      return;
    }
    // 密码必需6-16位
    if (!GFunc.validPasswordLen(password.value)) {
      message.error(t("TID_ERROR_INPUT_PWD"));
      return;
    }
    // 密码字符类型不是英文或数字或指定特殊字符，提示对应字符
    invalidArr = GFunc.getNonNumOrLetter(password.value);
    if (invalidArr.length > 0) {
      let invalidStr = GFunc.replaceSpaces(invalidArr.join(","));
      message.error(t("TID_ERROR_INPUT_PWD_NAME_UN_INCLUDE") + invalidStr);
      return;
    }
  } else {
    if (accountName.value === "" || phoneNumber.value === "" ||
      password.value === "" || confirmPassword.value === "") {
      message.error(t("TID_REGISTER_INFO_INCOMPLETE"));
      return;
    }
    // 账号必需8-16位
    if (!GFunc.validUsernameLen(accountName.value)) {
      message.error(t("TID_ERROR_INPUT_ACCOUNT_NAME"));
      return;
    }
    // 账号字符类型不是英文或数字，提示对应字符
    let invalidArr = GFunc.getNonNumOrLetter(accountName.value);
    if (invalidArr.length > 0) {
      let invalidStr = GFunc.replaceSpaces(invalidArr.join(","));
      message.error(t("TID_ERROR_INPUT_ACCOUNT_NAME_UN_INCLUDE") + invalidStr);
      return;
    }
    // 密码必需6-16位
    if (!GFunc.validPasswordLen(password.value)) {
      message.error(t("TID_ERROR_INPUT_PWD"));
      return;
    }
    // 密码字符类型不是英文或数字或指定特殊字符，提示对应字符
    invalidArr = GFunc.getNonNumOrLetter(password.value);
    if (invalidArr.length > 0) {
      let invalidStr = GFunc.replaceSpaces(invalidArr.join(","));
      message.error(t("TID_ERROR_INPUT_PWD_NAME_UN_INCLUDE") + invalidStr);
      return;
    }
    // 手机号是否正确
    if (!GFunc.checkRealPhoneLen(_getRealPhone(false), _phoneCode.value)) {
      message.error(t("TID_NOT_INPUT_PHONE_RULE"));
      return;
    }
    // 密码不一致
    if (password.value !== confirmPassword.value) {
      message.error(t("TID_LB_PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH"));
      return;
    }
  }

  store.commit("setPrevApiBaseUrl", apiBaseUrl.value);
  store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());

  if (selectedView.value === "login") {
    doLogin();
  } else {
    doRegister();
  }
};

const handleAccountNameInput = (e) => {
  accountName.value = e.target.value;
};

const handlePhoneNumberInput = (e) => {
  // e.target.value = e.target.value.replace(/[^0-9+()]/g, "");
  phoneNumber.value = e.target.value;
};

const handlePasswordInput = (e) => {
  password.value = e.target.value;
};

const handleInviteCodeInput = (e) => {
  inviteCode.value = e.target.value;
};

const onBack = () => {
  store.commit("goBack");
};

onMounted(() => {
  // // Extract the referral code from the query parameters
  // referralCode.value = route.query.referral_code || "";
  eventBus.on("onLoginType", (loginType) => {
    selectedView.value = loginType === ELoginType.ELogin_Register ? "register" : "login";
  });
  // 获取邀请码
  let keyCode = CfgAnalyze.getKeyCodeKey();
  inviteCode.value = GFunc.getQueryString(keyCode);
});

watch(route, () => {
  // referralCode.value = route.query.referral_code || "";
});

// 获取真实手机号
const _getRealPhone = (bCode) => {
  let counterCode = bCode ? "84" : "";
  return GFunc.getRealPhone(`${counterCode}${phoneNumber.value.toString()}`);
};

</script>

<style lang="scss">
.login-register-container {
  box-sizing: border-box;
  @include flex(column, center, center);

  .login-register-inner {
    @include flex(column, center, center);
    width: 600px;
    box-sizing: border-box;
    padding: 1rem;

    .login-register-btns {
      width: 100%;
      @include flex(row, center, center);
      box-sizing: border-box;

      .tab-btn {
        width: 45%;
        padding: 1.2rem 0;
        font-size: 1rem;
        font-weight: bolder;
        border-radius: 1.5625rem;
        box-sizing: border-box;
      }
    }

    .login-form {
      color: #fff !important;
      width: 100%;
      box-sizing: border-box;

      .ant-form-item {
        margin: 1rem 0;
      }
    }

    .submit-btn {
      font-weight: bolder;
      width: 100%;
      padding: 2.0rem 0;
      font-size: 1.5rem;
    }

    .otp-input-container {
      width: 100%;
      @include flex(row, space-between, center);
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (max-width: 300px) {
      width: 300px;
    }
  }
}
</style>
