<template>
  <section class="transaction-history-container">
    <PageHeader :back="onBack" title="TID_LB_TRANSACTION_HISTORY" />

    <div class="transaction-history-inner">
      <section class="type-btn-section">
        <div :class="`btn ${selectedAction === 0 && 'btn-selected'}`" @click="handleActionChange(0)">
          {{ t("TID_LB_DEPOSIT") }}
        </div>
        <div :class="`btn ${selectedAction === 1 && 'btn-selected'}`" @click="handleActionChange(1)">
          {{ t("TID_WALLET_WITHDRAW_TITLE") }}
        </div>
        <div :class="`btn ${selectedAction === 2 && 'btn-selected'}`" @click="handleActionChange(2)">
          {{ t("TID_LB_ALL") }}
        </div>
      </section>

      <section class="transaction-list-section">
        <div :style="{
          backgroundColor: 'black',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
          padding: '.5rem 1.5rem',
          fontSize: '1.1875rem',
          boxSizing: 'border-box',
        }">
          <span style="width: calc(100% / 3);">{{ t("TID_LB_TIME") }}</span>
          <span style="width: calc(100% / 3);" v-if="selectedAction === 2">{{ t("TID_LB_TYPE") }}</span>
          <span style="width: calc(100% / 3);">{{ t("TID_LB_AMOUNT") }}</span>
          <span style="width: calc(100% / 3);" v-if="selectedAction !== 2">{{ t("TID_VIP_V2_STATE_TITLE") }}</span>
        </div>
        <div :style="{
          width: '100%',
          boxSizing: 'border-box',
          padding: '0 1.5rem',
        }">
          <div class="transaction-item" v-for="(transaction, key) in dataList" :key="key">
            <span style="width: calc(100% / 3);">{{ transaction.time.split(" ")[0] }}</span>
            <span style="width: calc(100% / 3);" v-if="selectedAction === 2">{{ t(TransactionTypes[transaction.type]) }}</span>
            <span style="width: calc(100% / 3);">{{ GFunc.formatNumber(transaction.money) }}</span>
            <span style="width: calc(100% / 3);" v-if="selectedAction !== 2">{{ transaction.status }}</span>
          </div>
        </div>

        <div ref="sentinel" class="sentinel">
          <a-spin v-if="fetchingMoreData" style="color: #ffeb02" size="medium" />
          <span v-else-if="!fetchingMoreData && dataList.length > 0">
            {{ t("TID_LB_LOAD_NO_DATA") }}</span>
        </div>
      </section>
    </div>
  </section>
  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { netApi } from "@/api";
import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";
import { ETransactionType, TransactionTypes } from "@/lib/Consts";
import { GFunc } from "@/lib/GFunc";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const depositOrWithdraw = computed(() => store.state.depositOrWithdraw)
const selectedAction = ref(0);

const dataList = ref([]);
const requestLoading = ref(false);

const sentinel = ref(null);

const fetchingMoreData = ref(false);

const lastId = ref(0);
const row = 30;

onMounted(() => {
  handleActionChange(Number(depositOrWithdraw.value !== 'deposit'))
})

const fetchData = async (pageNumber) => {
  try {
    if (pageNumber < 2) {
      requestLoading.value = true;
    } else {
      fetchingMoreData.value = true;
    }

    const list = await (async () => {
      const data = { lastId: lastId.value, row }
      const status = {
        0: t("TID_LB_WAIT"),
        1: t("TID_LB_SUCCESS"),
        3: t("TID_LB_FAILURE"),
      }
      if (selectedAction.value === 0) {
        // 充值订单: 0 等待支付 1 支付成功 3 支付失败
        return (await netApi.getRecordOrder(data)).list.map(item => {
          return {
            ...item,
            status: status[item.status]
          }
        })
      }

      if (selectedAction.value === 1) {
        // 提现订单: 0 待审核 1 审核通过 3 拒绝提现  5 提现中 7 提现成功 9 提现失败
        return (await netApi.getRecordWithdrawalOrder(data)).list.map(item => {
          let idx = 0;
          if ([0, 1, 5].includes(item.status)) {
            idx = 0;
          }
          if ([7].includes(item.status)) {
            idx = 1;
          }
          if ([3, 9].includes(item.status)) {
            idx = 3;
          }
          return {
            ...item,
            status: status[idx]
          }
        })
      }

      if (selectedAction.value === 2) {
        // 资金记录
        return (await netApi.getRecordBonus(data)).list.filter(({ type }) => {
          // 过滤人工扣除
          return type !== ETransactionType.ETra_ManualDeduction;
        }).map(info => {
          return { ...info, time: info.create_time }
        })
      }
    })();

    console.log(list)
    lastId.value = list[list.length - 1].id;
    dataList.value = [...dataList.value, ...list];
  } catch (e) {
    console.error(e);
  } finally {
    requestLoading.value = false;
    fetchingMoreData.value = false;
  }
};

const onBack = () => {
  store.commit("goBack");
};

const handleActionChange = (action) => {
  dataList.value = [];
  selectedAction.value = action;
  lastId.value = 0;
  fetchData(1);
};
</script>

<style lang="scss">
.transaction-history-container {
  @include flex(column, center, center);
  box-sizing: border-box;

  .transaction-history-inner {
    @include flex(column, center, center);
    width: 100%;
    box-sizing: border-box;
    padding-top: 1rem;
    gap: 1rem;
    max-width: 600px;

    .type-btn-section {
      box-sizing: border-box;
      @include flex(row, flex-start, center);
      justify-content: center;
      text-align: center;
      gap: 1rem;

      .btn {
        background-color: #545454;
        color: #fff;
        border-radius: 3.125rem;
        width: 10.3125rem;
        height: 3.125rem;
        cursor: pointer;
        font-size: 1.625rem;
        line-height: 3.125rem;

        &-selected {
          background-color: #e71e62;
        }
      }
    }

    .date-picker-section {
      width: 100%;
      box-sizing: border-box;
    }

    .transaction-list-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(column, center, center);

      .transaction-item {
        width: 100%;
        box-sizing: border-box;
        min-height: 40px;
        @include flex(row, flex-start, center);
        gap: 1rem;
        font-size: 1.125rem;
        text-align: center;
        border-bottom: 1px solid #8c8c8c;
      }
    }
  }
}
</style>
