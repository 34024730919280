/**
 * 设备相关接口
 */


// 以下是一些常见浏览器版本号的正则表达式，可以根据需要添加其他浏览器的正则表达式
var regexList = {
    "Chrome": /(?:Chrome|CriOS)\/([\d.]+)/,
    "Firefox": /Firefox\/([\d.]+)/,
    "Edge": /Edg(?:e|A|iOS)\/([\d.]+)/,
    "IE": /MSIE ([\d.]+)|Trident\/.*; rv:([\d.]+)/,
    "Safari": /Version\/([\d.]+).*Safari/,
    "Opera": /(?:Opera|Opr)\/.*?Version\/([\d.]+).*?$/,
    "UC": /ucbrowser\/?([\d.]+)/,
};

const DeviceMgr = {
    // 操作系统
    osType: "",
    // 操作系统版本号
    osVersion: "",
    // 设备型号
    deviceModel: "",
    // 设备版本号
    deviceVersion: "",
    // 备浏览器型号
    browserModel: "",
    // 设备浏览器版本号
    browserVersion: "",

    initModel() {
        this.getOSModel();
        this.getOSVersion();
        this.getDeviceModel();
        this.getDeviceVersion();
        this.getBrowserModel();
        this.getBrowserVersion();
    },

    // 获取设备信息
    getDeviceInfo() {
        return {
            om: this.osType,
            ov: this.osVersion,
            dm: this.deviceModel,
            dv: this.deviceVersion,
            bm: this.browserModel,
            bv: this.browserVersion,
            app: /*MaytooSdk.hasMaytooSdk() ? 1 :*/ 0
        };
    },

    // 获取操作系统类型
    getOSModel() {
        let getOSType = () => {
            const userAgent = navigator.userAgent;
            if (userAgent.indexOf('Win') !== -1) return 'Windows';
            if (userAgent.indexOf('Mac') !== -1) return 'MacOS';
            if (userAgent.indexOf('Linux') !== -1) return 'Linux';
            if (userAgent.indexOf('Android') !== -1) return 'Android';
            if (userAgent.indexOf('like Mac') !== -1) return 'iOS';
            return 'Unknown OS';
        }
        this.osType = typeof window !== 'undefined' ? getOSType() : "Unknown";
        // console.log("Device System: " + this.osType);
    },

    // 获取操作系统版本号的方法
    getOSVersion() {
        this.osVersion = "Unknown";
        // if (typeof window !== 'undefined') {
        //     // 如果是浏览器平台，获取操作系统版本号
        //     this.osVersion = cc.sys.osVersion;
        // }

        // // 打印操作系统版本号
        // // console.log("OS Version: " + this.osVersion);
    },

    // 获取设备型号的方法
    // 该方法在Web平台上可能无法获取准确的设备型号，因为navigator.userAgent可能会返回不同的设备信息格式。
    // 在原生平台上，需要相应的原生代码来获取设备信息。
    getDeviceModel2() {
        this.deviceModel = "Unknown";
        if (typeof window !== 'undefined') {
            // 如果是Web平台，使用navigator.userAgent来获取设备信息
            let userAgent = navigator.userAgent;
            // 正则表达式匹配设备型号信息
            // eslint-disable-next-line no-useless-escape
            let modelRegex = /(?:iPhone|iPad|iPod);?[\s\/]+([\w\s]+)/i;
            let match = userAgent.match(modelRegex);
            if (match) {
                this.deviceModel = match[1];
            } else {
                this.deviceModel = "Unknown";
            }
        }

        // 打印设备型号
        // console.log("Device Model: " + this.deviceModel);
    },
    getDeviceModel() {
        this.deviceModel = "Unknown";
        if (typeof window !== 'undefined') {
            // 如果是Web平台，使用navigator.userAgent来获取设备信息
            let userAgent = navigator.userAgent;
            // 正则表达式匹配设备型号信息
            if (/Android|Adr|Linux/i.test(userAgent)) {
                this.deviceModel = "Android";
            } else if (/iPhone/i.test(userAgent)) {
                this.deviceModel = "iPhone";
            } else if (/iPod/i.test(userAgent)) {
                this.deviceModel = "iPod";
            } else if (/iPad/i.test(userAgent)) {
                this.deviceModel = "iPad";
            } else if (/iOS/i.test(userAgent)) {
                this.deviceModel = "iOS";
            } else if (/Mac/i.test(userAgent)) {
                this.deviceModel = "Mac";
            }
        }

        // 打印设备型号
        // console.log("Device Model: " + this.deviceModel);
    },

    // 获取设备版本号
    getDeviceVersion() {
        this.deviceVersion = "Unknown";
        if (typeof window !== 'undefined') {
            // 如果是Web平台，使用navigator.userAgent来获取设备信息
            let userAgent = navigator.userAgent;
            // 正则表达式匹配设备型版本号
            let versionRegex = /(Android|iPhone|iPad|iPod).*?([\d.]+)/i;
            let match = userAgent.match(versionRegex);
            if (match) {
                this.deviceVersion = match[2];
            } else {
                this.deviceVersion = "Unknown";
            }
        }

        // 打印设备版本号
        // console.log("Device Model: " + this.deviceVersion);
    },

    // 获取设备浏览器型号
    // 该方法只适用于浏览器平台，不能在原生平台上获取设备浏览器型号。
    // 如果需要在原生平台上获取设备浏览器型号，需要根据相应平台的API进行调用。
    getBrowserModel() {
        this.browserModel = "Unknown";
        if (typeof window !== 'undefined') {
            // 如果是浏览器平台，使用navigator.userAgent来获取设备信息
            let userAgent = navigator.userAgent;

            // 正则表达式匹配设备浏览器型号信息
            for (const browser in regexList) {
                const match = userAgent.match(regexList[browser]);
                if (match) {
                    this.browserModel = browser;
                    break;
                }
            }
        }

        // 打印设备浏览器型号
        // console.log("Browser Model: " + this.browserModel);
        return this.browserModel;
    },

    // 检测是否为 Safari 浏览器
    isSafariBrowser() {
        return this.browserModel == "Safari";
    },

    // 获取设备浏览器版本号的方法
    // 方法只适用于浏览器平台，不能在原生平台上获取设备浏览器版本号。
    // 如果需要在原生平台上获取设备浏览器版本号，需要根据相应平台的 API 进行调用。
    getBrowserVersion() {
        this.browserVersion = "Unknown";
        if (typeof window !== 'undefined') {
            // 如果是浏览器平台，使用navigator.userAgent来获取设备信息
            const userAgent = navigator.userAgent;

            // 正则表达式匹配设备浏览器版本号信息
            for (const browser in regexList) {
                const match = userAgent.match(regexList[browser]);
                if (match) {
                    this.browserVersion = match[1];
                    break;
                }
            }
        }
        // console.log("Browser Version: " + this.browserVersion);
        return this.browserVersion;
    },

    // // 生成仿设备唯一识别码
    // getDeviceId() {
    //     let uuid = cc.sys.localStorage.getItem("lg_deviceId");
    //     if (!uuid) {
    //         let rs = `${GFunc.getRandStr(7)}-${DateEx.getCurDateTime()}-${GFunc.getRandStr(8)}`;
    //         uuid = rich.md5.encode(rs).toLocaleUpperCase();
    //         cc.sys.localStorage.setItem("lg_deviceId", uuid);
    //     }
    //     uuid && (uuid = uuid.slice(0, 32));
    //     return uuid;
    // },

    // // 获取设备注册账号数量
    // getDeviceAccountLimit() {
    //     let uuid = cc.sys.localStorage.getItem("lg_deviceId");
    //     let limit = !uuid ? 0 : (DataIns.checkint(uuid.slice(32)) || 1);
    //     return DataIns.checkint(limit);
    // },

    // // 增加设备注册账号数量
    // appendDeviceAccountLimit() {
    //     let limit = DeviceMgr.getInstance().getDeviceAccountLimit() + 1;
    //     let uuid = this.getDeviceId() + limit;
    //     cc.sys.localStorage.setItem("lg_deviceId", uuid);
    // },

    /******************************************************/
    // 是否移动端
    isMobile() {
        let ua = navigator.userAgent;
        let flag = ua.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        return flag;
    },

    // 是否PC端，尽量不用此方法
    // 注意：这里获取的是平台的运行环境，PC浏览器调试时，不管是否开启设备工具，返回的均为PC环境
    isPCPlatform() {
        let pf = navigator.platform;
        let flag = pf.match(/(Win32|Win64|MacPPC|MacIntel|X11|Linux i686)/i);
        return flag;
    },

    // 是否PC环境，根据屏幕尺寸判断
    isPCEnv() {
        // let screenWidth = cc.winSize.width;
        // return screenWidth > 830 && !this.isMobile();
        return false;
    },

    // 是否安卓机型
    isAndroid() {
        let ua = navigator.userAgent;
        let isAndroid = /Android|Adr|Linux/i.test(ua);
        return isAndroid;
    },

    isAndroid2() {
        let ua = navigator.userAgent;
        let isAndroid = /(Android)/i.test(ua) ? true : false;
        return isAndroid;
    },

    // 是否ios机型
    isIOS() {
        let ua = navigator.userAgent;
        let isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        return isIOS;
    },

    isIOS2() {
        let ua = navigator.userAgent;
        let isIOS = /(iPhone|iPad|iPod|iOS)/i.test(ua) ? true : false;
        return isIOS;
    },

    isIOSOrMac() {
        const ua = navigator.userAgent.toLowerCase();
        // const iosRegex = /\((iPhone|iPad|iPod);[\s\w]+(OS|CPU)\s([\d_]+)\)/i;
        const iosRegex = /\(i[^;]+;( U;)? CPU.+Mac OS X/i;
        const macRegex = /\(Macintosh;[\s\w]+(Mac OS X)\s([\d_.]+)\)/i;
        return iosRegex.test(ua) || macRegex.test(ua);
    },

    // 是否微信浏览器
    isWeixinBrowser() {
        let ua = navigator.userAgent.toLowerCase();
        let isWeixin = /micromessenger/.test(ua) ? true : false;
        return isWeixin;
    },

    // 是否支付宝浏览器
    isAliBrowser() {
        let ua = navigator.userAgent;
        let isAli = /AlipayClient/.test(ua) ? true : false;
        return isAli;
    },
}

export { DeviceMgr }
