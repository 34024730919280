/**
 * 国际化
 */

import store from "@/store";
import { createI18n } from "vue-i18n";
import en from "./en.json"
import pt from "./pt.json"
import vn from "./vn.json"

/**
 * 国际化实例，组合语言包对象
 * legacy: 在创建实例时，一定要将 legacy 属性设置为 false，
 *         因为 legacy 默认为 true，它支持使用 Vue2 框架中的 Options API，
 *         如果要在 Vue3 更好的使用 Composition API 模式，那么就需要将此属性手动设置为 false。
 */
const i18n = createI18n({
  // Use createI18n
  legacy: false, // You must specify that you're not using the legacy API
  locale: store.state.language, // 设置默认语言
  fallbackLocale: "en",
  messages: {
    en,
    pt,
    vn,
  },
});

// 监听语言变化
// 1、选择要监听的数据
// 2、数据改变后的回调监听
store.watch(
  (state) => state.language,
  (newLanguage) => {
    i18n.global.locale.value = newLanguage;
  }
);

export default i18n;
