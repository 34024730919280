<template>
  <section class="downline-list-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_DOWNLINE") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
        style="opacity: 0"
      />
      <span class="deposit-history" @click="goTo('deposit-history')">
        {{ t("TID_LB_DEPOSIT_HISTORY") }}
      </span>
    </div>

    <div class="downline-list-inner">
      <div class="downline-list-inner-top">
        <section class="tier-section">
          <span>{{ t("TID_LB_DOWNLINE_TIER") }}</span>
          <QuestionCircleOutlined
            style="color: #ff8800; font-size: 1.2rem"
            @click="showInfoModal = true"
          />
        </section>

        <section class="tier-list">
          <div class="tier-item">
            <span class="tier">
              {{ t("TID_LB_TIER_1") }}
              <span class="value">{{ " (" + firstLayerTotal + ")" }}</span>
            </span>
            <span class="amount">{{ "₫ " + firstLayerBonus }}</span>
          </div>
          <div class="tier-item">
            <span class="tier">
              {{ t("TID_LB_TIER_2") }}
              <span class="value">{{ " (" + secondLayerTotal + ")" }}</span>
            </span>
            <span class="amount">{{ "₫ " + secondLayerBonus }}</span>
          </div>
          <div class="tier-item">
            <span class="tier">
              {{ t("TID_LB_TIER_3") }}
              <span class="value">{{ " (" + thirdLayerTotal + ")" }}</span>
            </span>
            <span class="amount">{{ "₫ " + thirdLayerBonus }}</span>
          </div>
        </section>

        <section class="input-section">
          <a-range-picker
            v-model:value="selectedDate"
            :default-picker-value="[defaultStartDate, defaultEndDate]"
            format="MMM DD"
            style="width: 60%"
          />
          <div class="tier-input" @click="tierSelectionOpen = true">
            <div>
              <FilterOutlined style="color: #ffeb02; margin-right: 8px" />
              <span>{{ "Tier" + " " + selectedTier }}</span>
            </div>
            <DownOutlined style="font-size: 12px" />
          </div>
        </section>
      </div>
      <section class="downline-list-section" v-if="!requestLoading">
        <div
          class="downline-list-item"
          v-for="(item, key) in dataList"
          :key="key"
        >
          <div class="downline-list-item-cell">
            <span class="tier-value">
              {{ "T" + selectedTier + " " }}
              <span style="color: #fff; font-weight: 300">{{ item.member_code }}</span>
            </span>
          </div>

          <div class="downline-list-item-cell">
            <span>{{ t("TID_LB_TOTAL") + " " + item.commission + "%" }}</span>
            <span>{{ "₫ " + item.total_valid_bet }}</span>
          </div>

          <div class="downline-list-item-cell">
            <span>{{ t("TID_LB_EARNED") }}</span>
            <span style="color: #ffeb02">{{ "₫ " + item.commission }}</span>
          </div>
        </div>
      </section>

      <section class="skeletons" v-else>
        <div class="skeletons-item" v-for="(i, key) in 10" :key="key"></div>
      </section>

      <section
        class="no-results-section"
        v-if="dataList.length <= 0 && !requestLoading"
        style="margin-top: 3rem"
      >
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <section class="pagination-section" v-if="!requestLoading">
    <a-pagination
      v-model:current="currentPage"
      :defaultCurrent="1"
      :pageSize="perPage"
      :total="totalItems"
    />
  </section>

  <a-drawer
    placement="bottom"
    :closable="false"
    :open="tierSelectionOpen"
    @close="tierSelectionOpen = false"
    height="230"
    style="
      width: 100vw;
      background-color: #252525;
      border-radius: 15px 15px 0 0;
    "
  >
    <div class="tier-selection">
      <div class="tier-selection-item" @click="handleSelectTier(1)">Tier 1</div>
      <div class="tier-selection-item" @click="handleSelectTier(2)">Tier 2</div>
      <div class="tier-selection-item" @click="handleSelectTier(3)">Tier 3</div>
    </div>
  </a-drawer>

  <Loading v-if="requestLoading" />

  <a-modal
    :open="showInfoModal"
    centered
    :closable="false"
    :maskClosable="true"
    class="downline-modal"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        display: 'none',
      },
    }"
  >
    <div class="info-modal-content">
      <img :src="bannerImg" alt="info" class="info-modal-bg" />
      <img
        src="@/assets/common/close.png"
        alt="close"
        class="info-modal-close-icon"
        @click="showInfoModal = false"
      />
    </div>
  </a-modal>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import {
  QuestionCircleOutlined,
  FilterOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

//img from api
const defaultStartDate = dayjs().startOf("year");
const defaultEndDate = dayjs().endOf("year");
const selectedDate = ref([defaultStartDate, defaultEndDate]);

const requestLoading = ref(false);

const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const perPage = ref(0);
const totalItems = ref(0);

const selectedTier = ref(1);

const firstLayerBonus = ref(0);
const secondLayerBonus = ref(0);
const thirdLayerBonus = ref(0);
const firstLayerTotal = ref(0);
const secondLayerTotal = ref(0);
const thirdLayerTotal = ref(0);

const bannerImg = ref("");

const tierSelectionOpen = ref(false);
const showInfoModal = ref(false);

const goTo = (page) => {
  store.commit("setCurrentView", page);
};

const handleSelectTier = (tier) => {
  selectedTier.value = tier;
  tierSelectionOpen.value = false;
};

const onBack = () => {
  store.commit("goBack");
};

const fetchData = async () => {
  try {
    requestLoading.value = true;

    const res = await reqApi.getAllAgentRewardList({
      language: locale.value,
      page: currentPage.value,
      layer: selectedTier.value,
      start_date: selectedDate.value[0].format("YYYY-MM-DD"),
      end_date: selectedDate.value[1].format("YYYY-MM-DD"),
      version: "3.6.2",
    });
    if (res) {
      dataList.value = res.agent_reward.data;
      currentPage.value = res.agent_reward.current_page;
      lastPage.value = res.agent_reward.last_page;
      perPage.value = res.agent_reward.per_page;
      totalItems.value = res.agent_reward.total;

      firstLayerBonus.value = res.first_layer_bonus;
      secondLayerBonus.value = res.second_layer_bonus;
      thirdLayerBonus.value = res.third_layer_bonus;

      firstLayerTotal.value = res.first_layer_total;
      secondLayerTotal.value = res.second_layer_total;
      thirdLayerTotal.value = res.third_layer_total;

      bannerImg.value = res.image;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

watch(selectedDate, () => {
  fetchData();
});

watch(selectedTier, () => {
  fetchData();
});

onMounted(() => {
  fetchData();
});
</script>
<style lang="scss">
.downline-list-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    position: sticky;
    top: 0;
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    background-color: #000;
    @include flex(row, space-between, center);

    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .deposit-history {
      text-align: right;
      font-size: 0.8rem;
      width: 80px;
      height: auto;
      padding-right: 0.5rem;
      color: #ffeb02;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .downline-list-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 430px;
    padding-bottom: 5rem;
    &-top {
      position: sticky;
      top: 67px;
      background-color: #000;
      @media (max-width: 600px) {
        padding: 0 0.5rem;
      }
    }
    .tier-section {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, center);
      @media (max-width: 600px) {
        padding: 0 0.5rem;
      }
    }
    .tier-list {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #463f04;
      border-radius: 10px;
      @include flex(row, space-between, center);
      margin: 1rem 0;
      .tier-item {
        flex: 0 0 33%;
        border-right: 1px solid #463f04;
        @include flex(column, center, center);
        padding: 1rem 0;

        .tier {
          color: #efb677;
        }
        .value {
          color: #fff;
          font-size: 0.8rem;
          font-weight: bold;
        }
        .amount {
          color: #ffeb02;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }
    .tier-list .tier-item:last-child {
      border-right: none;
    }

    .input-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(row, space-between, stretch);
      .tier-input {
        border: 1px solid #463f04;
        width: 35%;
        @include flex(row, space-around, center);
        border-radius: 6px;
        cursor: pointer;
      }
      .tier-input:hover {
        border: 1px solid #ffeb02;
      }
    }

    .downline-list-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(column, center, center);
    }
    .downline-list-item {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, stretch);
      height: 80px;
      border-bottom: 1px solid #252525;
      padding: 1rem 0 1rem 0;
      &-cell {
        width: 33%;
        text-align: center;
        font-size: 0.8rem;
        @include flex(column, center, center);
      }
      .tier-value {
        font-weight: bold;
        color: #ffeb02;
      }
    }
  }
}
.downline-modal {
  .ant-modal-content {
    background: transparent !important;
  }
}
.info-modal-content {
  width: 100%;
  height: 500px;
  @include flex(column, center, center);
  gap: 1rem;
  .info-modal-bg {
    width: auto;
    height: 40vh;
  }
  .info-modal-close-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.tier-selection {
  height: 100%;
  @include flex(column, space-between, center);
  font-family: "Poppins", sans-serif !important;
  &-item {
    height: 50px;
    box-sizing: border-box;
    width: 100%;
    @include flex(row, center, center);
    font-size: 1.2rem;
    font-weight: bold;
    background-color: #ffeb02;
    border-radius: 10px;
    border: 2px solid #ff8800;
    color: #000;
    cursor: pointer;
  }
}
</style>
