<template>
  <div :style="{
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999',
    pointerEvents: 'all',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }">
    <div @click="open" :style="{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }">
      <div style="width: 0;height: 0;">
        <img src="@/assets/common/close2.png" alt="" @click.stop="props.next()" :style="{
          width: '3.2666666667rem',
          position: 'relative',
          zIndex: 1,
          top: '-1rem',
          left: '14.5rem'
        }">
      </div>
      <img :src="src" alt="" :style="{ width: '33.5rem' }">
      <div style="width: 0;height: 0;" v-if="props.popupad[props.idx].type == EJumpToType.EJump_Channel">
        <img src="@/assets/common/btn_gonggao.png" alt="" :style="{
          height: '7.1rem',
          position: 'relative',
          top: '-8.2rem',
          left: '-15.6rem',
        }">
      </div>
      <div style="width: 0;height: 0;text-align: center;" v-if="props.popupad[props.idx].type == EJumpToType.EJump_Channel">
        <span :style="{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          position: 'relative',
          top: '-5.8rem',
          left: '-8rem',
          display: 'block',
          width: '22.5rem',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }">{{ props.popupad[props.idx].body }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { EJumpToType } from "@/lib/Consts";
import { GlobalCtrl } from "@/ctrl/GlobalCtrl";

const props = defineProps({
  popupad: Array,
  idx: Number,
  next: Function,
})

const src = computed(() => {
  return props.popupad[props.idx].iconurl;
})

const open = () => {
  GlobalCtrl.jumpToAnyWhere(props.popupad[props.idx]);
}

</script>
