<template>
  <section class="deposit-history-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_DOWNLINE") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
        style="opacity: 0"
      />
    </div>

    <div class="deposit-history-inner">
      <div class="table-header">
        <span class="title">{{ t("TID_LB_DATE") }}</span>
        <spa class="title">{{ t("TID_LB_MEMBER") }}</spa>
        <span class="title">{{ t("TID_LB_AMOUNT") }}</span>
      </div>

      <section class="deposit-history-section" v-if="!requestLoading">
        <div
          class="deposit-history-item"
          v-for="(item, key) in dataList"
          :key="key"
        >
          <div class="deposit-history-item-cell">
            {{ moment(item.created_at).format("YYYY-MM-DD") }}
          </div>

          <div class="deposit-history-item-cell">
            {{ item.member_code }}
          </div>

          <div class="deposit-history-item-cell">
            {{ item.amount }}
          </div>
        </div>
      </section>

      <section class="skeletons" v-else>
        <div class="skeletons-item" v-for="(i, key) in 10" :key="key"></div>
      </section>

      <section
        class="no-results-section"
        v-if="dataList.length <= 0 && !requestLoading"
        style="margin-top: 3rem"
      >
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>
  <section class="pagination-section" v-if="!requestLoading">
    <a-pagination
      v-model:current="currentPage"
      :defaultCurrent="1"
      :pageSize="perPage"
      :total="totalItems"
    />
  </section>
  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import moment from "moment";

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const requestLoading = ref(false);

const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const perPage = ref(0);
const totalItems = ref(0);

const onBack = () => {
  store.commit("goBack");
};

const fetchData = async () => {
  try {
    requestLoading.value = true;

    const res = await reqApi.getDownlineDepositHistory({
      language: locale.value,
      page: currentPage.value,
      version: "3.6.2",
    });
    if (res) {
      dataList.value = res.data;
      currentPage.value = res.current_page;
      lastPage.value = res.last_page;
      perPage.value = res.per_page;
      totalItems.value = res.total;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

watch(currentPage, () => {
  fetchData();
});

onMounted(() => {
  fetchData();
});
</script>

<style lang="scss">
.deposit-history-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    position: sticky;
    top: 0;
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    background-color: #000;
    @include flex(row, space-between, center);

    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .deposit-history-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 430px;
    padding-bottom: 5rem;
    .table-header {
      width: 100%;
      box-sizing: border-box;
      background-color: #666;
      @include flex(row, space-around, center);
      padding: 0.4rem 0;
      font-size: 0.8rem;
      margin: 1rem 0;
      gap: 0.5rem;
      .title {
        width: 25%;
        text-align: center;
      }
    }
    .deposit-history-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(column, center, center);
    }
    .deposit-history-item {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, stretch);
      height: 80px;
      border-bottom: 1px solid #252525;
      padding: 1rem 0 1rem 0;

      &-cell {
        width: 33%;
        text-align: center;
        font-size: 0.9rem;
        @include flex(row, center, center);
      }
    }
    .deposit-history-item:last-child {
      border-bottom: none;
    }
  }
}
</style>
