<template>
  <TopBanner v-if="!topIndependentViews.includes(currentView) && false" />
  <LoginBanner v-if="!independentViews.includes(currentView) && token === ''" />
  <UserInfoBanner
    v-if="
      !topIndependentViews.includes(currentView) &&
      token !== '' &&
      currentView !== 'esports'
    "
  />

  <keep-alive>
    <Home v-if="currentView === 'casino'" />
  </keep-alive>

  <LoginRegister v-if="currentView === 'login-register'" />

  <Affiliate v-if="currentView === 'main_affiliate_page'" />

  <keep-alive>
    <Profile v-if="currentView === 'profile'" />
  </keep-alive>

  <ChangeAvatar v-if="currentView === 'change-avatar'" />

  <Game v-if="currentView === 'in-game' || currentView === 'esports'" />

  <keep-alive>
    <DepositWithdraw v-if="currentView === 'deposit-withdraw'" />
  </keep-alive>

  <TransactionHistory v-if="currentView === 'transaction-history'" />

  <keep-alive>
    <Promotion v-if="currentView === 'promotion'" />
  </keep-alive>

  <CasinoBetHistory v-if="currentView === 'casino-bet-history'" />

  <EsportsBetHistory v-if="currentView === 'esports-bet-history'" />

  <Rebate v-if="currentView === 'rebate'" />

  <Tnc v-if="currentView === 'tnc'" />

  <AboutUs v-if="currentView === 'about-us'" />

  <SearchGame v-if="currentView === 'search-game'" />

  <GameList type="list" v-if="currentView === 'game-list' && (selectedGamePlatform !== 0 || selectedGameCategory !== 0)"/>
  <GameList type="search" v-if="currentView === 'game-list' && (selectedGamePlatform === 0 && selectedGameCategory === 0)"/>

  <FavouriteGames v-if="currentView === 'favourite-games'" />

  <ChangePassword v-if="currentView === 'change-password'" />

  <AffiliateList v-if="currentView === 'affiliate-list'" />

  <DownlineList v-if="currentView === 'downline-list'" />

  <DepositHistory v-if="currentView === 'deposit-history'" />

  <SevenDaysBonus v-if="currentView === '7_days_check_in_bonus_page'" />

  <Cashback v-if="currentView === 'cashback_25_page'" />

  <WheelSpin v-if="currentView === 'receive_50_for_free_page'" />

  <ConvertBonus v-if="currentView === 'convert_bonus'" />

  <BottomBanner v-if="!independentViews.includes(currentView)" />

  <LogoutModal />

  <Vip v-if="currentView === 'vip'"/>

  <audio ref="backgroundAudio" loop>
    <source :src="audioUrl" type="audio/mpeg" />
    Your browser does not support the audio tag.
  </audio>
</template>

<script setup>
import TopBanner from "@/components/TopBanner";
import LoginBanner from "@/components/LoginBanner";
import BottomBanner from "@/components/BottomBanner";
import UserInfoBanner from "@/components/UserInfoBanner";

import LogoutModal from "@/components/LogoutModal";
import Home from "@/components/views/Home";
import LoginRegister from "@/components/views/LoginRegister";
import Profile from "@/components/views/Profile";
import Affiliate from "@/components/views/Affiliate";
import Game from "@/components/views/Game";
import DepositWithdraw from "@/components/views/DepositWithdraw";
import TransactionHistory from "@/components/views/TransactionHistory";
import Promotion from "@/components/views/Promotion";
import Tnc from "@/components/views/Tnc";
import CasinoBetHistory from "@/components/views/CasinoBetHistory";
import EsportsBetHistory from "@/components/views/EsportsBetHistory";
import Rebate from "@/components/views/Rebate";
import AboutUs from "@/components/views/AboutUs";
import SearchGame from "@/components/views/SearchGame";
import FavouriteGames from "@/components/views/FavouriteGames";
import ChangePassword from "@/components/views/ChangePassword";
import AffiliateList from "@/components/views/AffiliateList";
import DownlineList from "@/components/views/DownlineList";
import DepositHistory from "@/components/views/DepositHistory";
import SevenDaysBonus from "@/components/views/SevenDaysBonus";
import Cashback from "@/components/views/Cashback";
import WheelSpin from "@/components/views/WheelSpin";
import ConvertBonus from "@/components/views/ConvertBonus";
import ChangeAvatar from "@/components/views/ChangeAvatar";
import GameList from "@/components/views/Game/List";
import Vip from "@/components/views/Vip";

import { onMounted, onUnmounted, watch, ref, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { independentViews, topIndependentViews } from "@/lib/utils";
import eventBus from "@/eventBus";
import { useI18n } from "vue-i18n";

import { message } from "ant-design-vue";
import { UserStore } from "./lib/UserStore";
import { CtrlMgr } from "./ctrl";

// 修改 html 的 font-size，配合 rem 实现页面缩放
const onresize = () => {
  const html = document.documentElement;
  const { width } = html.getBoundingClientRect()
  if (width > 300 && width < 600) {
    html.style['font-size'] = `${width / 600 * 16}px`
  }
}
window.addEventListener('resize', onresize)
onresize();

const { t } = useI18n();
const store = useStore();
const currentView = computed(() => store.state.currentView);
const selectedGamePlatform = computed(() => store.state.selectedGamePlatform);
const selectedGameCategory = computed(() => store.state.selectedGameCategory);
const token = computed(() => store.state.token);
const rememberMe = computed(() => store.state.rememberMe);
const audioUrl = computed(() => store.state.audio);
const isAudioPlaying = computed(() => store.state.isAudioPlaying);

const backgroundAudio = ref(null);

function handleMessage(payload) {
  // console.warn("handleMessage:", payload);
  message[payload.type]({
    content: t(payload.message, payload.args),
    duration: 2,
  });
}

watch([audioUrl, isAudioPlaying], ([newAudioUrl, newIsPlaying]) => {
  if (newAudioUrl) {
    backgroundAudio.value.load(); // Reload audio with the new source
  }
  if (newIsPlaying) {
    backgroundAudio.value.play(); // Start playing if audio is on
  } else {
    backgroundAudio.value.pause(); // Pause if audio is off
  }
});

watch(currentView, () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
});

// Function to check if the modal is present and disable scrolling
function toggleScrollForModal() {
  const modal = document.querySelector(".ant-modal");

  if (modal && modal.style.display !== "none") {
    // Disable document scrolling if the modal is visible
    document.body.style.overflow = "hidden";
  } else {
    // Re-enable document scrolling if the modal is not visible
    document.body.style.overflow = "";
  }
}

// Initialize a MutationObserver
function initializeObserver() {
  const observer = new MutationObserver(toggleScrollForModal);
  observer.observe(document.body, { childList: true, subtree: true });

  // Perform an initial check
  toggleScrollForModal();

  // Disconnect the observer when the component unmounts
  return () => observer.disconnect();
}

onBeforeMount(() => {
})

onMounted(() => {
  initializeObserver;
  CtrlMgr.initCtrl();
  if (!sessionStorage.getItem("firstLoadDone")) {
    if (!rememberMe.value) {
      store.commit("setUserInfo", "");
      store.commit("setToken", "");
      store.commit("setAffiliateInfo", "");
      store.commit("setFavouriteGames", []);
      store.commit("setRememberMe", false);
      store.commit("clearHistoryStack");
      store.commit("setCurrentView", "casino");
      store.commit("setSpinValue", 0);
      store.commit("setPrevApiBaseUrl", UserStore.getBaseApiUrl());
      store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());
    }
    sessionStorage.setItem("firstLoadDone", "1");
  } else {
    if (token.value === "") {
      store.commit("setPrevApiBaseUrl", UserStore.getBaseApiUrl());
      store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());
    }
    console.log("This is a page refresh");
  }
  eventBus.on("message", handleMessage);

  // 获取浏览器语言
  const language = store.state.language;
  const langSupport = ["en", "vn"];
  const langExsit = langSupport.includes(language);
  store.commit("setLanguage", langExsit ? language : "en");

  // 忽略背景音乐
  // if (isAudioPlaying.value && audioUrl.value) {
  //   backgroundAudio.value.load();
  //   backgroundAudio.value.play().catch((error) => {
  //     console.error("Error starting audio playback:", error);
  //   });
  // }

  if (currentView.value === "in-game") {
    store.commit("setCurrentGame", "");
    store.commit("goBack");
  }
});

onUnmounted(() => {
  eventBus.off("message", handleMessage);
});
</script>

<style></style>
