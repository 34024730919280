<!-- 我的 - 个人信息 -->
<template>
  <div class="profile-container">
    <PageHeader :back="onBack" title="TID_LB_PROFILE" />
    <div class="profile-inner">

      <section class="user-section">
        <section class="user-profile">
          <div class="profile-pic">
            <img src="@/assets/profile/profile_1.png" alt="profile" />
            <!-- <img src="@/assets/profile/profile_1.png" alt="profile" v-if="!userInfo?.avatar" />
            <img :src="userInfo?.avatar" alt="profile" v-else /> -->

            <!-- TODO 没有自定义头像功能 -->
            <div class="edit" v-if="false">
              <EditOutlined style="color: #ec4e84; font-size: 0.8rem" @click="goTo('change-avatar')" />
            </div>
          </div>

          <div class="user-info" style="flex-grow: 1">
            <span class="member-code">
              {{ userInfo?.id ? userInfo.id : "-" }}
            </span>

            <span class="referral-code">
              <span class="title">{{ t("TID_LB_REFERRAL_CODE") + ": " }}</span>
              {{ userInfo.uuid ?? "-" }}
              <CopyOutlined style="color: #fff; font-size: 1rem" @click="copyToClipboard(userInfo.uuid)" />
            </span>
          </div>

          <!-- 修改登录密码，移到左侧抽屉 -->
          <div :style="{display: 'flex', flexDirection: 'row', gap: '1rem'}" @click="goTo('change-password')" v-if="false">
            <img src="@/assets/nav-bar/change_password.png" alt="" :style="{width: '3.3333333333rem'}" />
            <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" :style="{height: '1.7rem', margin: 'auto'}" />
          </div>
        </section>

        <section class="personal-profile-section">
          <div class="content">
            <div class="item">
              <div style="display: flex;flex-direction: row;gap: 1rem;">
                <img src="@/assets/profile/icon_wallet.png" alt="" :style="{
                  height: '2.3rem',
                  margin: 'auto',
                }" />
                <div>
                  <span class="item-title" :style="{
                    justifyContent: 'flex-start'
                  }">{{ t("TID_LB_WALLET") }}</span>
                  <span class="item-value">₫ {{ GFunc.formatNumber(userInfo?.currentmoney) }}</span>
                </div>
              </div>
              <div class="convert-btn" @click="goTo('deposit-withdraw/deposit')">
                {{ t("TID_LB_DEPOSIT") }}
              </div>
            </div>
            <div class="item">
              <span class="item-title">
                {{ t("TID_LB_BONUS") }}
                <img src="@/assets/common/btn_help.png" alt="question"
                  style="cursor: pointer; width: 1.5rem" @click="showDepositModal = true" />
              </span>
              <span class="item-value">₫ {{ GFunc.formatNumber(userInfo?.bonus) }}</span>
              <div class="convert-btn" @click="goTo('deposit-withdraw/withdraw')">
                {{ t("TID_WALLET_WITHDRAW_TITLE") }}
              </div>
            </div>
          </div>
        </section>
      </section>

      <section class="activity-section">
        <!-- 免费投注 -->
        <div class="activity-item activity-item-first" v-show="false">
          <div :style="{display: 'flex', flexDirection: 'column', flexGrow: 1,}">
            <span class="activity-item-title">{{ t("TID_FREE_BET") }}</span>
            <span :style="{color: '#8c8c8c', fontSize: '1.125rem',}">{{ t("TID_FREE_BET_DESC") }}</span>
          </div>
          <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" />
        </div>
        <div class="activity-item" @click="goTo('receive_50_for_free_page')">
          <img src="@/assets/profile/icon_event1.png" alt="" />
          <span class="activity-item-title">{{ t("TID_LB_RECEIVE_50_FOR_FREE") }}</span>
          <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" />
        </div>
        <div class="activity-item" @click="goTo('cashback_25_page')">
          <img src="@/assets/profile/icon_event2.png" alt="" />
          <span class="activity-item-title">{{ t("TID_LB_CASHBACK_25") }}</span>
          <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" />
        </div>
        <div class="activity-item" @click="goTo('7_days_check_in_bonus_page')">
          <img src="@/assets/profile/icon_event3.png" alt="" />
          <span class="activity-item-title">{{ t("TID_LB_SEVEN_DAYS_CHECK_IN_BONUS") }}</span>
          <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" />
        </div>
        <div class="activity-item" @click="goTo('transaction-history')">
          <span class="activity-item-title">{{ t("TID_LB_TRANSACTION_HISTORY") }}</span>
          <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" />
        </div>
        <div class="activity-item" @click="goTo('casino-bet-history')">
          <span class="activity-item-title">{{ t("TID_LB_MY_GAME_BETS") }}</span>
          <img src="@/assets/common/arrow_1.png" alt="" class="activity-item-icon" />
        </div>
      </section>

      <section class="log-out-section" @click="logout">
        {{ t("TID_LB_LOGOUT") }}
      </section>
    </div>
  </div>

  <a-modal :open="showClaimComModal" centered :closable="false" :maskClosable="true" class="claim-com-modal"
    :cancelButtonProps="{ style: { display: 'none' } }" :okButtonProps="{
      style: {
        display: 'none',
      },
    }">
    <div class="bind-withdraw-content">
      <span class="content" style="font-size: 1.1rem">
        {{ t("TID_LB_CLAIM_YOUR_COMMISSION") }}
      </span>

      <div class="yes-cancel-btns">
        <div class="btn" @click="showClaimComModal = false">
          {{ t("TID_LB_CANCEL") }}
        </div>

        <div class="btn yes" @click="claimCommission">
          {{ t("TID_LB_YES") }}
        </div>
      </div>
    </div>
  </a-modal>

  <DepositBonusModal :showDepositModal="showDepositModal" @onCloseModal="showDepositModal = false" />
  <Loading v-if="requestLoading" />
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref, computed } from "vue";
import { EditOutlined, CopyOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import {
  // formatDate,
  copyToClipboard,
  // sendEmail,
  // shareOnTelegram,
  // shareOnFacebook,
  // shareOnWhatsApp,
} from "@/lib/utils";
import { netApi, reqApi } from "@/api";
import Loading from "@/components/Loading";
import { message } from "ant-design-vue";
import DepositBonusModal from "@/components/DepositBonusModal";
import { UserStore } from "@/lib/UserStore";
import PageHeader from "@/components/PageHeader"
import { GFunc } from "@/lib/GFunc";
import { showDialog } from "@/lib/utils";

const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const requestLoading = ref(false);
const store = useStore();

const showClaimComModal = ref(false);
const showDepositModal = ref(false);

const userInfo = computed(() => store.state.userInfo);
// const affiliateInfo = computed(() => store.state.affiliateInfo);
const apiBaseUrl = computed(() => store.state.apiBaseUrl);

const claimCommission = async () => {
  try {
    requestLoading.value = true;
    const res = await reqApi.claimAgentReward({
      language: locale.value,
      version: "3.6.2",
    });
    if (res) {
      message.success(t("TID_LB_CLAIM_SUCCESSFULLY"));
      store.commit("setAffiliateInfo", res.downline_dashboard);
      store.commit("setUserInfo", res.member);
    }
  } catch (e) {
    console.log(e);
  } finally {
    showClaimComModal.value = false;
    requestLoading.value = false;
  }
};

const logout = async () => {
  try {
    const action = await showDialog({
      content: t("TID_LB_LOGOUT_TIP"),
      close: () => {}
    })
    if (action) {
      requestLoading.value = true;
      const res = await netApi.userLogout();
      if (res) {
        window.location.reload();
        store.commit("setToken", "");
        store.commit("setUserInfo", "");
        store.commit("clearHistoryStack");
        store.commit("setDepositOrWithdraw", "deposit");
        store.commit("setCurrentView", "casino");
        store.commit("setSpinValue", 0);
        store.commit("setPrevApiBaseUrl", apiBaseUrl.value);
        store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());
        store.commit("setRechargeCommissionConf", {});
        store.commit("setCommissionRate", {});
        store.commit("setPlatBaseConf", {});
        store.commit("setLocalLastUser", "");
      }
      let s = window.location.href;
      window.location.href = s;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

// store.commit("setDepositOrWithdraw", "deposit");

const goTo = (page) => {
  if (page.startsWith("deposit-withdraw")) {
    const [ _page, _view ] = page.split('/')
    store.commit("setDepositOrWithdraw", _view);
    store.commit("setCurrentView", _page);
  } else {
    if (page === 'change-password') {
      store.commit("setChangedPwdType", 0);
    }
    store.commit("setCurrentView", page);
  }
};

const onBack = () => {
  store.commit("goBack");
}
</script>

<style lang="scss">
.profile-container {
  @include flex(column, center, center);
  padding-bottom: 8rem;
  box-sizing: border-box;
  width: 100%;

  .profile-inner {
    box-sizing: border-box;
    @include flex(column, center, center);
    width: 600px;

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (max-width: 300px) {
      width: 300px;
    }

    .user-section {
      box-sizing: border-box;
      width: 100%;
      background: linear-gradient(#ff2f75, #8e1541);
    }

    .user-profile {
      box-sizing: border-box;
      padding: .5rem 0;
      margin: 0 1rem;
      @include flex(row, flex-start, center);
      gap: 1rem;
      border-bottom: 1px solid #ec4e84;

      .profile-pic {
        position: relative;
        // border: 2px solid #ffeb02;
        border-radius: 2.1875px;
        width: 4.375rem;
        height: 4.375rem;
        @include flex(row, center, center);
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          position: absolute;
          border-radius: 50%;
        }

        .edit {
          position: absolute;
          right: -2px;
          bottom: -3px;
          @include flex(row, center, center);
          background-color: #000;
          padding: 0.3rem;
          border-radius: 50%;
          border: 2px solid #ec4e84;
        }
      }

      .user-info {
        @include flex(column, center, flex-start);
        gap: 0.3rem;

        .member-code {
          font-size: 1.5rem;
          color: #fff;
        }

        .referral-code {
          font-size: 1.125rem;

          .title {
            color: #fff;
          }
        }
      }
    }

    .personal-profile-section {
      padding: .5rem 0 2.5rem 0;
      margin: 0 1rem;
      box-sizing: border-box;

      .title {
        font-weight: bold;
      }

      .content {
        @include flex(row, space-around, center);
        width: 100%;
        margin: 1rem 0 0 0;

        .item {
          @include flex(column, center, center);
          width: 50%;
          gap: 0.2rem;
          border-right: 1px solid #ec4e84;
          position: relative;

          &-title {
            font-size: 1.3125rem;
            color: #fff;
            @include flex(row, center, center);
            gap: 0.5rem;
          }

          &-value {
            font-size: 1.625rem;
            font-weight: bold;
          }

          .convert-btn {
            background: linear-gradient(#ff2f75, #8e1541);
            color: #fff;
            border-radius: 0.375rem;
            padding: 0.5rem 0;
            position: absolute;
            bottom: -4.25rem;
            font-size: 1.625rem;
            font-weight: bold;
            cursor: pointer;
            width: 80%;
            text-align: center;
          }
        }

        .item:last-child {
          border-right: none;
        }
      }
    }

    .activity-section {
      padding: 2.5rem 0 0;
      width: 100%;
      box-sizing: border-box;

      .activity-item {
        margin: 0 1rem;
        @include flex(row, left, center);
        cursor: pointer;
        border-bottom: 1px solid #757575;
        height: 4.875rem;
        font-size: 1.3125rem;
        gap: 1rem;

        img {
          width: 2.2333333333rem;
        }

        &-title {
          flex-grow: 1;
        }

        &-icon {
          width: 0.8rem !important;
        }

        &-first {
          background-color: black;
          padding: 0 1rem;
          margin: 0;
          border-bottom: 0 solid #757575;
        }
      }

      .activity-item:last-child {
        border-bottom: none;
        padding-bottom: 1rem;
      }
    }

    .log-out-section {
      cursor: pointer;
      padding: 1rem 0 0;
      font-size: 1.625rem;
    }
  }
}

.claim-com-modal {
  .ant-modal-content {
    background-color: #000 !important;
    border: 1px solid #463f04 !important;
    padding: 1.5rem 1.5rem 0.5rem 1.5rem !important;
  }
}
</style>
