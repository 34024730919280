/**
 * 推广逻辑模块
 */

import { netApi } from "@/api";
import { DataIns } from "@/lib/DataIns";
import { GFunc } from "@/lib/GFunc";

class _PromotionCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听

    constructor() {
        GFunc.log("PromotionCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("PromotionCtrl initCtrl");
    }

    initModel() {
        GFunc.log("PromotionCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
    }

    initData() {
        this.config = [];
        this.status = { todaycnt: 0, todaymoney: 0, yesterdaycnt: 0, yesterdaymoney: 0 };
    }

    checkModuleEnable() {
        return true;
    }

    /**
     * 缓存中获取配置数据
     * @param callFunc
     * @param failFunc
     * @returns
     */
    getPullConfByCache(callFunc, failFunc) {
        if (this.config && this.config.length > 0) {
            callFunc && callFunc(this.config);
        } else {
            this.reqPullConf(callFunc, failFunc);
        }
    }

    /**
     * 获取拉新配置
     * @param callFunc
     * @param failFunc
     */
    /* static */ async reqPullConf(callFunc, failFunc) {
        try {
            const data = await netApi.getRegInviteConf({});
            this.config = data;
            callFunc && callFunc(data);
        } catch (error) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    /**
     * 获取拉新记录
     * @param reqParams { lastid: 0, row: 20 }
     * @param callFunc
     * @param failFunc
     */
    async getPullRecord(reqParams, callFunc, failFunc) {
        try {
            const data = await netApi.getRegPullRecord(reqParams);
            this.record = DataIns.checkarray(data.list);
            callFunc && callFunc(data);
        } catch (error) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    /**
     * 获取拉新状态
     * @param callFunc
     * @param failFunc
     */
    async reqPullStatus(callFunc, failFunc) {
        try {
            const data = await netApi.getRegPullStatus({});
            this.status = data;
            callFunc && callFunc(data);
        } catch (error) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }
}

const PromotionCtrl = new _PromotionCtrl_();

export { PromotionCtrl }

// DEBUG
window.PromotionCtrl = PromotionCtrl;
