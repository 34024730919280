/**
 * 日期时间
 */

import DataIns from "./DataIns"

const DateIns = {
    // 获取当前时间戳
    getCurDateTime() {
        return new Date().getTime()
    },

    // 获取当前年份 2100
    getCurYear() {
        return new Date().getFullYear()
    },

    // 获取当前月份 1-12
    getCurMonth() {
        return new Date().getMonth() + 1
    },

    // 获取当前日 1-31
    getCurDate() {
        return new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
    },

    // 获取当前星期数 0 - 6 [0 周日]
    getCurWeekDay: function () {
        let day = new Date().getDay()
        day = day === 0 ? 7 : day
        return day
    },

    // 获取当前年月日
    getDateTimeYMD(time, sp = '-') {
        const dt = time ? new Date(time) : new Date()
        // console.log('dt:' + dt)
        const year = dt.getFullYear()
        const month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1
        const day = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate()
        return `${year}${sp}${month}${sp}${day}`
    },

    // 当天年月
    getCurDateTimeYM() {
        return this.parseTime(this.getCurDateTime(), '{y}-{m}')
    },
    // 当天年月日
    getCurDateTimeYMD() {
        return this.parseTime(this.getCurDateTime(), '{y}-{m}-{d}')
    },

    // 最近7天
    getCurDateTimeYMD7() {
        return this.parseTime(this.getCurDateTime() - 7 * 24 * 3600 * 1000, '{y}-{m}-{d}')
    },

    // 最近30天
    getCurDateTimeYMD30() {
        return this.parseTime(this.getCurDateTime() - 30 * 24 * 3600 * 1000, '{y}-{m}-{d}')
    },

    // 最近90天
    getCurDateTimeYMD90() {
        return this.parseTime(this.getCurDateTime() - 90 * 24 * 3600 * 1000, '{y}-{m}-{d}')
    },

    // 获取当天开始时间戳
    getTodayStartTime() {
        // const dt = this.getDateTimeYMD()
        // return new Date(dt).setHours(0, 0, 0, 0)
        const date = new Date()
        const firstDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
        return firstDate.getTime()
    },

    // 获取指定日期当天开始时间戳 返回单位 秒
    getStartTime(time, isMini = false) {
        if (!time) {
            return 0
        }
        const date = new Date(time)
        const firstDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
        const dev = isMini ? 1 : 1000
        return Math.ceil(firstDate.getTime() / dev)
    },

    // 指定日期当天开始时间字符串，返回 xxxx-xx-xx xx:xx:xx
    getStartTimeFormat(time) {
        return time ? `${time} 00:00:00` : ''
    },

    // 指定日期当天开始时间字符串，经过时间戳转换，返回 xxxx-xx-xx xx:xx:xx
    getStartTimeFormatByStamp(time) {
        const t = this.getStartTime(time, true)
        if (!t) {
            return ''
        }
        return this.getTimeStringWithSec('-', t)
    },

    // 获取当天结束时间戳
    getTodayEndTime() {
        // const dt = this.getDateTimeYMD()
        // return new Date(dt).setHours(23, 59, 59, 999)
        const date = new Date()
        const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
        return endDate.getTime()
    },

    // 获取指定日期当天结束时间戳 返回单位 秒
    getEndTime(time, isMini = false) {
        if (!time) {
            return 0
        }
        const date = new Date(time)
        const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999)
        const dev = isMini ? 1 : 1000
        return Math.floor(endDate.getTime() / dev)
    },

    // 指定日期当天结束时间字符串，返回 xxxx-xx-xx xx:xx:xx
    getEndTimeFormat(time) {
        return time ? `${time} 23:59:59` : ''
    },

    // 指定日期当天结束时间字符串，经过时间戳转换，返回 xxxx-xx-xx xx:xx:xx
    getEndTimeFormatByStamp(time) {
        const t = this.getEndTime(time, true)
        if (!t) {
            return ''
        }
        return this.getTimeStringWithSec('-', t)
    },

    // 获取明天开始时间戳
    getTomorrowStartTime() {
        return this.getTodayStartTime() + 24 * 3600 * 1000
    },

    // 获取明天结束时间戳
    getTomorrowEndTime() {
        return this.getTodayEndTime() + 24 * 3600 * 1000
    },

    // 获取昨天开始时间戳
    getYesterdayStartTime() {
        return this.getTodayStartTime() - 24 * 3600 * 1000
    },

    // 获取昨天结束时间戳
    getYesterdayEndTime() {
        return this.getTodayStartTime() - 1000
    },

    // 本周开始时间戳
    getCurWeekStartTime() {
        return this.getTodayStartTime() - (this.getCurWeekDay() - 1) * 24 * 3600 * 1000
    },

    // 本周结束时间戳
    getCurWeekEndTime() {
        return this.getTodayEndTime() + (7 - this.getCurWeekDay()) * 24 * 3600 * 1000
    },

    // 上周开始时间戳
    getLastWeekStartTime() {
        return this.getCurWeekStartTime() - 7 * 24 * 3600 * 1000
    },

    // 上周结束时间戳
    getLastWeekEdnTime() {
        return this.getCurWeekStartTime() - 1000
    },

    // 本月开始时间戳
    // 通过 setDate(1) 获取本月第一天
    getCurMonthStartTime() {
        // const st = (new Date()).setDate(1)
        // const dt = this.getDateTimeYMD(st)
        // return new Date(dt).setHours(0, 0, 0, 0)
        const date = new Date()
        const firstDate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0)
        return firstDate.getTime()
    },

    // 本月结束时间戳
    getCurMonthEndTime() {
        const date = new Date()
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999)
        return endDate.getTime()
    },

    // 上月开始时间戳
    getLastMonthStartTime() {
        const date = new Date()
        const firstDate = new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0, 0)
        return firstDate.getTime()
    },

    // 上月结束时间戳
    getLastMonthEndTime() {
        const date = new Date()
        const endDate = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59, 999)
        return endDate.getTime()
    },

    /**
     * 获取当前年月日毫秒值
     * @param {*} format  '/' 或者'-' 默认为'-'
     * @param {*} time    当前时间 1528942784005
     * @returns
     */
    getTimeStringWithSec(format, time) {
        format = format || '-'
        let newTime = 0
        if (!time) {
            newTime = new Date()
        } else {
            newTime = new Date(time)
        }
        const year = newTime.getFullYear()
        const month = newTime.getMonth() + 1 < 10 ? '0' + (newTime.getMonth() + 1) : newTime.getMonth() + 1
        const day = newTime.getDate() < 10 ? '0' + newTime.getDate() : newTime.getDate()
        const hours = newTime.getHours() < 10 ? '0' + newTime.getHours() : newTime.getHours()
        const minutes = newTime.getMinutes() < 10 ? '0' + newTime.getMinutes() : newTime.getMinutes()
        const seconds = newTime.getSeconds() < 10 ? '0' + newTime.getSeconds() : newTime.getSeconds()
        const timeStr = year + '' + format + month + '' + format + day + ' ' + hours + ':' + minutes + ':' + seconds
        return timeStr
    },

    /**
     * 获取当前时分秒
     * @param time
     * @returns
     */
    getTimeStringWithHMS(time) {
        const newTime = time || new Date()
        const hours = newTime.getHours() < 10 ? '0' + newTime.getHours() : newTime.getHours()
        const minutes = newTime.getMinutes() < 10 ? '0' + newTime.getMinutes() : newTime.getMinutes()
        const seconds = newTime.getSeconds() < 10 ? '0' + newTime.getSeconds() : newTime.getSeconds()
        const timeStr = hours + ':' + minutes + ':' + seconds
        return timeStr
    },

    /**
     * 将字符串格式化为日期格式
     * @param str 日期字符串，如 20000101
     * @param sp  日期连接符，如 -
     * @returns   2000-01-01
     */
    formatTimeString(str, sp = '-') {
        str = DataIns.checkstring(str).replace(/ /g, '')
        let newstr = ''
        const dtstr = str.match(/(\d{4})(\d{2})(\d{2})/)
        if (dtstr && dtstr.length === 4) {
            newstr = `${dtstr[1]}${sp}${dtstr[2]}${sp}${dtstr[3]}`
        }
        return newstr
    },

    /**
     * 日期格式化
     * @param date 时间戳
     * @param format 格式化模板，yyyy-MM-dd 或则 yyyy-MM，- 可以是任意字符
     * @returns 2023-06-30 或则 2023-06
     */
    format(time, format = 'yyyy-MM-dd') {
        if (!time) return ''
        const date = new Date(time)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        return format
            .replace('yyyy', year.toString())
            .replace('MM', month.toString().padStart(2, '0'))
            .replace('dd', day.toString().padStart(2, '0'))
            .replace('HH', hours.toString().padStart(2, '0'))
            .replace('mm', minutes.toString().padStart(2, '0'))
            .replace('ss', seconds.toString().padStart(2, '0'))
    },

    /**
     * 判断日期是否非法
     * @param date
     * @returns
     */
    isValidDate(date) {
        return date instanceof Date && !isNaN(date.getTime())
    },

    parseTime(time, cFormat) {
        if (time === undefined || !time) {
            return null
        }
        const origTime = time
        const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if (typeof time === 'string') {
                if (/^[0-9]+$/.test(time)) {
                    // support '1548221490638'
                    time = parseInt(time)
                } else {
                    // support safari
                    // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                    time = time.replace(/-/gm, '/')
                }
            }
            if (typeof time === 'number' && time.toString().length === 10) {
                time = time * 1000
            }
            date = new Date(time)
            // console.error('time:' + time, 'date:' + date)
            // 非法时间，转回原字串
            if (!this.isValidDate(date)) {
                return DataIns.checkstring(origTime)
            }
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
            const value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') {
                return ['日', '一', '二', '三', '四', '五', '六'][value]
            }
            return value.toString().padStart(2, '0')
        })
        return timeStr
    }
}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S": this.getMilliseconds()
    };

    let match = fmt.match(/(y+)/)
    if (match) {
        fmt = fmt.replace(match[1], this.getFullYear().toString().padStart(4, '0')); // 年份
    }

    for (let k in o) {
        match = fmt.match(new RegExp("(" + k + ")"))
        if (match) {
            fmt = fmt.replace(match[1], (match[1].length === 1) ? (o[k]) : (("00" + o[k]).substring(("" + o[k]).length)));
        }
    }

    return fmt;
}

export { DateIns }
