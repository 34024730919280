<!-- 轮盘组件 -->
<template>
  <div class="wheel">
    <FortuneWheel type="image" :useWeight="true" :prizeId="prizeId" :prizes="prizesImage"
      @rotateStart="onImageRotateStart" @rotateEnd="onRotateEnd" :duration="5000" :disabled="disabled">
      <template #wheel>
        <div class="pan">
          <div v-for="(item, key) in [
            { type: 0, code: '₫50k' },
            { type: 1, url: require('@/assets/wheel-spin/thank_you.png') },
            { type: 0, code: '₫100k' },
            { type: 1, url: require('@/assets/wheel-spin/cash.png') },
            { type: 0, code: '₫150k' },
            { type: 1, url: require('@/assets/wheel-spin/thank_you.png') },
            { type: 0, code: '₫300k' },
            { type: 1, url: require('@/assets/wheel-spin/cash.png') },
          ]" :key="key" style="width: 0;height: 0;position: relative;top: 50%;left: 50%;" :style="{
  transform: `rotate(${22.5 + 45 * Number(key)}deg)`
}">
            <div style="position: absolute;transform: translate(-50%, -50%);top: -9rem;">
              <span v-if="item.type === 0" style="font-size: 1.5625rem;">{{ item.code }}</span>
              <img v-if="item.type === 1" style='height: 3.3333333333rem;' :src="item.url" alt="">
            </div>
          </div>
        </div>
      </template>
      <template #button>
          <div style="width: 0;height: 0;position: relative;top: 50%;left: 50%;z-index: -1;"
            :class="`wheel-result${rolling ? '' : '-blink'}`">
            <div style="position: absolute;transform: translate(-50%, -50%);top: .25rem;">
              <img style="height: 12rem;" src="@/assets/wheel-spin/bg_kuang.png" alt="">
            </div>
          </div>
        <div class="wheel-btn" @click="spinWheel">
          <span class="wheel-btn-text">
            <span :style="{
              fontWeight: 'bold',
              textShadow: (props.count || 0) > 0 ? '' : '#767676 .125rem 0 0, #767676 0 .125rem 0, #767676 -.125rem 0 0, #767676 0 -.125rem 0'
            }">{{ props.count || 0 }}</span>
          </span>
          <img v-if="(props.count || 0) > 0" src="@/assets/wheel-spin/spin_button_bg.png" alt="btn" class="wheel-btn-img" />
          <img v-else src="@/assets/wheel-spin/spin_button_bg2.png" alt="btn" class="wheel-btn-img" />
        </div>
      </template>
    </FortuneWheel>

    <div class="background">
      <div class="lamp-container">
        <img src="@/assets/wheel-spin/light_1.png" class="lamp lamp-1">
        <img src="@/assets/wheel-spin/light_2.png" class="lamp lamp-2">
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import FortuneWheel from "vue-fortune-wheel";
import { netApi } from "@/api";
import { message } from "ant-design-vue";
import { GFunc } from "@/lib/GFunc";

const store = useStore();
const props = defineProps({
  rotateEnd: Function,
  count: Number,
  disabled: Boolean,
});

const referralInfo = ref({});
const initialCurrentDate = ref(new Date());
const tickingDate = ref(initialCurrentDate.value);
const token = computed(() => store.state.token);
const bufferValue = ref({});
const showWonModal = ref(false);
const showLoginModal = ref(false);
const eventExpired = ref(false);
const prizeId = ref(0);
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const spinLeft = computed(() => {
  if (referralInfo.value.referral_bonus) {
    let currentDate = new Date(tickingDate.value);
    let startDate = new Date(referralInfo.value.referral_bonus.start_date);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - startDate;

    // Convert milliseconds to days
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const remainingDays = Math.floor(
      differenceInMilliseconds / millisecondsInADay
    );

    let remainingClick =
      remainingDays + 1 - referralInfo.value.referral_bonus.click;

    return remainingClick;
  }
  return 1;
});

const prizesImage = computed(() => [
  {
    id: 1,
    value: "1",
    weight: 1,
  },
  {
    id: 2,
    value: "2",
    weight: 1,
  },
  {
    id: 3,
    value: "3",
    weight: 1,
  },
  {
    id: 4,
    value: "4",
    weight: 1,
  },
  {
    id: 5,
    value: "5",
    weight: 1,
  },
  {
    id: 6,
    value: "6",
    weight: 1,
  },
  {
    id: 7,
    value: "7",
    weight: 1,
  },
  {
    id: 8,
    value: "8",
    weight: 1,
  },
]);

const rolling = ref(true);
function onImageRotateStart() {
  spinLeft.value -= 1;
  if (token.value !== "" || referralInfo.value.referral_bonus) {
    rolling.value = true;
    onWheelStart();
  }
}

function onRotateEnd() {
  if (token.value !== "" || referralInfo.value.referral_bonus) {
    referralInfo.value = bufferValue.value;
    showWonModal.value = true;
    props.rotateEnd();
    rolling.value = false;
    message.success(t("TID_LB_EARNED") + ` ₫${GFunc.formatNumber(bufferValue.value)}`);
  } else {
    generateRandomSpinAmount();
    showLoginModal.value = true;
  }
}

const onWheelStart = async () => {
  if (token.value !== "") {
    try {
      const { addmoney } = await netApi.getEventTrunTableLottery();
      prizeId.value = [4, 8].sort(() => Math.random() - 0.5).shift();
      bufferValue.value = addmoney;
    } catch (e) {
      prizeId.value = [2, 6].sort(() => Math.random() - 0.5).shift();
    }
  } else {
    console.log("lol");
  }
};

const generateRandomSpinAmount = () => {
  const min = 45.01;
  const max = 45.49;
  const randomValue = Math.random() * (max - min) + min;
  store.commit("setSpinValue", parseFloat(randomValue.toFixed(2)));
};

const spinWheel = () => {
  if (spinLeft.value < 1) {
    if (eventExpired.value) {
      return message.error(t("TID_LB_EVENT_EXPIRED"));
    }
    return message.error(t("TID_LB_NO_SPIN_LEFT"));
  }
};
</script>

<style lang="scss">
.background {
  background-image: url("@/assets/wheel-spin/base_up.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 32.4650478293rem;
  height: 40rem;
  position: absolute;
  margin-right: 1.5rem;
}

.pan {
  background-image: url("@/assets/wheel-spin/pan.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  width: 24rem;
  height: 24rem;
}

.wheel {
  @include flex(row, center, center);

  .fw-container {
    height: 40rem !important;

    .fw-wheel {
      position: relative;
      top: 11.8rem;
    }
  }
}

.lamp-container {
  width: 27.5rem;
  height: 27.5rem;
  position: absolute;
  top: 10.1rem;
  left: 3.3rem;
}

.lamp {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  animation: fade 1s infinite;

  &-1 {
    animation-delay: 0s;
  }

  &-2 {
    animation-delay: 0.5s;
  }
}

@keyframes fade {

  0%,
  50% {
    opacity: 1;
  }

  50.01%,
  100% {
    opacity: 0;
  }
}

.fw-btn {
  &__image {
    position: relative;
    top: 2.5rem;
  }
}

.wheel-btn {
  width: auto !important;
}

.wheel-btn-text {
  position: absolute !important;
  top: 6.2rem !important;
  color: #fff !important;
  text-shadow: #f36b00 .125rem 0 0, #f36b00 0 .125rem 0, #f36b00 -.125rem 0 0, #f36b00 0 -.125rem 0;
  font-size: 4rem;
}

.wheel-btn-img {
  width: 7.2rem !important;
}

.spin-now-btn-outer {
  display: none !important;
}

.wheel-result {
  opacity: 0;

  &-blink {
    opacity: 1;
    animation: blink-animation 1s infinite;
    /* 动画持续1秒，无限循环 */
  }

  @keyframes blink-animation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}</style>