<!-- 底部导航 -->
<template>
  <div class="bottom-nav">
    <!-- 导航按钮 -->
<!--    <div class="menu-item" @click="openMenu">-->
<!--      <img src="@/assets/bottom-nav/bottom_nav_0_off.png" alt="nav-off" />-->
<!--      <span class="text">{{ t("TID_LB_MENU") }}</span>-->
<!--    </div>-->
    <div class="menu-item" @click="setView('casino')">
      <img src="@/assets/bottom-nav/bottom_nav_0_off.png" alt="nav-off" v-if="currentView !== 'casino'" />
      <img src="@/assets/bottom-nav/bottom_nav_0_on.png" alt="nav-off" v-else />
      <span :class="currentView === 'casino' ? 'text--selected' : 'text'">
        {{ t("TID_LB_CASINO") }}
      </span>
    </div>
    <!-- 导航按钮 -->
    <div class="menu-item" @click="setView('main_affiliate_page')">
      <!-- <div
        class="selected-indicator"
        v-if="currentView === 'main_affiliate_page'"
      >
        <div class="circle"></div>
      </div> -->
      <img
        src="@/assets/bottom-nav/bottom_nav_1_off.png"
        alt="nav-off"
        v-if="currentView !== 'main_affiliate_page'"
      />
      <img src="@/assets/bottom-nav/bottom_nav_1_on.png" alt="nav-on" v-else />
      <span
        :class="
          currentView === 'main_affiliate_page' ? 'text--selected' : 'text'
        "
      >
        {{ t("TID_LB_AFFILIATE") }}
      </span>
    </div>
    <!-- 导航按钮 -->
    <div class="menu-item" @click="setView('deposit-withdraw')">
      <!-- <div class="selected-indicator" v-if="currentView === 'casino'">
        <div class="circle"></div>
      </div> -->
      <img
        src="@/assets/bottom-nav/bottom_nav_2_off.png"
        alt="nav-off"
        v-if="currentView !== 'deposit-withdraw'"
      />
      <img src="@/assets/bottom-nav/bottom_nav_2_on.png" alt="nav-off" v-else />
      <span :class="currentView === 'deposit-withdraw' ? 'text--selected' : 'text'">
        {{ t("TID_LB_DEPOSIT") }}
      </span>
    </div>
    <!-- 导航按钮 -->
    <div class="menu-item" @click="setView('promotion')">
      <!-- <div class="selected-indicator" v-if="currentView === 'esports'">
        <div class="circle"></div>
      </div> -->
      <img
        src="@/assets/bottom-nav/bottom_nav_3_off.png"
        alt="nav-off"
        v-if="currentView !== 'promotion'"
      />
      <img src="@/assets/bottom-nav/bottom_nav_3_on.png" alt="nav-on" v-else />
      <span :class="currentView === 'promotion' ? 'text--selected' : 'text'">
        {{ t("TID_LB_PROMOTE")}}
      </span>
    </div>
    <!-- 导航按钮 -->
    <div class="menu-item" @click="setView('profile')">
      <!-- <div class="selected-indicator" v-if="currentView === 'profile'">
        <div class="circle"></div>
      </div> -->
      <img
        src="@/assets/bottom-nav/bottom_nav_4_off.png"
        alt="nav-off"
        v-if="currentView !== 'profile'"
      />
      <img src="@/assets/bottom-nav/bottom_nav_4_on.png" alt="nav-on" v-else />
      <span class="text">
        {{ t("TID_LB_PROFILE") }}
      </span>
    </div>
  </div>

  <LeftMenu :close="() => (menuOpen=false)" :open="menuOpen"/>

  <IosGuideModal
    v-model:showIosModal="showIosModal"
    @onCloseModal="showIosModal = false"
    @onClosePopover="showPopover = false"
  />

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Loading from "@/components/Loading";
import IosGuideModal from "@/components/IosGuideModal";
import LeftMenu from "@/components/LeftMenu";

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const menuOpen = ref(false);
const requestLoading = ref(false);

const store = useStore();
const currentView = computed(() => store.state.currentView);
const token = computed(() => store.state.token);

const showPopover = ref(false);
const showIosModal = ref(false);
const isPopoverOpen = ref(false);

const goTo = (page) => {
  if (token.value === "") {
    if (
      page === "rebate" ||
      page === "transaction-history" ||
      page === "casino-bet-history" ||
      page === "esports-bet-history" ||
      page === "deposit_100"
    ) {
      store.commit("setCurrentView", "login-register");
      return;
    }
  } else if (page === "deposit-withdraw") {
    store.commit("setDepositOrWithdraw", "deposit");
  }
  store.commit("setCurrentView", page);
};

const toggleBodyScroll = (disableScroll) => {
  if (disableScroll) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
};

watch(isPopoverOpen, (newValue) => {
  toggleBodyScroll(newValue);
});

watch(showPopover, (newValue) => {
  toggleBodyScroll(newValue);
});

const setView = (view) => {
  if ([
    "profile", "esports", "promotion",
    "main_affiliate_page", "deposit-withdraw",
  ].includes(view)) {
    if (token.value === "") {
      store.commit("setCurrentView", "login-register");
      return;
    }
  }

  goTo(view)
  // store.commit("setCurrentView", view);
};
</script>
<style lang="scss">
.bottom-nav {
  box-sizing: border-box;
  min-width: 320px;
  background: rgb(136, 71, 1);
  z-index: 999;
  background: -moz-linear-gradient(
    180deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 56%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 56%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 56%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#884701",endColorstr="#000000",GradientType=1);
  height: 7.1875rem;
  position: fixed;
  bottom: 0;
  width: 100%;

  @include flex(row, space-around, center);
  .menu-item {
    cursor: pointer;
    position: relative;
    width: 20%;
    @include flex(column, stretch, center);

    img {
      width: auto;
      height: 7.1875rem;
    }
    .selected-indicator {
      width: 3.125rem;
      background-color: rgb(136, 71, 1);
      height: 1.25rem;
      position: absolute;
      top: -1.25rem;
      border-radius: 18.75rem 18.75rem 0 0;
      background: radial-gradient(
        closest-side at 50% 50%,
        #ffc107,
        #ff8f00,
        #ff6f00
      );
      box-shadow: inset 0px -.5rem 1rem 0px rgba(0, 0, 0, 0.6);
      @include flex(row, center, center);
      .circle {
        width: 0.625rem;
        height: 0.625rem;
        background-color: #ffd740;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .text {
      position: absolute;
      bottom: 1.2rem;

      color: #838788;
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;

      &--selected {
        position: absolute;
        bottom: 1.2rem;

        color: #fff;
        font-size: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }
}
// .ant-drawer-content-wrapper {
//   width: 400px !important;
//   @media (max-width: 600px) {
//     width: 90vw !important;
//   }
// }
.ant-drawer-body::webkit-scrollbar {
  display: none !important;
}
.ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.ant-drawer-close {
  margin-right: 0 !important;
  padding: 0 !important;
}
.drawer-content {
  width: 100%;
}
.drawer-content::webkit-scrollbar {
  display: none !important;
  max-width: 100%;
}

.banner-img-section {
  width: 100%;
  @include flex(row, space-around, center);
  flex-wrap: wrap;
  background-color: #000;
  gap: .5rem;
  padding: .5rem 0;
  img {
    // flex: 0 0 49%;
    // max-width: 49%;
    width: calc(100% - 1rem);
    height: auto;
    // margin-bottom: 1%;
    border-radius: 0.3125rem;
    cursor: pointer;
  }
}
.tab-section {
  @include flex(column, center, center);
  width: 100%;
  margin: 1rem 0;
  gap: 0.3rem;
  background-color: #000;

  .tab-item {
    font-family: "Poppins", sans-serif !important;
    // background-color: #000;
    width: 95%;
    gap: 0.5rem;
    // border-radius: 10px;
    padding: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: 500;
    box-sizing: border-box;
    @include flex(row, flex-start, center);
    cursor: pointer;
    border-bottom: 0.0625rem solid #656666;
  }
}
.social-media-logo {
  @include flex(row, flex-start, center);
  gap: 1rem;
  margin-top: 1rem;
  img {
    width: 1.875rem;
    height: 1.875rem;
  }
}

.ant-drawer-body,
.drawer-content {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-collapse {
  width: 100%;
  background-color: #000;
}
.ant-collapse-header {
  @include flex(row-reverse, flex-start, stretch);
  // padding: 8px !important;
  padding: .5rem 0px !important;
  border-bottom: 0.0625rem solid #656666;
  border-radius: 0px !important;
}
.ant-collapse-header-text {
  @include flex(row, flex-start, center);
}
.ant-collapse-expand-icon {
  color: #fff;
  font-weight: bold;
  text-align: center;
  height: 2.8125rem !important;
}
.ant-collapse-item {
  border-bottom: none !important;
  padding: 0 .8rem;
}
.ant-collapse {
  border: none;
}
.ant-collapse-content {
  border-top: none !important;
  background-color: #000 !important;
}

.ant-collapse-content-box {
  background-color: #000;
  color: #fff;
  border-radius: 0 0 0.625rem 0.625rem;
}

.menu-collapse-content {
  @include flex(column, center, flex-start);
  gap: 1rem;
  padding: 0 1rem;
  .menu-collapse-item {
    @include flex(row, space-between, stretch);
    width: 100%;
    gap: 0.5rem;
    cursor: pointer;
    .left {
      @include flex(row, center, center);
      gap: 0.5rem;
    }
    .right {
      @include flex(row, center, center);
      color: #ff8800;
      width: max-content;
      font-weight: bold;
    }
    border-bottom: 0.0625rem solid #656666;
  }
}
</style>
