<template>
  <a-drawer placement="bottom" :open="props.open" @close="close" :headerStyle="headerStyle" :bodyStyle="bodyStyle">
    <template #title>
      <div style="font-size: 1.5rem;">
        {{ t('TID_LB_SUPPORT') }}
      </div>
    </template>
    <div v-for="(customer, k) in customers" :key="k" @click="GFunc.openUrl(customer.url)" :style="itemStyle">
      {{ customer.name }}
    </div>
  </a-drawer>
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { netApi } from "@/api";
import { GFunc } from "@/lib/GFunc";

const headerStyle = {
  border: '0',
}

const bodyStyle = {
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}

const itemStyle = {
  backgroundColor: '#e71e62',
  boxSizing: 'border-box',
  padding: '1rem 3rem',
  fontSize: '1.6875rem',
  fontWeight: 'bold',
  height: '4.125rem',
}

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const props = defineProps({
  open: Boolean,
  close: Function
})

const customers = ref([]);
onMounted(async () => {
  customers.value = await netApi.getCustomer();
})
</script>