<template>
  <section class="cashback-container">
    <PageHeader :back="onBack" title="TID_LB_CASHBACK_25" />

    <div class="cashback-inner">
      <section class="cashback-top">
        <div class="cashback-top-amount">
          <div class="cashback-top-amount-left">
            <img src="@/assets/events/cashback/icon_event3_2.png" alt="money" class="money-icon" />
            <div class="cashback-top-amount-left-right">
              <span class="title" v-if="currConf.rate">
                {{ parseFloat(currConf.rate).toFixed(0) }}% Cashback
              </span>
              <span class="title" v-else> 10% Cashback </span>
              <span class="desc">Max 25%</span>
            </div>
          </div>
        </div>

        <div class="cashback-top-progress">
          <div class="cashback-top-progress-header">
            <div class="cashback-top-amount-right">
              <p>v{{ currConf.lv ?? "1" }}</p>
            </div>
            <div class="cashback-top-progress-text" v-if="weeklyReliefStatus">
              {{ formatWithCommas(weeklyReliefStatus.playMoney) }} /
              {{ formatWithCommas(nextConf.playMoney) }} to vip
              {{ formatWithCommas(nextConf.lv) }}
            </div>
            <div class="cashback-top-progress-text" v-else>
              0.00 / 5,000.00 to vip 2
            </div>
          </div>
          <a-progress v-if="userInfo" :percent="Number((
            (weeklyReliefStatus.playMoney / nextConf.playMoney) *
            100 || 0
          ).toFixed(2))" />

          <a-progress v-else :percent="0" />
        </div>
      </section>

      <section class="cashback-second">
        <div class="cashback-second-top">
          <div class="cashback-second-top-left">
            <span class="cashback-second-top-left-title">
              {{ t("TID_LB_YOUR_WEEKLY_CASHBACK") }}
            </span>

            <span class="cashback-second-top-left-desc">
              {{ t("TID_LB_YOU_WILL_WIN_X_BRL_IN_CASHBACK_PLAYING_THIS_WEEK", {
                value:
                  GFunc.formatNumber(weeklyReliefStatus.bonus),
              }) }}
            </span>
          </div>

          <img src="@/assets/events/cashback/cash2.png" alt="cashback-money" class="cashback-second-top-right" />
        </div>

        <div class="cashback-second-period" v-if="userInfo">
          Period {{
            DateIns.format(DateIns.getCurWeekStartTime(), 'yyyy-MM-dd HH:mm:ss')
          }} ~ {{
  DateIns.format(DateIns.getCurWeekEndTime(), 'yyyy-MM-dd HH:mm:ss')
}}
        </div>

        <div v-if="weeklyReliefStatus">
          <div class="cashback-second-btn cashback-second-btn-active"
            v-if="!weeklyReliefStatus.isReceive && weeklyReliefStatus.bonus > 0" @click="claimCashback">
            {{ t("TID_LB_CLAIM_NOW") }}
          </div>

          <div class="cashback-second-btn cashback-second-btn-disabled" v-else>
            {{ t("TID_LB_CLAIM_NOW") }}
          </div>
        </div>

        <div class="cashback-second-btn cashback-second-btn-active" style="margin: 1rem 0" v-else
          @click="goTo('login-register')">
          {{ t("TID_LB_CLAIM_NOW") }}
        </div>

        <div class="cashback-second-claim-time" v-if="userInfo">
          <span>{{ t("TID_LB_NEXT_CLAIM_TIME") }}</span>
          <span>
            {{
              DateIns.format(DateIns.getCurWeekEndTime() + 1, 'yyyy-MM-dd HH:mm:ss')
            }} ~ {{
  DateIns.format(DateIns.getCurWeekEndTime() + 1000 * 60 * 60 * 24 * 4, 'yyyy-MM-dd HH:mm:ss')
}}
          </span>
        </div>
      </section>

      <section class="cashback-third">
        <div class="cashback-third-title">
          {{ t("TID_LB_VIP_CASHBACK_STATUSES") }}
        </div>

        <div class="cashback-table-content">
          <div class="table-header cashback-table-row-odd">
            <span>{{ t("TID_LB_VIP").toUpperCase() }}</span>
            <span>{{ t("TID_LB_BETS").toUpperCase() }}</span>
            <span>{{ t("TID_LB_CASHBACK").toUpperCase() }}</span>
          </div>
          <div v-for="(item, index) in weeklyReliefStatus.conf" :key="index" :class="`cashback-table-row ${index == currConf.lv ? 'cashback-table-row-selected' : ''
            } ${index % 2 == 1 ? 'cashback-table-row-odd' : ''}`">
            <span>{{ index }}</span>
            <span>{{ GFunc.formatNumber(item.playMoney) }}+ ₫</span>
            <span>{{ item.rate }}%</span>
          </div>
        </div>
      </section>

      <section class="cashback-tnc">
        <div class="title">{{ t("TID_LB_TERMS_AND_CONDITIONS") }}</div>

        <ol style="margin: 0;padding-left: 15px;">
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_1") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_2") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_3") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_4") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_5") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_6") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_7") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_RULES_CASHBACK_25_8") }}</li>
        </ol>
      </section>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { formatWithCommas } from "@/lib/utils";
import { netApi } from "@/api";
import Loading from "@/components/Loading";
import { message } from "ant-design-vue";
import PageHeader from "@/components/PageHeader";
import { DateIns } from "@/lib/DateIns";
import { GFunc } from "@/lib/GFunc";

// 30,000 - 5,000
// treat 5,000 as intiial, 30,000 as 100%
// ((8459- 5000)/(30000-5000) * 100).toFixed(0)
// status = 1 (not claimed)
// status = 2 (claimed)
// /cashback/claim
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const requestLoading = ref(false);
const store = useStore();
const userInfo = computed(() => store.state.userInfo);
const token = computed(() => store.state.token);

// 周奖励配置
const weeklyRelief = ref({});
const getWeeklyRelief = async () => {
  const resp = await netApi.getAllEventConf();
  weeklyRelief.value = resp.weeklyRelief;
}
// 周奖励信息
const weeklyReliefStatus = ref({});
const getWeeklyReliefStatus = async () => {
  const resp = await netApi.getEventWeeklyReliefStatus();
  weeklyReliefStatus.value = resp;
}

const nextConf = computed(() => {
  const { playMoney, conf } = weeklyReliefStatus.value;
  if (!conf) return {};
  const arr = conf.map(conf => conf.playMoney);
  let idx = arr.indexOf(playMoney);
  if (idx === -1) {
    arr.push(playMoney);
    arr.sort((a, b) => a - b);
    idx = arr.indexOf(playMoney) - 1;
  }
  return { ...conf[idx + 1], lv: idx + 1 };
})

const currConf = computed(() => {
  const { playMoney, conf } = weeklyReliefStatus.value;
  if (!conf) return {};
  const arr = conf.map(conf => conf.playMoney);
  let idx = arr.indexOf(playMoney);
  if (idx === -1) {
    arr.push(playMoney);
    arr.sort((a, b) => a - b);
    idx = arr.indexOf(playMoney) - 1;
  }
  return { ...conf[idx], lv: idx };
})

const goTo = (page) => {
  store.commit("setCurrentView", page);
};

const claimCashback = async () => {
  try {
    requestLoading.value = true;
    await netApi.getEventWeeklyReliefAward();
    await getWeeklyReliefStatus();
    await getWeeklyRelief();
    message.success(t("TID_LB_CLAIM_SUCCESSFULLY") + ` ₫${GFunc.formatNumber(weeklyReliefStatus.value.bonus)}`);
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const onBack = () => {
  store.commit("goBack");
};

onMounted(async () => {
  if (token.value !== "") {
    getWeeklyReliefStatus();
    getWeeklyRelief();

    try {
      requestLoading.value = true;
      const resp = await netApi.getUserInfo()
      store.commit("setUserInfo", resp.player);
    } catch (e) {
      console.log(e);
    } finally {
      requestLoading.value = false;
    }
  }
});
</script>

<style lang="scss">
.cashback-container {
  @include flex(column, center, center);
  box-sizing: border-box;

  .cashback-inner {
    width: 100%;
    box-sizing: border-box;
    @include flex(column, center, center);
    gap: 1rem;
    padding: 1rem 0;
    max-width: 600px;

    .cashback-top {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      @include flex(column, center, center);
      background-image: url("@/assets/events/cashback/25bg.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 2rem 1rem;
      color: #000;

      &-amount {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, space-between, center);
        gap: 1rem;

        &-left {
          @include flex(row, center, center);
          gap: 0.5rem;

          &-right {
            @include flex(row, flex-start, center);
            flex-wrap: wrap;
            row-gap: 0.1rem;
            column-gap: 0.5rem;

            .title {
              font-weight: 700;
              font-size: 2.875rem;
              color: #f8ff30;
              -webkit-text-stroke: 2px #240d02;
            }

            .desc {
              font-weight: 700;
              font-size: 1.375rem;
              vertical-align: middle;
              color: #ffffff;
              -webkit-text-stroke: 1px #240d02;
              padding-top: 0.95rem;
            }
          }

          .money-icon {
            width: auto;
            height: 60px;
          }
        }

        &-right {
          background-image: url("@/assets/events/cashback/bg_vip.png");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          width: 160px;
          height: 70.4px;

          p {
            text-align: center;
            color: #f96d30;
            font-size: 1.625rem;
            font-weight: 700;
            vertical-align: bottom;
            position: relative;
            left: 1rem;
            bottom: .5rem;
          }
        }
      }

      &-progress {
        box-sizing: border-box;
        width: 100%;
        @include flex(column, center, left);

        &-header {
          @include flex(row, center, center);
        }

        &-text {
          font-size: 1.25rem;
          width: 100%;
          box-sizing: border-box;
          text-align: right;
          color: #f8ff30;
          text-shadow: #240d02 2px 0 0,
            #240d02 0 2px 0,
            #240d02 -2px 0 0,
            #240d02 0 -2px 0;
        }
      }

      .ant-progress-text {
        color: #f8ff30;
      }
    }

    .cashback-second {
      background-color: #000;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem 1rem;

      &-top {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, space-between, stretch);
        gap: 0.5rem;

        &-left {
          @include flex(column, space-around, flex-start);
          font-size: 0.9rem;

          &-title {
            font-weight: bold;
            color: #fff;
            font-size: 1.625rem;
          }

          &-desc {
            font-size: 1.125rem;
          }
        }

        &-right {
          width: 20%;
        }
      }

      &-period {
        color: #e71e62;
        width: 100%;
        box-sizing: border-box;
        font-size: 1.3125rem;
        margin: 1rem 0;
      }

      &-btn {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        padding: 1rem 0;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;

        &-disabled {
          background: linear-gradient(#232627, #6b6b64);
          color: #8c8c8c;
        }

        &-active {
          background: linear-gradient(#ff2f75, #8e1541);
          color: #fff;
        }
      }

      &-claim-time {
        width: 100%;
        box-sizing: border-box;
        font-size: 1.3125rem;
        @include flex(column, center, flex-start);
        margin: 1rem 0 0.5rem 0;
      }
    }

    .cashback-third {
      background-color: #000;
      width: 100%;
      box-sizing: border-box;
      padding: .5rem 0;

      &-title {
        width: 100%;
        box-sizing: border-box;
        color: #fff;
        font-size: 1.625rem;
        padding: .5rem;
      }

      .table-header {
        width: 100%;
        box-sizing: border-box;
        font-size: 1.125rem;
        padding: 0.5rem 0;
        @include flex(row, space-around, center);

        span {
          flex: 0 0 32%;
          text-align: center;
          color: #e71e62;
        }
      }

      .cashback-table-content {
        @include flex(column, center, center);
        width: 100%;
        box-sizing: border-box;

        .cashback-table-row {
          width: 100%;
          box-sizing: border-box;
          @include flex(row, space-around, center);
          padding: 0.5rem 0;

          span {
            flex: 0 0 32%;
            font-size: 1.125rem;
            text-align: center;
          }

          &-odd {
            background-color: #252525;
          }

          &-selected {
            color: #e71e62;
          }
        }
      }
    }

    .cashback-tnc {
      width: 100%;
      box-sizing: border-box;
      font-size: 1.125rem;
      padding: 0 1rem;

      .title {
        color: #fff;
        font-weight: bold;
        font-size: 1.5625rem;
      }

      li {
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
