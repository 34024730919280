<template>
  <a-modal
    :open="showDepositModal"
    centered
    :closable="false"
    :maskClosable="true"
    class="deposit-bonus-modal"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        display: 'none',
      },
    }"
  >
    <div class="deposit-bonus-modal-content">
      <div class="deposit-bonus-modal-content-close-icon">
        <span>{{ t("TID_LB_DEPOSIT_BONUS") }}</span>
        <img
          src="@/assets/common/close.png"
          alt="close"
          @click="onCloseModal"
        />
      </div>
      <div class="deposit-bonus-modal-content-inner">
        <span>{{ t("TID_LB_BONUS_TNC_1") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_2") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_3") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_4") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_5") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_6") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_7") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_8") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_9") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_10") }}</span>
        <br />
        <span>{{ t("TID_LB_BONUS_TNC_11") }}</span>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

// Correct event names and prop handling
defineProps({
  showDepositModal: Boolean,
});

const emit = defineEmits(["onCloseModal"]);

const { t } = useI18n();

const onCloseModal = () => {
  emit("onCloseModal");
};
</script>

<style lang="scss">
.deposit-bonus-modal {
  .ant-modal-content {
    background-color: #000 !important;
    padding: 0.5rem;

    img {
      width: 100%;
      height: auto;
    }
  }
  &-content {
    &-close-icon {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, center);
      margin-bottom: 0.5rem;
      font-weight: bold;
      color: #ff9700;
      img {
        width: 30px !important;
        cursor: pointer;
        height: 30px !important;
      }
    }
    &-inner {
      width: 100%;
      box-sizing: border-box;
      text-align: left;
      @include flex(column, flex-start, flex-start);
      overflow-y: scroll;
      height: 500px;
    }
  }
}
</style>
