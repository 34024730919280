/**
 * 针对 config.js 配置文件解析
 * 开关控制管理
 */

import { decode } from "js-base64";
import { Consts } from "./Consts";
import { DataIns } from "./DataIns";

// import { ChannelMgr } from "../mgr/ChannelMgr";

const CFG_CFG = [
    "__lg_webclient_api_cfg__"
]
let CFG_NAME = "__lg_webclient_api_cfg__";
(function () {
    for (let i = 0; i < CFG_CFG.length; i++) {
        let key = CFG_CFG[i];
        if (window[key]) {
            CFG_NAME = key;
            break;
        }
    }
})();
const CfgAnalyze = {
    // 获取配置数据
    getCfgData(key) {
        return window[CFG_NAME] && window[CFG_NAME][key];
    },

    // 是否关闭日志
    isLogHide() {
        return this.getCfgData("D_LOG") || false;
    },

    // 是否测试渠道
    isDebugChannel() {
        return this.getCfgData("DEBUG_CHANNEL") || false;
    },

    // 审核版本号
    getReviewVersion() {
        return this.getCfgData("R_VERSION") || 0;
    },

    // Faq链接地址
    getFaqLink() {
        return this.getCfgData("FAQ_LINK");
    },

    // Google链接地址
    getInviteLink() {
        return DataIns.checkstring(this.getCfgData("INVITE_LINK")).trim();
    },

    // 获取 api 地址
    getBaseApi() {
        return this.getCfgData("BASE_API");
    },

    // 获取二次跳转 api 地址
    getBaseApiEx() {
        return this.getCfgData("BASE_APIEx");
    },

    // 包类型
    getPackType() {
        return this.getCfgData("PACK_TYPE") || 0;
    },

    // 货币类型 $ || R$ || ₫ || ...
    getCurrencyType() {
        return this.getCfgData("CURRENCY_TYPE") || "$"
    },

    // 货币简称 USDT || PHP || MXN || VND || ...
    getCurrencyShortName() {
        return this.getCfgData("CURRENCY_SHORT_NAME") || "USD"
    },

    // 包产品类型 APP || HOTEL
    getProductType() {
        return this.getCfgData("PRODUCT_TYPE") || "APP";
    },

    // 产品包版本
    getProductPackVer() {
        return this.getCfgData("PRODUCT_PACK_VER") || 1;
    },

    // 是否马甲包
    isVestPack() {
        return this.getCfgData("PACK_VEST") || false;
    },

    // 设备注册账号上限
    getDeviceAccountLimit() {
        return DataIns.checkint(this.getCfgData("DEVICE_ACCOUNT_LIMIT")) || 1;
    },

    // 获取U快捷金额支付 "充值数量,奖励数量,奖励比例"
    getFastUPayAmounts() {
        return DataIns.checkarray(this.getCfgData("FAST_U_PAY_AMOUNTS"));
    },

    // 首次充值奖励 "充值下限,充值上限,奖励比例"
    getFirstRechargeAward() {
        return this.getCfgData("FIRST_RECHARGE_AWARD") || "0,0,0";
    },

    // 存款最低额度
    getDepositLimitMin() {
        return this.getCfgData("DEPOSIT_LIMIT_MIN") || "10,10";
    },

    // 存款佣金
    getDepositCommission() {
        return this.getCfgData("DEPOSIT_COMMISSION") || [1500, 10, [1500]];
    },

    // 获取 ios移动配置文件 *.mobileconfig
    getIosMobileConfigFilePath() {
        return DataIns.checkstring(this.getCfgData("IOS_MOBILE_CONFIG_FILE_PATH")).trim();
    },

    // 获取滚动通知
    getNoticeContent() {
        return DataIns.checkstring(this.getCfgData("NOTICE_CONTENT"));
    },

    // 获取语种
    getI18nLanguage() {
        return this.getCfgData("I_Lan") || "EN";
    },

    // 获取维护公告
    getMaintenanceNotice() {
        return DataIns.checkstring(this.getCfgData("MAINTENANCE_NOTICE")).trim();
    },

    // 获取维护公告客服
    getMaintenanceCS() {
        return DataIns.checkstring(this.getCfgData("MAINTENANCE_CS")).trim();
    },

    // 获取可提现时间，调整为限制提现时间
    getWithdrawTime() {
        return DataIns.checkstring(this.getCfgData("WITHDRAW_TIME")).trim() || "00:00:00-23:59:59";
    },

    // 获取官方频道
    getOfficialChannel() {
        return DataIns.checkstring(this.getCfgData("OFFICIAL_CHANNEL")).trim();
    },

    // 获取官方跳转链接
    getOfficialCS() {
        return DataIns.checkstring(this.getCfgData("OFFICIAL_CS")).trim();
    },

    // 获取配置的游戏
    getConfigGames() {
        return DataIns.checkarray(this.getCfgData("CONFIG_GAMES"));
    },

    // 获取不支持的游戏平台
    getDisableGames() {
        return DataIns.checkarray(this.getCfgData("D_GAMES"));
    },

    // 获取未开放的游戏列表
    getLockGames() {
        return DataIns.checkarray(this.getCfgData("LOCK_GAMES"));
    },

    // 获取独开浏览器页面的游戏列表
    getBlankGames() {
        return DataIns.checkarray(this.getCfgData("BLANK_GAMES"));
    },

    // 获取独开浏览器页面的指定游戏列表
    getBlankGameKeys() {
        return DataIns.checkarray(this.getCfgData("BLANK_GAME_KEYS"));
    },

    // 获取配置的推荐游戏
    getHotGames() {
        return DataIns.checkarray(this.getCfgData("HOT_GAMES"));
    },

    // 获取玩过游戏数量上限
    getHisGamesMax() {
        return DataIns.checkint(this.getCfgData("HIS_GAMES_MAX"));
    },

    // 邀请码Key
    getKeyCodeKey() {
        return DataIns.checkstring(this.getCfgData("KEY_CODE")).trim() || "code";
    },

    // 红包码Key
    getKeyRPCodeKey() {
        return DataIns.checkstring(this.getCfgData("KEY_RP_CODE")).trim() || "rpcode";
    },

    // 获取免责声明
    getDisclaimersTip() {
        return DataIns.checkstring(this.getCfgData("DISCLAIMERS_TIP")).trim();
    },

    // 版权申明
    getCopyRight() {
        return DataIns.checkstring(this.getCfgData("COPY_RIGHT")).trim();
    },

    // PIX绑定开关，分别为 [0:Name, 1:CPF, 2:Account, 3:Phone, 4:Email]
    getPixBindSwitch() {
        let cfg = DataIns.checkarray(this.getCfgData("PIX_BIND"));
        return cfg.length ? cfg : [true, true, true, true, true];
    },

    // 本地活动配置
    getEventSwitch() {
        return DataIns.checkarray(this.getCfgData("EVENT_LISTS"));
    },

    // 会员日奖励配置
    getEventMemberAward() {
        return DataIns.checkint(this.getCfgData("EVENT_MEMBER_AMAX"));
    },

    // VIP周奖励
    getVipWeekAward() {
        return DataIns.checkarray(this.getCfgData("VIP_WEEK"));
    },

    // VIP月奖励
    getVipMonthAward() {
        return DataIns.checkarray(this.getCfgData("VIP_MONTH"));
    },

    // VIP活动内数据，依次为 充值金额，升级奖励，月奖励，周奖励
    getVipEventAward() {
        return DataIns.checkarray(this.getCfgData("VIP_EVENT"));
    },

    // 奖池基数，-1表示关闭，不显示
    getJackpotBase() {
        return DataIns.checknumber(this.getCfgData("JACKPOT_BASE"));
    },

    /****************** 以下API控制开关 ******************/
    // BASE: 是否进行 base64 客户端约定 true|false
    // ROUTE_VER: 路由版本 0:默认版本 其他版本客户端需要跟服务器统一版本
    // MUL_WALLETS: 是否多钱包 true|false
    // BOX_VER: 0:注册邀请宝箱[每阶段金额] 1:信封宝箱[累计可拆奖励，奖励一致] 2:信封宝箱手动拆封[累计可拆奖励，奖励不一致]
    // RECHARGE_VER: 充值档位赠送规则 0:默认版本 1:winbox定制版本
    // RECHARGE_REBATE_VER: 0:不开启充值返佣 1:充值返佣版本

    // 是否Base64
    getApiIsBase64() {
        let apiPackSwitch = DataIns.checktable(this.getCfgData("API_PACK_SWITCH"));
        return DataIns.checkbool(apiPackSwitch.BASE);
    },

    // 获取路由版本号
    getApiRouteVer() {
        let apiPackSwitch = DataIns.checktable(this.getCfgData("API_PACK_SWITCH"));
        return DataIns.checkint(apiPackSwitch.ROUTE_VER);
    },

    // API版本号
    getApiPackVer() {
        return DataIns.checkint(this.getCfgData("API_PACK_VER"));
    },
};

// 开关控制管理
const SwitchModule = {
    _appRemoteVer: null,
    /**
     * 检查Native版本是否高于指定版本
     * @param baseVer 待检查的版本号
     */
    _checkNativeVersion(baseVer) {
        baseVer = baseVer || Consts.baseVersion;
        // SwitchModule._appRemoteVer = SwitchModule._appRemoteVer || MaytooSdk.getVersionName();
        let appVer = SwitchModule._appRemoteVer || Consts.baseVersion;
        let baseVerArr = baseVer.split(".");
        let appVerArr = appVer.split(".");
        return (appVerArr[0] > baseVerArr[0] || appVerArr[1] > baseVerArr[1] || appVerArr[2] > baseVerArr[2]);
    },

    // 是否审核版本
    _isReviewVersion() {
        return false;
        // if (!ChannelMgr.isGoogle()) {
        //     return false;
        // }
        // let baseVerList = {
        //     0: Consts.baseVersion,    // 容错
        // }
        // let packtype = CfgAnalyze.getPackType();
        // let clientReviewVer = CfgAnalyze.getReviewVersion();
        // let appReviewVer = 0;
        // if (SwitchModule._checkNativeVersion(baseVerList[packtype])) {
        //     appReviewVer = MaytooSdk.getReviewPackVer();
        // }
        // return appReviewVer > clientReviewVer;
    },

    // 获取开关配置，默认关闭
    _checkCfgSwitch(key) {
        if (!SwitchModule._isReviewVersion()) {
            return CfgAnalyze.getCfgData(key) || false;
        }
        return CfgAnalyze.getCfgData(`${key}_R`) || false;
    },

    // 检查是否审核模式
    // checkReviewModel() {
    //     return SwitchModule._isReviewVersion();
    // },

    // 公告开关
    checkAgentNoticeSwitch() {
        // if (!SwitchModule._isReviewVersion()) {
        //     const initconf = JSON.parse(cc.sys.localStorage.getItem("lg_initconf"));
        //     const noticeSwitch = DataIns.checkint(initconf.noticeSwitch);
        //     // 1开 3关
        //     return noticeSwitch == 1;
        // }
        // return CfgAnalyze.getCfgData("AGENT_NOTICE_R") || false;
        const key = "E_NOTICE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 分享开关
    // checkShareSwitch() {
    //     if (!SwitchModule._isReviewVersion()) {
    //         const initconf = JSON.parse(cc.sys.localStorage.getItem("lg_initconf"));
    //         const gameShareSwitch = DataIns.checkint(initconf.gameShareSwitch);
    //         // 1开 3关
    //         return gameShareSwitch == 1;
    //     }
    //     return CfgAnalyze.getCfgData("SHARE_R") || false;
    // },

    // 钱包开关
    checkWithdrawalSwitch() {
        // if (!SwitchModule._isReviewVersion()) {
        //     const initconf = JSON.parse(cc.sys.localStorage.getItem("lg_initconf"));
        //     const withdrawalSwitch = DataIns.checkint(DataIns.checktable(initconf.withdrawalconf).withdrawalSwitch);
        //     // 1开 3关
        //     return withdrawalSwitch == 1;
        // }
        // return CfgAnalyze.getCfgData("WITHDRAWAL_R") || false;
        const key = "E_WITHDRAWAL";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 钱包提现是否需要密码
    checkEnableWithdrawalPwdSwitch() {
        const key = "D_WITHDRAWAL_PWD";
        return !SwitchModule._checkCfgSwitch(key);
    },

    // 钱包提现是否允许小数
    checkEnableWithdrawalDecimalSwitch() {
        const key = "D_WITHDRAWAL_DECIMAL";
        return !SwitchModule._checkCfgSwitch(key);
    },

    // 提现打码进度是否显示为进度值
    checkWithdrawalPMSwitch() {
        const key = "E_WITHDRAWAL_PM";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 提现打码进度是否显示为文本形式
    checkWithdrawalTxtSwitch() {
        const key = "E_WITHDRAWAL_TXT";
        return SwitchModule._checkCfgSwitch(key);
    },

    // Usdt开关
    // checkUsdtPaymentSwitch() {
    //     if (!SwitchModule._isReviewVersion()) {
    //         const initconf = JSON.parse(cc.sys.localStorage.getItem("lg_initconf"));
    //         const usdtSwitch = DataIns.checkint(initconf.usdtSwitch);
    //         // 1开 0关
    //         return usdtSwitch == 1;
    //     }
    //     return CfgAnalyze.getCfgData("USDT_PAYMENT_R") || false;
    // },

    // 钻石商城充值提示开关
    // checkCurrencyTipSwitch() {
    //     if (!SwitchModule._isReviewVersion()) {
    //         const initconf = JSON.parse(cc.sys.localStorage.getItem("lg_initconf"));
    //         const payNotice = DataIns.checkstring(initconf.payNotice);
    //         return payNotice.trim().length > 0;
    //     }
    //     return CfgAnalyze.getCfgData("CURRENCY_TIP_R") || false;
    // },

    // Faq链接开关，默认关闭
    // checkFaqLinkSwitch() {
    //     const key = "E_FAQ_LINK";
    //     return SwitchModule._checkCfgSwitch(key);
    // },

    // AppsFlyer统计开关，默认关闭
    checkAppsFlyerSwitch() {
        const key = "E_APPS_FLYER";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 在线客服，默认关闭
    checkCustomerOnlineSwitch() {
        const key = "E_CUSTOMER_ONLINE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 邮箱开关，默认关闭
    // checkEmailSwitch() {
    //     const key = "E_EMAIL";
    //     return SwitchModule._checkCfgSwitch(key);
    // },

    // 允许Google登录界面显示账号登录，默认关闭
    checkAccLoginOnGoogleSwitch() {
        const key = "E_ACC_LOGIN_ON_GOOGLE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否强制关闭Google登录
    checkForceCloseGoogleLogin() {
        const key = "E_FORCE_CLOSE_GOOGLE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 邀请开关，默认关闭
    // checkInviteSwitch() {
    //     const key = "E_INVITE";
    //     return SwitchModule._checkCfgSwitch(key);
    // },

    // 签到开关，默认关闭
    // checkSigninSwitch() {
    //     const key = "E_SIGNIN";
    //     return SwitchModule._checkCfgSwitch(key);
    // },

    // 排行榜开关，默认关闭
    // checkRankingSwitch() {
    //     const key = "E_RANKING";
    //     return SwitchModule._checkCfgSwitch(key);
    // },

    // H5支付开关，默认关闭
    checkPurchaseH5Switch() {
        const key = "E_PURCHASE_H5";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示下载按钮，默认关闭
    checkDownloadAppSwitch() {
        const key = "E_DOWNLOAD_APP";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示多语言，默认关闭
    checkMultipleLanguageSwitch() {
        const key = "E_MULTIPLE_LANGUAGE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // H5图片上传开关，默认关闭
    checkUploadPicH5Switch() {
        const key = "E_UPLOAD_PIC_H5";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示忘记密码，默认显示，不需要配置
    checkForgotSwitch() {
        const key = "D_FORGOT_PWD";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否启用记住密码复选，不显示时，默认都记住密码
    checkRememberPwdSwitch() {
        const key = "E_REMEMBER_PWD";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示轮播链接图片
    checkRollLinkSwitch() {
        const key = "E_ROLL_LINK";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否使用配置轮播链接图片
    checkRollRemoteSwitch() {
        const key = "E_ROLL_NODE_REMOTE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否解除TRC绑定限制
    checkFreeLimitTRCSwitch() {
        const key = "D_LIMIT_TRC";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示修改登录密码
    checkChangeLoginPwdSwitch() {
        const key = "E_CHANGE_LOGIN";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示修改提现密码
    checkChangePinPwdSwitch() {
        const key = "E_CHANGE_PIN";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否支付手机验证码
    checkVerifyCodeSwitch() {
        const key = "E_VERIFY_CODE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否支付红包功能
    checkRedPacketSwitch() {
        const key = "E_RED_PACKET";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否直接进入大厅
    checkEnterHallDirectSwitch() {
        const key = "E_ENTER_HALL_DIRECT";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否使用用户头像
    checkPlayerHeadSwitch() {
        const key = "D_PLAYER_HEAD";
        return !SwitchModule._checkCfgSwitch(key);
    },

    // 是否显示合作商
    checkPartnerSwitch() {
        const key = "E_PARTNER";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否启用签到，默认关闭
    checkCheckInSwitch() {
        const key = "E_CHECK_IN";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否使用手机做为账号，默认关闭
    checkPhoneAccSwitch() {
        const key = "E_PHONE_ACC";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否使用随机数字验证码，默认关闭
    checkNumVerifyCodeSwitch() {
        const key = "E_NUM_VERIFY_CODE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否切屏动画版本，默认关闭
    checkActionMonitorSwitch() {
        const key = "E_ACTION_MONITOR_SWITCH";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否支持PC环境切换
    checkPCEnvSwitch() {
        const key = "E_PC_ENV";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否以桌面方式进入游戏
    checkDesktopLoginGameSwitch() {
        const key = "E_DESKTOP_LOGIN_GAME";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否绑定银行卡后才能充值
    checkDepositBindSwitch() {
        const key = "E_DEPOSIT_BIND";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否启用拉新暗扣功能
    checkDeductPullReg() {
        const key = "E_DEDUCT_PULLREG";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否启用用户注册协议
    checkUserRegAgreeSwitch() {
        const key = "E_USER_REG_AGREE";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 是否弹首充活动
    checkPopFirstDepositSwitch() {
        const key = "E_POP_FIRST_DEPOSIT";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 取消游戏独开浏览器
    checkCancelBlankGameSwitch() {
        const key = "E_CANCEL_BLANK";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 游戏中是否启用悬浮按钮
    checkGamingFloatingButtonSwitch() {
        const key = "E_GAMING_FLOATING_BUTTON";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 游戏禁用WebView
    checkGamingDisableUseWebViewSwitch() {
        const key = "D_GAMING_USE_WEBVIEW";
        return SwitchModule._checkCfgSwitch(key);
    },

    // 游戏跳转H5运行
    checkGamingJumpOrigSwitch() {
        const key = "E_GAMING_JUMP_ORIG";
        return SwitchModule._checkCfgSwitch(key);
    },
}

// export default CfgAnalyze;
export { CfgAnalyze, SwitchModule };

// 本地配置
(function () {
    if (!window[CFG_NAME]) {
        let cfgs = {
            103001: {
                "PACK_TYPE": 103001,
                "PACK_VEST": false,
                "D_LOG": true,
                "FAST_U_PAY_AMOUNTS": [
                    "25,,",
                    "30,,",
                    "50,,",
                    "100,,8",
                    "200,,8",
                    "500,,8",
                    "1000,,8",
                    "2000,,8",
                    "5000,,8",
                    "10000,,8",
                    "15000,,8",
                    "20000,,8"
                ],
                "FIRST_RECHARGE_AWARD": "50,1000000,20",
                "DEPOSIT_LIMIT_MIN": "25,25",
                "DEPOSIT_COMMISSION": [1500, 10, [1500, 20000, 50000, 100000]],
                "NOTICE_CONTENT": "<b><color=#00F4F5>${p1} dá as boas-vindas aos membros! Atualmente, muitas pessoas fingem ser o serviço de atendimento ao cliente da ${p2} o objetivo de desviar propriedades. Espero que os membros sejam mais vigilantes, não enviem mensagens privadas para reembolsos e não acreditem que não haja promoções de alto valor a serem evitadas perdas de bens pessoais. atualmente tem apenas 1 telegrama oficial:</c> <color=EA4E3D  click='onClick' param='${p3}'>${p4}</c></b>",
                "MAINTENANCE_NOTICE": "",
                "MAINTENANCE_CS": "https://",
                "WITHDRAW_TIME": "22:00:00-23:59:59",
                "OFFICIAL_CHANNEL": "SofiaWin",
                "OFFICIAL_CS": "https://www.google.com",
                "COPY_RIGHT": "@Direitos autorais 2002-2024",
                "COPY_RIGHT1": "Copyright © 2024 SofiaWin. All rights reserved.",
                "INVITE_LINK": "/mobileconfig/SofiaWin.apk",
                "IOS_MOBILE_CONFIG_FILE_PATH": "/mobileconfig/SofiaWinS.mobileconfig",
                "DISCLAIMERS_TIP": "Este site oferece jogos que atendem a indivíduos experientes em risco. Para usar nossa plataforma, você deve ter pelo menos 18 anos de idade. Não podemos ser responsabilizados ou responsáveis por quaisquer consequências.",
                "I_Lan": "ZH",
                "CURRENCY_TYPE": "₫",
                "E_APPS_FLYER": false,
                "E_NOTICE": true,
                "E_WITHDRAWAL": true,
                "E_WITHDRAWAL_PM": true,
                "E_WITHDRAWAL_TXT": true,
                "E_CUSTOMER_ONLINE": true,
                "E_ACC_LOGIN_ON_GOOGLE": false,
                "E_FORCE_CLOSE_GOOGLE": false,
                "E_PURCHASE_H5": false,
                "E_DOWNLOAD_APP": false,
                "E_MULTIPLE_LANGUAGE": false,
                "E_UPLOAD_PIC_H5": true,
                "E_ROLL_NODE_REMOTE": true,
                "D_LIMIT_TRC": false,
                "E_CHANGE_LOGIN": true,
                "E_CHANGE_PIN": true,
                "E_VERIFY_CODE": false,
                "E_RED_PACKET": true,
                "E_ENTER_HALL_DIRECT": true,
                "D_PLAYER_HEAD": true,
                "E_CANCEL_BLANK": true,
                "E_GAMING_FLOATING_BUTTON": true,
                "D_GAMING_USE_WEBVIEW": true,
                "E_GAMING_JUMP_ORIG": true,
                "D_GAMES": ["SABA_VIRTUAL", "E1SPORT_ESPORTS", "DB_HASH", "PP_EGAME", "PP_LIVE", "JDB_FH", "JILI_FH"],
                "LOCK_GAMES": [],
                "BLANK_GAMES": ["PP_EGAME", "PP_LIVE", "SABA_VIRTUAL", "E1SPORT_ESPORTS"],
                "BLANK_GAME_KEYS": [
                    "PG-SLOT-132"
                ],
                "CONFIG_GAMES": [
                    "COLL_COLL",
                    "HOT_HOT",
                    "PG_SLOT",
                    "PP_SLOT",
                    "PP_EGAME",
                    "DB_HASH",
                    "PP_LIVE",
                    "JDB_FH",
                    "JILI_FH",
                    "SABA_VIRTUAL",
                    "E1SPORT_ESPORTS"
                ],
                "HOT_GAMES": [
                    "PG-SLOT-132",
                    "PG-SLOT-093",
                    "PG-SLOT-070",
                    "PG-SLOT-043",
                    "PG-SLOT-042",
                    "PG-SLOT-030",
                    "PG-SLOT-023",
                    "PG-SLOT-003",
                    "PG-SLOT-018",
                    "PG-SLOT-101",
                    "PG-SLOT-112",
                    "PG-SLOT-111",
                    "PG-SLOT-105",
                    "PG-SLOT-065",
                    "PG-SLOT-058",
                    "PG-SLOT-026",
                    "PP-SLOT-002",
                    "PP-SLOT-245",
                    "PP-SLOT-343",
                    "PP-SLOT-344",
                    "PP-EGAME-001",
                    "PP-EGAME-005",
                    "JILI-FISH-001",
                    "JILI-FISH-007",
                    "JILI-FISH-011",
                    "JDB-FISH-009"
                ],
                "HIS_GAMES_MAX": 255,
                "PIX_BIND": [true, true, true, true, true],
                "EVENT_LISTS": [[1, 1], [2, 1], [3, 1], [4, 1], [5, 0], [6, 0], [7, 0]],
                "JACKPOT_BASE": 2500000,
                "EVENT_MEMBER_AMAX": 77777,
                "KEY_CODE": "sofiawinkey",
                "KEY_RP_CODE": "sofiawinrpkey",
                "E_PARTNER": true,
                "E_CHECK_IN": false,
                "E_PHONE_ACC": false,
                "E_NUM_VERIFY_CODE": false,
                "E_ACTION_MONITOR_SWITCH": false,
                "E_ACTION_SWITCH": false,
                "E_PC_ENV": false,
                "E_DESKTOP_LOGIN_GAME": false,
                "E_DEPOSIT_BIND": false,
                "E_DEDUCT_PULLREG": false,
                "D_WITHDRAWAL_PWD": false,
                "D_WITHDRAWAL_DECIMAL": false,
                "E_USER_REG_AGREE": true,
                "E_REMEMBER_PWD": true,
                "E_POP_FIRST_DEPOSIT": true,
                "R_VERSION": 0,
                "API_PACK_VER": 1,
                "API_PACK_SWITCH": {
                    "BASE": false,
                    "ROUTE_VER": 0,
                    "MUL_WALLETS": true,
                    "BOX_VER": 1,
                    "RECHARGE_VER": 1,
                    "RECHARGE_REBATE_VER": 1,
                },
            },
        }
        let appid = 103001;
        window[CFG_NAME] = cfgs[appid];
        window[CFG_NAME].D_LOG = false;
        window[CFG_NAME].DEBUG_CHANNEL = true;
        window[CFG_NAME].BASE_API = "http://localhost:49888";
        window[CFG_NAME].BASE_APIEx = "http://localhost:49888";
    }
    if (window[CFG_NAME] && typeof (window[CFG_NAME]) === "string") {
        let cfg = decode(window[CFG_NAME]);
        window[CFG_NAME] = JSON.parse(decode(cfg.slice(31)));
    }
})()
