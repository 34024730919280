/**
 * 游戏数据及逻辑模块
 */

import { ref } from "vue";
import { KGEvent } from "@/api/KGEvent";
import eventBus from "@/eventBus";
import { CfgAnalyze, SwitchModule } from "@/lib/CfgAnalyze";
import { EGamePlatform, EGameType } from "@/lib/Consts";
import { GFunc } from "@/lib/GFunc";
import { ObjectIns } from "@/lib/ObjectIns";
import { UserCtrl } from "./UserCtrl";
// import { GlobalCtrl } from "./GlobalCtrl";
// import { MutualCtrl } from "./MutualCtrl";
import { DataIns } from "@/lib/DataIns";
import { netApi } from "@/api";

class _GameCtrl_ {
    /* static */ hadEventInit = false;  // 模块是否已经初始化事件监听
    /* static */ gameReady = false;     // 游戏准备中，防止同时启动多个游戏
    /* static */ gamesList = ref({});        // 游戏列表 服务端数据结构
    /* static */ gamesInfo = ref({});        // 游戏列表 { gamekey: {} }
    /* static */ gamesInfoList = ref([]);    // 游戏列表排序 [{}, {}]
    /* static */ collectsList = ref([]);     // 收藏游戏列表
    /* static */ hisGamesList = ref([]);     // 玩过的游戏列表
    /* static */ hotGamesList = ref([]);     // 热门游戏列表
    /* static */ newGamesList = ref([]);     // 最新游戏列表
    /* static */ newGameTypeList = ref([]);  // 包含有新游戏的游戏类型列表

    /* static */ defaultImg = "empty";  // 游戏配置默认图片
    /* static */ localGamesCfg = {
        // 运动类
        "SABA_VIRTUAL": [
            { "gameKey": "SABA-VIRTUAL-001", "imgName": "empty" },
        ],
        // 热门
        "HOT_HOT": [
            { "gameKey": "PG-SLOT-132", "imgName": "empty" },
        ],
        // 最新
        "NEW_NEW": [],
    }

    // 游戏类型短名映射
    /* static */ GameTypeListMap = {
        "COLL": EGameType.EGT_COLL,
        "HOT": EGameType.EGT_HOT,
        "HIS": EGameType.EGT_HIS,
        "NEW": EGameType.EGT_NEW,
        "SLOT": EGameType.EGT_SLOT,
        "VIRTUAL": EGameType.EGT_VIRTUAL,
        "ESPORTS": EGameType.EGT_ESPORTS,
        "EGAME": EGameType.EGT_EGAME,
        "LIVE": EGameType.EGT_LIVE,
        "FH": EGameType.EGT_FH,
        "HASH": EGameType.EGT_HASH,
        "TABLE": EGameType.EGT_TABLE,
        "BINGO": EGameType.EGT_BINGO,
        "LOTTO": EGameType.EGT_LOTTO,
    };
    // 游戏类型短名转置映射
    /* static */ GameTypeListTransposeMap = ObjectIns.transpose(this.GameTypeListMap);

    // 游戏平台短名映射
    /* static */ GamePlatformListMap = {
        "COLL": EGamePlatform.EGP_COLL,
        "HOT": EGamePlatform.EGP_HOT,
        "HIS": EGamePlatform.EGP_HIS,
        "NEW": EGamePlatform.EGP_NEW,
        "PG": EGamePlatform.EGP_PG,
        "PP": EGamePlatform.EGP_PP,
        "SABA": EGamePlatform.EGP_SABA,
        "E1SPORT": EGamePlatform.EGP_E1SPORT,
        "JDB": EGamePlatform.EGP_JDB,
        "JILI": EGamePlatform.EGP_JILI,
        "DB": EGamePlatform.EGP_DB,
        "BG": EGamePlatform.EGP_BG,
        "EVOLUTION": EGamePlatform.EGP_EVOLUTION,
        "HOTROAD": EGamePlatform.EGP_HOTROAD,
        "SEXYBCRT": EGamePlatform.EGP_SEXYBCRT,
        "FC": EGamePlatform.EGP_FC,
        "YL": EGamePlatform.EGP_YL,
        "KINGMAKER": EGamePlatform.EGP_KINGMAKER,
    };
    // 游戏平台短名转置映射
    /* static */ GamePlatformListTransposeMap = ObjectIns.transpose(this.GamePlatformListMap);

    // 游戏类型与游戏平台映射表 [游戏类型]: [游戏平台1, 游戏平台2, ...]
    /* static */ GameTypeWithPlatformMap = {
        [EGameType.EGT_COLL]: [EGamePlatform.EGP_COLL],
        [EGameType.EGT_HOT]: [EGamePlatform.EGP_HOT],
        [EGameType.EGT_HIS]: [EGamePlatform.EGP_HIS],
        [EGameType.EGT_NEW]: [EGamePlatform.EGP_NEW],
        [EGameType.EGT_SLOT]: [EGamePlatform.EGP_PG, EGamePlatform.EGP_PP, EGamePlatform.EGP_JILI],
        [EGameType.EGT_VIRTUAL]: [EGamePlatform.EGP_SABA],
        [EGameType.EGT_ESPORTS]: [EGamePlatform.EGP_E1SPORT],
        [EGameType.EGT_EGAME]: [EGamePlatform.EGP_PP, EGamePlatform.EGP_FC, EGamePlatform.EGP_YL],
        [EGameType.EGT_LIVE]: [EGamePlatform.EGP_PP, EGamePlatform.EGP_BG, EGamePlatform.EGP_EVOLUTION, EGamePlatform.EGP_HOTROAD, EGamePlatform.EGP_SEXYBCRT],
        [EGameType.EGT_FH]: [EGamePlatform.EGP_JDB, EGamePlatform.EGP_JILI],
        [EGameType.EGT_HASH]: [EGamePlatform.EGP_DB],
        [EGameType.EGT_TABLE]: [EGamePlatform.EGP_JILI, EGamePlatform.EGP_KINGMAKER],
    };

    // 游戏平台名称
    /* static */ GamePlatformName = {
        [EGamePlatform.EGP_COLL]: `TID_LB_COLLECTION`,
        [EGamePlatform.EGP_HOT]: `TID_LB_HOT`,
        [EGamePlatform.EGP_NEW]: `TID_LB_NEW`,
        [EGamePlatform.EGP_HIS]: `TID_LB_GAME_PLAY_HISTORY`,
        [EGamePlatform.EGP_PG]: `TID_LB_PGGAME`,
        [EGamePlatform.EGP_PP]: `TID_LB_PP2GAME`,
        [EGamePlatform.EGP_SABA]: `TID_LB_SABA`,
        [EGamePlatform.EGP_E1SPORT]: `TID_LB_E1SPORT`,
        [EGamePlatform.EGP_JDB]: `TID_LB_JDB`,
        [EGamePlatform.EGP_JILI]: `TID_LB_JILI`,
        [EGamePlatform.EGP_DB]: `TID_LB_DB`,
        [EGamePlatform.EGP_BG]: `TID_LB_BG`,
        [EGamePlatform.EGP_EVOLUTION]: `TID_LB_EVOLUTION`,
        [EGamePlatform.EGP_HOTROAD]: `TID_LB_HOTROAD`,
        [EGamePlatform.EGP_SEXYBCRT]: `TID_LB_SEXYBCRT`,
        [EGamePlatform.EGP_FC]: `TID_LB_FC`,
        [EGamePlatform.EGP_YL]: `TID_LB_YL`,
        [EGamePlatform.EGP_KINGMAKER]: `TID_LB_KINGMAKER`,
    };
    /* static */ GamePlatformNameFor3001 = {
    };

    // 游戏类型名称
    /* static */ GameTypeName = {
        [EGameType.EGT_COLL]: `TID_LB_COLLECTION`,
        [EGameType.EGT_HOT]: `TID_GAME_TYPE_HOT`,
        [EGameType.EGT_NEW]: `TID_GAME_TYPE_NEW`,
        [EGameType.EGT_HIS]: `TID_LB_GAME_PLAY_HISTORY`,
        [EGameType.EGT_SLOT]: `TID_GAME_TYPE_SLOTS`,
        [EGameType.EGT_VIRTUAL]: `TID_GAME_TYPE_VIRTUAL`,
        [EGameType.EGT_ESPORTS]: `TID_GAME_TYPE_ESPORTS`,
        [EGameType.EGT_EGAME]: `TID_GAME_TYPE_EGAME`,
        [EGameType.EGT_LIVE]: `TID_GAME_TYPE_LIVE`,
        [EGameType.EGT_FH]: `TID_GAME_TYPE_FISH`,
        [EGameType.EGT_HASH]: `TID_GAME_TYPE_HASH`,
        [EGameType.EGT_TABLE]: `TID_GAME_TYPE_TABLE`,
        [EGameType.EGT_BINGO]: `TID_GAME_TYPE_BINGO`,
        [EGameType.EGT_LOTTO]: `TID_GAME_TYPE_LOTTO`,
    };
    /* static */ GameTypeNameFor3001 = {
    };

    // 游戏类型列表
    /* static */ GameTypeCfgList = ref([]);
    // 游戏平台列表
    /* static */ GamePlatformCfgList = [];
    // 游戏类型:平台列表 {类型: [平台1, 平台2, ...]}
    /* static */ GameTypeToPlatformCfgList = {};
    // 游戏平台:类型列表 {平台: [类型1, 类型2, ...]}
    /* static */ GamePlatformToTypeCfgList = {};

    constructor() {
        GFunc.log("GameCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("GameCtrl initCtrl");
        this.initData();
    }

    initModel() {
        GFunc.log("GameCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
        // TODO 待处理
        let self = this;
        eventBus.on(KGEvent.EVENT_TOKEN_TIMEOUT, () => {
            self.resetGameCollectsStatus();
            self.collectsList.value = []; // 收藏游戏列表
            self.hisGamesList.value = []; // 玩过的游戏列表
            // 通知收藏列表改变
            eventBus.emit(KGEvent.EVENT_COLLECTS_GAME_CHANGE, {});
        });
    }


    // 初始化代理数据
    initData() {
        this.gameReady = false;
        this.gamesList.value = {};
        this.gamesInfo.value = {};        // 游戏列表
        this.gamesInfoList.value = [];    // 游戏列表排序
        this.collectsList.value = [];     // 收藏游戏列表
        this.hisGamesList.value = [];     // 玩过的游戏列表
        this.hotGamesList.value = [];     // 热门游戏列表
        this.newGamesList.value = [];     // 最新游戏列表
        this.newGameTypeList = [];  // 包含有新游戏的游戏类型列表
        // 游戏平台及类型名称
        this.tranGamePlatformOrTypeName();
        // 当前配置的游戏
        this.tranConfigGames();
        // // 初始化本地游戏配置
        // this.initLocalGamesCfg();
    }

    // // 初始化本地游戏配置
    // // 热门游戏，优先使用配置列表
    // /* static */ initLocalGamesCfg() {
    //     let hotGameCfg = CfgAnalyze.getHotGames();
    //     if (hotGameCfg.length > 0) {
    //         let hotGames = [];
    //         for (let i = 0; i < hotGameCfg.length; i++) {
    //             hotGames.push({ gameKey: hotGameCfg[i] });
    //         }
    //         this.localGamesCfg["HOT_HOT"] = hotGames;
    //     }
    // }

    // 整理游戏平台及类型名称
    /* static */ tranGamePlatformOrTypeName() {
        let platformName = this.GamePlatformNameFor3001;
        let typeName = this.GameTypeNameFor3001;
        if (platformName) {
            this.GamePlatformName = ObjectIns.mergeKeep(this.GamePlatformName, platformName);
        }
        if (typeName) {
            this.GameTypeName = ObjectIns.mergeKeep(this.GameTypeName, typeName);
        }
    }

    // TODO 待拓展支持配置格式类型...
    // 整理当前配置的游戏类型及平台，目前只支持配置格式: platform_gameType
    /* static */ tranConfigGames() {
        let cfgGames = CfgAnalyze.getConfigGames();
        let disGames = CfgAnalyze.getDisableGames();
        cfgGames = cfgGames.filter(game => !disGames.includes(game));
        for (let i = 0; i < cfgGames.length; i++) {
            let cfg = cfgGames[i];
            let [platform, type] = cfg.split(/_/);
            if (!platform || !type) {
                continue;
            }
            let typeIdx = this.GameTypeListMap[type];
            let platformIdx = this.GamePlatformListMap[platform];
            // 游戏类型
            if (this.GameTypeCfgList.value.indexOf(typeIdx) === -1) {
                this.GameTypeCfgList.value.push(typeIdx);
            }
            // 游戏平台
            if (this.GamePlatformCfgList.indexOf(platformIdx) === -1) {
                this.GamePlatformCfgList.push(platformIdx);
            }
            // 游戏类型:平台
            if (!this.GameTypeToPlatformCfgList[typeIdx]) {
                this.GameTypeToPlatformCfgList[typeIdx] = [];
            }
            if (this.GameTypeToPlatformCfgList[typeIdx].indexOf(platformIdx) === -1) {
                this.GameTypeToPlatformCfgList[typeIdx].push(platformIdx);
            }
            // 游戏平台:类型
            if (!this.GamePlatformToTypeCfgList[platformIdx]) {
                this.GamePlatformToTypeCfgList[platformIdx] = [];
            }
            if (this.GamePlatformToTypeCfgList[platformIdx].indexOf(typeIdx) === -1) {
                this.GamePlatformToTypeCfgList[platformIdx].push(typeIdx);
            }
        }
    }

    /**
     * 获取游戏平台
     */
    getGamePlatforms(gameType) {
        let gamePlatforms = DataIns.checkarray(this.GameTypeWithPlatformMap[gameType]);
        return gamePlatforms;
    }

    /**
     * 获取配置的游戏类型
     */
    getGameTypeCfgList() {
        return DataIns.checkarray(this.GameTypeCfgList.value);
    }

    /**
     * 获取配置的游戏平台
     */
    getGamePlatformCfgList() {
        return DataIns.checkarray(this.GamePlatformCfgList);
    }

    /**
     * 通过游戏平台，获取对应平台下配置的游戏类型
     * @param {number} gamePlatform 参考 EGamePlatform
     */
    getSubGameTypeCfgListFromPlatform(gamePlatform) {
        let cfg = DataIns.checktable(this.GamePlatformToTypeCfgList);
        return DataIns.checkarray(cfg[gamePlatform]);
    }

    /**
     * 通过游戏类型，获取对应类型下配置的游戏平台
     * @param {number} gameType     参考 EGameType
     */
    getSubGamePlatformCfgListFromType(gameType) {
        let cfg = DataIns.checktable(this.GameTypeToPlatformCfgList);
        return DataIns.checkarray(cfg[gameType]);
    }

    /**
     * 获取游戏合并KEY: gamePlatform_gameType
     * @param {number|string} gamePlatform 参考 EGamePlatform
     * @param {number|string} gameType     参考 EGameType
     * @returns {string} "PG_SLOT"
     */
    getGameUnionName(gamePlatform, gameType, character = "_") {
        if (DataIns.getType(gamePlatform) === "string" || DataIns.getType(gameType) === "string") {
            return `${gamePlatform}${character}${gameType}`;
        } else {
            let pfs = this.GamePlatformListTransposeMap;
            let gts = this.GameTypeListTransposeMap;
            return `${pfs[gamePlatform]}${character}${gts[gameType]}`;
        }
    }

    /**
     * 检查游戏是启用
     * @param {number|string} gamePlatform 参考 EGamePlatform
     * @param {number|string} gameType     参考 EGameType
     * @returns {boolean} true|false
     */
    checkGameEnable(gamePlatform, gameType) {
        let gameUnionName = this.getGameUnionName(gamePlatform, gameType);
        return this.checkGameEnableByUnionName(gameUnionName);
    }
    /* static */ checkGameEnableByUnionName(unionName) {
        let disGames = CfgAnalyze.getDisableGames();
        let enable = disGames.indexOf(unionName) == -1;
        return enable;
    }

    /**
     * 检查游戏是否开放
     * @param {number|string} gamePlatform 参考 EGamePlatform
     * @param {number|string} gameType     参考 EGameType
     * @returns {boolean} true|false true未开放
     */
    checkGameLocked(gamePlatform, gameType) {
        let lockGames = CfgAnalyze.getLockGames();
        let gameUnionName = this.getGameUnionName(gamePlatform, gameType);
        let locked = lockGames.indexOf(gameUnionName) !== -1;
        return locked;
    }

    /**
     * 显示游戏未开放弹窗提示
     * @param {number|string} gamePlatform 参考 EGamePlatform
     * @param {number|string} gameType     参考 EGameType
     */
    showGameLockedDialog(gamePlatform, gameType) {
        let gameUnionName = this.getGameUnionName(gamePlatform, gameType);
        let tip = `TID_GAME_MAINTAIN`;
        switch (gameUnionName) {
            case "SABA_VIRTUAL":
                tip = `TID_GAME_LOCK`;
                break;
        }
        // TODO 待处理
        eventBus.emit("message", {
            type: "warning",
            message: tip
        });
    }

    /**
     * 检查游戏是否独开浏览器页面
     * @param {number|string} gamePlatform 参考 EGamePlatform
     * @param {number|string} gameType     参考 EGameType
     * @returns {boolean} true|false
     */
    checkGameBlank(gamePlatform, gameType) {
        let blankGames = CfgAnalyze.getBlankGames();
        let gameUnionName = this.getGameUnionName(gamePlatform, gameType);
        let blank = blankGames.indexOf(gameUnionName) !== -1;
        return blank;
    }

    /**
     * 检查指定游戏是否独开浏览器页面
     * @param gameKey 游戏短名
     * @returns
     */
    checkGameBlankKey(gameKey) {
        gameKey = DataIns.checkstring(gameKey).trim();
        let blankGamesKey = CfgAnalyze.getBlankGameKeys();
        let blank = blankGamesKey.indexOf(gameKey) !== -1;
        return blank;
    }

    /**
     * 检查指定游戏类型是否有新游戏
     * @param {number} gameType 参考 EGameType
     * @returns
     */
    checkNewGameType(gameType) {
        return this.newGameTypeList.indexOf(gameType) != -1;
    }

    /**
     * 获取游戏数据【参数都不传入时，将返回所有游戏列表】
     * @param {number} gamePlatform 参考 EGamePlatform
     * @param {number} gameType     参考 EGameType
     * @param {number} filterGT     参考 EGameType 需筛选出的游戏类型，仅 EGP_NEW、EGP_HOT、EGP_COLL、EGP_HIS 有效
     * @returns {Array}
     */
    /* static */ getGameConfDataByPlatform(gamePlatform, gameType, filterGT) {
        let gameUnionName = "";
        let games = (this.gamesInfoList.value);
        if (gamePlatform && gameType) {
            let enable = this.checkGameEnable(gamePlatform, gameType);
            switch (gamePlatform) {
                case EGamePlatform.EGP_NEW:
                    games = this.getGameConfDataByList(this.newGamesList.value, filterGT);
                    break;
                case EGamePlatform.EGP_HOT:
                    games = this.getGameConfDataByList(this.hotGamesList.value, filterGT);
                    break;
                case EGamePlatform.EGP_COLL:
                    games = this.getGameConfDataByList(this.collectsList.value, filterGT);
                    break;
                case EGamePlatform.EGP_HIS:
                    games = this.getGameConfDataByList(this.hisGamesList.value, filterGT);
                    break;
                default:
                    gameUnionName = this.getGameUnionName(gamePlatform, gameType);
                    games = enable ? DataIns.checkarray(this.gamesList.value[gameUnionName]) : [];
                    break;
            }
        }
        return games;
    }
    /* static */ getGameConfDataByList(gameList, gameType) {
        gameList = DataIns.checkarray(gameList);
        let games = [];
        if (!gameType) {
            games = gameList;
        } else {
            let gtStr = this.GameTypeListTransposeMap[gameType];
            for (let i = 0; i < gameList.length; i++) {
                let gameInfo = gameList[i];
                if (gameInfo.gametype === gtStr) {
                    games.push(gameInfo);
                }
            }
        }
        return games;
    }

    /**
     * 获取游戏数据，根据游戏类型获取
     * @param {number} gameType     参考 EGameType
     * @param {number} gamePlatform 参考 EGamePlatform
     * @param {number} filterGT     参考 EGameType 需筛选出的游戏类型，仅 EGP_HOT、EGP_COLL、EGP_HIS 有效
     * @returns {Array}
     */
    getGameConfDataByGameType(gameType, gamePlatform, filterGT) {
        let games = [];
        let gamePlatforms = DataIns.checkarray(this.GameTypeWithPlatformMap[gameType]);
        for (let i = 0; i < gamePlatforms.length; i++) {
            let _gamePlatform = gamePlatforms[i];
            if (gamePlatform && _gamePlatform != gamePlatform) {
                continue;
            }
            let _games = this.getGameConfDataByPlatform(_gamePlatform, gameType, filterGT);
            games = games.concat(_games);
        }
        return games;
    }

    // 获取游戏数据 - 单个游戏
    getGameConfDataByGameKey(gameKey) {
        gameKey = DataIns.checkstring(gameKey);
        let cfg = gameKey ? DataIns.checktable(this.gamesInfo.value[gameKey]) : {};
        return cfg;
    }

    // 获取游戏类型显示名称
    getGameTypeName(gameType) {
        return this.GameTypeName[gameType];
    }

    // 获取游戏平台显示名称
    getGamePlatformName(gamePlatform) {
        return this.GamePlatformName[gamePlatform];
    }

    // 渠道页面显示的游戏标题
    getGameTitleForMode(gameType, gamePlatform) {
        let title = this.getGameTypeName(gameType);
        if (!title) {
            title = this.getGamePlatformName(gamePlatform);
        }
        return title;
    }

    // 游戏列表标题
    // 有多个游戏平台时，显示平台名称，否则显示游戏类型名称
    getGameListTitle(gameType, gamePlatform) {
        let gamePlatforms = DataIns.checkarray(this.GameTypeWithPlatformMap[gameType]);
        let title = `TID_GAME_LIST_TITLE`;
        if (gamePlatforms.length > 1) {
            title = this.getGamePlatformName(gamePlatform) || title;
        } else {
            title = this.getGameTypeName(gameType) || title;
        }
        return title;
    }

    /**
     * 搜索游戏 - 模糊搜索
     * 去除前后空格之后，将搜索内容通过空格符分隔
     * @param keyword
     * @returns
     */
    fuzzySearch(keyword) {
        keyword = DataIns.checkstring(keyword).trim();
        let keywords = keyword.toLowerCase().split(/[ ]/);
        let fLists = [];
        let gIdxKeys = Object.keys(this.gamesList.value);
        for (let idx = 0; idx < gIdxKeys.length; idx++) {
            let gIdxKey = gIdxKeys[idx];
            let enable = this.checkGameEnableByUnionName(gIdxKey);
            if (!enable) { continue; }
            let gList = this.gamesList.value[gIdxKey];
            let lists = gList.filter(item => {
                let itemName = item.name.toLowerCase();
                return keywords.every(kw => itemName.includes(kw));
            });
            fLists = fLists.concat(lists);
        }
        return fLists;
    }

    // 请求游戏配置
    reqGameConf(callFunc) {
        if (!UserCtrl.getIsLogin()) {
            this.reqGameConfData({}, callFunc);
        } else {
            let gameKindIds = {};
            let reqs = [
                "reqGameCollectConfData",
                "reqGamePlayRecordConfData",
                "reqGameConfData"
            ];
            let tryReqs = async (gameKindIds) => {
                let func = reqs.shift();
                if (!func) return callFunc?.call();
                await GameCtrl[func](gameKindIds, tryReqs);
            }
            tryReqs(gameKindIds);
        }
    }

    // 请求游戏列表数据
    async reqGameConfData(gameKindIds, callFunc) {
        let self = this;
        try {
            let data = await netApi.getGameConfNew({});
            data = DataIns.checktable(data);
            self.gamesList.value = DataIns.checktable(data.games);
            self.initGameInfo(gameKindIds);
            eventBus.emit(KGEvent.EVENT_GAME_CONF_RECEIVE);
        } catch (error) {
            /* */
        } finally {
            callFunc && callFunc(gameKindIds);
        }
    }

    // 请求收藏列表数据
    async reqGameCollectConfData(gameKindIds, callFunc) {
        if (!UserCtrl.getIsLogin()) {
            callFunc && callFunc(gameKindIds);
            return;
        }
        try {
            const data = await netApi.getGameCollect({});
            let collects = DataIns.checkarray(DataIns.checktable(data).collects);
            gameKindIds.collects = collects;
        } catch (error) {
            /* */
        } finally {
            callFunc && callFunc(gameKindIds);
        }
    }

    // 请求玩过的游戏列表数据
    async reqGamePlayRecordConfData(gameKindIds, callFunc) {
        if (!UserCtrl.getIsLogin()) {
            callFunc && callFunc(gameKindIds);
            return;
        }
        try {
            const data = await netApi.getGamePlayRecord({});
            let historys = DataIns.checkarray(DataIns.checktable(data).info);
            historys.length = Math.min(historys.length, CfgAnalyze.getHisGamesMax());
            gameKindIds.historys = historys;
        } catch (error) {
            /* */
        } finally {
            callFunc && callFunc(gameKindIds);
        }
    }

    // 设置收藏
    async reqGameCollectSet(gameInfo) {
        if (!gameInfo) {
            return;
        }
        let isCollect = !DataIns.checkbool(gameInfo.isCollect);
        let reqParams = {
            id: gameInfo.id,
            type: isCollect ? 1 : 3
        };
        try {
            await netApi.setGameCollect(reqParams);
            // 设置成功，将游戏加入或移出收藏列表
            this.updateCollectsList(gameInfo);
        } catch (error) {
            /* */
        } finally {
            /* */
        }
    }

    // 设置玩过的游戏
    async reqGamePlayRecordSet(gameInfo) {
        if (!gameInfo) { return; }
        if (!UserCtrl.getIsLogin()) { return; }
        let gameid = DataIns.checkint(gameInfo.id);
        if (!gameid) { return; }
        let ids = [];
        // 判断是否已存在列表中，若存在，则删除
        let maxLength = Math.min(this.hisGamesList.value.length, CfgAnalyze.getHisGamesMax());
        for (let idx = maxLength - 1; idx >= 0; idx--) {
            let hIdx = DataIns.checkint(this.hisGamesList.value[idx].id);
            if (gameid === hIdx) {
                this.hisGamesList.value.splice(idx, 1);
            } else {
                ids.unshift(hIdx);
            }
        }
        this.hisGamesList.value.unshift(gameInfo);
        ids.unshift(gameid);
        let reqParams = { ids: ids };
        await netApi.setGamePlayRecord(reqParams);
    }

    // 请求获取游戏地址
    async reqGameAddress(gameInfo, callback) {
        if (!gameInfo) {
            return;
        }
        if (this.gameReady) {
            GFunc.log("Game in ready!");
            return;
        }
        this.gameReady = true;

        let reqParams = {
            id: 0,
            gamekey: gameInfo.gamekey,
            isDesktopLogin: SwitchModule.checkDesktopLoginGameSwitch()
        };
        try {
            let data = await netApi.getGameAddress(reqParams);
            data = DataIns.checktable(data);
            let address = DataIns.checkstring(data.url).trim();
            this.enterGame(address, callback, gameInfo);
        } catch (error) {
            this.gameReady = false;
            callback && callback();
        } finally {
            /* */
        }

        // // TODO Test Code
        // let url = "https://1mxbt.pragmaticplay.net/gs2c/playGame.do?key=token%3DMTY4NzE5OTU3NLyMsmi60Ivejdt0q4-sc52Gd37KiKafyg%26symbol%3Dvs20fruitsw%26language%3Den&stylename=1mxb_brlwin";
        // url = "https://ttt.onlinegames22.com/player/login/apiLogin0?userId=nb0ayqayEe&agentId=tagent001&tokenId=gVNoM4q_9Cujq9Rn-FnZd4qIXBUlyen5SxouoJqR3tw1mhDbjDuilYFsHm7-o_aQ&isMobileLogin=true&isShowSymbol=true&currencySymbol=&isApp=false&platform=PP&gameType=SLOT&gameCode=PP-SLOT-339&isLaunchGame=true&isLaunchGameTable=false&language=en&hall=SEXY&autoBetMode=0"
        // this.enterGame(url, callback, gameInfo);
    }

    // 进入游戏
    /* static */ enterGame(address, callback, gameInfo) {
        // 记录到玩过游戏
        this.reqGamePlayRecordSet(gameInfo);
        let isBlankGame = this.checkGameBlank(gameInfo.platform, gameInfo.gametype) || this.checkGameBlankKey(gameInfo.gamekey);
        if (isBlankGame && !SwitchModule.checkCancelBlankGameSwitch()) {
            this.enterGameBlank(address, callback);
        } else {
            this.enterGameDirect(address, isBlankGame, callback);
        }
    }

    // 独开浏览器页面进入游戏
    /* static */ enterGameBlank(address, callback) {
        this.gameReady = false;
        callback && callback();
        GFunc.openUrl(address, true);
    }

    // 在APP中进入游戏
    // eslint-disable-next-line no-unused-vars
    /* static */ enterGameDirect(address, isBlankGame, callback) {
        this.gameReady = false;
        callback(address);
        // TODO 待处理
        // let enterCallFunc = () => {
        //     this.gameReady = false;
        //     callback && callback();
        //     eventBus.emit(KGEvent.EVENT_GAME_PLAY_RECEIVE, { inGame: true });
        //     GlobalCtrl.getInstance().initNavigationData();
        // }
        // let reqParams = { name: "entergame", url: address };
        // if (MutualCtrl.getInstance().tryEnterAnother(reqParams)) {
        //     // 手机端跳转H5
        //     enterCallFunc();
        // } else {
        //     UIMgr.getInstance().addUI(UI.UI_NAME.UIGamePlay, UIIndex.UI, {
        //         url: address,
        //         isBlankGame: isBlankGame,
        //         ignore: true,
        //     }, undefined, () => { enterCallFunc(); }, false);
        // }
    }

    // 初始化游戏列表，整理成 key - value
    /* static */ initGameInfo(gameKindIds) {
        this.gamesInfo.value = {};        // 游戏列表
        this.gamesInfoList.value = [];    // 游戏列表排序
        this.collectsList.value = [];     // 收藏游戏列表
        this.hisGamesList.value = [];     // 玩过的游戏列表
        this.hotGamesList.value = [];     // 热门游戏列表
        this.newGamesList.value = [];     // 最新游戏列表
        this.newGameTypeList = [];  // 包含有新游戏的游戏类型列表
        let collects = DataIns.checkarray(gameKindIds.collects);
        let historys = DataIns.checkarray(gameKindIds.historys);
        let gIdxKeys = Object.keys(this.gamesList.value);
        for (let idx = 0; idx < gIdxKeys.length; idx++) {
            let gIdxKey = gIdxKeys[idx];
            let gList = this.gamesList.value[gIdxKey];
            // eslint-disable-next-line no-unused-vars
            gList.forEach((v, k) => {
                // 校正服务器游戏列表字段错误
                v.platform = v.platfrom;
                v.platfrom = null;
                // 游戏列表
                let gamekey = DataIns.checkstring(v.gamekey);
                this.gamesInfo.value[gamekey] = v;
                this.gamesInfoList.value.push(v);
                // 特殊游戏
                this.initSpecialGame(v);
                // 收藏游戏列表
                this.addCollectGame(v, collects);
                // 玩过的游戏列表
                this.addHisGame(v, historys);
                // 热门游戏列表
                this.addHotGameFromSer(v);
                // 最新游戏列表
                this.addNewGameFromSer(v);
                // 包含有新游戏的游戏类型列表
                this.addNewGameTypeList(v);
            });
        }
        // 过滤收藏、玩过的、热门游戏列表中，禁用的游戏
        this.filterGame(this.collectsList.value);
        this.filterGame(this.hisGamesList.value);
        this.filterGame(this.hotGamesList.value);
        this.filterGame(this.newGamesList.value);
        this.filterGame(this.gamesInfoList.value);
        this.sortGame();
    }
    /* static */ initSpecialGame(gameInfo) {
        let gameUnionName = this.getGameUnionName(gameInfo.platform, gameInfo.gametype);
        let games = this.localGamesCfg[gameUnionName];
        if (!games || games.length <= 0) { return; }
        for (let idx = 0; idx < games.length; idx++) {
            let specialGameInfo = games[idx];
            if (gameInfo.gamekey === specialGameInfo.gameKey) {
                gameInfo._imgName = specialGameInfo.imgName || this.defaultImg;
                break;
            }
        }
    }
    /* static */ addCollectGame(gameInfo, collects) {
        let gameid = DataIns.checkstring(gameInfo.id);
        let cIdx = collects.indexOf(gameid);
        gameInfo.isCollect = cIdx != -1;
        if (cIdx != -1) {
            this.collectsList.value.splice(cIdx, 0, gameInfo);
        }
    }
    /* static */ addHisGame(gameInfo, historys) {
        let gameid = DataIns.checkint(gameInfo.id);
        let hIdx = historys.indexOf(gameid);
        if (hIdx != -1) {
            this.hisGamesList.value.splice(hIdx, 0, gameInfo);
        }
    }
    /* static */ addHotGame(gameInfo) {
        let hotGames = this.localGamesCfg["HOT_HOT"];
        for (let idx = 0; idx < hotGames.length; idx++) {
            let hotGameInfo = hotGames[idx];
            if (gameInfo.gamekey === hotGameInfo.gameKey) {
                gameInfo._imgName = hotGameInfo.imgName || this.defaultImg;
                // this.hotGamesList.value.splice(idx, 0, gameInfo);
                this.hotGamesList.value[idx] = gameInfo;
                break;
            }
        }
    }
    /* static */ addHotGameFromSer(gameInfo) {
        let hotnum = DataIns.checkint(gameInfo.hotnum);
        if (hotnum > 0) {
            this.hotGamesList.value.push(gameInfo);
        }
    }
    /* static */ addNewGameFromSer(gameInfo) {
        let picknum = DataIns.checkint(gameInfo.picknum);
        if (picknum > 0) {
            this.newGamesList.value.push(gameInfo);
        }
    }
    /* static */ addNewGameTypeList(gameInfo) {
        let picknum = DataIns.checkint(gameInfo.picknum);
        let typeIdx = this.GameTypeListMap[gameInfo.gametype];
        let tIdx = this.newGameTypeList.indexOf(typeIdx);
        if (picknum > 0 && tIdx == -1) {
            this.newGameTypeList.push(typeIdx);
        }
    }
    // 过滤收藏、玩过的、热门游戏列表中，禁用的游戏
    /* static */ filterGame(games) {
        games = DataIns.checkarray(games);
        let len = games.length;
        for (let i = len - 1; i >= 0; i--) {
            let gameInfo = games[i];
            let enable = gameInfo ? this.checkGameEnable(gameInfo.platform, gameInfo.gametype) : false;
            !enable && games.splice(i, 1);
        }
    }
    // 列表排序
    // 总列表：游戏 -> 最新 -> 火热 降序
    // 火热：火热 -> 游戏 降序
    // 最新：最新 -> 游戏 降序
    /* static */ sortGame() {
        this.hotGamesList.value.sort((a, b) => {
            if (a.hotnum != b.hotnum) {
                return b.hotnum - a.hotnum;
            } else {
                return b.sort - a.sort;
            }
        });
        this.newGamesList.value.sort((a, b) => {
            if (a.picknum != b.picknum) {
                return b.picknum - a.picknum;
            } else {
                return b.sort - a.sort;
            }
        });
        this.gamesInfoList.value.sort((a, b) => {
            if (a.sort != b.sort) {
                return b.sort - a.sort;
            } else if (a.picknum != b.picknum) {
                return b.picknum - a.picknum;
            } else {
                return b.hotnum - a.hotnum;
            }
        });
    }

    // 重置游戏收藏状态
    /* static */ resetGameCollectsStatus() {
        // eslint-disable-next-line no-unused-vars
        this.collectsList.value.forEach((v, k) => {
            v.isCollect = false;
        });
    }

    // 添加或移出收藏列表
    /* static */ updateCollectsList(gameInfo) {
        let isCollect = !DataIns.checkbool(gameInfo.isCollect);
        gameInfo.isCollect = isCollect;
        if (isCollect) {
            this.collectsList.value.unshift(gameInfo);
        } else {
            for (let i = this.collectsList.value.length - 1; i >= 0; i--) {
                let info = this.collectsList.value[i];
                if (gameInfo.id == info.id) {
                    this.collectsList.value.splice(i, 1);
                }
            }
        }
        // 通知收藏列表改变
        eventBus.emit(KGEvent.EVENT_COLLECTS_GAME_CHANGE, gameInfo);
    }
}

const GameCtrl = new _GameCtrl_();

export { GameCtrl }

// DEBUG
window.GameCtrl = GameCtrl;
