import eventBus from "@/eventBus";
import { CfgAnalyze } from "./CfgAnalyze";
import { GFunc } from "./GFunc";

export const independentViews = [
  "login-register",
  "in-game",
  "transaction-history",
  "casino-bet-history",
  "esports-bet-history",
  "rebate",
  "about-us",
  "search-game",
  "game-list",
  "favourite-games",
  "change-password",
  "main_affiliate_page-list",
  "downline-list",
  "deposit-history",
  "7_days_check_in_bonus_page",
  "cashback_25_page",
  "receive_50_for_free_page",
  "convert_bonus",
  "tnc",
  "affiliate-list",
  "change-avatar",
  "vip"
];

export const topIndependentViews = [
  ...independentViews,
  "profile",
  "deposit-withdraw",
  "main_affiliate_page",
  "promotion",
]

export function sanitizeMobileNumber(inputValue) {
  return inputValue.replace(/[^0-9+()]/g, "");
}

export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-indexed
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Function to copy text to clipboard
export function copyToClipboard(content) {
  // Create a textarea element for copying the text
  const textArea = document.createElement("textarea");
  textArea.value = content;

  // Prevent the textarea from being visible or selectable
  textArea.style.position = "fixed"; // Ensure it's not scrollable
  textArea.style.opacity = "0";
  textArea.style.left = "-9999px";

  // Add it to the DOM
  document.body.appendChild(textArea);

  // Select the text in the textarea
  textArea.select();
  textArea.setSelectionRange(0, textArea.value.length);

  // Execute the copy command
  const successful = document.execCommand("copy");

  // Remove the textarea from the DOM
  document.body.removeChild(textArea);

  return new Promise((resolve, reject) => {
    if (successful) {
      eventBus.emit("message", { type: "success", message: "TID_COPY_SUCCESS" });
      resolve(content);
    } else {
      eventBus.emit("message", { type: "error", message: "TID_COPY_FAILURE" });
      reject(content);
    }
  });
}

export function formatWithCommas(input) {
  // Convert input to string if it's a number
  const strNumber = typeof input === "number" ? input.toString() : input;

  // Check if strNumber is a string
  if (typeof strNumber !== "string") {
    console.error("Input must be a string or a number.");
    return null;
  }

  // Reverse the string, and use a regex to insert a comma every three characters
  const reversed = strNumber.split("").reverse().join("");
  const withCommas = reversed.match(/.{1,3}/g).join(",");
  // Reverse it back to the original order
  return withCommas.split("").reverse().join("");
}

export function shareOnTelegram(code) {
  let keyCode = CfgAnalyze.getKeyCodeKey();
  let keyRPCode = CfgAnalyze.getKeyRPCodeKey();
  let inviteUrl = `${GFunc.getInviteLinkEx({ [keyCode]: code }, [keyRPCode])}`;
  const message = `Eu me tornei um agente no 0038.com e ganhei dinheiro enquanto jogava! Vamos ganhar dinheiro juntos, nos vemos no 0038.com!\n\n${inviteUrl}`;
  const telegramUrl = `https://telegram.me/share/url?url=${encodeURIComponent(inviteUrl)}&text=${encodeURIComponent(message)}`;
  window.open(telegramUrl, "_blank");
}

export function sendEmail(code) {
  let keyCode = CfgAnalyze.getKeyCodeKey();
  let keyRPCode = CfgAnalyze.getKeyRPCodeKey();
  let inviteUrl = `${GFunc.getInviteLinkEx({ [keyCode]: code }, [keyRPCode])}`;
  const emailBody = `
    Eu me tornei um agente no 0038.com e ganhei dinheiro enquanto jogava! Vamos ganhar dinheiro juntos, nos vemos no 0038.com!

    ${inviteUrl}
  `;
  const mailtoLink = `mailto:?subject=Join me on 0038.com&body=${encodeURIComponent(
    emailBody
  )}`;
  window.location.href = mailtoLink;
}

export function shareOnWhatsApp(code) {
  let keyCode = CfgAnalyze.getKeyCodeKey();
  let keyRPCode = CfgAnalyze.getKeyRPCodeKey();
  let inviteUrl = `${GFunc.getInviteLinkEx({ [keyCode]: code }, [keyRPCode])}`;
  const message = `Eu me tornei um agente no 0038.com e ganhei dinheiro enquanto jogava! Vamos ganhar dinheiro juntos, nos vemos no 0038.com!\n\n${inviteUrl}`;
  const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  window.open(whatsappUrl, "_blank");
}

export const shareOnFacebook = (code) => {
  let keyCode = CfgAnalyze.getKeyCodeKey();
  let keyRPCode = CfgAnalyze.getKeyRPCodeKey();
  let inviteUrl = `${GFunc.getInviteLinkEx({ [keyCode]: code }, [keyRPCode])}`;
  const baseUrl = "https://www.facebook.com/share_channel/";
  const appId = "966242223397117";
  const link = encodeURIComponent(`${inviteUrl}`);
  const url = `${baseUrl}?link=${link}&app_id=${appId}&source_surface=external_reshare&display&hashtag`;

  window.open(url, "_blank");
};

import { createApp } from "vue";
import Dialog from "@/components/Dialog";
export const showDialog = ({ title, content, close, confirm }) => {
  return new Promise(resolve => {
    const app = createApp(Dialog, {
      title, content,
      hideClose: !close,
      onClose() {
        app.unmount();
        close?.call(this);
        resolve(false);
      },
      onConfirm() {
        app.unmount();
        confirm?.call(this);
        resolve(true);
      }
    });

    const element = document.createElement('div');
    document.body.appendChild(app.mount(element).$el);
  })
}