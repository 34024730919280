<template>
  <section class="esports-bet-history-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_ESPORT_BET_HISTORY") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        class="back-btn"
        style="opacity: 0"
      />
    </div>

    <div class="esports-bet-history-inner">
      <section class="date-picker-section">
        <a-range-picker
          v-model:value="selectedDate"
          :default-picker-value="[defaultStartDate, defaultEndDate]"
          format="MMM DD"
        />
      </section>

      <!-- 1 - WIN, 2 - LOSS, 5 - NEUTRAL -->
      <section class="bet-list-section" v-if="!requestLoading">
        <div class="bet-list-item" v-for="(item, key) in dataList" :key="key">
          <span class="bet-list-item-date">{{ item.bet_time }}</span>
          <div class="bet-list-item-details">
            <span class="bet-list-item-details-duration">
              {{ calculateDuration(item.bet_time, item.settle_date) }}
            </span>
            <spa class="bet-list-item-details-amount">
              {{ t("TID_LB_BETS") + ": " }}
              <span style="font-weight: bold">{{ formatWithCommas(item.bet_amount) }}</span>
            </spa>
            <span class="bet-list-item-details-win-loss">
              {{ t("TID_LB_GAINS_LOSS") + ": " }}
              <span
                :class="
                  item.win_status === 1
                    ? 'green'
                    : item.win_status === 2
                    ? 'red'
                    : item.win_status === 5
                    ? 'grey'
                    : ''
                "
                style="font-weight: bold; font-style: normal"
              >
                {{ item.win_loss }}
              </span>
            </span>
          </div>
          <div
            class="bet-list-inner-item"
            v-for="(inner, key) in item.bet_list"
            :key="key"
          >
            <div class="bet-list-item-info-1">
              <span class="yellow">{{ inner.tournamentName }}</span>
              <span class="orange">{{ inner.matchName }}</span>
            </div>

            <div class="bet-list-item-info-2">
              <span class="bet-list-item-info-2-left">
                {{ sportsMap[inner.sportId] }}
              </span>
              <div class="bet-list-item-info-2-right">
                <span>{{ inner.marketName }}</span>
                <span>{{ inner.optionName }}</span>
              </div>
            </div>
          </div>
        </div>

        <div ref="sentinel" class="sentinel">
          <a-spin
            v-if="fetchingMoreData"
            style="color: #ffeb02"
            size="medium"
          />
          <span v-else-if="!fetchingMoreData && dataList.length > 0">
            {{ t("TID_LB_LOAD_NO_DATA") }}</span
          >
        </div>
      </section>

      <section class="skeletons" v-else>
        <div class="skeletons-item" v-for="(i, key) in 10" :key="key"></div>
      </section>

      <section
        class="no-results-section"
        v-if="dataList.length <= 0 && !requestLoading"
      >
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted, watchEffect } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import moment from "moment";
import { formatWithCommas } from "@/lib/utils";

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const defaultStartDate = dayjs().startOf("month");
const defaultEndDate = dayjs().endOf("month");
const selectedDate = ref([defaultStartDate, defaultEndDate]);

const requestLoading = ref(false);
const fetchingMoreData = ref(false);

const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const sentinel = ref(null);

const sportsMap = {
  1: "Soccer",
  2: "Ice Hockey",
  3: "Basketball",
  4: "Rugby",
  5: "Tennis",
  6: "American Football",
  7: "Baseball",
  8: "Handball",
  10: "Floorball",
  12: "Golf",
  13: "Volleyball",
  14: "Cricket",
  15: "Table Tennis",
  16: "Snooker",
  17: "Futsal",
  18: "Mixed Martial Arts",
  19: "Boxing",
  20: "Darts",
  21: "Bowls",
  24: "Water Polo",
  25: "Cycling",
  47: "Badminton",
  51: "Beach Volleyball",
  92: "Formula 1",
  93: "Specials",
  94: "Stock Car Racing",
  95: "Motorcycle Racing",
  100: "Olympic",
  101: "Asian Game",
  164: "Dota 2",
  165: "League of Legends (LoL)",
  177: "E-Football",
  178: "E-Basketball",
  179: "CS:GO",
  180: "King of Glory (KoG)",
  1001: "Virtual Soccer",
  1020: "Virtual Horse Racing",
  1021: "Virtual Greyhounds",
  1022: "Virtual Speedway",
  1023: "Virtual Motorbike Racing",
};

const onBack = () => {
  store.commit("goBack");
};

const calculateDuration = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
};

const fetchData = async (pageNumber) => {
  try {
    if (pageNumber < 2) {
      requestLoading.value = true;
    } else {
      fetchingMoreData.value = true;
    }

    const res = await reqApi.getGameHistory({
      language: locale.value,
      page: currentPage.value,
      sport: 1,
      start_date: selectedDate.value[0].format("YYYY-MM-DD"),
      end_date: selectedDate.value[1].format("YYYY-MM-DD"),
      version: "3.6.2",
    });
    if (res) {
      dataList.value = [...dataList.value, ...res.data]; // Append new data
      currentPage.value = res.current_page;
      lastPage.value = res.last_page;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
    fetchingMoreData.value = false;
  }
};

const observer = new IntersectionObserver(
  ([entry]) => {
    if (entry.isIntersecting && lastPage.value > currentPage.value) {
      currentPage.value += 1;
      fetchData(currentPage.value + 1);
    }
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  }
);

watchEffect(() => {
  if (sentinel.value) {
    observer.observe(sentinel.value);
  }
});

watch(selectedDate, () => {
  dataList.value = [];
  fetchData(1);
});

onMounted(() => {
  fetchData(currentPage.value);
});

onUnmounted(() => {
  if (sentinel.value) {
    observer.unobserve(sentinel.value);
  }
});
</script>

<style lang="scss">
.esports-bet-history-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    @include flex(row, space-between, center);
    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
      text-align: center;
      width: 60%;
    }
  }
  .esports-bet-history-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 430px;
    .date-picker-section {
      width: 100%;
      box-sizing: border-box;
    }
    .table-header {
      width: 100%;
      box-sizing: border-box;
      background-color: #7c6d08;
      @include flex(row, space-around, center);
      padding: 0.4rem 0;
      font-size: 0.8rem;
      margin: 1rem 0;
      gap: 0.5rem;
      .title {
        width: 25%;
        text-align: center;
      }
    }
    .bet-list-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(column, center, center);
    }
    .bet-list-item {
      width: 100%;
      box-sizing: border-box;
      @include flex(column, center, center);
      height: max-content;
      border-bottom: 1px solid #d9d9d9;
      padding: 1rem 0 1rem 0;
      gap: 0.5rem;
      .bet-list-inner-item {
        width: 100%;
        box-sizing: border-box;
      }
      &-date {
        width: 100%;
        box-sizing: border-box;
      }
      &-details {
        width: 100%;
        background-color: #7c6d08;
        @include flex(row, space-between, center);
        padding: 0.2rem 0.3rem;
        box-sizing: border-box;
        &-win-loss {
          font-style: italic;
        }
      }
      &-info-1 {
        width: 100%;
        @include flex(column, center, flex-start);
        font-size: 0.9rem;
        box-sizing: border-box;
      }
      &-info-2 {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, space-between, stretch);
        font-size: 0.9rem;
        &-right {
          @include flex(column, center, flex-end);
        }
      }
    }

    .bet-list-item:last-child {
      border-bottom: none;
    }

    @media (max-width: 600px) {
      padding: 0.5rem;
    }
  }
}
</style>
