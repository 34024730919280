/**
 * 数据类型转换及检测
 */

const typeMap = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
};

const DataIns = {
    getType(obj) {
        const toString = Object.prototype.toString;
        return typeMap[toString.call(obj)];
    },

    /**
     * 判断是否数值
     * @param {*} value
     * @param {*} def
     * @returns
     */
    checknumber(value, def = 0) {
        const num = Number(value);
        if (isNaN(num)) {
            return def;
        }
        return num;
    },

    /**
     * 返回 int 值
     * 1. 如果不是整数，则返回上取整的值
     * 2. 如果不是数字，返回 0
     * @param {*} value
     * @returns
     */
    checkint(value) {
        return Math.round(this.checknumber(value));
    },

    /**
     * 返回 bool 值 原始值
     * @param {*} value
     * @returns
     */
    checkbool(value) {
        const bool = new Boolean(value);
        return bool.valueOf();
    },

    /**
     * 返回 string 值
     * @param {*} value
     * @returns
     */
    checkstring(value, def = "") {
        const sType = this.getType(value);
        // 不是字符串或数字类型，直接返回默认空字符串
        if (sType !== "string" && sType !== "number") {
            return def;
        }
        if (value.toString) {
            return value.toString();
        }
        return def;
    },

    checktable(value) {
        if (typeof value !== "object") {
            value = {};
        }
        // null 用typeof也是object，这边要做兼容
        if (this.getType(value) === "null") {
            value = {};
        }
        return value;
    },

    checkarray(value) {
        if (typeof value !== "object") {
            value = [];
        }
        if (this.getType(value) === "null") {
            value = [];
        }
        return value;
    },

    isEmptyObject(object) {
        if (!object) {
            return true;
        }
        return Object.keys(object).length == 0;
    },
};

export { DataIns };
