<template>
  <div class="login-register-banner">
    <div class="top-section">
      <div class="menu">
        <img src="@/assets/common/btn_manu.png" @click="openMenu" />
      </div>
      <div class="logo">
        <img src="@/assets/common/app_icon.png" alt="logo" />
      </div>

      <div class="right">
        <div class="btn btn-login" @click="goToLogin(ELoginType.ELogin_Login)">
          {{ t("TID_LOGIN_TITLE") }}
        </div>

        <div class="btn btn-register" @click="goToLogin(ELoginType.ELogin_Register)">
          {{ t("TID_REGISTER_TITLE") }}
        </div>
      </div>
    </div>
  </div>

  <LeftMenu :open="menuOpen" :close="() => (menuOpen = false)" />
</template>

<script setup>
import { nextTick, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import LeftMenu from "@/components/LeftMenu";
import { ELoginType } from "@/lib/Consts";
import eventBus from "@/eventBus";

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const menuOpen = ref(false);
const store = useStore();

const openMenu = () => {
  menuOpen.value = true;
};

const goToLogin = (loginType) => {
  store.commit("setCurrentView", "login-register");
  nextTick(() => {
    eventBus.emit("onLoginType", loginType);
  });
};
</script>

<style lang="scss" scoped>
.login-register-banner {
  color: #fff;
  padding: 0.5rem 0.5rem;
  position: sticky;
  top: 0; /*3.5rem;*/
  z-index: 999;
  background-color: #000;

  .top-section {
    @include flex(row, space-between, center);
    gap: .5rem;
    height: 2.8rem;
    @media (max-width: 600px) {
      height: 4.4rem;
    }

    .menu img {
      height: 1.7rem;
      display: flex;
      justify-content: center;
      @media (max-width: 600px) {
        height: 2.2rem;
      }
    }

    .logo {
      flex-grow: 1;
      float: left;

      img {
        height: 1.6rem;
        display: flex;
        justify-content: center;
        @media (max-width: 600px) {
          height: 1.9rem;
        }
      }
    }

    .right {
      @include flex(row, center, stretch);
      width: max-content;
      gap: 0.5rem;
      font-size: 1.2rem;
      @media (max-width: 600px) {
        font-size: 1.625rem;
      }

      .btn {
        @include flex(row, center, center);
        color: #fff;
        padding: 0.3rem 1.5rem;
        border-radius: 1.5625rem;
        font-weight: 700;
        white-space: nowrap;
        max-width: 9rem;
        cursor: pointer;
        @media (max-width: 600px) {
        }

        &-login {
          background: #0f8864;
        }

        &-register {
          background: #e71e62;
        }
      }
    }
  }
}
</style>
