
import { CfgAnalyze } from "./CfgAnalyze";
import { GFunc } from "./GFunc";
import { UserCtrl } from "@/ctrl/UserCtrl";

const UserStore = {
  getInviteLink() {
    // return `https://0038.com?referral_code=${userInfo.value.uuid ?? 'XXXXX'}`;
    let uuid = UserCtrl.getUuid();
    let keyCode = CfgAnalyze.getKeyCodeKey();
    let keyRPCode = CfgAnalyze.getKeyRPCodeKey();
    let url = `${GFunc.getInviteLinkEx({ [keyCode]: uuid }, [keyRPCode])}`;
    return url;
  },

  getBaseApiUrl() {
    return `https://38api.co/api`;
  },

  getStagingBaseApiUrl() {
    return `https://stagingbo.0038z.com/api`;
  }
}

export { UserStore }
