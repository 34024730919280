/**
 * 数据逻辑中心
 */

import { CheckinCtrl } from "./CheckinCtrl";
import { EventCtrl } from "./EventCtrl";
import { GameCtrl } from "./GameCtrl";
import { GlobalCtrl } from "./GlobalCtrl";
import { LoginCtrl } from "./LoginCtrl";
// import { MutualCtrl } from "./MutualCtrl";
import { NoticeCtrl } from "./NoticeCtrl";
import { PromotionCtrl } from "./PromotionCtrl";
import { RedPacketCtrl } from "./RedPacketCtrl";
import { ShopCtrl } from "./ShopCtrl";
import { UserCtrl } from "./UserCtrl";
import { VipCtrl } from "./VipCtrl";
import { WalletCtrl } from "./WalletCtrl";
import { DeviceMgr } from "@/lib/DeviceMgr";

const CtrlMgr = {
    initCtrl() {
        DeviceMgr.initModel();
        // MutualCtrl.initCtrl();
        GlobalCtrl.initCtrl();
        UserCtrl.initCtrl();
        CheckinCtrl.initCtrl();
        EventCtrl.initCtrl();
        GameCtrl.initCtrl();
        ShopCtrl.initCtrl();
        WalletCtrl.initCtrl();
        VipCtrl.initCtrl();
        PromotionCtrl.initCtrl();
        RedPacketCtrl.initCtrl();
        NoticeCtrl.initCtrl();
        LoginCtrl.initCtrl();
    }
}

export { CtrlMgr }
