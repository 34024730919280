/**
 * 登录逻辑模块
 */

import { SwitchModule } from "@/lib/CfgAnalyze";
import { GFunc } from "@/lib/GFunc";
import { GlobalCtrl } from "./GlobalCtrl";
import { GameCtrl } from "./GameCtrl";
import { NoticeCtrl } from "./NoticeCtrl";
import { EventCtrl } from "./EventCtrl";
import { WalletCtrl } from "./WalletCtrl";
import { VipCtrl } from "./VipCtrl";
import { CheckinCtrl } from "./CheckinCtrl";
import { PromotionCtrl } from "./PromotionCtrl";
import { RedPacketCtrl } from "./RedPacketCtrl";
import { setInternalAccFlag } from "@/api/NetAxios";
import { netApi } from "@/api";
import { UserCtrl } from "./UserCtrl";
import eventBus from "@/eventBus";
import { KGEvent } from "@/api/KGEvent";
import store from "@/store";

class _LoginCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听

    constructor() {
        GFunc.log("LoginCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("LoginCtrl initCtrl");
        this.initData(true);
        this.toLogin();
    }

    initModel() {
        GFunc.log("LoginCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
    }

    /**
     * 初始化数据
     * @param isStartUp 是否启动时初始化
     */
    initData(isStartUp) {
        this.cleanLocalUser(isStartUp);
        let enterHallDirect = SwitchModule.checkEnterHallDirectSwitch();
        // 初始化数据
        if (!enterHallDirect || isStartUp) {
            GlobalCtrl.initData();
            GameCtrl.initData();
            NoticeCtrl.initData();
            EventCtrl.initData();
        }
        WalletCtrl.initData();
        VipCtrl.initData();
        CheckinCtrl.initData();
        PromotionCtrl.initData();
        RedPacketCtrl.initData();
        setInternalAccFlag(false);
    }

    // 打开登录界面
    async toLogin(callFunc) {
        // GlobalCtrl.showUIDownloadApp();
        // let enterHallDirect = SwitchModule.checkEnterHallDirectSwitch();
        // if (enterHallDirect) {
        //     netApi.tryLoadLocalData(true, () => {
        //         GlobalCtrl.gotoUIHome(() => {
        //             callFunc && callFunc();
        //             this.tryRefreshToken();
        //         });
        //     });
        // } else {
        //     GlobalCtrl.gotoUILogin();
        //     GlobalCtrl.showUIFloating();
        // }

        // 同步父级location数据
        // MutualCtrl.syncLocation();

        try {
            await netApi.tryLoadLocalData(true);
        } catch (error) {
            console.log(error);
        } finally {
            callFunc && callFunc();
            this.tryRefreshToken();
        }
    }

    // 尝试校验Token
    // 注意：仅提供给大厅启动模式下的启动校验
    /* static */ async tryRefreshToken() {
        let reqParams = { silence: true };
        try {
            await netApi.refreshToken(reqParams);
            UserCtrl.setIsLogin(true);
            await netApi.tryRequestAuthConfData();
            await netApi.getUserInfo();
            await netApi.tryRequestConfData();
            // 启动刷新Token定时器
            eventBus.emit(KGEvent.EVENT_START_REFRESH_TOKEN_SCHEDULE);
        } catch (error) {
            await netApi.tryRequestAuthConfData();
            this.cleanLocalUser();
        } finally {
            /** */
        }
    }

    // 设置退出并清空数据
    /* static */ cleanLocalUser(isStartUp) {
        // 主动退出或过期时才清空数据，启动时不清空
        if (!isStartUp) {
            store.commit("setLocalLastUser", "");
            store.commit("setUserInfo", "");
        }
        UserCtrl.setIsLogin(false);
        // 停止刷新Token定时器
        eventBus.emit(KGEvent.EVENT_STOP_REFRESH_TOKEN_SCHEDULE);
    }
}

const LoginCtrl = new _LoginCtrl_();

export { LoginCtrl }

// DEBUG
window.LoginCtrl = LoginCtrl;
