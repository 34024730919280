<template>
  <div class="game-list">
    <PageHeader title="TID_LB_SEARCH" :back="onBack" v-if="props.type === 'search'" />
    <PageHeader title="TID_GAME_LIST_TITLE" :back="onBack" v-if="props.type === 'list'" />
    <PageHeader title="TID_LB_GAME_PROVIDER" :back="onBack" v-if="props.type === 'provider'" />

    <div class="game-list-inner">
      <div class="game-list-type" v-if="props.type === 'list'">
        <div v-for="(gt, key) in gametypes" :key="key" class="game-list-type-item" :class="(gametype === 0 ? gametypes[0] : gametype) === gt ? 'game-list-type-item-selected' : ''
          " @click="onClickGameType(gt)">
          {{ t(`${GameCtrl.getGameTypeName(gt, platform)}`) }}
        </div>
      </div>

      <div class="game-list-search" v-if="props.type === 'search'">
        <a-input :value="search" :placeholder="t('TID_LB_SEARCH_HINT')" allowClear ref="searchInput"
          @input="handleSearchGame">
          <template #prefix>
            <SearchOutlined style="font-size: 1.875rem; color: #666; margin-right: 1rem" />
          </template>
        </a-input>

        <div class="favourite" @click="goTo('favourite-games')">
          <img src="@/assets/landing/icon_favourite.png" alt="favourite" />
        </div>
      </div>

      <div class="game-list-container">
        <div class="game-list-container-platform" v-if="props.type === 'list'">
          <div v-for="(p, key) in platforms" :key="key" class="game-list-container-platform-item" :class="platform === p ? 'game-list-container-platform-item-selected' : ''
            " @click="onClickPlatform(p)">
            <img :src="{
              [EGamePlatform.EGP_HOT]: require('@/assets/factory/HOTROAD.png'),
              [EGamePlatform.EGP_PG]: require('@/assets/factory/PG.png'),
              [EGamePlatform.EGP_PP]: require('@/assets/factory/PP.png'),
              [EGamePlatform.EGP_JILI]: require('@/assets/factory/JILI.png'),
              [EGamePlatform.EGP_BG]: require('@/assets/factory/BG.png'),
              [EGamePlatform.EGP_SEXYBCRT]: require('@/assets/factory/SEXYBCRT.png'),
              [EGamePlatform.EGP_FC]: require('@/assets/factory/FC.png'),
              [EGamePlatform.EGP_YL]: require('@/assets/factory/YL.png'),
              [EGamePlatform.EGP_KINGMAKER]: require('@/assets/factory/KINGMAKER.png'),
              [EGamePlatform.EGP_EVOLUTION]: require('@/assets/factory/EVOLUTION.png'),
              [EGamePlatform.EGP_HOT]: require('@/assets/factory/HOT.png'),
              [EGamePlatform.EGP_COLL]: require('@/assets/factory/COLLECT.png'),
            }[p] || require('@/assets/factory/HOTROAD.png')" alt="">
            {{ t(`${GameCtrl.getGamePlatformName(p)}`) }}
          </div>
        </div>
        <div class="game-list-container-content" :style="props.type === 'search' ? {
          width: '100vw',
          maxWidth: '600px',
          padding: '0 2rem',
        } : {}">
          <a-list :pagination="pagination" :data-source="games" :grid="{ column: 3, }">
            <template #renderItem="{ item }">
              <div @click="(event) => pick(item, event)" id="game-list-item" :style="{
                width: '100%',
                boxSizing: 'border-box',
                padding: '.5rem',
              }">
                <img :src="item.iconurl" alt="" :style="{
                  width: '100%',
                  borderRadius: '1.25rem',
                  height: props.type === 'list' ? '10.575625rem' : '14.275rem',
                }">
                <div :style="{
                  width: '0',
                  height: '0',
                  position: 'relative',
                  float: 'right',
                  left: '-3.7rem',
                }">
                  <img src="@/assets/common/favourite_on.png" @click="(event) => favourite(item, event)"
                    v-if="GameCtrl.collectsList.value.find(({ id }) => id === item.id)" :style="{
                      width: '3.7rem',
                      borderRadius: '0'
                    }">
                  <img src="@/assets/common/favourite_off.png" @click="(event) => favourite(item, event)" v-else :style="{
                    width: '3.7rem',
                    borderRadius: '0'
                  }">
                </div>
              </div>
            </template>
          </a-list>
          <!-- <div class="game-list-container-content-layout">
            <GameItem class="game-list-container-content-item" v-for="(game, key) of games" :key="key" :game="game" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import PageHeader from "@/components/PageHeader";
import { GameCtrl } from "@/ctrl/GameCtrl";
// import GameItem from "@/components/views/Game/Item";
import { EGamePlatform } from "@/lib/Consts";
import { useI18n } from "vue-i18n";
import { SearchOutlined } from "@ant-design/icons-vue";
import { GFunc } from "@/lib/GFunc";

const pagination = ref({
  onChange: (page) => {
    console.log(page);
  },
  pageSize: 12,
  responsive: true,
  hideOnSinglePage: true,
  showSizeChanger: false,
});

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const store = useStore();
const props = defineProps({
  type: String,
})
if (['search'].includes(props.type)) {
  pagination.value.pageSize = 9;
}

const resize = () => {
  const item = document.querySelector("#game-list-item");
  const element = document.querySelector(".game-list-container-content");
  const alpagination = document.querySelector(".ant-list-pagination");
  if (item && element && alpagination) {
    let height = element.clientHeight;
    if (alpagination.children.length > 0) {
      height -= (alpagination.clientHeight + 24);
    }
    pagination.value.pageSize = Math.round(height / item.clientHeight) * 3;
  }
}

onMounted(() => {
  window.addEventListener('resize', resize);
  resize();
});

onUnmounted(() => {
  window.removeEventListener('resize', resize);
})

const search = ref("");
const handleSearchGame = (e) => {
  search.value = e.target.value;
}

const gametypes = ref(GameCtrl.GameTypeCfgList.value);
const _gametype = ref(store.state.selectedGameCategory);
const gametype = computed(() => {
  const exist = gametypes.value.includes(_gametype.value);
  return exist ? _gametype.value : gametypes.value[0];
});

const platforms = computed(() => {
  return GameCtrl.getSubGamePlatformCfgListFromType(gametype.value);
});
const _platform = ref(store.state.selectedGamePlatform);
const platform = computed(() => {
  const exist = platforms.value.includes(_platform.value);
  return exist ? _platform.value : platforms.value[0];
});

const games = computed(() => {
  if (props.type === 'search') {
    return GameCtrl.fuzzySearch(search.value);
  }
  return GameCtrl.getGameConfDataByGameType(gametype.value, platform.value);
})

const onBack = () => {
  store.commit("goBack");
}

const onClickPlatform = (p) => {
  _platform.value = p;
}

const onClickGameType = (gt) => {
  _gametype.value = gt;
  const platforms = GameCtrl.getSubGamePlatformCfgListFromType(gt)
  _platform.value = platforms[0] || 0;
}

const goTo = (view) => {
  store.commit("setCurrentView", view);
};

// 选择游戏
const pick = (game, event) => {
  GFunc.log('选择游戏', game)
  event.stopPropagation();

  GameCtrl.reqGameAddress(game, (url) => {
    if (url) {
      store.commit("setCurrentGame", url);
      store.commit("setCurrentView", "in-game");
    }
  })
}

// 收藏游戏
const favourite = (game, event) => {
  GFunc.log('收藏游戏', game)
  event.stopPropagation();
  GameCtrl.reqGameCollectSet(game);
}
</script>

<style lang="scss">
.game-list {
  @include flex(column, center, center);
  gap: 1rem;

  &-inner {
    @include flex(column, center, center);
    gap: 1rem;
    max-width: 600px;
    width: 100%
  }

  &-type {
    @include flex(row, flex-start, center);
    box-sizing: border-box;
    padding: 0 1rem;
    gap: .5rem;
    width: 37.5rem;
    overflow-x: auto; // Allow horizontal scrolling

    &-item {
      background-color: #545454;
      border-radius: 1.5625rem;
      font-size: 1.5rem;
      height: 3.125rem;
      line-height: 3.125rem;
      padding: 0 1rem;

      &-selected {
        background-color: #e71e62;
      }
    }
  }

  &-search {
    @include flex(row, center, center);
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;

    .ant-input-affix-wrapper {
      height: 3.125rem;

      input.ant-input {
        height: 100%;
      }
    }

    .favourite img {
      display: block;
      height: 3.125rem,
    }
  }

  &-container {
    @include flex(row, center, center);
    padding: 1rem;
    gap: 1rem;
    background-color: black;
    box-sizing: border-box;
    height: 100%;
    width: 100%;

    &-platform {
      @include flex(column, flex-start, center);
      overflow-x: hidden;
      overflow-y: scroll;
      height: calc(100vh - 11.5rem);
      gap: .5rem;

      &-item {
        flex: 0 0 auto;
        background-color: #545454;
        width: 7rem;
        height: 4.8125rem;
        border-radius: .5rem;
        box-sizing: border-box;

        @include flex(column, center, center);

        &-selected {
          background-color: #e71e62;
        }

        img {
          height: 2.8333333333rem;
        }
      }
    }

    &-content {
      width: 27.649375rem;
      height: calc(100vh - 11.5rem);
      overflow-x: hidden;
      overflow-y: scroll;
      flex-grow: 1;
      padding: 0 1rem;
      box-sizing: border-box;

      &-layout {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }

      &-item {
        width: 100%;
      }

      .ant-list {
        .ant-list-pagination {
          text-align: center;
        }
      }
    }
  }
}
</style>