<template>
  <div class="container" :style="{
    display: list.length > 0 ? '' : 'none'
  }">
    <div :class="props.type === 'hot' ? 'header-1' : 'header-2'">
      <div :class="props.type === 'hot' ? 'header-1-left' : 'header-2-left'">
        <img src="@/assets/landing/icon_popular.png" alt="icon" v-if="props.type === 'hot'" />
        <img src="@/assets/landing/icon_new.png" alt="icon" v-else-if="props.type === 'new'" />
        <img src="@/assets/landing/icon_PG.png" alt="icon" v-else-if="props.type === 'pgslot'" />
        <img src="@/assets/landing/icon_live.png" alt="icon" v-else-if="props.type === 'live'" />
        <img src="@/assets/landing/icon_egame.png" alt="icon" v-else-if="props.type === 'egame'" />
        <img src="@/assets/landing/icon_all.png" alt="icon" v-else-if="props.type === 'all'" />
        <img src="@/assets/factory/JILI.png" alt="icon" v-else-if="props.type === 'jili'" />
        <span>{{ t(`${title}`) }}</span>
      </div>

      <div :class="props.type === 'hot' ? 'header-1-right' : 'header-2-right'" @click="more">
        <span>{{ t("TID_LB_ALL") }}</span>
        <img src="@/assets/common/double_arrow_right.png" alt="arrow" />
      </div>
    </div>

    <div class="content">
      <GameItem class="content-item" v-for="(game, key) of list" :key="key" :game="game" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { GameCtrl } from "@/ctrl/GameCtrl";
import { EGamePlatform, EGameType } from "@/lib/Consts";
import GameItem from "@/components/views/Game/Item";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

// 组件参数
const props = defineProps({ type: String })

// 游戏列表
const [platform, gametype] = {
  hot: [EGamePlatform.EGP_HOT, EGameType.EGT_HOT],
  pgslot: [EGamePlatform.EGP_PG, EGameType.EGT_SLOT],
  jili: [EGamePlatform.EGP_JILI, EGameType.EGT_SLOT],
  new: [EGamePlatform.EGP_NEW, EGameType.EGT_NEW],
  all: [EGamePlatform.EGP_None, EGameType.EGT_None],
  // egame: [EGamePlatform.EGP_PP, EGameType.EGT_EGAME],
  // live: [EGamePlatform.EGP_BG, EGameType.EGT_LIVE],
}[props.type];
const list = computed(() => {
  return GameCtrl.getGameConfDataByPlatform(platform, gametype)
  // return GameCtrl.getGameConfDataByGameType(gametype, platform)
    .filter(game => !!game).slice(0, 9);
});

// 标题
const title = ref(GameCtrl.getGameListTitle(gametype, platform));

// 更多
const more = () => {
  // if (props.type === 'all') {
  //   store.commit("setCurrentView", "search-game");
  // } else {
    store.commit("setSelectedGameCategory", gametype);
    store.commit("setSelectedGamePlatform", platform);

    store.commit("setCurrentView", "game-list");
  // }
}
</script>

<style lang="scss" scoped>
.container {
  @include flex(column, center, center);
  width: 100%;
}

@mixin header {
  @include flex(row, flex-start, center);
  background-image: url("@/assets/landing/game-tab-bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 1.875rem;

  &-left {
    @include flex(row, flex-start, center);
    gap: 1rem;
    flex-grow: 1;
    position: relative;
    bottom: .3rem;
    font-weight: bold;
    color: #fff300;
    padding-left: .5rem;

    img {
      height: 3.125rem;
    }
  }

  &-right {
    @include flex(row, center, center);
    gap: 1rem;
    position: relative;
    bottom: .3rem;
    width: 8rem;

    img {
      height: 1.6666666667rem;
    }
  }
}

.header-1 {
  @include header;
}

.header-2 {
  @include header;
  background-image: url("@/assets/landing/game-tab-bg2.png");

  &-left {
    color: #e71e62;
  }
}

.content {
  @include flex(row, flex-start, center);
  flex-wrap: wrap;
  background-color: #121212;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  padding: .3rem .5rem .5rem;

  &-item {
    width: calc((100% - 2rem) / 3);
  }
}
</style>