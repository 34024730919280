<template>
  <section class="deposit-withdraw-section" v-if="!paymentOpen && !bindWithdrawOpen">
    <PageHeader :back="onBack" title="TID_LB_WALLET" :history="goToTransactionHistory" />
    <div class="deposit-withdraw-inner">

      <section class="type-btn-section">
        <div :class="`btn ${selectedAction === 'deposit' && 'btn-selected'}`" @click="handleActionChange('deposit')">
          {{ t("TID_LB_DEPOSIT") }}
        </div>
        <div :class="`btn ${selectedAction === 'withdraw' && 'btn-selected'}`" @click="handleActionChange('withdraw')">
          {{ t("TID_WALLET_WITHDRAW_TITLE") }}
        </div>
      </section>

      <!-- <img
        style="
          width: 100%;
          height: auto;
          max-height: 187px;
          margin-top: 1.5rem;
          box-sizing: border-box;
        "
        src="@/assets/landing/deposit-100.png"
        alt="deposit-100"
        v-if="selectedAction === 'deposit'"
      /> -->
      <div class="wallet-balance" v-if="!showSkeleton">
        <span class="wallet-balance-title">{{ t("TID_LB_BALANCE") }}</span>
        <span class="wallet-balance-value">₫ {{ GFunc.formatNumber(userInfo.currentmoney) ?? "-" }}</span>
      </div>

      <section v-if="selectedAction === 'deposit' && !showSkeleton" class="payment-info">
        <!-- <div class="payment-channels" v-if="depositInfo?.channels">
          <div
            :class="`payment-channels-item ${
              selectedChannel.name === channel.name &&
              'payment-channels-item-selected'
            }`"
            v-for="(channel, key) in depositInfo?.channels"
            :key="key"
            @click="selectedChannel = channel"
          >
            <span>{{ channel.name }}</span>
          </div>
        </div>

        <div class="payment-channels" v-else>
          <div
            class="payment-channels-item"
            v-for="(channel, key) in 3"
            :key="key"
          >
            <span style="opacity: 0">PIX I</span>
          </div>
        </div> -->

        <div class="input-section">
          <div :style="{
            fontSize: '1.625rem',
          }">
            {{ t("TID_WALLET_INPUT_AMOUNT") }} + {{ "(VND)" }}
          </div>
          <a-input :value="amount" :placeholder="t('TID_LB_MIN_X', { value: selectedChannel.min_deposit ?? GFunc.formatNumber(minRechargeLimit) })
            " style="width: 100%; font-size: 1.5rem !important;" @input="handleAmountInput">
          </a-input>
          <!-- <span class="hint">
            {{
              "(" +
              t("TID_LB_MIN_X", {
                value: selectedChannel?.min_deposit
                  ? parseFloat(selectedChannel?.min_deposit).toFixed(0)
                  : "-",
              }) +
              " - " +
              t("TID_LB_MAX_X", {
                value: selectedChannel?.max_deposit
                  ? parseFloat(selectedChannel?.max_deposit).toFixed(0)
                  : "-",
              }) +
              ")"
            }}
            </span> -->
        </div>

        <div class="amount-list">
          <div :class="`amount ${amount === money && 'amount-selected'}`" v-for="({
            money, fristgiftRatio, fristgiftType, repeatgiftRatio, repeatgiftType
          }, key) in payAmounts" :key="key" @click="handlePriceClick(money)">
            <span>₫ {{ GFunc.formatNumber(money) }}</span>
            <span class="bonus" :style="{
              display: (UserCtrl.getTotalRecharge() > 0 ? repeatgiftRatio : fristgiftRatio) > 0 ? '' : 'none'
            }" v-if="tick">+{{
              UserCtrl.getTotalRecharge() > 0 ?
                `${repeatgiftRatio}${repeatgiftType === 1 ? '%' : ''}` :
                `${fristgiftRatio}${fristgiftType === 1 ? '%' : ''}`
            }}</span>
            <img src="@/assets/common/hot_depsot.png" alt="hot" class="hot-icon"
              v-if="money == 50 || money == 100 || money == 1000" />
          </div>

          <div v-if="hasIncompleteRow" class="amount" style="opacity: 0"></div>
        </div>

        <!-- <section class="tnc-section">
          <a-checkbox
            v-model="tncChecked"
            :autofocus="false"
            @change="tncChecked = !tncChecked"
          >
            <span style="color: #fff">
              {{ t("TID_LB_I_HAD_READ_THE") }}
              <span
                style="color: #ffeb02; text-decoration: underline"
                @click="store.commit('setCurrentView', 'tnc')"
              >
                {{ t("TID_LB_TERMS_AND_CONDITIONS") }}
              </span>
            </span>
          </a-checkbox>
        </section> -->

        <div style="width: 100%;">
          <a-checkbox v-model="tick" @change="tick = !tick" :autofocus="false">
            <span style="color: #fff;font-size: 1.2rem;">
              {{ t("TID_WALLET_PARTICIPATE_TIP") }}
            </span>
          </a-checkbox>
        </div>
        <div class="deposit-btn" @click="onSubmit">
          {{ t("TID_LB_DEPOSIT_NOW") }}
        </div>

        <!-- <section class="instruction-section">
          <span class="title">{{ t("TID_LB_INSTRUCTION") }}</span>
          <ul class="instruction-list">
            <li> {{ t("TID_LB_DEPOSIT_INSTRUCTION_1") }} </li>
            <li> {{ t("TID_LB_DEPOSIT_INSTRUCTION_2") }} </li>
            <li> {{ t("TID_LB_DEPOSIT_INSTRUCTION_3") }} </li>
          </ul>
        </section> -->
      </section>

      <section v-if="selectedAction === 'withdraw' && !showSkeleton" style="width: 100%;">
        <a-form layout="vertical" :style="{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          marginBottom: '1rem',
        }">
          <div :style="{
            backgroundColor: '#000',
            boxSizing: 'border-box',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }">
            <a-form-item name="name">
              <a-input v-model:value="withdraw_name" :placeholder="t('TID_LB_NAME')"
                style="font-size: 1.5rem !important;" />
            </a-form-item>

            <a-form-item name="card">
              <a-input v-model:value="withdraw_card" :placeholder="t('TID_LB_BIND_BANK_CARD')"
                style="font-size: 1.5rem !important;" />
            </a-form-item>

            <a-form-item name="bank">
              <a-dropdown :overlayStyle="{
                width: 'calc(100% - 2rem)',
                maxHeight: '200px',
                overflow: 'auto',
                backgroundColor: '#383838'
              }">
                <a-input v-model:value="banks[withdraw_bank]" :placeholder="t('TID_LB_BIND_BANK_NAME')" readonly
                  @click.prevent style="font-size: 1.5rem !important;">
                  <template #suffix>
                    <img src="@/assets/common/arrow_1.png" alt="" :style="{
                      height: '1.1333333333rem',
                      transform: 'rotate(90deg)',
                    }">
                  </template>
                </a-input>
                <template #overlay>
                  <a-menu :style="{
                    backgroundColor: '#383838',
                    borderRadius: '0',
                    color: '#fff',
                  }">
                    <a-menu-item v-for="(v, k) in banks" :key="k" @click="onChangeBank(k)">
                      <a :style="{
                        fontSize: '1.5rem',
                        color: '#fff',
                      }">{{ v }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-form-item>
          </div>

          <div :style="{
            backgroundColor: '#000',
            boxSizing: 'border-box',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }">
            <a-form-item>
              <a-input-group compact>
                <a-input v-model:value="withdraw_amount" style="width: calc(100% - 8rem);font-size: 1.5rem !important;"
                  :placeholder="t('TID_WALLET_INPUT_WITHDRAW_AMOUNT_PLACEHOLDER')" @input="handleAmountInput" />
                <div :style="{
                  backgroundColor: '#e71e62',
                  borderRadius: '.5rem',
                  width: '8rem',
                  height: '3.125rem',
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  lineHeight: '3.125rem',
                  textAlign: 'center',
                  color: '#fff',
                }" @click="withdraw_amount = userInfo.currentmoney">{{ t("TID_WALLET_WITHDRAW_MAX") }}</div>
              </a-input-group>
              <!-- <a-input v-model:value="userInfo.withdraw_phone" placeholder="Card">
              </a-input> -->
            </a-form-item>

            <a-form-item style="margin: 0">
              <a-input v-model:value="withdraw_password" :placeholder="t('TID_LB_PASSWORD_HINT')"
                :type="!passwordVisible ? 'password' : 'text'" style="font-size: 1.5rem !important;">
                <template #suffix>
                  <img src="@/assets/common/eye_off.png" style="width: auto;height: 1.875rem;" v-if="!passwordVisible"
                    @click="togglePasswordVisible" />
                  <img src="@/assets/common/eye_on.png" style="width: auto;height: 1.875rem;" v-else
                    @click="togglePasswordVisible" />
                </template>
              </a-input>
            </a-form-item>
          </div>
        </a-form>

        <div style="padding: 0 1rem; box-sizing: border-box;" v-if="UserCtrl.getWalletPassword() !== ''">
          <div class="deposit-btn" @click="onSubmit">
            <span>{{ t("TID_WALLET_WITHDRAW") }}</span>
          </div>
        </div>
        <div style="padding: 0 1rem; box-sizing: border-box; text-align: center;" v-else>
          <div class="deposit-btn" @click="goBindWithdrawPassword">
            <span>{{ t("TID_LB_SET_SAQUE_PWD") }}</span>
          </div>
          <span :style="{ color: '#878787' }">{{ t("TID_LB_SET_SAQUE_PWD_TIP") }}</span>
        </div>
      </section>

      <section class="skeleton-section" v-if="showSkeleton">
        <div class="skeleton skeleton-one"></div>
        <div class="skeleton skeleton-two"></div>
        <div class="skeleton skeleton-three"></div>
        <div class="skeleton skeleton-four"></div>
        <div class="skeleton skeleton-four"></div>
        <div class="skeleton skeleton-four"></div>
      </section>
    </div>
  </section>

  <PaymentQR v-if="paymentOpen" :paymentInfo="paymentInfo" :amount="amount" @onClose="closePaymentQR" />

  <BindWithdraw v-if="bindWithdrawOpen" :withdrawInfo="withdrawInfo" @onBack="bindWithdrawOpen = false" />

  <Loading v-if="requestLoading" />

  <a-modal :open="bindWithdrawModalOpen" centered :closable="false" :maskClosable="false" :okText="t('TID_LB_BIND_NOW')"
    @ok="handleBindWithdraw" :cancelButtonProps="{ style: { display: 'none' } }" :okButtonProps="{
      style: {
        width: '100%',
        height: '50px',
        fontSize: '1rem',
        marginInlineStart: '0',
        borderTop: 'none',
        background:
          'linear-gradient(0deg, rgba(255,235,2,1) 2%, rgba(254,136,0,1) 97%)',
        fontWeight: '500',
        marginTop: '2rem',
      },
    }" style="background-color: #000 !important">
    <div class="withdraw-bind-content">
      <span style="font-size: 1.3rem; font-weight: 500">
        {{ t("TID_LB_BIND_FIRST_BEFORE_WITHDRAW") }}
      </span>
    </div>
  </a-modal>

  <!-- 充值确认框 -->
  <div v-if="payTypeOpen" :style="{
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999',
    pointerEvents: 'all',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }" @click="payTypeOpen = false">
    <div :style="{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#383838',
      borderRadius: '.5rem',
      gap: '.5rem',
      boxSizing: 'border-box',
      padding: '.5rem 0',
    }" @click="(e) => e.stopPropagation()">
      <div :style="{
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 1rem',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }">
        <span :style="{ fontSize: '1.5rem' }">{{ t("TID_LB_RECHARGE_AMOUNT") }}</span>
        <span :style="{ fontSize: '1.5rem', color: '#ffeb02', }">{{ GFunc.formatNumber(amount) }}</span>
        <div style="flex-grow: 1;height: 3rem;" @click="payTypeOpen = false">
          <img src="@/assets/common/close.png" alt="" style="height: 3rem;float: right;">
        </div>
      </div>
      <div :style="{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: '.5rem',
        padding: '.5rem .5rem 1rem',
        boxSizing: 'border-box',
        backgroundColor: '#000',
      }">
        <span :style="{ fontSize: '1.5rem' }">{{ t("TID_LB_SELECT_PAYMEMT_METHOD") }}</span>
        <div :style="{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          textAlign: 'center',
          gap: '1rem',
          padding: '0 1rem',
        }">
          <div>
            <div :style="{
              width: '14.7666666667rem',
              height: '5.8666666667rem',
            }" @click="payType = 0">
              <img :style="{
                height: '4.6666666667rem',
                position: 'relative',
                left: '-.15rem',
                top: '.6rem',
              }" src="@/assets/landing/depost_QR.png" alt="">
            </div>
            <div :style="{
              width: 0,
              height: 0,
              position: 'relative',
              top: '-5.8666666667rem',
              display: payType === 1 ? 'none' : '',
            }">
              <img :style="{
                height: '5.8666666667rem',
              }" src="@/assets/landing/goukuang.png" alt="">
            </div>
          </div>
          <div>
            <div :style="{
              width: '14.7666666667rem',
              height: '5.8666666667rem',
            }" @click="payType = 1">
              <img :style="{
                height: '4.6666666667rem',
                position: 'relative',
                left: '-.15rem',
                top: '.6rem',
              }" src="@/assets/landing/depost_zalo.png" alt="">
            </div>
            <div :style="{
              width: 0,
              height: 0,
              position: 'relative',
              top: '-5.8666666667rem',
              display: payType === 0 ? 'none' : '',
            }">
              <img :style="{
                height: '5.8666666667rem',
              }" src="@/assets/landing/goukuang.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div :style="{
        fontSize: '2rem',
        fontWeight: 'bold',
        height: '4rem',
        width: '16rem',
        background: 'linear-gradient(#ff2f75, #841541)',
        borderRadius: '.5rem',
        textAlign: 'center',
        lineHeight: '4rem',
      }" @click="handleDeposit">{{ "OK" }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Loading from "@/components/Loading";
import { message } from "ant-design-vue";
import PaymentQR from "@/components/views/PaymentQR";
import BindWithdraw from "@/components/views/BindWithdraw";
import PageHeader from "@/components/PageHeader";
import { UserCtrl } from "@/ctrl/UserCtrl";
import { netApi } from "@/api";
import { GFunc } from "@/lib/GFunc";
import { EEventType } from "@/lib/Consts";
import { EventCtrl } from "@/ctrl/EventCtrl";

const payTypeOpen = ref(false);
const payType = ref(0);
// const selectedAction = ref("deposit");
const store = useStore();
// const depositInfo = ref(null);
const requestLoading = ref(false);
const selectedChannel = ref("");
const amount = ref("");
const tncChecked = ref(false);
const paymentOpen = ref(false);
const paymentInfo = ref({});
const withdrawInfo = ref({});

const bindWithdrawModalOpen = ref(false);
const bindWithdrawOpen = ref(false);
const showSkeleton = ref(false);

const banks = {
  "VCB": "Ngân hàng TMCP Ngoại thương Việt Nam",
  "VIB": "Ngân hàng TMCP Quốc tế",
  "MB": "Ngân hàng TMCP Quân đội",
  "VIETINBANK": "Ngân hàng TMCP Công thương Việt Nam",
  "HDBANK": "Ngân hàng TMCP phát triển TPHCM",
  "NCB": "Ngân hàng TMCP Quốc dân",
  "MSB": "Ngân hàng TMCP Hàng Hải Việt Nam",
  "VIETABANK": "Ngân hàng TMCP Việt Á",
  "GPBANK": "Ngân hàng TNHH MTV Dầu khí toàn cầu",
  "OCEANBANK": "Ngân hàng TMCP Đại Dương",
  "NASB": "Ngân hàng TMCP Bắc Á",
  "DAB": "Ngân hàng TMCP Đông Á",
  "ABBANK": "Ngân hàng TMCP An Bình",
  "BIDV": "Ngân hàng TMCP Đầu tư và phát triển Việt Nam",
  "SHB": "Ngân hàng TMCP Sài Gòn Hà Nội",
  "BVBANK": "Ngân hàng TMCP Bảo Việt",
  "LPB": "Ngân hàng TMCP Bưu điện Liên Việt",
  "TPBANK": "Ngân hàng TMCP Tiên Phong",
  "AGRIBANK": "Ngân hàng Nông Nghiệp và PTNT Việt Nam",
  "SCB": "Ngân hàng TMCP Sài Gòn",
  "KLB": "Ngân hàng TMCP Kiên Long",
  "SEABANK": "Ngân hàng TMCP Đông Nam Á",
  "VRB": "Ngân hàng Liên doanh Việt Nga",
  "PBVN": "Ngân hàng TNHH MTV PUBLIC Việt Nam",
  "NAMABANK": "Ngân hàng TMCP NAM Á",
  "PVC": "Ngân hàng TMCP Đại Chúng Việt Nam",
  "SAIGONBANK": "Ngân hàng Sài Gòn Công Thương",
  "TECHCOMBANK": "Ngân hàng TMCP Kỹ thương Việt Nam",
  "EXIMBANK": "Ngân hàng TMCP Xuất nhập nhẩu Việt Nam",
  "ACB": "Ngân hàng TMCP Á châu",
  "SACOMBANK": "Ngân hàng TMCP Sài gòn Thương tín",
  "VPBANK": "Ngân hàng TMCP Việt Nam Thịnh vượng",
  "OCB": "Ngân hàng TMCP Phương Đông",
  "PGBANK": "Ngân hàng TNHH MTV Xăng dầu Petrolimex",
  "UOB": "Ngân hàng TNHH MTV United Overseas Bank (Việt Nam)",
  "IVB": "Ngân hàng TNHH Indovina",
  "WOORIBANK": "Ngân hàng TNHH Woori bank",
  "SVFC": "Ngân hàng TNHH MTV Shinhan (Việt Nam)",
  "VIETBANK": "Ngân hàng TMCP Việt Nam thương tín",
  "VIETCAPITALBANK": "Ngân hàng TMCP Bản Việt",
  "HONGLEONG": "Ngân hàng TNHH một thành viên Hong Leong Việt Nam",
  "SCVN": "Ngân hàng TNHH Một Thành Viên Standard Chartered (Việt Nam)",
  "IBK": "Ngân Hàng Công Nghiệp Hàn Quốc",
  "COOPBANK": "Ngân hàng hợp tác Co-opBank",
  "LIOBANK": "Ngân hàng số LioBank",
  "CIMB": "Ngân hàng CIMB Việt Nam",
  "KBHN": "Ngân hàng Kookmin - Chi nhánh Hà Nội",
  "DBS": "Ngân hàng DBS - Chi nhánh TP. Hồ Chí Minh",
  "KBHCM": "Ngân hàng Kookmin - Chi nhánh TP. Hồ Chí Minh",
  "UMEE": "Ngân hàng số Umee – Kiên Long Bank",
  "NHB": "Ngân hàng Nonghyup - Chi nhánh Hà Nội",
  "KBANK": "Ngân hàng Đại chúng Kasikornbank - Chi nhánh TP. Hồ Chí Minh",
  "TIMO": "Ngân hàng số Timo – Bản Việt Bank",
  "KEBHCM": "Ngân hàng Keb Hana - Chi nhánh TP. Hồ Chí Minh",
  "CAKE": "Ngân hàng số Cake – VPBank",
  "CITIBANK": "Ngân hàng Citibank - Chi nhánh Hà Nội",
  "UBANK": "Ngân hàng số UBank – VPBank",
  "BNPHN": "Ngân hàng BNP Paribas - Chi nhánh Hà Nội",
  "KEBHN": "Ngân hàng Keb Hana - Chi nhánh Hà Nội",
  "BNPHCM": "Ngân hàng BNP Paribas - Chi nhánh TP. Hồ Chí Minh",
  "CUBHCM": "Ngân hàng Cathay United Bank – Chi nhánh TP. Hồ Chí Minh",
  "BIDC": "Ngân hàng Đầu tư và Phát triển Campuchia – Chi nhánh Hà Nội",
  "CBBANK": "Ngân hàng Xây dựng Việt Nam",
  "HSBC": "Ngân hàng HSBC Việt Nam",
}
const withdraw_name = ref("");
const withdraw_card = ref("");
const withdraw_bank = ref("");
const withdraw_amount = ref("");
const withdraw_password = ref("");
const passwordVisible = ref(false);

const payAmounts = ref([]);
const minRechargeLimit = ref(0);

const togglePasswordVisible = () => {
  passwordVisible.value = !passwordVisible.value;
}

const onChangeBank = (b) => {
  withdraw_bank.value = b;
}

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const userInfo = computed(() => store.state.userInfo);
const selectedAction = computed(() => store.state.depositOrWithdraw);

const amounts = computed(() => {
  return selectedChannel.value.amount?.split(",") || [];
});

const hasIncompleteRow = computed(() => {
  return amounts?.value?.length % 3 !== 0;
});
const onBack = () => {
  store.commit("setDepositOrWithdraw", "deposit");
  store.commit("goBack");
};

const handleActionChange = (action) => {
  amount.value = "";
  tncChecked.value = false;
  store.commit("setDepositOrWithdraw", action);
  // if (action === "withdraw" && !userInfo.value.withdraw_pix_key) {
  //   bindWithdrawModalOpen.value = true;
  // }
};

const goBindWithdrawPassword = () => {
  store.commit("setChangedPwdType", 1);
  store.commit("setCurrentView", "change-password");
}

const goToTransactionHistory = () => {
  store.commit("setCurrentView", "transaction-history");
};

const handleAmountInput = (e) => {
  let inputValue = e.target.value;
  inputValue = inputValue
    .replace(/(?!^\d*\.?\d*$)[^\d.]/g, "")
    .replace(/\.(?=.*\.)/g, "");
  e.target.value = e.target.value.replace(/[^0-9+()]/g, "");
  if (selectedAction.value === "withdraw") {
    withdraw_amount.value = inputValue;
  } else {
    amount.value = inputValue;
  }
};

const handleBindWithdraw = () => {
  bindWithdrawModalOpen.value = false;
  bindWithdrawOpen.value = true;
};

const tick = ref(false);
const handleDeposit = async () => {
  try {
    payTypeOpen.value = false;
    const resp = await netApi.rechargeOrder({
      amount: amount.value,
      type: payType.value + 1,
      tick: tick.value,
      jumpURL: location.href
    })

    if (resp.payURL) {
      GFunc.openUrl(resp.payURL, true, t('TID_LB_GOTO_RIGHT_NOW'));
    }
  } catch(e) {
    console.log(e)
  }
}

const closePaymentQR = () => {
  tncChecked.value = false;
  amount.value = false;
  paymentOpen.value = false;
};

const onSubmit = async () => {
  console.trace()
  // if (selectedAction.value === "withdraw" && !userInfo.value.withdraw_pix_key) {
  //   bindWithdrawModalOpen.value = true;
  //   return;
  // }
  // if (!tncChecked.value || amount.value === "") {
  if (selectedAction.value === "withdraw") {
    if (withdraw_amount.value === '') {
      message.error({
        content: t("TID_WALLET_WITHDRAW_INFO_INCOMPLETE"),
        duration: 2,
      });
      return;
    }
  } else if (selectedAction.value === "deposit") {
    if (amount.value === '') {
      message.error({
        content: t("TID_LB_DEPOSIT_INFO_INCOMPLETE"),
        duration: 2,
      });
      return;
    }
    if (amount.value < minRechargeLimit.value) {
      message.error({
        content: t("TID_LB_DOES_NOT_MEET_MINIMUM_DEPOSIT_AMOUNT_X", {
          value: minRechargeLimit.value
        }),
        duration: 2,
      });
      return;
    }
  }

  if (selectedAction.value === "withdraw") {
    // 最小提现金额
    // if (
    //   parseFloat(amount.value) < parseFloat(withdrawInfo.value.min_withdraw)
    // ) {
    //   message.error({
    //     content: t("TID_LB_DOES_NOT_MEET_MINIMUM_WITHDRAW_AMOUNT_X", {
    //       value: `₫ ${withdrawInfo.value.min_withdraw}`,
    //     }).replace(),
    //     duration: 2,
    //   });
    //   return;
    // }
  } else {
    if (
      parseFloat(amount.value) < parseFloat(selectedChannel.value.min_deposit)
    ) {
      message.error({
        content: t("TID_LB_DOES_NOT_MEET_MINIMUM_DEPOSIT_AMOUNT_X", {
          value: `₫ ${selectedChannel.value.min_deposit}`,
        }),
        duration: 2,
      });
      return;
    }
  }

  try {
    requestLoading.value = true;
    if (selectedAction.value === "deposit") {
      payTypeOpen.value = true;
    } else {
      if (withdraw_name.value === '') {
        message.error("Vui lòng nhập tên")
        return;
      }
      if (withdraw_card.value === '') {
        message.error("Vui lòng nhập số thẻ ngân hàng")
        return;
      }
      if (withdraw_bank.value === '') {
        message.error("Vui lòng chọn ngân hàng")
        return;
      }
      if (withdraw_amount.value === '') {
        message.error("Vui lòng nhập số tiền rút")
        return;
      }
      if (withdraw_password.value === '') {
        message.error("Vui lòng nhập mật khẩu rút tiền")
        return;
      }
      // TODO 提现
      await netApi.postWithdrawalWithBank({
        money: withdraw_amount.value,
        password: withdraw_password.value,
        bkAccount: withdraw_name.value,
        bkCardNo: withdraw_card.value,
        bkBankID: withdraw_bank.value,
      })
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const handlePriceClick = (price) => {
  amount.value = price;
};

onMounted(async () => {
  try {
    requestLoading.value = true;
    showSkeleton.value = true;

    EventCtrl.getEventDataByCache(EEventType.EEvent_Vip, () => {
      const { fixedRechargeSendConf, minRechaegeMoney } = EventCtrl.eventData.rechargeSendConf;
      payAmounts.value = fixedRechargeSendConf;
      minRechargeLimit.value = minRechaegeMoney;
    });

  } catch (error) {
    console.log(error);
  } finally {
    requestLoading.value = false;
    showSkeleton.value = false;
  }
});
</script>

<style lang="scss">
.deposit-withdraw-section {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 8rem;
  @include flex(column, center, center);

  .type-btn-section {
    box-sizing: border-box;
    @include flex(row, flex-start, center);
    justify-content: center;
    text-align: center;
    gap: 1.5rem;
    width: 100%;

    .btn {
      background-color: #545454;
      color: #fff;
      padding: 0.3rem 1.5rem;
      border-radius: 2rem;
      cursor: pointer;
      font-size: 2rem;

      &-selected {
        background-color: #e71e62;
      }

      width: 30%
    }
  }

  .deposit-withdraw-inner {
    @include flex(column, center, center);
    width: 100%;
    box-sizing: border-box;
    padding-top: 1rem;
    max-width: 600px;

    .deposit-withdraw-action {
      width: 100%;
      @include flex(row, center, center);
      gap: 1rem;
      font-size: 1rem;
      font-weight: 700;
      padding: 0 1rem;

      .action {
        padding: 0.2rem 1rem;
        border-bottom: 3px solid black;
        cursor: pointer;

        &--selected {
          border-bottom: 3px solid #ffeb02;
        }
      }
    }

    .wallet-balance {
      @include flex(column, center, center);
      // background-image: url("@/assets/common/container_bg.png");
      // width: 100%;
      // min-height: 200px;
      // background-size: contain;
      // background-position: center;
      // background-repeat: no-repeat;
      background: linear-gradient(#ff2f75, #8e1541) !important;
      width: 100%;
      padding: 1rem 0 2rem 0;
      margin: 1rem 0;
      gap: 0.5rem;

      &-title {
        font-size: 2rem;
      }

      &-value {
        width: 95%;
        background-color: #87113b;
        border-radius: 0.4rem;
        text-align: center;
        font-weight: 700;
        font-size: 3.75rem;
      }
    }

    .payment-info {
      @include flex(column, center, center);
      padding: 0 1rem;
      gap: 1rem;
      width: calc(100% - 2rem);
    }

    .payment-channels {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, center);
      flex-wrap: wrap;

      &-item {
        cursor: pointer;
        box-sizing: border-box;
        flex: 0 0 31%;
        max-width: 31%;
        background-color: #252525;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        @include flex(row, center, center);
        font-size: 1.2rem;

        &-selected {
          background-color: #fe8800;
        }
      }
    }

    .input-section {
      width: 100%;
      box-sizing: border-box;
      @include flex(column, center, flex-start);
      gap: 0.5rem;

      .hint {
        font-size: 0.8rem;
      }
    }

    .amount-list {
      box-sizing: border-box;
      width: 100%;
      @include flex(row, flex-start, center);
      flex-wrap: wrap;
      gap: 1.24rem;

      .amount {
        flex: 0 0 31%;
        cursor: pointer;
        box-sizing: border-box;
        max-width: 31%;
        background-color: #545454;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        @include flex(column, center, center);
        font-size: 1.6875rem;
        position: relative;
        height: 5.625rem;

        &-selected {
          background-color: #e71e62;

          .bonus {
            color: #fff600 !important;
          }
        }

        .bonus {
          color: #fff600;
          font-size: 1.1875rem;
        }

        .hot-icon {
          position: absolute;
          left: -3px;
          top: -3px;
          width: 40px;
          height: auto;

          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }

    .tnc-section {
      width: 100%;
      box-sizing: border-box;
      margin: 1rem 0;

      span {
        font-weight: 700 !important;
      }
    }

    .deposit-btn {
      cursor: pointer;
      background-color: #e71e62;
      font-weight: 700;
      box-sizing: border-box;
      //margin: 1rem;
      border-radius: 5px;
      @include flex(row, center, center);
      width: 100%;
      height: 4.375rem;
      font-size: 2rem;

      span {
        font-size: 1.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .ant-form-item-label {
      Label {
        font-size: 1.5rem;
        padding: 0;
      }
    }

    .withdraw-amount-section {
      .hint {
        color: #fff;
        font-size: 0.8rem;
      }
    }
  }

  .withdraw-bind-content {
    font-size: 3rem !important;
  }

  .instruction-section {
    font-size: 0.9rem;
    margin-top: 3rem;

    .instruction-list {
      padding: 0 0 0 1rem;
      @include flex(column, center, center);
      gap: 0.5rem;
      list-style: none;
    }

    ul.instruction-list li::before {
      content: "•";
      color: #fe8800;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  .skeleton-section {
    width: 100%;
    box-sizing: border-box;
    margin-top: 2rem;
    @include flex(column, center, flex-start);
    gap: 0.5rem;

    .skeleton {
      background-color: #252525;
      border-radius: 10px;
      width: 100%;
      height: 25px;

      &-one {
        height: 120px;
      }

      &-two {
        height: 220px;
      }

      &-three {
        width: 40%;
        border-radius: 20px;
        margin-top: 2rem;
      }

      &-four {
        border-radius: 20px;
      }
    }
  }
}
</style>
