<!-- 邀请模块 -->
<template>
  <div class="affiliate-section">
    <PageHeader :back="onBack" title="TID_LB_AFFILIATE" />

    <div class="affiliate-section-inner">
      <section class="type-btn-section">
        <!-- 我的团队 -->
        <div :class="`btn ${type === 0 && 'btn-selected'}`" @click="type = 0">
          {{ t("TID_LB_MY_TEAM") }}
        </div>
        <!-- 团队详情 -->
        <div :class="`btn ${type === 1 && 'btn-selected'}`" @click="type = 1">
          {{ t("TID_MYTEAM_DETAILS_TITLE") }}
        </div>
        <!-- 团队投注 -->
        <div :class="`btn ${type === 2 && 'btn-selected'}`" @click="type = 2">
          {{ t("TID_TEAM1_TITLE2") }}
        </div>
      </section>

      <section v-if="type === 0" class="invite">
        <div class="invite-item">
          <div class="invite-item-title">
            {{ t("TID_LB_TODAY") }}
            <!-- <img src="@/assets/common/btn_help.png" alt=""> -->
          </div>

          <div class="invite-item-content">
            <div class="invite-item-content-item">
              <div class="code">{{ todayInfo.level1_member }}</div>
              <div class="desc">{{ t("TID_MYTEAM_SUB_TITLE") }}</div>
            </div>
            <div class="invite-item-content-item">
              <div class="code">{{ todayInfo.level1_fristrecharge_cnt }}</div>
              <div class="desc">{{ t("TID_MYTEAM_DEPOSIT_FIRST_TITLE") }}</div>
            </div>
            <div class="invite-item-content-item">
              <div class="code">{{ GFunc.formatNumber(todayInfo.level1_play_money) }}</div>
              <div class="desc">{{ t("TID_MYTEAM_VALID_BETS_TITLE") }}</div>
            </div>
            <div class="invite-item-content-item">
              <div class="code">{{ GFunc.formatNumber(todayInfo.level1_recharge_money) }}</div>
              <div class="desc">{{ t("TID_MYTEAM_DEPOSIT_TITLE") }}</div>
            </div>
          </div>
        </div>
        <div class="invite-item">
          <div class="invite-item-title">
            {{ t("TID_LB_TOTAL") }}
          </div>

          <div class="invite-item-content">
            <div class="invite-item-content-item">
              <div class="code">{{ allInfo.level1_member }}</div>
              <div class="desc">{{ t("TID_MYTEAM_SUB_TITLE") }}</div>
            </div>
            <div class="invite-item-content-item">
              <div class="code">{{ allInfo.level1_fristrecharge_cnt }}</div>
              <div class="desc">{{ t("TID_MYTEAM_DEPOSIT_FIRST_TITLE") }}</div>
            </div>
            <div class="invite-item-content-item">
              <div class="code">{{ GFunc.formatNumber(allInfo.level1_play_money) }}</div>
              <div class="desc">{{ t("TID_MYTEAM_VALID_BETS_TITLE") }}</div>
            </div>
            <div class="invite-item-content-item">
              <div class="code">{{ GFunc.formatNumber(allInfo.level1_recharge_money) }}</div>
              <div class="desc">{{ t("TID_MYTEAM_DEPOSIT_TITLE") }}</div>
            </div>
          </div>
        </div>
        <div class="invite-item">
          <div class="invite-item-title">
            {{ t("TID_LB_COMMISSION") }}
          </div>

          <div class="invite-item-content2">
            <div class="invite-item-content2-item">
              <div class="item">
                <div class="code">{{ GFunc.formatNumber(UserCtrl.getUserMoney()) }}</div>
                <div class="desc">{{ t("TID_LB_BALANCE") }}</div>
              </div>
              <div class="item">
                <div class="code">{{ GFunc.formatNumber(UserCtrl.getUserBonusMoney()) }}</div>
                <div class="desc">{{ t("TID_LB_BONUS") }}</div>
              </div>
            </div>
            <div class="invite-item-content2-btn" @click="bonusExchangeOpen = true">
              {{ t("TID_LB_CONVERT_TO_WALLET_BALANCE") }}
            </div>
            <div class="invite-item-content2-desc">{{ t("TID_LB_EXCHANGE_LIMIT", { value1: 50000 }) }}</div>
          </div>
        </div>
        <div class="invite-item">
          <div class="invite-item-title">
            {{ t("TID_LB_INVITE_FRIENDS_TO_HELP_WITH") }}
          </div>
          <div>
            <div class="social-media-icons">
              <!-- <img src="@/assets/common/share.png" alt="share" @click="sendEmail(userInfo?.uuid)" /> -->
              <img src="@/assets/common/facebook.png" alt="facebook" @click="shareOnFacebook(userInfo.uuid)" />
              <img src="@/assets/common/telegram.png" alt="telegram" @click="shareOnTelegram(userInfo?.uuid)" />
              <img src="@/assets/common/whatsapp.png" alt="whatsapp" @click="shareOnWhatsApp(userInfo?.uuid)" />
              <!-- <img src="@/assets/common/email.png" alt="email" @click="sendEmail(userInfo?.uuid)" /> -->
            </div>

            <div class="share-link-section">
              <div class="link-box" @click="copyToClipboard(`${UserStore.getInviteLink()}`)">
                <span class="referral-link">
                  {{ `${UserStore.getInviteLink()}` }}
                </span>
                <CopyOutlined style="color: #898989; font-size: 1rem" />
              </div>
            </div>
          </div>
        </div>
        <div class="invite-item">
          <div class="invite-item-title">
            {{ t("TID_PROMOTE_PROXY_RULE_TITLE") }}
          </div>
          <img :style="{
            width: '100%',
            margin: '.5rem 0'
          }" src="@/assets/affiliate/small_share.png" alt="">
          <!-- 邀请说明文本-->
          <span style="font-size: 1.3125rem; color: #8c8c8c; padding-bottom: 10rem;">
            {{ t("TID_PROMOTE_PROXY_RULE_DESC1") }}
          </span>
        </div>
      </section>
      <section v-else-if="type === 1" class="section2">
        <div class="section2-header">
          <div class="section2-header-item" :class="{
            'section2-header-item-selected': teamType === 0
          }" @click="switchTeamConf(0)">{{ t("TID_LB_ALL") }}</div>
          <div class="section2-header-item" :class="{
            'section2-header-item-selected': teamType === 1
          }" @click="switchTeamConf(1)">{{ t("TID_LB_TODAY") }}</div>
          <div class="section2-header-item" :class="{
            'section2-header-item-selected': teamType === 2
          }" @click="switchTeamConf(2)">{{ t("TID_LB_YESTERDAY") }}</div>
        </div>
        <div class="section2-item">
          <div class="section2-item-title">{{ t("TID_LB_ALL") }}</div>
          <div class="section2-item-content">
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.team_play_money }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_VALID_BETS_TITLE") }}</div>
            </div>
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.team_recharge_money }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_DEPOSIT_TITLE") }}</div>
            </div>
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.team_fristrecharge_member }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_DEPOSIT_FIRST_TITLE") }}</div>
            </div>
          </div>
        </div>
        <div class="section2-item">
          <div class="section2-item-title">{{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 1 }) }}</div>
          <div class="section2-item-content">
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.level1_play_money }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_VALID_BETS_TITLE") }}</div>
            </div>
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.level1_recharge_money }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_DEPOSIT_TITLE") }}</div>
            </div>
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.level1_fristrecharge_cnt }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_DEPOSIT_FIRST_TITLE") }}</div>
            </div>
          </div>
        </div>
        <div class="section2-item">
          <div class="section2-item-title">{{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 2 }) }}</div>
          <div class="section2-item-content">
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.level2_play_money }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_VALID_BETS_TITLE") }}</div>
            </div>
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.level2_recharge_money }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_DEPOSIT_TITLE") }}</div>
            </div>
            <div class="section2-item-content-item">
              <div class="section2-item-content-item-code">{{ teamConf.level2_fristrecharge_cnt }}</div>
              <div class="section2-item-content-item-desc">{{ t("TID_MYTEAM_DEPOSIT_FIRST_TITLE") }}</div>
            </div>
          </div>
        </div>
      </section>
      <section v-else-if="type === 2" class="section3">
        <div class="section3-header">
          <div class="section3-header-item">
            <div class="section3-header-item-title">
              {{ t("TID_LB_ALL") }}
            </div>
            <div class="section3-header-item-content">
              <div class="section3-header-item-content-left">
                {{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 1 }) }}
              </div>
              <div class="section3-header-item-content-right">
                {{ teamConf2.statistics.total_level1_play_money }}
              </div>
            </div>
            <div :style="{
              border: '.0625rem solid #545454',
            }"></div>
            <div class="section3-header-item-content">
              <div class="section3-header-item-content-left">
                {{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 2 }) }}
              </div>
              <div class="section3-header-item-content-right">
                {{ teamConf2.statistics.total_level2_play_money }}
              </div>
            </div>
          </div>
          <div class="section3-header-item">
            <div class="section3-header-item-title">
              {{ t("TID_LB_WEEK") }}
            </div>
            <div class="section3-header-item-content">
              <div class="section3-header-item-content-left">
                {{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 1 }) }}
              </div>
              <div class="section3-header-item-content-right">
                {{ teamConf2.statistics.lastweek_level1_play_money }}
              </div>
            </div>
            <div :style="{
              border: '.0625rem solid #545454',
            }"></div>
            <div class="section3-header-item-content">
              <div class="section3-header-item-content-left">
                {{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 2 }) }}
              </div>
              <div class="section3-header-item-content-right">
                {{ teamConf2.statistics.lastweek_level2_play_money }}
              </div>
            </div>
          </div>
        </div>
        <div class="section3-item" v-for="(title, key) in [
          t('TID_WEEK_1_1'), t('TID_WEEK_1_2'), t('TID_WEEK_1_3'), t('TID_WEEK_1_4'),
          t('TID_WEEK_1_5'), t('TID_WEEK_1_6'), t('TID_WEEK_1_0')
        ]" :key="key">
          <div class="section3-item-title">
            {{ title }}
          </div>
          <div class="section3-item-content">
            <div class="section3-item-content-item">
              <div class="section3-item-content-item-code">{{ 0 }}</div>
              <div class="section3-item-content-item-desc">{{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 1 }) }}</div>
            </div>
            <div class="section3-item-content-item">
              <div class="section3-item-content-item-code">{{ 0 }}</div>
              <div class="section3-item-content-item-desc">{{ t("TID_MYTEAM_LEVEL_TITLE", { value1: 2 }) }}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <a-modal v-model:open="bonusExchangeOpen" centered :closable="false" @ok="bonusExchange" 
  @cancel="exchangeAmount=null"
  :destroyOnClose="true"
  :cancelButtonProps="{
    style: {
      width: '49%',
      height: '3.125rem',
      fontSize: '1rem',
      fontWeight: '700',
    }
  }" :okButtonProps="{
    style: {
      width: '49%',
      height: '3.125rem',
      fontSize: '1rem',
      fontWeight: '700',
    }
  }">
    <a-input :value="exchangeAmount" :placeholder="t('TID_WALLET_BONUS_WITHDRAW_PLACEHOLDER')" @input="handleExchangeAmountInput"/>
  </a-modal>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { CopyOutlined } from "@ant-design/icons-vue";
import PageHeader from "@/components/PageHeader";
import { UserStore } from "@/lib/UserStore";
import {
  copyToClipboard,
  // sendEmail,
  shareOnTelegram,
  shareOnFacebook,
  shareOnWhatsApp,
} from "@/lib/utils";
import { netApi } from "@/api";
import { DateIns } from "@/lib/DateIns";
import { GFunc } from "@/lib/GFunc";
import { UserCtrl } from "@/ctrl/UserCtrl";
import { message } from "ant-design-vue";
import { DataIns } from "@/lib/DataIns";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const type = ref(0);
const todayInfo = ref({});
const allInfo = ref({});
const teamType = ref(0);
const teamConf = ref({});
const teamConf2 = ref({});
const userInfo = computed(() => store.state.userInfo);

onMounted(async () => {
  const resp = await getTeamConf(0);
  teamConf.value = resp;
  allInfo.value = resp;
  todayInfo.value = await getTeamConf(2);
  teamConf2.value = await getTeamConf2();
})

const onBack = () => {
  store.commit("setDepositOrWithdraw", "deposit");
  store.commit("goBack");
}

// type: 0 全部，1 昨天，2 今天
const switchTeamConf = async (type) => {
  teamType.value = type;
  teamConf.value = await getTeamConf(type);
}
const getTeamConf = async (type) => {
  const params = {};
  const curDate = new Date();
  if (type === 1) {
    curDate.setDate(curDate.getDate() - 1);
    params.start = params.end = DateIns.format(curDate.getTime())
  }
  if (type === 2) {
    params.start = params.end = DateIns.format(curDate.getTime())
  }
  return (await netApi.getTeamConf(params)).info;
}

const getTeamConf2 = async () => {
  return await netApi.getTeamConf2();
}

const bonusExchangeOpen = ref(false);
const exchangeAmount = ref(null);
const handleExchangeAmountInput = (e) => {
  let inputValue = e.target.value;
  inputValue = inputValue
    .replace(/(?!^\d*\.?\d*$)[^\d.]/g, "")
    .replace(/\.(?=.*\.)/g, "");
  e.target.value = e.target.value.replace(/[^0-9+()]/g, "");
  exchangeAmount.value = inputValue;
}
const bonusExchange = async () => {
  // 判断金额是否合法
  const money = DataIns.checknumber(exchangeAmount.value)
  if (money === 0) {
    message.error(t("TID_NOT_WALLET_BONUS_WITHDRAW"));
    return;
  }
  if (money < UserCtrl.getUserBonusMoney()) {
    message.error(t("TID_NOT_ENOUGH_WALLET_BONUS_WITHDRAW"));
    return;
  }
  try {
    await netApi.walletExchange({ money: Number(exchangeAmount.value) })
    bonusExchangeOpen.value = false;
    message.success(t("TID_WALLET_BONUS_TRANS_SUCCESS"));
  } catch(e) {
    console.log(e);
  }
}
</script>

<style lang="scss" scoped>
.affiliate-section {
  @include flex(column, center, center);
  gap: 1rem;
  padding-bottom: 8rem;

  &-inner {
    @include flex(column, center, center);
    gap: 1rem;
    max-width: 600px;
    width: 100%;
  }

  .type-btn-section {
    @include flex(row, center, center);
    gap: 1.5rem;
    width: 100%;

    .btn {
      cursor: pointer;
      width: 10.3125rem;
      height: 3.125rem;
      background-color: #545454;
      color: #fff;
      font-size: 1.625rem;
      border-radius: 1.5625rem;
      box-sizing: border-box;
      text-align: center;
      line-height: 3.125rem;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &-selected {
        background-color: #e71e62;
      }
    }
  }

  .invite {
    @include flex(column, center, center);
    width: 100%;
    gap: 1rem;

    &-item {
      background-color: black;
      width: 100%;
      padding: 1rem 1rem;
      box-sizing: border-box;

      &-title {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
        font-size: 1.5rem;
        border-bottom: 0.0625rem solid #666;
        width: 100%;
        padding: .2rem 0;

        img {
          height: 1.5rem;
        }
      }

      &-content {
        @include flex(row, center, center);
        width: 100%;
        gap: .8rem;
        text-align: center;
        align-items: flex-start;

        &-item {
          @include flex(column, center, center);
          width: 25%;
          padding: .5rem 0;

          .code {
            font-size: 2rem;
            font-weight: bold;
            color: #ffea00;
          }

          .desc {
            font-size: 1rem;
            color: #8c8c8c;
            flex: 1;
          }
        }
      }

      &-content2 {
        @include flex(column, center, center);
        width: 100%;
        padding: 1rem 0;
        gap: 1rem;

        &-item {
          @include flex(row, center, center);
          width: 100%;

          .item {
            @include flex(column, center, center);
            width: 50%;

            .code {
              font-size: 2rem;
              font-weight: bold;
              color: #ffea00;
            }

            .desc {
              font-size: 1rem;
              color: #8c8c8c;
            }
          }
        }

        &-btn {
          background-color: #e71e62;
          font-size: 2rem;
          border-radius: .5rem;
          padding: 0 .5rem;
        }

        &-desc {
          font-size: 1.125rem;
          color: #8c8c8c;
        }
      }
    }
  }

  .section2 {
    @include flex(column, center, center);
    width: 100%;
    gap: .5rem;
    box-sizing: border-box;

    &-header {
      @include flex(row, center, center);
      background-color: black;
      width: 100%;
      padding: .5rem 0;

      &-item {
        width: calc(100%/3);
        text-align: center;
        font-size: 1.5rem;

        &-selected {
          color: #e71e62;
          text-decoration: underline solid;
        }
      }
    }

    &-item {
      @include flex(column, center, center);
      background-color: black;
      width: 100%;
      padding: .5rem 1rem;
      box-sizing: border-box;

      &-title {
        width: 100%;
        font-size: 1.1875rem;
        border-bottom: 0.0625rem solid #666;
      }

      &-content {
        @include flex(row, center, center);
        width: 100%;
        padding: .5rem 0;
        align-items: flex-start;

        &-item {
          width: calc(100%/3);
          text-align: center;

          &-code {
            font-size: 1.625rem;
            color: #ffea00;
          }

          &-desc {
            color: #8c8c8c;
          }
        }
      }
    }
  }

  .section3 {
    @include flex(column, center, center);
    width: 100%;
    gap: .5rem;
    box-sizing: border-box;

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
      width: 100%;
      gap: .5rem;

      &-item {
        background-color: black;
        width: 50%;
        padding: .5rem;

        &-title {
          font-size: 1.1875rem;
          color: #fff;
        }

        &-content {
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;

          &-left {
            width: 50%;
            text-align: left;
            color: #8c8c8c;
            margin: auto;
          }

          &-right {
            width: 50%;
            text-align: right;
            font-size: 1.3125rem;
            color: #ffea00;
          }
        }
      }
    }

    &-item {
      background-color: black;
      width: 100%;
      padding: 0 1rem;
      box-sizing: border-box;

      &-title {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
        font-size: 1.5rem;
        border-bottom: 0.0625rem solid #666;
        width: 100%;
        padding: .2rem 0;
      }

      &-content {
        @include flex(row, center, center);
        padding: .5rem 0;

        &-item {
          width: 50%;
          text-align: center;

          &-code {
            font-size: 1.3125rem;
            color: #ffea00
          }

          &-desc {
            font-size: 0.9375rem;
            color: #8c8c8c
          }
        }
      }
    }
  }

  .social-media-icons {
    @include flex(row, flex-start, center);
    width: 100%;
    box-sizing: border-box;
    padding-top: 1rem;

    img {
      cursor: pointer;
      width: 10%;
      height: auto;

      @media (max-width: 600px) {
        width: 15%;
      }
    }
  }

  .share-link-section {
    @include flex(column, center, flex-start);
    gap: 0.5rem;
    padding-bottom: 1rem;

    .subtitle {
      font-size: 1.2rem;
    }

    .link-box {
      @include flex(row, space-between, center);
      padding: 0.4rem;
      background-color: #545454;
      font-size: 1.2rem;
      border-radius: 5px;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
</style>
