<template>
  <section class="about-us-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_AUBOUT_US") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        class="back-btn"
        style="opacity: 0"
      />
    </div>

    <div class="about-us-inner">
      <div class="logo-outer">
        <div class="logo-inner">
          <img src="@/assets/common/app_icon.png" alt="app_logo" />
        </div>
      </div>

      <div class="version-section">
        <span>Version: 3.6.2 (+94)</span>
        <span class="contact-us">
          {{ t("TID_CUSTOMER_CONTACT_US") }}
          <span class="telegram-link" @click="copyToClipboard(officialCS)">
            {{ officialCS }}
          </span>
          <CopyOutlined
            style="color: #d9d9d9; font-size: 1rem"
            @click="copyToClipboard(officialCS)"
          />
        </span>
      </div>

      <div class="device-information-section">
        <span class="title">{{ t("TID_LB_DEVICE_INFORMATION") }}</span>
        <div class="horizontal-line"></div>
        <div class="browser-row">
          <span>{{ t("TID_LB_BROWSER") }}</span>
          <span>{{ result.browser.name }}</span>
        </div>
        <div class="horizontal-line"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
// import { ref, onMounted, onUnmounted, watchEffect, computed } from "vue";

import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { CopyOutlined } from "@ant-design/icons-vue";
import { copyToClipboard } from "@/lib/utils";
import UAParser from "ua-parser-js";
import { CfgAnalyze } from "@/lib/CfgAnalyze";
import { GFunc } from "@/lib/GFunc";

// import Loading from "@/components/Loading";
// import { CaretRightOutlined } from "@ant-design/icons-vue";

// 频道号
const officialCS = ref(CfgAnalyze.getOfficialCS());

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const parser = new UAParser();
const result = parser.getResult();

const onBack = () => {
  store.commit("goBack");
};

GFunc.log(locale.value);
GFunc.log(result);
</script>

<style lang="scss">
.about-us-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    @include flex(row, space-between, center);
    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .about-us-inner {
    width: 100%;
    max-width: 430px;
    box-sizing: border-box;
    @include flex(column, center, center);
    .logo-outer {
      width: 100px;
      height: 100px;
      padding: 0.2rem;
      background: rgb(255, 235, 2);
      background: linear-gradient(
        0deg,
        rgba(255, 235, 2, 1) 2%,
        rgba(254, 136, 0, 1) 97%
      );
      border-radius: 50%;
    }
    .logo-inner {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #000;
      @include flex(row, center, center);
      img {
        width: 80%;
        height: auto;
      }
    }
    .version-section {
      width: 100%;
      box-sizing: border-box;
      @include flex(column, center, center);
      font-size: 0.9rem;
      gap: 0.5rem;
      margin: 2rem 0;
      .contact-us {
        color: #d9d9d9;
      }
      .telegram-link {
        color: cyan;
      }
    }
    .device-information-section {
      @include flex(column, center, flex-start);
      margin: 1rem 0;
      width: 100%;
      box-sizing: border-box;
      font-size: 0.9rem;
      .title {
        font-weight: bold;
        padding: 0 0.5rem;
      }
      .browser-row {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, space-between, center);
        padding: 0.8rem 0.5rem;
      }
    }
  }
}
</style>
