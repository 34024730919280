/**
 * 字典数据类型操作
 */

const ObjectIns = {
  /**
   * 将obj2的字典数据合并到obj1
   * @param obj1
   * @param obj2
   * @returns 合并后的obj1
   */
  merge(obj1, obj2) {
    for (let key in obj2) {
      obj1[key] = obj2[key];
    }
    return obj1;
  },

  /**
   * 将obj2的字典数据合并到obj1
   * @param obj1
   * @param obj2
   * @returns 合并后的obj1
   */
  mergeKeep(obj1, obj2) {
    for (let key in obj2) {
      if (obj2[key] != undefined) {
        obj1[key] = obj2[key];
      }
    }
    return obj1;
  },

  /**
   * 浅拷贝
   * @param obj
   * @returns
   */
  copy(obj) {
    let newObj = {}
    for (let k in obj) {
      newObj[k] = obj[k];
    }
    return newObj;
  },

  /**
   * 转置对象，将key与value对调成新对象
   * @param obj
   * @returns 转置后的对象
   */
  transpose(obj) {
    let newObj = {};
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      if (obj[key]) {
        newObj[obj[key]] = key;
      }
    })
    return newObj;
  },
};

export { ObjectIns };
