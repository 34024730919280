/**
 * 事件定义
 */

let KGEvent = {

    EVENT_ENTER_BACKGROUND              : "ENTER_BACKGROUND",                   // 游戏进入后台
    EVENT_ENTER_FOREGROUND              : "ENTER_FOREGROUND",                   // 游戏进入前台
    EVENT_CHECK_DIAMOND_STATE           : "event_check_diamond_state",          // 校验钻石状态【停用】
    EVENT_FLOATING_STATE_CHANGE         : "event_floating_state_change",        // 浮层界面状态改变
    EVENT_NAV_FLOATING_STATE_CHANGE     : "event_nav_floating_state_change",    // 菜单栏浮层状态改变
    EVENT_UILOADING_DESTROY             : "event_uiloading_destroy",            // 析构资源加载界面
    EVENT_DOWNLOAD_APP                  : "event_download_app",                 // 下载App或MobileConfig

    EVENT_GOOGLE_LOGIN_SUCCESS          : "event_google_login_success",         // google注册登录成功
    EVENT_GOOGLE_LOGIN_FAILED           : "event_google_login_failed",          // google注册登录失败
    EVENT_GOOGLE_LOGINED                : "event_google_logined",               // google账号已登录

    EVENT_GOOGLE_PAY_SUCCESS            : "event_google_pay_success",           // google支付成功
    EVENT_GOOGLE_PAY_FAILED             : "event_google_pay_failed",            // google支付失败
    EVENT_GOOGLE_PAY_CANCEL             : "event_google_pay_cancel",            // google支付取消
    EVENT_GOOGLE_PAY_SDK_UNREADY        : "event_google_pay_sdk_unready",       // google支付SDK未初始化或未支持
    EVENT_GOOGLE_PAY_PRODUCT_NOT_FOUND  : "event_google_pay_product_not_found", // google商品未找到

    EVENT_START_REFRESH_TOKEN_SCHEDULE  : "event_start_refresh_token_schedule", // 启动刷新Token定时器
    EVENT_STOP_REFRESH_TOKEN_SCHEDULE   : "event_stop_refresh_token_schedule",  // 停止刷新Token定时器
    EVENT_START_CHECK_DIAMOND_SCHEDULE  : "event_start_check_diamond_schedule", // 启动定时校对钻石【停用】
    EVENT_EVERY_MINUTE_SCHEDULE         : "event_every_minute_schedule",        // 每分钟定时刷新定时器
    EVENT_TOKEN_TIMEOUT                 : "event_token_timeout",                // Token过期

    EVENT_AUTO_OPEN_AGENT_CONSULTING    : "event_auto_open_agent_consulting",   // 自动弹出公告（废弃，未使用）
    EVENT_AUTO_POP_STACK                : "event_auto_pop_stack",               // 自动弹窗

    EVENT_SIGNIN_DATA_RECEIVE           : "event_signin_data_receive",          // 接收到签到数据
    EVENT_SIGNIN_DATA_UPDATE            : "event_signin_data_update",           // 签到数据更新
    EVENT_SIGNIN_SUCCESS                : "event_signin_success",               // 签到成功

    EVENT_WALLET_DATA_RECEIVE           : "event_wallet_data_receive",          // 接收钱包数据
    EVENT_WALLET_SWITCH_RECEIVE         : "event_wallet_switch_receive",        // 钱包充值提现切换通知

    EVENT_VIP_DATA_RECEIVE              : "event_vip_data_receive",             // 接收到Vip数据
    EVENT_VIP_LV_UPDATE                 : "event_vip_lv_update",                // vip等级更新
    EVENT_VIP_LV_CHOOSE                 : "event_vip_lv_choose",                // 选中vip等级

    EVENT_ACTIVITY_COMPLETE_MSG         : "event_activity_complete_msg",        // 活动完成通知

    EVENT_IMAGE_BUFFER_RECEIVE          : "event_image_buffer_receive",         // 获取支付凭证图片数据

    EVENT_USDT_PAYMENT_ADDR_RECEIVE     : "event_usdt_payment_addr_receive",    // 获取到Usdt支付地址

    EVENT_NAVIGATION_RECEIVE            : "event_navigation_receive",           // 导航栏事件
    EVENT_NAVIGATION_CHANGE             : "event_navigation_change",            // 导航栏状态改变
    EVENT_VIEW_STATE_CHANGE             : "event_view_state_change",            // 界面状态改变
    EVENT_VIEW_ACTION_FINISH            : "event_view_action_finish",           // 界面动画播放完成

    EVENT_INTRODUCTION_DATA_RECEIVE     : "event_introduction_data_receive",    // 接收简介数据（废弃，未使用）
    EVENT_ROLL_CONFIG_DATA_RECEIVE      : "event_roll_config_data_receive",     // 轮播图片配置数据【停用】
    EVENT_NOTICE_CONFIG_DATA_RECEIVE    : "event_notice_config_data_receive",   // 公告及轮播图配置

    EVENT_PROFILE_STATE_CHANGE          : "event_profile_state_change",         // 信息界面状态改变
    EVENT_AVATAR_CHANGE                 : "event_avatar_change",                // 修改用户头像

    EVENT_GAME_CONF_RECEIVE             : "event_game_conf_receive",            // 接收游戏列表事件
    EVENT_COLLECTS_GAME_CHANGE          : "event_collects_game_change",         // 收藏游戏状态改变
    EVENT_GAME_VIEW_FINISHED            : "event_game_view_finished",           // 游戏列表显示完成
    EVENT_GAME_PLAY_RECEIVE             : "event_game_play_receive",            // 进入退出游戏事件
    EVENT_GAME_WAITING_RECEIVE          : "event_game_waiting_receive",         // 进入游戏等待事件
    EVENT_GAME_SEARCH_RECEIVE           : "event_game_search_receive",          // 搜索字符改变

    EVENT_PROMOTION_BOX_AWARD           : "event_promotion_box_award",          // 推广宝箱领奖事件
    EVENT_REG_PULL_AWARD_RECEIVE        : "event_reg_pull_award_receive",       // 领取信封奖励

    EVENT_USER_REWARD_DATA_RECEIVE      : "event_user_reward_data_receive",     // 中奖滚动数据
    EVENT_JACKPOT_REWARD_DATA_RECEIVE   : "event_jackpot_reward_data_receive",  // 滚动奖池数据

    EVENT_PROMOTION_PAGE_CHANNEL_CHANGE : "event_promotion_page_channel_change",// 推广页面切换
    EVENT_REMOVE_EVENT_VIEW_RECEIVE     : "event_remove_event_view_receive",    // 关闭活动事件窗口
}

export { KGEvent }
