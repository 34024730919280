<template>
  <div class="dialog" @click="props.onClose">
    <div class="base" @click="(e) => e.stopPropagation()">
      <div class="title-layout" :style="{
        paddingLeft: hideClose ? '4rem' : ''
      }">
        <div class="title">{{ props.title || i18n.global.t("TID_DIALOG_TITLE") }}</div>
        <img src="@/assets/common/close.png" alt="" @click="props.onClose" v-if="hideClose">
      </div>
      <div class="content">{{ props.content }}</div>
      <div class="btn-layout">
        <div class="btn btn-cancel" @click="props.onClose">{{ i18n.global.t("TID_LB_CANCEL") }}</div>
        <div class="btn btn-confirm" @click="props.onConfirm">{{ i18n.global.t("TID_LB_OK") }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import { Dialog } from "@/components/Dialog";
// Dialog({
//   title: '标题',
//   content: '内容',
//   close: () => {
//     console.log('关闭')
//   },
//   confirm: () => {
//     console.log('确认')
//   }
// })
// or
// const bool = await Dialog({ ... })

// TODO: content 暂时支持 string 类型，后续可能会在支持 VNode 类型

import { defineProps } from "vue";
import i18n from "@/locales";

const props = defineProps({
  title: String,
  content: String,
  hideClose: Boolean,
  onClose: Function,
  onConfirm: Function,
})
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base {
  @include flex(column, center, center);
  background-color: #383838;
  border-radius: .5rem;
  width: 32rem;
}

.title-layout {
  @include flex(row, center, center);
  gap: 1rem;
  width: 100%;
  text-align: center;
  box-sizing: border-box;

  img {
    height: 3rem;
  }
}

.title {
  color: #fff;
  font-size: 1.5rem;
  height: 4.125rem;
  line-height: 4.125rem;
  flex-grow: 1;
}

.content {
  background-color: #000;
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
}

.btn-layout {
  @include flex(row, center, center);
  width: 100%;
  gap: 0.5rem;
  box-sizing: border-box;
  padding: 1rem;
}

.btn {
  background-color: bisque;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  border-radius: .5rem;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;

  &-cancel {
    background-color: #fff;
    color: #000;
  }

  &-confirm {
    background-color: #e71e62;
    color: #fff;
  }
}
</style>