<template>
  <section class="promotion-container">
    <PageHeader title="TID_LB_PROMOTE" :back="onBack" />
    <div class="promotion-inner">
      <img v-for="([src, feature], key) in [
        [require('@/assets/landing/banner1.jpg'), 'receive_50_for_free_page'],
        [require('@/assets/landing/banner2.jpg'), 'main_affiliate_page'],
        [require('@/assets/landing/banner3.jpg'), 'deposit-withdraw'],
        [require('@/assets/landing/banner4.jpg'), 'cashback_25_page'],
        [require('@/assets/landing/banner5.jpg'), '7_days_check_in_bonus_page'],
        [require('@/assets/landing/banner6.jpg'), 'vip'],
      ]" :key="key" :src="src" @click="onJump(feature)"/>
    </div>
  </section>
  <Loading v-if="requestLoading" />
</template>

<script setup>
import { useStore } from "vuex";

import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";

const store = useStore();
const onBack = () => {
  store.commit("goBack");
};

const onJump = (feature) => {
  if (feature === '') return;

  if (feature === "deposit-withdraw") {
    store.commit("setDepositOrWithdraw", "deposit");
  }
  store.commit("setCurrentView", feature);
}
</script>

<style lang="scss">
.promotion-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  padding-bottom: 8rem;

  .promotion-inner {
    @include flex(column, center, center);
    width: 100%;
    box-sizing: border-box;
    padding: .5rem;
    gap: .5rem;
    max-width: 600px;

    img {
      width: 100%
    }
  }
}
</style>
