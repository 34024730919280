<template>
  <div class="game-screen">
    <InGameBanner />
    <iframe
      :src="currentGame"
      style="
        width: 100%;
        height: 94vh;
        box-sizing: border-box;
        overflow: hidden;
        border: none;
      "
    ></iframe>
  </div>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { netApi } from "@/api";
import Loading from "@/components/Loading";
import { onUnmounted, ref, computed } from "vue";
import { useStore } from "vuex";
import InGameBanner from "@/components/InGameBanner";

const store = useStore();

const currentGame = computed(() => store.state.currentGame);
const token = computed(() => store.state.token);

const requestLoading = ref(false);

onUnmounted(async () => {
  if (token.value !== "") {
    try {
      requestLoading.value = true;
      const resp = await netApi.getUserInfo()
      store.commit("setUserInfo", resp.player);
    } catch (e) {
      console.log(e);
    } finally {
      requestLoading.value = false;
    }
  }
});
</script>

<style lang="scss">
.game-screen {
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
</style>
