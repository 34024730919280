<template>
  <section class="wheelspin-container">
    <PageHeader title="TID_LB_RECEIVE_50_FOR_FREE" :back="onBack" />

    <div class="wheelspin-inner" v-if="!requestLoading">
      <div class="wheelspin-top">
        <div class="wheelspin-top-first">
          <img src="@/assets/wheel-spin/cash.png" alt="money" class="money-icon" />
          <span class="amount">
            {{ "₫ " + parseFloat(info.totalmoney || 0).toFixed(2) }}
          </span>

          <div style="flex-grow: 1;">
            <div class="claim-btn claim-btn-active" @click="showClaimModal = true" v-if="awardStatus">
              {{ t("TID_LB_CLAIM") }}
            </div>

            <div class="claim-btn claim-btn-disabled" v-else>
              {{ t("TID_LB_CLAIM") }}
            </div>
          </div>
        </div>

        <div class="wheelspin-require">
          {{
            t("TID_LB_STILL_REQUIRED_X_TO_WITHDRAW", {
              value: '₫' + GFunc.formatNumber(wheelWithdrawCost),
            })
          }}
        </div>

        <div class="wheelspin-progress">
          <a-progress :percent="(((parseFloat(info.totalmoney) / conf.money) || 0) * 100).toFixed(0)" />
        </div>
      </div>

      <!-- <section class="announcement-section">
        <Vue3Marquee :duration="12">
          <div class="text text--horizontal" v-for="(msg, index) in annoucementMessages" :key="index">
            {{ msg }}
          </div>
        </Vue3Marquee>
      </section> -->

      <section class="wheel-spinner">
        <Wheel :rotateEnd="onWheelRotateEnd" :count="wheelCount" :disabled="wheelDisabled" />

        <div class="spin-now-btn-outer" @click="externalSpinWheel">
          <div class="spin-now-btn-inner">
            {{ t("TID_LB_SPIN_NOW") }}
          </div>
        </div>
      </section>

      <span class="expired-in">
        <span>{{ t("TID_LB_EXPIRED_IN") }}</span>
        <span>{{ countdown }}</span>
      </span>

      <section class="referral-section" @click="openShare">
        <p style="font-size: 1.5rem;font-weight: bold;flex-grow: 1;text-align: center;">
          {{ t("TID_LB_INVITE_FRIENDS_TO_HELP_WITH") }}
        </p>
        <img src="@/assets/common/icon_share.png" style="height: 2.0666666667rem;" />
        <a-drawer height="16.875rem" title="Basic Drawer" placement="bottom" :closable="false" :visible="drawerVisible"
          :headerStyle="{
            display: 'none'
          }" :bodyStyle="{
  color: '#898989',
  'font-size': '1.5rem',
  padding: '1rem',
}" @close="closeShare">
          <div class="referral-section-top">
            {{ t("TID_LB_INVITE_FRIENDS_TO_HELP_WITH") }}
          </div>
          <div :style="{
            padding: '1rem 0'
          }">
            <!-- <span class="title">{{ t("TID_LB_SHARE_WITH_YOUR_SOCIAL_COMMUNITY") }}</span> -->
            <div class="social-media-icons">
              <!-- <img src="@/assets/common/share.png" alt="share" @click="sendEmail(userInfo?.uuid)" /> -->
              <div class="social-media-icons-item">
                <img src="@/assets/common/telegram.png" alt="telegram" @click="shareOnTelegram(userInfo?.uuid)" />
                <span>Telegram</span>
              </div>
              <div class="social-media-icons-item">
                <img src="@/assets/common/whatsapp.png" alt="whatsapp" @click="shareOnWhatsApp(userInfo?.uuid)" />
                <span>WhatsAPP</span>
              </div>
              <div class="social-media-icons-item">
                <img src="@/assets/common/facebook.png" alt="facebook" @click="shareOnFacebook(userInfo.uuid)" />
                <span>Facebook</span>
              </div>
              <!-- <img src="@/assets/common/email.png" alt="email" @click="sendEmail(userInfo?.uuid)" /> -->
            </div>

            <div class="share-link-section">
              <!-- <span class="subtitle">
                {{ t("TID_LB_SHARE_THIS_REFERRAL_LINK_WITH_YOUR_FRIENDS") }}
              </span> -->
              <div class="link-box" @click="copyToClipboard(`${UserStore.getInviteLink()}`)">
                <span style="color: #fff;font-size: 1.5rem;">
                  {{ `${UserStore.getInviteLink()}` }}
                </span>
                <img src="@/assets/common/copy_link.png" alt="copy" :style="{
                  width: '3.125rem'
                }">
              </div>
            </div>
          </div>
        </a-drawer>
      </section>

      <section :style="{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        width: '100%',
        boxSizing: 'border-box',
        margin: '1rem 0',
      }">
        <div :style="{
          width: '100%',
          fontSize: '1.5rem',
          color: '#aeaeae',
          textAlign: 'center',
        }">{{ 'Báo cáo' }}</div>
        <Vue3Marquee :duration="5" :vertical="true" :style="{
          width: '100%',
          height: '13.125rem',
          backgroundColor: '#121212',
          boxSizing: 'border-box',
          padding: '1rem',
          overflowX: 'hidden',
        }">
          <div :src="img" v-for="(reward, k) in rewards" :key="k" :style="{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '1.3125rem',
          }">
            <div style="width: 40%;">{{ reward[0] }}</div>
            <div style="width: 20%;text-align: center;">{{ reward[1] }}</div>
            <div style="width: 30%;text-align: right;color: #fff430;">{{ GFunc.formatNumber(conf.money) }} ₫</div>
          </div>
        </Vue3Marquee>
      </section>

      <section class="how-to-section">
        <!-- <span class="title">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_1") }}</span>
        <br />
        <span class="subtitle">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_2") }}</span>
        <br />
        <span class="desc">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_3") }}</span>
        <br />
        <br />
        <span class="subtitle">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_4") }}</span>
        <br />
        <span class="desc">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_5") }}</span>
        <br />
        <br />
        <span class="subtitle">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_6") }}</span>
        <br />
        <span class="desc">{{ t("TID_LB_RECEIVE_50_HOW_TO_PLAY_7") }}</span>

        <br />
        <br /> -->
        <span>{{ t("TID_LB_TERMS_AND_CONDITIONS") }}</span>
        <ol>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_1") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_2") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_3") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_4") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_5") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_6") }}</li>
          <li>{{ t("TID_LB_TERMS_AND_CONDITIONS_RECEIVE_50_FOR_FREE_7") }}</li>
        </ol>
      </section>
    </div>

    <a-modal :open="showWonModal" centered @ok="showWonModal = false" :closable="false" :maskClosable="false"
      :okText="t('TID_LB_OK')" class="wheel-spin-won-modal" :cancelButtonProps="{ style: { display: 'none' } }"
      :okButtonProps="{
        style: {
          width: '100%',
          height: '50px',
          fontSize: '1rem',
          marginInlineStart: '0',
          color: '#000',
          fontWeight: 'bold',
          background: 'rgb(255,151,0)',
          background:
            'linear-gradient(180deg, rgba(255,151,0,1) 0%, rgba(255,155,0,1) 11%, rgba(255,164,1,1) 35%, rgba(255,172,1,1) 53%, rgba(255,192,1,1) 74%, rgba(255,195,1,1) 75%, rgba(255,200,1,1) 83%, rgba(255,211,1,1) 91%, rgba(255,214,1,1) 94%, rgba(255,235,2,1) 99%)',
          border: 'none',
        },
      }">
      <div class="wheel-spin-won-modal-content" v-if="referralInfo.referral_bonus">
        <span class="title" v-if="referralInfo.referral_bonus.click < 2">
          {{ t("TID_LB_CONGRATULATIONS_YOU_HAVE_WON") }}</span>
        <span class="title" v-else>
          {{ t("TID_LB_THANK_YOU_BETTER_LUCK_NEXT_TIME") }}</span>
        <span class="amount" v-if="referralInfo.referral_bonus.click < 2">
          {{ "₫ " + referralInfo?.referral_bonus?.total_amount ?? "-" }}</span>
      </div>
    </a-modal>

    <a-modal :open="showLoginModal" centered @ok="onLoginModalOk" @cancel="onBack" :closable="false" :maskClosable="false"
      :okText="t('TID_LOGIN_TITLE')" :cancelText="t('back')" class="wheel-spin-won-modal" :cancelButtonProps="{
        style: {
          width: '49%',
          height: '50px',
          fontSize: '1rem',
          marginInlineStart: '0',
          color: '#000',
          fontWeight: 'bold',
          background: '#9e9e9e',
          border: 'none',
        },
      }" :okButtonProps="{
  style: {
    width: '49%',
    height: '50px',
    fontSize: '1rem',
    marginInlineStart: '0',
    color: '#000',
    fontWeight: 'bold',
    background: 'rgb(255,151,0)',
    background:
      'linear-gradient(180deg, rgba(255,151,0,1) 0%, rgba(255,155,0,1) 11%, rgba(255,164,1,1) 35%, rgba(255,172,1,1) 53%, rgba(255,192,1,1) 74%, rgba(255,195,1,1) 75%, rgba(255,200,1,1) 83%, rgba(255,211,1,1) 91%, rgba(255,214,1,1) 94%, rgba(255,235,2,1) 99%)',
    border: 'none',
  },
}">
      <div class="wheel-spin-won-modal-content">
        <span class="title"> {{ t("TID_LB_CONGRATULATIONS_YOU_HAVE_WON") }}</span>
        <span class="amount"> {{ "₫ " + spinValue }}</span>
        <span>{{ t("TID_LB_PLEASE_LOGIN_TO_RECEIVE_YOUR_REWARDS") }}</span>
      </div>
    </a-modal>

    <a-modal :open="showEventExpiredModal" centered @ok="closeEventExpiredModal" :closable="false" :maskClosable="false"
      :okText="t('TID_LB_OK')" class="wheel-spin-won-modal" :cancelButtonProps="{ style: { display: 'none' } }"
      :okButtonProps="{
        style: {
          width: '100%',
          height: '50px',
          fontSize: '1rem',
          marginInlineStart: '0',
          color: '#000',
          fontWeight: 'bold',
          background: 'rgb(255,151,0)',
          background:
            'linear-gradient(180deg, rgba(255,151,0,1) 0%, rgba(255,155,0,1) 11%, rgba(255,164,1,1) 35%, rgba(255,172,1,1) 53%, rgba(255,192,1,1) 74%, rgba(255,195,1,1) 75%, rgba(255,200,1,1) 83%, rgba(255,211,1,1) 91%, rgba(255,214,1,1) 94%, rgba(255,235,2,1) 99%)',
          border: 'none',
        },
      }">
      <div class="wheel-spin-won-modal-content">
        <span class="title"> {{ t("TID_LB_EVENT_EXPIRED") }}</span>
      </div>
    </a-modal>

    <a-modal :open="showClaimModal" centered @ok="onClaim" :closable="false" :maskClosable="false"
      :okText="t('TID_LB_CLAIM_NOW')" class="wheel-spin-won-modal" :cancelButtonProps="{ style: { display: 'none' } }"
      :okButtonProps="{
        style: {
          width: '100%',
          height: '50px',
          fontSize: '1.4rem',
          marginInlineStart: '0',
          color: '#fff',
          fontWeight: 'bold',
          background: 'rgb(255,151,0)',
          background:
            'linear-gradient(180deg, rgba(255,151,0,1) 0%, rgba(255,155,0,1) 11%, rgba(255,164,1,1) 35%, rgba(255,172,1,1) 53%, rgba(255,192,1,1) 74%, rgba(255,195,1,1) 75%, rgba(255,200,1,1) 83%, rgba(255,211,1,1) 91%, rgba(255,214,1,1) 94%, rgba(255,235,2,1) 99%)',
          border: 'none',
        },
      }" :bodyStyle="{
  fontSize: '2rem',
  color: '#fff',
}">
      <div class="wheel-spin-won-modal-content">
        <span> {{ t("TID_LB_CLAIM_YOUR_REWARD") }}</span>

        <span> {{ ` ₫ ${info.totalmoney}` }}</span>
      </div>
    </a-modal>

    <Loading v-if="requestLoading" />
  </section>
</template>

<script setup>
import { onMounted, onUnmounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import "vue-fortune-wheel/style.css";
import { netApi } from "@/api";
import Loading from "@/components/Loading";
import {
  copyToClipboard,
  // sendEmail,
  shareOnTelegram,
  shareOnFacebook,
  shareOnWhatsApp,
} from "@/lib/utils";
import { message } from "ant-design-vue";
import { UserStore } from "@/lib/UserStore";
import Wheel from "@/components/Wheel";
import PageHeader from "@/components/PageHeader";
import { GFunc } from "@/lib/GFunc";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

// 中奖列表
const rewards = ref([]);
// 转盘用户数据
const info = ref({});
// 转盘配置
const conf = ref({});
// 转盘次数
const wheelCount = computed(() => {
  const { free_times, free_usetimes, times, usetimes } = info.value;
  return free_times + times - free_usetimes - usetimes;
});
const wheelDisabled = computed(() => {
  return info.value === 1 || wheelCount.value < 1;
});
// 转盘剩余可提现金额
const wheelWithdrawCost = computed(() => {
  const cost = conf.value.money - info.value.totalmoney;
  return Math.max(cost, 0);
});
// 领奖状态
const awardStatus = computed(() => {
  return info.value.status !== 1 && info.value.totalmoney >= conf.value.money;
});

const drawerVisible = ref(false);
const requestLoading = ref(false);

const showLoginModal = ref(false);

const wheelEl = ref();

// const selectedTab = ref("reference");
const referralInfo = ref({});

const showWonModal = ref(false);
const showClaimModal = ref(false);

const userInfo = computed(() => store.state.userInfo);
const spinValue = computed(() => store.state.spinValue);

const eventExpired = ref(false);
const showEventExpiredModal = ref(false);

const onLoginModalOk = () => {
  store.commit("setCurrentView", "login-register");
};

const closeEventExpiredModal = () => {
  showEventExpiredModal.value = false;
  window.location.reload();
};

const onClaim = async () => {
  showClaimModal.value = false;
  try {
    requestLoading.value = true;
    await netApi.getEventTrunTableAward();
    message.success(t("TID_LB_CLAIM_SUCCESSFULLY"));
    // 领奖完更新转盘状态
    await reqWheelStatus();
    // TODO 用户数据
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const spinLeft = computed(() => {
  if (referralInfo.value.referral_bonus) {
    let currentDate = new Date(tickingDate.value);
    let startDate = new Date(referralInfo.value.referral_bonus.start_date);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - startDate;

    // Convert milliseconds to days
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const remainingDays = Math.floor(
      differenceInMilliseconds / millisecondsInADay
    );

    let remainingClick =
      remainingDays + 1 - referralInfo.value.referral_bonus.click;

    return remainingClick;
  }
  return 1;
});

const getPrizeWeight = computed(() => {
  // Check if referralInfo has valid data and set weight accordingly
  if (referralInfo.value?.referral_bonus) {
    return referralInfo.value.referral_bonus.click <= 0 ? 1 : 0;
  }
  return 1; // Default weight when referral_bonus is unavailable
});

const getThankYouWeight = computed(() => {
  // Check if referralInfo has valid data and set weight accordingly
  if (referralInfo.value?.referral_bonus) {
    return referralInfo.value.referral_bonus.click >= 1 ? 1 : 0;
  }
  return 0; // Default weight for 'thank you' when referral_bonus is unavailable
});

const initialCurrentDate = ref(new Date());
const tickingDate = ref(initialCurrentDate.value); // Keeps a mutable, incrementing current date
const countdown = ref("");

let interval;

// Function to update countdown display
function updateCountdown() {
  // Ensure `referralInfo` and `end_date` are available
  const end = info.value.end_time
    ? new Date(info.value.end_time)
    : null;

  // Return early if `end` is invalid
  if (!end || isNaN(end.getTime())) {
    countdown.value = "0D 00:00:00";
    clearInterval(interval);
    return;
  }

  const remainingTime = end - tickingDate.value;

  // If time has expired, stop counting
  if (remainingTime <= 0) {
    countdown.value = "0D 00:00:00";
    clearInterval(interval);
    return;
  }

  // Calculate remaining days, hours, minutes, and seconds
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  // Format the countdown string
  countdown.value = `${days}D ${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  // Increment ticking date by one second to simulate the passage of time
  tickingDate.value = new Date(tickingDate.value.getTime() + 1000);
}

// Initialize countdown and set interval
function startCountdown() {
  // Make sure `initialCurrentDate` is set properly before starting
  // const serverCurrentDate = new Date(referralInfo.value.current_date);
  const serverCurrentDate = new Date();

  // Check for validity
  if (!isNaN(serverCurrentDate.getTime())) {
    tickingDate.value = serverCurrentDate; // Set to the server-provided current date
  }

  updateCountdown(); // Initial calculation
  interval = setInterval(updateCountdown, 1000); // Update every second
}

const onBack = () => {
  store.commit("goBack");
};

const externalSpinWheel = () => {
  if (spinLeft.value < 1) {
    if (eventExpired.value) {
      return message.error(t("TID_LB_EVENT_EXPIRED"));
    }
    return message.error(t("TID_LB_NO_SPIN_LEFT"));
  }
  wheelEl.value.startRotate();
};

watch(
  [getPrizeWeight, getThankYouWeight],
  ([newPrizeWeight, newThankYouWeight]) => {
    console.log(newPrizeWeight);
    console.log(newThankYouWeight);
  }
);

watch(info, () => {
  startCountdown();
});

watch([countdown], async ([newCountdown]) => {
  if (info.value.end_time !== "" && newCountdown === "0D 00:00:00") {
    // 到期 重新请求转盘数据
    reqWheelStatus();
  }
});

const reqWheelStatus = async () => {
  const wheelinfo = await netApi.getEventTrunTableStatus();
  info.value = wheelinfo.info;
  conf.value = wheelinfo.trunTableConf;
  const { end_time } = info.value;
  // 没有过期时间/超过过期时间 重新发起参与转盘
  if (!end_time || (new Date(end_time)).getTime() < Date.now()) {
    // 参与转盘
    await netApi.getEventTrunTableOpen();
    // 在获取一次转盘数据
    const wheelinfo = await netApi.getEventTrunTableStatus();
    info.value = wheelinfo.info;
    conf.value = wheelinfo.trunTableConf;
  }
}

onMounted(async () => {
  reqWheelStatus();

  rewards.value = new Array(10).fill(null).map(() => {
    const number = Math.floor(Math.random() * 900 + 100) // Generates a number from 100 to 999
      .toString()
      .padStart(3, "0");
    const amount = [50000, 100000, 150000, 300000].sort(() => Math.random() - Math.random()).shift(); // Generates an amount in multiples of 10 from 1000 to 10000
    return [`84*******${number}`, t("TID_LB_HAS_WON"), `+ ${GFunc.formatNumber(amount)} ₫`]
  })
});

onUnmounted(() => {
  clearInterval(interval); // Clear the interval when the component unmounts
});

// 转盘旋转结束
const onWheelRotateEnd = async () => {
  // 在获取一次转盘数据
  const wheelinfo = await netApi.getEventTrunTableStatus();
  info.value = wheelinfo.info;
  conf.value = wheelinfo.trunTableConf;
}

const openShare = () => {
  drawerVisible.value = true;
}
const closeShare = () => {
  drawerVisible.value = false;
}
</script>

<style lang="scss">
.wheelspin-container {
  @include flex(column, center, center);
  box-sizing: border-box;

  .fw-container {
    font-size: 0.9rem !important;
    line-height: 0px !important;
  }

  .fw-wheel {
    @include flex(row, center, center);
  }

  .fw-btn {
    @include flex(row, center, center);
  }

  .top-section {
    position: sticky;
    top: 0;
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    z-index: 999;
    background-color: #000;
    @include flex(row, space-between, center);

    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }

    .deposit-history {
      text-align: right;
      font-size: 0.8rem;
      width: 80px;
      height: auto;
      padding-right: 0.5rem;
      color: #ffeb02;
      box-sizing: border-box;
      position: absolute;
      right: 0;
      cursor: pointer;
    }

    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }

  .wheelspin-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 600px;

    @include flex(column, center, center);

    .wheelspin-top {
      // background-color: #fe8800;
      @include flex(column, center, center);
      padding: 1rem 0;
      border-radius: 10px;
      width: calc(100% - 2rem);

      &-first {
        @include flex(row, left, center);
        gap: 1rem;
        width: 100%;

        .money-icon {
          height: 4.1666666667rem;
        }

        .amount {
          font-weight: bold;
          font-size: 2.6875rem;
        }

        .claim-btn {
          width: max-content;
          font-size: 1.625rem;
          border-radius: 8px;
          padding: 0 0.5rem;
          float: right;
          line-height: 3.75rem;

          &-disabled {
            color: #fff;
            background-color: #9e9e9e;
            border: 1px solid #d9d9d9;
            font-weight: bold;
          }

          &-active {
            background-color: #e71e62;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    .wheelspin-progress {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, center, center);

      .ant-progress-outer {
        padding: 0;
      }

      .ant-progress-text {
        // background-color: #e71e62 !important;
        // border: 2px solid #e71e62;
        font-size: 1.625rem !important;
        float: right;
      }
    }

    .wheelspin-require {
      font-size: 1.625rem;
    }

    .announcement-section {
      width: 100%;
      box-sizing: border-box;
      margin: 1rem 0;

      .text {
        margin: 0 0.5rem;
        font-size: 0.8rem;
        font-weight: bold;
      }
    }

    .expired-in {
      background-color: #121212;
      width: 100%;
      box-sizing: border-box;
      @include flex(row, center, center);
      font-size: 1.2rem;
      font-weight: bold;
      gap: 0.5rem;
    }

    .wheel-spinner {
      background-color: #121212;
      width: 100%;
      box-sizing: border-box;
      padding: 1rem 0;
      @include flex(column, center, center);

      .spin-now-btn {
        &-outer {
          cursor: pointer;
          background: rgb(206, 134, 48);
          background: linear-gradient(180deg,
              rgba(206, 134, 48, 1) 0%,
              rgba(222, 161, 56, 1) 11%,
              rgba(245, 202, 69, 1) 40%,
              rgba(241, 195, 67, 1) 55%,
              rgba(232, 179, 62, 1) 68%,
              rgba(218, 155, 55, 1) 86%,
              rgba(206, 134, 48, 1) 96%);
          margin-top: 1rem;
          width: 180px;
          text-align: center;
          border-radius: 30px;
          font-weight: bold;
          font-size: 1.2rem;
          height: 50px;
          @include flex(row, center, center);
          padding: 0.3rem;
        }

        &-inner {
          background: rgb(225, 25, 22);
          background: linear-gradient(180deg,
              rgba(225, 25, 22, 1) 0%,
              rgba(249, 64, 62, 1) 31%,
              rgba(255, 73, 71, 1) 49%,
              rgba(249, 64, 62, 1) 68%,
              rgba(225, 25, 22, 1) 96%);
          width: 100%;
          box-sizing: border-box;
          height: 100%;
          @include flex(row, center, center);
          border-radius: 30px;
        }
      }
    }

    .referral-section {
      background-color: #121212;
      @include flex(row, center, center);
      box-sizing: border-box;
      width: 100%;
      padding: 1rem;

      &-top {
        width: 100%;
        box-sizing: border-box;

        background-color: #ffeb02;
        color: #000;
        font-weight: bold;
        text-align: center;
        border-radius: 10px 10px 0 0;
        padding: 0.5rem 0;
      }
    }

    .how-to-section {
      background-color: #121212;
      font-size: 0.9rem;
      padding: 1rem;

      .title {
        color: #ff8800;
        font-weight: bold;
      }

      .subtitle {
        color: #efb677;
        font-weight: bold;
      }

      ol {
        padding-left: 0.8rem;
      }
    }

    .tabs-section {
      width: 100%;
      box-sizing: border-box;
      font-size: 0.9rem;
      margin: 1rem 0;
      padding: 0 1rem;

      &-top {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, flex-start, center);
        gap: 2rem;

        span {
          border-bottom: 2px solid #000;
          padding-bottom: 0.2rem;
          cursor: pointer;
        }

        .selected-tab {
          border-bottom: 2px solid #ffeb02;
          padding-bottom: 0.2rem;
        }
      }

      &-content {
        width: 100%;
        box-sizing: border-box;
        margin: 0.5rem 0;
        min-height: 150px;

        .table-header {
          @include flex(row, space-around, center);
          background-color: #666;
          color: #fff;

          span {
            flex: 0 0 30%;
            text-align: center;
          }
        }

        .tab-row {
          @include flex(row, space-around, center);
          margin: 0.5rem 0;

          span {
            flex: 0 0 30%;
            text-align: center;
            font-size: 0.8rem;
          }
        }

        .tab-no-data {
          height: 150px;
          width: 100%;
          box-sizing: border-box;
          @include flex(row, center, center);
        }
      }
    }
  }

  .triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #000;
    position: absolute;
    top: -12;
    left: 50%;
    transform: translate(-50%, -80%);
    z-index: -1;
  }

  .wheel-btn {
    width: 60px;
    height: max-content;
    cursor: pointer;

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -55%);
      z-index: 999;
      @include flex(column, center, center);
      line-height: 20px;
      color: #000;
    }

    &-img {
      width: 100%;
    }
  }

  .wheel-spin-won-modal {
    .ant-modal-content {
      background-color: #000 !important;
      border: 1px solid #463f04;
      padding: 1rem !important;
    }

    .wheel-spin-won-modal-content {
      @include flex(column, center, center);
      padding: 0.5rem 0;

      .title {
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif !important;
      }

      .amount {
        font-weight: bold;
        color: #fe8800;
        font-size: 1.6rem;
      }
    }
  }

}


.title {
  font-weight: bold;
  color: #ff8800;
}

.social-media-icons {
  @include flex(row, center, center);
  width: 100%;
  box-sizing: border-box;
  gap: 2rem;

  &-item {
    @include flex(column, center, center);

    img {
      cursor: pointer;
      width: 4.6875rem;
    }

    span {
      font-size: 1.1875rem;
      text-align: center;
    }
  }
}

.share-link-section {
  @include flex(column, center, flex-start);
  gap: 0.5rem;
  margin-top: 1.5rem;

  .subtitle {
    font-size: 1.2rem;
  }

  .link-box {
    @include flex(row, space-between, center);
    padding: 0.4rem;
    background-color: #e71e62;
    font-size: 1.2rem;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    height: 3.3125rem;
  }
}
</style>
