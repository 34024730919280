<template>
  <section class="casino-bet-history-container">
    <PageHeader title="TID_LB_MY_GAME_BETS" :back="onBack" />

    <div class="casino-bet-history-inner">
      <div class="table-header">
        <span class="title">{{ t("TID_GAME_RECORD_CELL_GAME") }}</span>
        <span class="title">{{ t("TID_LB_TIME") }}</span>
        <span class="title">{{ t("TID_GAME_RECORD_CELL_BETS") }}</span>
        <span class="title">{{ t('TID_GAME_RECORD_CELL_PAYMENTS') }}</span>
      </div>

      <section class="bet-list-section">
        <div class="bet-list-item" v-for="(item, key) in dataList" :key="key">
          <span>{{ item.create_time.split(" ")[0] }}</span>
          <span>{{ item.gameName }}</span>
          <span>{{ item.betAmount }}</span>
          <span>{{ item.winAmount }}</span>
        </div>
        <div ref="sentinel" class="sentinel">
          <a-spin v-if="fetchingMoreData" style="color: #ffeb02" size="medium" />
          <span v-else-if="!fetchingMoreData && dataList.length > 0">
            {{ t("TID_LB_LOAD_NO_DATA") }}
          </span>
        </div>
      </section>

      <section class="no-results-section" v-if="dataList.length <= 0 && !requestLoading">
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { netApi } from "@/api";
import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const requestLoading = ref(false);
const fetchingMoreData = ref(false);

const total = ref(0);
const dataList = ref([]);
const currentPage = ref(1);
const sentinel = ref(null);

const onBack = () => {
  store.commit("goBack");
};

const fetchData = async (pageNumber) => {
  try {
    if (pageNumber < 2) {
      requestLoading.value = true;
    } else {
      fetchingMoreData.value = true;
    }

    const query = { lastid: 0, row: 50 };
    const resp = await netApi.getBetRecord(query);
    dataList.value = [...dataList.value, ...resp.list]; // Append new data
    total.value = resp.total;
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
    fetchingMoreData.value = false;
  }
};

onMounted(() => {
  fetchData(currentPage.value);
});

</script>

<style lang="scss">
.casino-bet-history-container {
  @include flex(column, center, center);
  box-sizing: border-box;

  .casino-bet-history-inner {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 0;
    max-width: 600px;

    .table-header {
      width: 100%;
      box-sizing: border-box;
      background-color: #191919;
      @include flex(row, space-around, center);
      padding: 0.5rem 1.5rem;
      gap: 0.5rem;

      .title {
        width: 25%;
        text-align: center;
        font-size: 1.0556rem;
        color: #8c8c8c;
      }
    }

    .bet-list-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(column, center, center);
      padding: .5rem 1.5rem;
      gap: .5rem;
    }

    .bet-list-item {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, stretch);
      border-bottom: 1px solid #8c8c8c;
      min-height: 2.5rem;
      align-items: center;

      span {
        width: calc(100% / 4);
        text-align: center;
        font-size: 1.125rem;
      }
    }

    .bet-list-item:last-child {
      border-bottom: none;
    }
  }
}
</style>
