<template>
  <section class="search-game-container">
    <PageHeader title="TID_LB_SEARCH" :back="onBack" v-if="!selectedGameCategory && !selectedProvider" />
    <PageHeader title="TID_LB_GAME_CATEGORY" :back="onBack" v-if="selectedGameCategory" />
    <PageHeader title="TID_LB_GAME_PROVIDER" :back="onBack" v-if="selectedProvider" />

    <div class="search-game-inner">
      <section class="category-section" v-if="selectedGameCategory !== ''">
        <div :class="`category-item ${selectedGameCategory === item.id && 'category-item-selected'
          }`" v-for="(item, key) in categories" :key="key" @click="switchCategory(item.id)">
          <img :src="item.image_off" :alt="item.category_name" v-if="selectedGameCategory !== item.id" />
          <img :src="item.image_on" :alt="item.category_name" v-else />
          <span>{{ item.category_name }}</span>
        </div>
      </section>

      <img :src="selectedProvider?.logo" alt="logo" class="provider-logo" v-if="selectedProvider" />

      <section class="search-game-bar">
        <a-input v-model:value="game" :placeholder="t('TID_LB_SEARCH_HINT')" allowClear ref="searchInput"
          @keyup.enter="searchGame()">
          <template #prefix>
            <SearchOutlined @click="searchGame()"
              style="font-size: 1.875rem; color: #666; margin-right: 1rem" />
          </template>
        </a-input>

        <div class="favourite" @click="goTo('favourite-games')">
          <img src="@/assets/landing/icon_favourite.png" alt="favourite" />
        </div>
      </section>

      <section class="game-list-section">
        <GameItem v-for="(game, key) in dataList" :key="key" :game="game" style="width: calc((100% - 2rem) / 3);"/>
      </section>

      <section class="no-results-section" v-if="dataList.length <= 0 && !requestLoading">
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted, watchEffect } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { SearchOutlined } from "@ant-design/icons-vue";
import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";
import { GameCtrl } from "@/ctrl/GameCtrl";
import GameItem from "@/components/views/Game/Item";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const game = ref("");
const requestLoading = ref(false);

const selectedGameCategory = computed(() => store.state.selectedGameCategory);
const selectedProvider = computed(() => store.state.selectedProvider);
// const favouriteGameList = computed(() => store.state.favouriteGames);

const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const sentinel = ref(null);
const searchInput = ref(null);

const categories = ref([]);

const onBack = () => {
  store.commit("setSelectedGameCategory", "");
  store.commit("setSelectedProvider", "");
  store.commit("goBack");
};

const switchCategory = (id) => {
  store.commit("setSelectedGameCategory", id);
};

// 收藏游戏
// const addFavourite = (item) => {
//   let list = favouriteGameList.value;
//   list.push(item);
//   store.commit("setFavouriteGames", list);
// };

// 移除收藏游戏
// const removeFavourite = (item) => {
//   let list = favouriteGameList.value.filter((fav) => fav.id !== item.id);
//   store.commit("setFavouriteGames", list);
// };

const searchGame = async () => {
  dataList.value = GameCtrl.fuzzySearch(game.value);
};

const goTo = (view) => {
  store.commit("setCurrentView", view);
};

const observer = new IntersectionObserver(
  ([entry]) => {
    if (entry.isIntersecting && lastPage.value > currentPage.value) {
      currentPage.value += 1;
      searchGame(currentPage.value, false);
    }
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  }
);

watch(selectedGameCategory, async () => {
  await searchGame(currentPage.value, true);
});

watchEffect(() => {
  if (sentinel.value) {
    observer.observe(sentinel.value);
  }
});

onMounted(async () => {
  searchGame();
  if (selectedGameCategory.value !== "") {
    try {
      requestLoading.value = true;
      categories.value = await reqApi.getAllCategorysInfo({
        language: "vn",
        version: "3.6.1",
      });
      await searchGame(1, true);
    } catch (e) {
      console.log(e);
    } finally {
      requestLoading.value = false;
    }
  } else {
    searchInput.value?.focus({
      preventScroll: true,
    });
  }
  if (selectedProvider.value !== "") {
    searchGame(currentPage.value, true);
  }
  const selectedElement = document.querySelector(".category-item-selected");
  if (selectedElement) {
    selectedElement.scrollIntoView({
      behavior: "smooth", // Optional for smooth scrolling
      block: "center", // Center the element vertically in the viewport
      inline: "center", // Center the element horizontally in the viewport
    });
  }
});

onUnmounted(() => {
  if (sentinel.value) {
    observer.unobserve(sentinel.value);
  }
});
</script>

<style lang="scss">
.search-game-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  gap: 1rem;

  .search-game-inner {
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    @include flex(column, center, center);
    max-width: 600px;

    .provider-logo {
      width: 80px;
      height: auto;
      margin-bottom: 1rem;
    }

    .search-game-bar {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, stretch);
      gap: 0.5rem;

      .ant-input {
        height: 100%;
        font-size: 1.625rem !important;

        &-affix-wrapper {
          box-sizing: border-box;
          height: 3.125rem;
          border-radius: 1.5625rem;
          padding: 0 1.5625rem;
        }
      }

      .favourite {
        cursor: pointer;

        img {
          height: 3.125rem;
        }
      }
    }

    .category-section {
      @include flex(row, space-between, center);
      gap: 0.5rem; // Increase the gap between items for spacing
      overflow-x: auto; // Allow horizontal scrolling
      scrollbar-width: none; // Firefox
      -ms-overflow-style: none; // IE and Edge
      margin: 1rem 0 0.5rem 0;
      width: 100%;
      box-sizing: border-box;

      // Hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }

      .category-item {
        @include flex(row, center, center);
        gap: 0.5rem;
        cursor: pointer;
        background-color: #3f3f3f;
        color: #e9e9e9;
        border-radius: 8px;
        width: max-content;
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
        white-space: nowrap; // Prevent text wrapping

        &-selected {
          color: #ff9700;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }

      @media (max-width: 600px) {
        padding: 0 0.5rem;
      }
    }

    .game-list-section {
      margin: 1rem 0;
      @include flex(row, flex-start, center);
      width: 100%;
      box-sizing: border-box;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
</style>
