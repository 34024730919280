<template>
  <section class="affiliate-list-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_AFFILIATE_LIST") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        class="back-btn"
        style="opacity: 0"
      />
    </div>

    <div class="affiliate-list-inner">
      <section class="date-picker-section">
        <a-range-picker
          v-model:value="selectedDate"
          :default-picker-value="[defaultStartDate, defaultEndDate]"
          format="MMM DD"
        />
      </section>

      <section class="table-header">
        <span class="title">{{ t("TID_REGISTER_DATE") }}</span>
        <span class="title">{{ t("TID_LB_MEMBER") }}</span>
      </section>

      <section class="affiliate-list-section" v-if="!requestLoading">
        <div
          class="affiliate-list-item"
          v-for="(item, key) in dataList"
          :key="key"
        >
          <div class="affiliate-list-item-cell">
            {{ moment(item.created_at).format("YYYY-MM-DD") }}
          </div>
          <div class="affiliate-list-item-cell">
            {{ item.member_code }}
          </div>
        </div>
      </section>
      <section class="skeletons" v-else>
        <div class="skeletons-item" v-for="(i, key) in 10" :key="key"></div>
      </section>

      <section class="pagination-section" v-if="!requestLoading">
        <a-pagination
          v-model:current="currentPage"
          :defaultCurrent="1"
          :pageSize="perPage"
          :total="totalItems"
        />
      </section>

      <section
        class="no-results-section"
        v-if="dataList.length <= 0 && !requestLoading"
        style="margin-top: 5rem"
      >
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import { reqApi } from "@/api";
import moment from "moment";
import Loading from "@/components/Loading";

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const defaultStartDate = dayjs().startOf("day");
const defaultEndDate = dayjs().endOf("day");
const selectedDate = ref([defaultStartDate, defaultEndDate]);

const requestLoading = ref(false);

const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const perPage = ref(0);
const totalItems = ref(0);

const onBack = () => {
  store.commit("goBack");
};

const fetchData = async () => {
  try {
    requestLoading.value = true;

    const res = await reqApi.getAgentDownlineList({
      language: locale.value,
      page: currentPage.value,
      from_date: selectedDate.value[0].format("YYYY-MM-DD"),
      to_date: selectedDate.value[1].format("YYYY-MM-DD"),
      version: "3.6.2",
    });
    if (res) {
      dataList.value = res.data;
      currentPage.value = res.current_page;
      lastPage.value = res.last_page;
      perPage.value = res.per_page;
      totalItems.value = res.total;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

watch(currentPage, () => {
  fetchData();
});

watch(selectedDate, () => {
  currentPage.value = 1;
  fetchData();
});

onMounted(() => {
  fetchData();
});
</script>

<style lang="scss">
.affiliate-list-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    @include flex(row, space-between, center);
    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .affiliate-list-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 430px;
    height: 90vh;
    position: relative;
    .date-picker-section {
      width: 100%;
      box-sizing: border-box;
      @media (max-width: 600px) {
        padding: 0 0.5rem;
      }
    }
    .table-header {
      width: 100%;
      box-sizing: border-box;
      background-color: #666;
      @include flex(row, space-around, center);
      padding: 0.4rem 0;
      font-size: 0.8rem;
      margin-top: 1rem;
      gap: 0.5rem;
      .title {
        width: 50%;
        text-align: center;
      }
    }
    .affiliate-list-section {
      box-sizing: border-box;
      width: 100%;
      @include flex(column, center, center);
    }
    .affiliate-list-item {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, stretch);
      height: max-content;
      border-bottom: 1px solid #252525;
      padding: 1rem 0;
      &-cell {
        width: 50%;
        text-align: center;
        font-size: 0.9rem;
        @include flex(row, center, center);
        img {
          width: 80%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
        }
      }
    }
    .affiliate-list-item:last-child {
      border-bottom: none;
    }
    .pagination-section {
      @include flex(row, center, center);
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      @media (max-width: 600px) {
        padding: 0 0.5rem;
      }
    }

    @media (max-width: 600px) {
      padding: 1rem 0;
    }
  }
}
</style>
