<template>
  <div class="game-item" @click="(event) => pick(props.game, event)">
    <img :src="props.game.iconurl" alt="">
    <div class="game-item-favourite">
      <img src="@/assets/common/favourite_on.png" @click="(event) => favourite(props.game, event)" v-if="status">
      <img src="@/assets/common/favourite_off.png" @click="(event) => favourite(props.game, event)" v-else>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import { GameCtrl } from "@/ctrl/GameCtrl";
import { GFunc } from "@/lib/GFunc";

const store = useStore();
const props = defineProps({ game: Object })

const status = computed(() => {
  return !!GameCtrl.collectsList.value.find(({ id }) => id === props.game.id);
});

// 选择游戏
const pick = (game, event) => {
  GFunc.log('选择游戏', game)
  event.stopPropagation();

  GameCtrl.reqGameAddress(game, (url) => {
    if (url) {
      store.commit("setCurrentGame", url);
      store.commit("setCurrentView", "in-game");
    }
  })
}

// 收藏游戏
const favourite = (game, event) => {
  GFunc.log('收藏游戏', game)
  event.stopPropagation();
  GameCtrl.reqGameCollectSet(game);
}

</script>

<style lang="scss" scoped>
.game-item {
  width: 100%;
  box-sizing: border-box;

  img {
    width: 100%;
    border-radius: 1.25rem;
  }

  &-favourite {
    width: 0;
    height: 0;
    position: relative;
    float: right;
    left: -3.7rem;

    img {
      width: 3.7rem;
      border-radius: 0
    }
  }
}
</style>