import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import "./styles/global.scss";
import Antd from "ant-design-vue";
import Vue3Marquee from "vue3-marquee";
import store from "./store";
import i18n from "./locales";

// 创建并运行工程，生产环境需关闭开发环境的提示和警告信息
const app = createApp(App)
  .use(router)
  .use(Antd)
  .use(Vue3Marquee)
  .use(i18n)
  .use(store);
app.config.productionTip = false;

app.mount("#app");
