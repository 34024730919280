<template>
  <a-modal
    :open="open"
    centered
    @ok="logout"
    :closable="false"
    :maskClosable="false"
    :okText="t('TID_LB_LOGOUT')"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        width: '100%',
        height: '50px',
        fontSize: '1rem',
        marginInlineStart: '0',
      },
    }"
  >
    <div class="logout-modal-content">
      <span>{{ t("TID_LB_UNAUTHENTICATED").toUpperCase() }}</span>
    </div>
  </a-modal>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import eventBus from "@/eventBus";
import { netApi } from "@/api";
import { useStore } from "vuex";
import Loading from "@/components/Loading";
import { UserStore } from "@/lib/UserStore";

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const store = useStore();
const apiBaseUrl = computed(() => store.state.apiBaseUrl);

const open = ref(false);
const requestLoading = ref(false);

onMounted(() => {
  console.log("Mounting LogoutModal and setting up event listener."); // Debug log
  eventBus.on("show-modal", setShowModal);
});

onUnmounted(() => {
  console.log("Unmounting LogoutModal and removing event listener."); // Debug log
  eventBus.off("show-modal", setShowModal);
});

function setShowModal(visible) {
  console.log(`Received showModal event with visibility: ${visible}`); // Debug log
  open.value = visible;
}

const logout = async () => {
  // Set open to false to close the modal
  open.value = false;

  // No longer need to call setShowModal from eventBus
  requestLoading.value = true;
  try {
    const res = await netApi.userLogout({});
    if (res) {
      window.location.reload();
      store.commit("setToken", "");
      store.commit("setUserInfo", "");
      store.commit("clearHistoryStack");
      store.commit("setDepositOrWithdraw", "deposit");
      store.commit("setCurrentView", "casino");
      store.commit("setSpinValue", 0);
      store.commit("setPrevApiBaseUrl", apiBaseUrl.value);
      store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());
      store.commit("setRechargeCommissionConf", {});
      store.commit("setCommissionRate", {});
      store.commit("setPlatBaseConf", {});
      store.commit("setLocalLastUser", "");
    }
  } catch (e) {
    console.error(e);
  } finally {
    requestLoading.value = false;
    let s = window.location.href;
    window.location.href = s;
  }
};
</script>

<style lang="scss">
.logout-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  height: 50px;
  font-weight: 500;
}
</style>
