/**
 * 签到逻辑模块
 */

import { KGEvent } from "@/api/KGEvent";
import eventBus from "@/eventBus";
import { SwitchModule } from "@/lib/CfgAnalyze";
import { GFunc } from "@/lib/GFunc";
import { UserCtrl } from "./UserCtrl";
import { netApi } from "@/api";
import { DataIns } from "@/lib/DataIns";
import { EventCtrl } from "./EventCtrl";
import { EEventType } from "@/lib/Consts";

const SigninStatus = { None: 1, Award: 3 }

class _CheckinCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听
    data = {};            // { day: number, status: number, expiredime: number }

    constructor() {
        GFunc.log("CheckinCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("CheckinCtrl initCtrl");
    }

    initModel() {
        GFunc.log("CheckinCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
        // TODO 待处理
        eventBus.on(KGEvent.EVENT_EVERY_MINUTE_SCHEDULE, () => {
            if (!this.data) { return; }
            if (Date.now() >= this.data.expiredime * 1000) {
                this.data = null;
            }
        });
    }

    initData() {
        this.data = null;
    }

    checkModuleEnable() {
        return SwitchModule.checkCheckInSwitch();
    }

    // 检查是否已经签到
    checking() {
        return this.data?.status === SigninStatus.Award;
    }

    // 获取缓存的签到数据
    async getSigninDataByCache(callFunc) {
        if (!this.checkModuleEnable()) {
            callFunc && callFunc();
            return;
        }
        if (!this.data) {
            await this.reqSigninIdx(callFunc);
        } else {
            callFunc && callFunc();
        }
    }

    // 获取最新的签到数据
    /*static*/ async reqSigninIdx(callFunc) {
        let reqSucc = (data) => {
            data = DataIns.checktable(data);
            data.day = Math.min(DataIns.checkint(data.day), 7);
            data.awards = DataIns.checkarray(data.conf?.awards || data.awards);
            data.minCoding = DataIns.checknumber(data.conf?.minCoding);
            data.minRecharge = DataIns.checknumber(data.conf?.minRecharge);
            data.playMoney = DataIns.checknumber(data.playMoney);
            data.rechargeMoney = DataIns.checknumber(data.rechargeMoney);
            data.totalMoney = DataIns.checknumber(data.totalMoney);
            this.data = data;
            callFunc && callFunc();
        }
        try {
            let eventEnable = EventCtrl.checkEventEnable(EEventType.EEvent_CheckIn);
            if (eventEnable) {
                await EventCtrl.getEventDataByCache(EEventType.EEvent_CheckIn, (data) => {
                    reqSucc(data);
                }, () => {
                    // failFunc && failFunc();
                });
            } else {
                const data = await netApi.getCheckinIndex({});
                reqSucc(data);
            }           
        } catch (error) {
            /** */
        } finally {
            /** */
        }
    }

    // 签到
    async reqSigninAward(callFunc, failFunc) {
        try {
            // TODO 待处理 提示文本
            // eslint-disable-next-line no-unused-vars
            const data = await netApi.getCheckinAward({});
            eventBus.emit("message", {
                type: "success",
                message: `TID_CHECKIN_SUCCESS`,
            });
            this.data.status = SigninStatus.Award;
            callFunc && callFunc();
        } catch (error) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    // 检查是否第一天签到
    checkIsFirstCheckIn() {
        return DataIns.checkint(this.data.day) == 1;
    }

    // 检查是否弹出签到
    checkAutoPop() {
        if (!this.checkModuleEnable()) {
            return false;
        }
        // 未登录，不处理
        if (!UserCtrl.getIsLogin()) {
            return false;
        }
        // 已经弹过，不弹
        if (UserCtrl.getIsAutoOpenSignin()) {
            return false;
        }
        // 没有数据，不处理
        if (this.data == null) {
            return false;
        }
        // 已经签到，不弹
        if (this.checking()) {
            return false;
        }
        return true;
    }
}

const CheckinCtrl = new _CheckinCtrl_();

export { CheckinCtrl, SigninStatus }

// DEBUG
window.CheckinCtrl = CheckinCtrl;
