/**
 * 钱包逻辑模块
 */

import { SwitchModule } from "@/lib/CfgAnalyze";
import { GFunc } from "@/lib/GFunc";
import { UserCtrl } from "./UserCtrl";
import { netApi } from "@/api";
import eventBus from "@/eventBus";
import { KGEvent } from "@/api/KGEvent";
import { DataIns } from "@/lib/DataIns";

class _WalletCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听
    walletData = null;      // 钱包数据

    constructor() {
        GFunc.log("WalletCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("WalletCtrl initCtrl");
    }

    initModel() {
        GFunc.log("WalletCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
    }

    // 初始化钱包数据
    initData() {
        this.walletData = null;
    }

    // 钱包模块是否可用
    checkModuleEnable() {
        return SwitchModule.checkWithdrawalSwitch();
    }

    // 获取钱包数据列表
    getWalletDataByCache(reqParams, callFunc, failFunc) {
        if (!this.checkModuleEnable()) {
            callFunc && callFunc();
            return;
        }
        // if (!this.walletData) {
        //     // 没有数据，请求...
        //     this.getWalletDataList(callFunc);
        // }
        // else {
        //     this.getExistWalletDataList(callFunc);
        // }
        // 每次都请求数据
        this.getWalletDataList(reqParams, callFunc, failFunc);
    }

    // 获取钱包数据列表
    /*static*/ async getWalletDataList(reqParams, callFunc, failFunc) {
        if (!this.checkModuleEnable()) {
            callFunc && callFunc();
            return;
        }
        let self = this;
        try {
            const data = await netApi.getBalance(reqParams);
            self.walletData = data || {};
            self.getExistWalletDataList(callFunc);
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    // 存在钱包数据时，直接获取
    /*static*/ getExistWalletDataList(callFunc) {
        let walletData = this.checkModuleEnable() ? this.walletData : {};
        callFunc && callFunc(walletData);
        eventBus.emit(KGEvent.EVENT_WALLET_DATA_RECEIVE, walletData);
    }

    // 获取已提现次数
    getWithdrawalCountCur() {
        if (!this.checkModuleEnable()) {
            return 0;
        }
        if (!this.walletData) {
            return 0;
        }
        return this.walletData.count;
    }

    // 增加已提现次数
    appendWithdrawalCountCur(value) {
        if (!this.checkModuleEnable()) {
            return;
        }
        if (!this.walletData) {
            return;
        }
        value = value != undefined ? value : 1;
        this.walletData.count += value;
    }

    // 获取充值剩余奖励次数
    getRechargeAwardCount(type) {
        if (!this.checkModuleEnable()) {
            return 0;
        }

        if (!this.walletData) {
            return 0;
        }

        if (type === 0) {
            return this.walletData.recharge1Count;
        }

        if (type === 1) {
            return this.walletData.recharge2Count;
        }

        return 0;
    }

    // 提现打码进度
    getWithdrawalPlayMoneyProgress() {
        let pms = this.getWithdrawalPlayMoney();
        return pms[1] == 0 ? 1 : Math.min(pms[0] / pms[1], 1);
    }

    // 提现打码进度值 [打码量,所需打码量]
    getWithdrawalPlayMoney() {
        let walletVipLevel = UserCtrl.getWalletVipLevel();
        let playMoney = UserCtrl.getPlaylMoney();
        let needPlayMoneyTotal = UserCtrl.getNeedPlayMoneyTotal();
        let pms = [1, 1];
        if (!UserCtrl.getIsLogin()) {
            pms = [0, 0];
        } else if (playMoney >= needPlayMoneyTotal || walletVipLevel != -1) {
            pms = [needPlayMoneyTotal, needPlayMoneyTotal];
        } else if (needPlayMoneyTotal > 0) {
            pms = [playMoney, needPlayMoneyTotal];
        }
        return pms;
    }

    // 计算钱换U
    getCashToUsdt(money) {
        if (!this.checkModuleEnable()) {
            return 0;
        }
        if (!this.walletData) {
            return 0;
        }
        let ret = 0;
        let cashtoURate = this.walletData.cashtoURate;
        if (cashtoURate > 0) {
            ret = GFunc.toFixedFloat(DataIns.checknumber(money) * cashtoURate, 6);
        }
        return ret;
    }
}

const WalletCtrl = new _WalletCtrl_();

export { WalletCtrl }

// DEBUG
window.WalletCtrl = WalletCtrl;
