/* eslint-disable no-case-declarations */
import axios from "axios";
import eventBus from "@/eventBus";
import CryptoJS from "crypto-js";
import { DataIns } from "@/lib/DataIns";
import { CfgAnalyze } from "@/lib/CfgAnalyze";
import { ObjectIns } from "@/lib/ObjectIns";
import { GFunc } from "@/lib/GFunc";
import { decode, encode } from "js-base64";
import { NetProtocols } from "./NetProtocols";
import { UserCtrl } from "@/ctrl/UserCtrl";
import { GlobalCtrl } from "@/ctrl/GlobalCtrl";
import store from "@/store";

// 标记虚拟账号
let dev_Api = "";

// 标记虚拟账号
let internalAccFlag = false;

// 清除虚拟账号标记
export const setInternalAccFlag = () => {
    internalAccFlag = false;
}

export const getInternalAccFlag = () => {
    return internalAccFlag;
}

/**
 * 获取API地址
 * @param {*} ignore 是否忽略虚拟账号
 * @returns
 */
export const getAppBaseApi = (ignore) => {
    const baseApi = DataIns.checkstring(CfgAnalyze.getBaseApi()).trim();
    const baseApiEx = DataIns.checkstring(CfgAnalyze.getBaseApiEx()).trim();
    return !ignore && internalAccFlag && baseApiEx ? baseApiEx : baseApi;
}

// 获取请求协议头
const getHeaders = () => {
    let headers = {};
    const userid = UserCtrl.getUserId();
    const token = UserCtrl.getUserToken();
    const nonce = GFunc.getRandStr(15);
    const timeStamp = Date.parse(new Date().toString()) / 1000;
    const getHeaderKey = (hKey) => {
        return NetProtocols.getRealProtocolsHeaderKey(hKey);
    }
    if (token) {
        // sign = MD5(随机数 + 时间戳(秒) + 用户ID + Token)
        const sign = CryptoJS.MD5(`${nonce}${timeStamp}${userid}${token}`);
        // headers["Token"] = token;
        headers[getHeaderKey("X-NONCE")] = nonce;
        headers[getHeaderKey("X-TS")] = timeStamp;
        headers[getHeaderKey("X-UID")] = userid;
        headers[getHeaderKey("X-SIGN")] = sign.toString().toUpperCase();
    }
    headers["Content-Type"] = "application/json; charset=utf-8";
    return headers;
}

// 路由映射，获取真实路由
const getRealProtocol = (protocol) => {
    let arr = protocol.split(/[//]/);
    let last = arr.length - 1;
    arr[0] = NetProtocols.getRealProtocolsFirstName(arr[0]);
    arr[last] = NetProtocols.getRealProtocolsLastName(arr[last]);
    let realProtocol = arr.join("/");
    return realProtocol;
}

// 获取请求URL地址
export const getUrl = (protocol, ignore) => {
    protocol = getRealProtocol(protocol);
    return `${getAppBaseApi(ignore)}/${dev_Api}${protocol}`;
}

// 格式化参数 组装为 key1=value1&key2=value2&...
// const formatParams = (params, encodeURI, appendVer) => {
//   let arr = [];
//   for (let key in params) {
//     let value = params[key];
//     if (value == undefined) {
//       continue;
//     }
//     if (encodeURI) {
//       key = encodeURIComponent(key);
//       value = encodeURIComponent(value);
//     }
//     arr.push(key + "=" + value);
//   }
//   appendVer && arr.push(("v=" + Math.random()).replace(".", ""));
//   return arr.join("&");
// }

// 加密请求数据
export const encodeReqData = (data) => {
    data = DataIns.checktable(data);
    let isBase64 = CfgAnalyze.getApiIsBase64();
    if (isBase64) {
        data = GFunc.getRandStr(7) + encode(JSON.stringify(data));
    }
    return data;
}

// 解密返回数据
export const decodeRespData = (data) => {
    let isBase64 = CfgAnalyze.getApiIsBase64();
    if (isBase64) {
        data = DataIns.checkstring(decode(data));
        data = JSON.parse(decode(data.slice(14)));
    }
    return data;
}

// 删除字符串前后所有双引号或单引号，并解密
export const removeAllQuotesFromString = (data) => {
    let isBase64 = CfgAnalyze.getApiIsBase64();
    if (isBase64) {
        data = DataIns.checkstring(data);
        data = data.replace(/(^['"]|['"]$)/g, '');
        data = GFunc.getDecodeApiRequest(data);
    }
    return JSON.parse(data);
}

// create an AxiosInstance
export const service = axios.create({
    baseURL: getAppBaseApi(),
    timeout: 20000,
    // withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
    (config) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        // GFunc.log('API request config:', config);
        // TODO 停服处理
        // if () {
        //     const message = {
        //         config: config,
        //         message: "Request failed!",
        //         response: {
        //             data: {},
        //         }
        //     }
        //     return Promise.reject(message);
        // }
        const headers = getHeaders();
        config.headers = ObjectIns.merge(config.headers, headers);
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
)

// Response interceptors
service.interceptors.response.use(
    (response) => {
        GFunc.log('API request url: ', response.config.url, ', response: ', response);
        // You can change this part for your own usage.
        const respDatas = decodeRespData(response.data);
        if (respDatas.status !== 0) {
            // TODO ...
            let ignoreFail = false;
            // const reqDatas = DataIns.checktable(JSON.parse(response?.config?.data));
            const reqDatasReal = removeAllQuotesFromString(response?.config?.data);
            const reqDatas = DataIns.checktable(reqDatasReal);
            if (reqDatas) {
                ignoreFail = DataIns.checkbool(reqDatas.__ignoreFail__);
                respDatas.__force__ = DataIns.checkbool(reqDatas.__force__);
                respDatas.__isAuth__ = DataIns.checkbool(reqDatas.__isAuth__);
                respDatas.__isRefresh__ = DataIns.checkbool(reqDatas.__isRefresh__);
                respDatas.__isGameCollect__ = DataIns.checkbool(reqDatas.__isGameCollect__);
                respDatas.__isGamePlayRecord__ = DataIns.checkbool(reqDatas.__isGamePlayRecord__);
                respDatas.__userSerMsg__ = DataIns.checkbool(reqDatas.__userSerMsg__);
            }
            !ignoreFail && onRequestFail(respDatas);
            // return Promise.reject(new Error(respDatas.msg || 'Error'));
            return Promise.reject(respDatas);
        } else {
            return DataIns.checktable(respDatas.data);
        }
    },
    (error) => {
        GFunc.log('API request url: ', error?.config?.url, ', error: ', error);
        const reqDatas = DataIns.checktable(JSON.parse(error?.config?.data));
        if (reqDatas && reqDatas.__errorCall__) {
            let errorCall = Function('"use strict"; return (' + reqDatas.__errorCall__ + ')')();
            errorCall.call();
            return;
        }
        // if (
        //   error.response.data.status === "Unauthenticated" ||
        //   error.response.data.message === "Unauthenticated."
        // ) {
        //   eventBus.emit("show-modal", true);
        //   return;
        // } else if (
        //   error.response.data.message === "Too Many Requests" ||
        //   error.response.data.message === "muitos pedidos"
        // ) {
        //   store.commit("setCurrentView", "casino");
        //   window.location.reload();
        // }
        eventBus.emit("message", {
            type: "error",
            message: error?.response?.data || error?.message,
        });
        return Promise.reject(error);
    }
)

/**
 * 请求失败统一处理
 * @param {*} respDatas
 * @param {*} force     是否静默模式，静默模式下由请求接口自己处理是否弹窗等
 */
const onRequestFail = (respDatas) => {
    let status = respDatas.status;
    let force = DataIns.checkbool(respDatas.__force__);
    let isAuth = DataIns.checkbool(respDatas.__isAuth__);
    let isRefresh = DataIns.checkbool(respDatas.__isRefresh__);
    let isGameCollect = DataIns.checkbool(respDatas.__isGameCollect__);
    let isGamePlayRecord = DataIns.checkbool(respDatas.__isGamePlayRecord__);
    let userSerMsg = DataIns.checkbool(respDatas.__userSerMsg__);
    let tid = getErrTid(status) || respDatas.msg;
    switch (status) {
        case 401:
            // token 失效
            if (isRefresh && force) {
                // 大厅启动模式下的静默Tokend校验，由请求者自行处理
            }
            else if ((isGameCollect || isGamePlayRecord) && force) {
                // 大厅启动模式下，获取游戏收藏或游戏记录，只抛出超时事件，不返回登录界面
                // 解决：1.上次登录后，未退出，再次重新启动时，退回登录界面的问题
                // 2.有带浮层的UI时，１的情况下，进入登录UI，浮层显示异常问题
                GlobalCtrl.cleanUserData();
            }
            else if (!isAuth) {
                GlobalCtrl.cleanUserData();
                // TODO 待处理
                // GlobalCtrl.getInstance().gotoUILogin();
                store.commit('setCurrentView', 'login-register');
            }
            else if (!force) {
                eventBus.emit("message", {
                    type: "error",
                    message: tid,
                });
            }
            break;
        case 200028:
            let freezeTime = DataIns.checktable(DataIns.checktable(respDatas).freezeTime);
            let begin = DataIns.checkstring(freezeTime.begin);
            let end = DataIns.checkstring(freezeTime.end);
            let time = begin && end ? `${begin}-${end}` : CfgAnalyze.getWithdrawTime();
            eventBus.emit("message", {
                type: "error",
                message: `${tid}`,
                args: {value1: `${time}`},
            });
            break;
        default:
            if (!force) {
                let msg = userSerMsg ? respDatas.msg : tid;
                eventBus.emit("message", {
                    type: "error",
                    message: msg,
                });
            }
            break;
    }
}

// 获取定制错误码
const getErrTid = (code) => {
    let errCode = {
        // "0"     : "TID_API_SUCCESS",                            // 成功
        // "401"   : "TID_API_ERROR_AUTH_401",                     // 用户未授权，token验证失败
        // "402"   : "TID_API_ERROR_AUTH_402",                     // 用户未授权，token验证失败
        // "100000": "TID_API_ERROR_UNKNOWN",                      // 未知错误
        // "100001": "TID_API_ERROR_SYSTEM",                       // 系统错误
        // "100002": "TID_API_ERROR_SERVER",                       // 服务器禁用
        "100005": "TID_API_ERROR_INVALID_PARAMETERS",           // 参数错误，可能是类型错误或者超出范围
        // "100006": "TID_API_ERROR_SYSTEM_BUSY",                  // 系统繁忙
        // "100007": "TID_API_ERROR_TIMEOUT",                      // 连接超时
        "100009": "TID_API_ERROR_BAD_MSG",                      // 数据错误，一般性的错误都用这个错误码
        // "100010": "TID_API_ERROR_REMOTE_SERVER",                // 跟远程服务通信成功，但远程服务执行逻辑失败
        "200001": "TID_API_ERROR_USERNAME_OR_PWD_ERR",          // 账号密码错误
        "200002": "TID_API_ERROR_TOKEN_TIMEOUT",                // token已过期，续token时，token过期才会返回
        "200003": "TID_API_ERROR_REG_PWD_UNLIKE",               // 两次密码不同
        "200004": "TID_API_ERROR_WITHDRAW_LOWER_IN_VIP",        // 提现小于最小限制提现金额
        "200005": "TID_API_ERROR_WITHDRAW_FAIL",                // 提现失败
        "200006": "TID_API_ERROR_USERNAME_ABNORMAL",            // 账号被封停
        "200007": "TID_API_ERROR_USERNAME_EXISTED",             // 账号已经存在
        "200008": "TID_API_ERROR_PHONE_EXISTED",                // 电话号码已经注册
        "200009": "TID_API_ERROR_INVITE_CODE",                  // 邀请码错误
        "200010": "TID_API_ERROR_VERIFY_CODE",                  // 验证码错误
        "200011": "TID_API_ERROR_INVITE_CODE_NOT_EXIST",        // 邀请码不存在
        "200012": "TID_API_ERROR_PHONE_NOT_LINK",               // 手机号码未被关联
        "200013": "TID_API_ERROR_OLD_PWD",                      // 旧密码错误
        "200014": "TID_API_ERROR_NOT_ENOUGH_LEVEL",             // 未达到该等级
        "200015": "TID_API_ERROR_NOT_AWARD_LEVEL",              // 该等级没有奖励
        "200016": "TID_API_ERROR_HAD_GET_AWARD_LEVEL",          // 该奖励已经领取
        "200017": "TID_API_ERROR_WITHDRAW_LIMIT",               // 提现次数超过上限
        "200018": "TID_API_ERROR_WITHDRAW_AMOUNT_INSUFFICIENT", // 提现金额不足
        "200019": "TID_API_ERROR_BET_INSUFFICIENT",             // Bet流水不足
        "200020": "TID_API_ERROR_OP_TOO_MANY",                  // 操作太频繁
        "200021": "TID_API_ERROR_GAME_NOT_OPEN",                // 游戏暂未开放
        "200022": "TID_API_ERROR_RECHARGE_CNT_NOT_ENOUGH",      // 领取邀请宝箱时，充值人数不足
        "200023": "TID_API_ERROR_WITHDRAW_PROHIBIT",            // 提现被限制
        "200024": "TID_API_ERROR_INVITE_CODE_SHUTDOWN",         // 邀请账号被冻结，邀请码不可用
        "200025": "TID_API_ERROR_WITHDRAW_PWD_HAD_SETTING",     // 已经设置过提款密码。
        "200026": "TID_API_ERROR_WITHDRAW_PWD_ERROR",           // 提款密码错误。
        "200027": "TID_API_ERROR_CPF_USED",                     // 提现绑定CPF已被使用
        "200028": "TID_API_ERROR_WITHDRAW_NOT_ALLOWED",         // 该时段不允许提现
        "200030": "TID_API_ERROR_RED_PACKET_GAME_OVER",         // 红包活动已结束
        "200031": "TID_API_ERROR_RED_PACKET_NOT_ENOUGH",        // 红包已经被抢光了
        "200032": "TID_API_ERROR_RED_PACKET_NOT_RECHARGE",      // 红包活动只有今日有充值过的用户才能参与
        "200033": "TID_API_ERROR_RED_PACKET_GET_AGAIN",         // 每日只能参与一次红包活动
        "200034": "TID_API_ERROR_WITHDRAW_AMOUNT_LIMIT",        // 今日提现金额达到上限
        "200035": "TID_API_ERROR_BONUS_MONEY_NOT_ENOUGH",       // 兑换奖金金额不足
        "200036": "TID_API_ERROR_REWARD_HAS_BEEN_COLLECTED",    // 信封奖励已领取
        "200037": "TID_API_ERROR_REWARD_DOES_NOT_EXIST",        // 信封奖励不存在
        "200038": "TID_API_ERROR_REWARD_CANNOT_BE_CLAIMED",     // 信封奖励不可领取
        "200039": "TID_API_ERROR_PHONE_USED",                   // 提现绑定手机号已被使用
        "200040": "TID_API_ERROR_CNPJ_USED",                    // 提现绑定CNPJ已被使用
        "200041": "TID_API_ERROR_EMAIL_USED",                   // 提现绑定Email已被使用
        "200042": "TID_API_ERROR_DEPOSIT_MATCH",                // 不是首充活动中的金额
        "200043": "TID_API_ERROR_BET_INSUFFICIENT2",            // 打码量不足
        "200044": "TID_API_ERROR_NOT_AWARD_YET",                // 暂无可领取奖励
        "200045": "TID_API_ERROR_GET_AWARD_TIME",               // 该时间不是领取奖励时间
        "200046": "TID_API_ERROR_REWARD_HAS_BEEN_CLAIMED1",     // 本次已经领取过该奖励
        "200047": "TID_APO_ERROR_BET_BEFORE_REWARD",            // 需要投注一次后才能领取
        "200049": "TID_API_ERROR_CNPJ_USED",                    // CNPJ已被使用
        "200050": "TID_API_ERROR_CHECKIN_NOT_CONDITION",        // 签到条件未满足
        "300042": "TID_API_ERROR_ACTIVITY_CLOSED",              // 活动没开启
        "300043": "TID_API_ERROR_ACTIVITY_JOINED",              // 已经参与过该活动
        "300044": "TID_API_ERROR_UN_AWARD_YET",                 // 未达到可领金额
        "300045": "TID_API_ERROR_NOT_TIMES",                    // 次数已经用完
        "300046": "TID_API_ERROR_NOT_AWARD_YET2",               // 暂无可领取奖励
        "300047": "TID_API_ERROR_HAD_GET_AWARD",                // 本次已经领取过该奖励
        "999999": "TID_API_ERROR",                              // 请求失败统一提示
    }
    let tid = errCode[DataIns.checkstring(code)];
    if (!tid && CfgAnalyze.getI18nLanguage() != "EN") {
        tid = `${errCode["999999"]},${code}`;
    }
    return tid;
}
