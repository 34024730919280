<template>
  <section class="bind-withdraw-section">
    <div class="bind-withdraw-inner">
      <div class="back-section">
        <img
          src="@/assets/common/back.png"
          alt="back"
          style="width: 35px; height: 35px; cursor: pointer"
          @click="onBack"
        />

        <span class="title">{{ t("TID_WALLET_WITHDRAW_ACCOUNT") }}</span>

        <img
          src="@/assets/common/back.png"
          alt="back"
          style="width: 35px; height: 35px; cursor: pointer; opacity: 0"
        />
      </div>

      <a-form
        class="login-form"
        layout="vertical"
        :form="form"
        @submit="onSubmit"
      >
        <a-form-item :label="t('TID_LB_NAME')" name="name">
          <a-input v-model:value="name" :placeholder="t('TID_LB_NAME_HINT')">
          </a-input>
        </a-form-item>
        <a-form-item :label="t('TID_INPUT_PHONE')" name="phone">
          <a-input
            v-model:value="phoneNumber"
            @input="handlePhoneNumberInput"
            :placeholder="t('TID_INPUT_PHONE_NUMBER_HINT')"
          >
            <template #prefix>
              <div class="input-prefix">
                <img
                  src="@/assets/common/brazil.png"
                  alt="br"
                  style="
                    width: 25px;
                    height: 25px;
                    border-radius: 80%;
                    object-fit: cover;
                  "
                />
                <span>+55</span>
              </div>
            </template>
          </a-input>
        </a-form-item>

        <a-form-item :label="t('TID_SELECT_YOUR_PIX_KEY_TYPE')" name="pixType">
          <a-select
            v-model:value="pixType"
            @change="onPixTypeChange"
            :placeholder="t('TID_LB_NAME_HINT')"
          >
            <a-select-option
              v-for="(option, key) in withdrawInfo?.pix_types"
              :value="option?.id"
              :key="key"
            >
              {{ option?.name }}
            </a-select-option>
            <!-- <a-select-option value="cpf">CPF</a-select-option>
            <a-select-option value="phone">Phone</a-select-option>

            <a-select-option value="email">Email</a-select-option> -->
          </a-select>
        </a-form-item>

        <a-form-item name="pixKey" v-if="pixType === 1">
          <a-input v-model:value="pixKey" :placeholder="t('TID_LB_ENTER_VALUE')">
          </a-input>
        </a-form-item>

        <a-form-item name="pixKey" v-if="pixType === 4">
          <a-input
            v-model:value="pixKey"
            @input="handlePixPhoneInput"
            :placeholder="t('TID_INPUT_PHONE_NUMBER_HINT')"
          >
            <template #prefix>
              <div class="input-prefix">
                <img
                  src="@/assets/common/brazil.png"
                  alt="br"
                  style="
                    width: 25px;
                    height: 25px;
                    border-radius: 80%;
                    object-fit: cover;
                  "
                />
                <span>+55</span>
              </div>
            </template>
          </a-input>
        </a-form-item>

        <a-form-item name="pixKey" v-if="pixType === 5">
          <a-input
            v-model:value="pixKey"
            @input="handlePixEmailInput"
            :placeholder="t('TID_LB_ENTER_VALUE')"
          >
          </a-input>
        </a-form-item>

        <a-form-item name="tncChecked">
          <a-checkbox
            v-model="tncChecked"
            @change="tncChecked = !tncChecked"
            :autofocus="false"
          >
            <span style="color: #fff">
              {{ t("TID_LB_BIND_CONFIRMATION") }}
            </span>
          </a-checkbox>
        </a-form-item>
      </a-form>

      <div class="bind-now-btn" @click="onSubmit">
        {{ t("TID_LB_BIND_NOW") }}
      </div>
    </div>
  </section>

  <a-modal
    :open="showConfirmModal"
    centered
    :closable="false"
    :maskClosable="true"
    :okText="t('TID_LB_LOGOUT')"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        display: 'none',
      },
    }"
  >
    <div class="bind-withdraw-content">
      <span class="title">{{ t("TID_LB_BIND_DIALOG_TITLE") }}</span>
      <span class="content">{{ t("TID_LB_BIND_DIALOG_DESCRIPTION") }}</span>

      <div class="yes-cancel-btns">
        <div class="btn" @click="showConfirmModal = false">
          {{ t("TID_LB_CANCEL") }}
        </div>

        <div class="btn yes" @click="bindWithdraw">
          {{ t("TID_LB_YES") }}
        </div>
      </div>
    </div>
  </a-modal>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, reactive, toRefs, defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
import Loading from "@/components/Loading";
import { reqApi } from "@/api";
import { useStore } from "vuex";
import { GFunc } from "@/lib/GFunc";

const props = defineProps({
  withdrawInfo: Object,
});
const emit = defineEmits(["onBack"]);

const store = useStore();

const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const formData = reactive({
  phoneNumber: "",
  pixType: props.withdrawInfo?.pix_types[0]?.id,
  pixKey: "",
  name: "",
  tncChecked: false,
});

const { phoneNumber, pixType, pixKey, name, tncChecked } = toRefs(formData);

const showConfirmModal = ref(false);
const requestLoading = ref(false);

const onBack = () => {
  emit("onBack");
};

const onSubmit = () => {
  if (
    phoneNumber.value === "" ||
    pixKey.value === "" ||
    name === "" ||
    !tncChecked.value
  ) {
    message.error({
      content: t("TID_WALLET_WITHDRAW_INFO_INCOMPLETE"),
      duration: 2,
    });
    return;
  }

  if (
    phoneNumber.value.toString().length < 9 ||
    phoneNumber.value.toString().length > 11
  ) {
    message.error(t("TID_LB_INVALID_PHONE_NUMBER"));
    return;
  }

  if (pixType.value === 4) {
    if (
      pixKey.value.toString().length < 9 ||
      pixKey.value.toString().length > 11
    ) {
      message.error(t("TID_LB_INVALID_PHONE_NUMBER"));
      return;
    }
  }

  if (pixType.value === 5) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!regex.test(pixKey.value)) {
      message.error(t("TID_LB_INVALID_EMAIL"));
      return;
    }
  }

  showConfirmModal.value = true;
};

const bindWithdraw = async () => {
  showConfirmModal.value = false;

  try {
    requestLoading.value = true;
    const res = await reqApi.bindWithdrawWallet({
      language: locale.value,
      version: "3.6.2",
      withdraw_name: name.value,
      withdraw_phone: "55" + phoneNumber.value.toString(),
      withdraw_pix_key:
        pixType.value === 4 ? "55" + pixKey.value.toString() : pixKey.value,
      withdraw_pix_type: pixType.value,
    });
    if (res) {
      message.success(t("TID_LB_SUCCESS"));
      store.commit("setUserInfo", res);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const handlePhoneNumberInput = (e) => {
  e.target.value = e.target.value.replace(/[^0-9+()]/g, "");
  phoneNumber.value = e.target.value;
};

const handlePixPhoneInput = (e) => {
  e.target.value = e.target.value.replace(/[^0-9+()]/g, "");
  pixKey.value = e.target.value;
};

const handlePixEmailInput = (e) => {
  // e.target.value = e.target.value.replace(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, "");
  pixKey.value = e.target.value;
};

const onPixTypeChange = (e) => {
  pixType.value = e;
  pixKey.value = "";
};

GFunc.log(locale);
</script>

<style lang="scss">
.bind-withdraw-section {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  @include flex(column, flex-start, center);
  height: 100vh;
  .bind-withdraw-inner {
    @include flex(column, flex-start, center);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    .back-section {
      width: 100%;
      box-sizing: border-box;
      @include flex(row, space-between, center);
      .title {
        font-weight: 700;
        font-size: 1.1rem;
      }
      @media (max-width: 600px) {
        margin-bottom: 1rem;
      }
    }
    input::placeholder {
      font-size: 0.9rem;
      font-weight: 700;
    }
    .bind-now-btn {
      background-color: #ffeb02;
      box-sizing: border-box;
      color: #000;
      border: 2px solid #fe8800;
      font-weight: 700;
      width: 100%;
      max-width: 430px;
      text-align: center;
      padding: 0.7rem 0;
      border-radius: 20px;
      bottom: 0;
      position: absolute;
    }
  }
}

.bind-withdraw-content {
  @include flex(column, center, flex-start);
  gap: 0.5rem;
  .title {
    font-weight: 700;
    color: #fe8800;
  }
  .content {
    text-align: left;
  }
  .yes-cancel-btns {
    width: 100%;
    @include flex(row, flex-end, center);
    gap: 2rem;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    .btn {
      font-size: 0.9rem;
      font-weight: 700;
      cursor: pointer;
    }
    .yes {
      color: #ffeb02;
    }
  }
}
</style>
