<!-- 页头组件 -->
<template>
  <!-- 左侧抽屉 -->
  <a-drawer width="33.4375rem" placement="left" :open="props.open" @close="onMenuClose" class="menu-drawer"
    :autofocus="false" :headerStyle="{
      borderBottom: 'none',
      backgroundColor: '#252525',
      color: '#fff',
      padding: '0'
    }" :bodyStyle="{
  backgroundColor: '#252525',
  padding: '0',
  width: '100%',
}" :footerStyle="{
  backgroundColor: '#252525',
  padding: '0 1rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',
}">
    <template #closeIcon>
      <img src="@/assets/common/close.png" alt="" style="height: 4.5rem" />
    </template>
    <template #title>
      <img src="@/assets/common/app_icon.png" alt="" style="height: 2.2rem;vertical-align: middle" />
    </template>

    <div class="banner-img-section">
      <img src="@/assets/landing/banner4.jpg" alt="affiliate" @click="goTo('cashback_25_page')" />
      <img src="@/assets/landing/banner1.jpg" alt="receive" @click="goTo('receive_50_for_free_page')" />
      <!-- <img
        src="@/assets/landing/drawer_cashback.png"
        alt="cashback"
        @click="goTo('cashback_25_page')"
      />
      <img
        src="@/assets/landing/drawer_7_days.png"
        alt="7days"
        @click="goTo('7_days_check_in_bonus_page')"
      /> -->
    </div>

    <div class="tab-section">
      <div class="tab-item" @click="goTo('promotion')">
        <img src="@/assets/nav-bar/promotion.png" alt="" />
        <span>{{ t("TID_LB_PROMOTE") }}</span>
      </div>
      <div class="tab-item" @click="goTo('transaction-history')">
        <img src="@/assets/nav-bar/transaction_history.png" alt="" />
        <span>{{ t("TID_LB_TRANSACTION_HISTORY") }}</span>
      </div>
      <div class="tab-item" @click="goTo('casino-bet-history')">
        <img src="@/assets/nav-bar/game_bet_history.png" alt="" />
        <span>{{ t("TID_LB_MY_GAME_BETS") }}</span>
      </div>
      <!-- <a-collapse v-model:activeKey="activeKey">
        <a-collapse-panel key="game_bet_history">
          <template #header>
            <img
                src="@/assets/nav-bar/game_bet_history.png"
                alt=""
                style="width: 2.8125rem"
            />
            <span class="menu-collapse-title">
              {{ t("TID_LB_GAME_BET_HISTORY") }}
            </span>
          </template>

          <div class="menu-collapse-content">
            <div class="menu-collapse-item" @click="goTo('casino-bet-history')">
              <div class="left">
                <img
                    src="@/assets/nav-bar/casino.png"
                    alt="casino"
                    class="menu-collapse-item-icon"
                />
                <span class="menu-collapse-item-text">
                  {{ t("TID_LB_CASINO_BET_HISTORY") }}
                </span>
              </div>
            </div>
            <div
                class="menu-collapse-item"
                @click="goTo('esports-bet-history')"
            >
              <div class="left">
                <img
                    src="@/assets/nav-bar/esport.png"
                    alt="casino"
                    class="menu-collapse-item-icon"
                />
                <span class="menu-collapse-item-text">
                  {{ t("TID_LB_ESPORT_BET_HISTORY") }}
                </span>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse> -->

      <!-- <div class="tab-item" @click="goTo('rebate')">
        <img
            src="@/assets/nav-bar/rebate.png"
            alt=""
        />
        <span>{{ t("TID_LB_REBATE") }}</span>
      </div> -->

      <!-- 设置 -->
      <a-collapse v-model:activeKey="activeKey">
        <a-collapse-panel key="settings">
          <template #header>
            <img src="@/assets/nav-bar/settings.png" alt="" style="width: 2.8125rem" />
            <span class="menu-collapse-title">
              {{ t("TID_LB_SETTINGS") }}
            </span>
          </template>

          <div class="menu-collapse-content">
            <!--            <div-->
            <!--                class="menu-collapse-item"-->
            <!--                @click="goTo('change-password')"-->
            <!--                v-if="token !== ''"-->
            <!--            >-->
            <!--              <div class="left">-->
            <!--                <img-->
            <!--                    src="@/assets/nav-bar/change_password.png"-->
            <!--                    alt="change-password"-->
            <!--                    class="menu-collapse-item-icon"-->
            <!--                />-->
            <!--                <span class="menu-collapse-item-text">-->
            <!--                  {{ t("TID_LB_CHANGE_PASSWORD") }}-->
            <!--                </span>-->
            <!--              </div>-->
            <!--            </div>-->

            <!-- 语言 -->
            <div class="menu-collapse-item" @click="switchLocale()">
              <div class="left">
                <img src="@/assets/nav-bar/language.png" alt="casino" class="menu-collapse-item-icon" />
                <span class="menu-collapse-item-text">
                  {{ t("TID_LB_LANGUAGE") }}
                </span>
              </div>

              <div class="right">
                {{ locale.toString().toUpperCase() }}
              </div>
            </div>
            <!-- 音乐 -->
            <div class="menu-collapse-item" @click="switchMusic" v-show="false">
              <div class="left">
                <img :src="isAudioPlaying ? musicOnImage : musicOffImage" alt="music" class="menu-collapse-item-icon" />
                <span class="menu-collapse-item-text">
                  {{ t("TID_LB_MUSIC") }}
                </span>
              </div>
              <div class="right">
                {{ isAudioPlaying ? t("TID_LB_ON").toUpperCase() : t("TID_LB_OFF").toUpperCase() }}
              </div>
            </div>
            <!-- 设置修改密码 -->
            <div class="menu-collapse-item" @click="gotoChangePwd(0)" v-if="token !== ''">
              <div class="left">
                <img src="@/assets/nav-bar/senha_login.png" alt="logout" class="menu-collapse-item-icon" />
                <span class="menu-collapse-item-text">
                  {{ t("TID_LB_MANAGE_PWD_TITLE") }}
                </span>
              </div>
            </div>
            <div class="menu-collapse-item" @click="gotoChangePwd(1)" v-if="token !== ''">
              <div class="left">
                <img src="@/assets/nav-bar/senha_sacar.png" alt="logout" class="menu-collapse-item-icon" />
                <span class="menu-collapse-item-text">
                  {{ t("TID_LB_WITHDRAWAL_PWD_TITLE") }}
                </span>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>

      <div class="tab-item" @click="goTo('about-us')">
        <img src="@/assets/nav-bar/about_us.png" alt="" />
        <span>{{ t("TID_LB_AUBOUT_US") }}</span>
      </div>

      <!-- 联系客服 -->
      <div class="tab-item" @click="isPopoverOpen = !isPopoverOpen">
        <img src="@/assets/nav-bar/customer_support.png" alt="" />
        <span>{{ t("TID_LB_SUPPORT") }}</span>
      </div>
      <a-popover trigger="click" placement="left" @visibleChange="isPopoverOpen = !isPopoverOpen" v-if="false">
        <template #content>
          <div class="download-row" style="border-bottom: 0.0625rem solid #252525" @click="openTelegramLink">
            <img src="@/assets/common/telegram.png" alt="" />
            <div class="download-with">
              <span>{{ t("TID_LB_CHAT_NOW") }}</span>
            </div>
          </div>
          <div class="download-row" @click="copyToClipboard(officialCS)">
            <img src="@/assets/common/copy_link.png" alt="" />
            <div class="download-with">
              <span>{{ t("TID_LB_COPY_LINK") }}</span>
            </div>
          </div>
        </template>
        <div class="tab-item">
          <img src="@/assets/nav-bar/customer_support.png" alt="" style="width: 2.8125rem; height: 2.8125rem" />
          <span>{{ t("TID_LB_SUPPORT") }}</span>
        </div>
      </a-popover>

      <!-- 下载 -->
      <a-popover trigger="click" v-model:open="showPopover" placement="leftTop">
        <template #content>
          <div class="download-row" style="border-bottom: 0.0625rem solid #252525" @click="handleIos">
            <img src="@/assets/landing/ios.png" alt="" />
            <div class="download-with">
              <span>{{ t("TID_LB_APPLICATION") }}</span>
              <span class="platform">
                {{ t("TID_LB_FOR") }}
                <span class="green">iOS</span>
              </span>
            </div>
          </div>
          <div class="download-row" @click="downloadApp()">
            <img src="@/assets/landing/android.png" alt="" />
            <div class="download-with">
              <span>{{ t("TID_LB_APPLICATION") }}</span>
              <span class="platform">
                {{ t("TID_LB_FOR") }}
                <span class="green">Android</span>
              </span>
            </div>
          </div>
        </template>
        <div class="tab-item" v-show="false">
          <img src="@/assets/nav-bar/download.png" alt="" style="width: 2.8125rem; height: 2.8125rem" />
          <span>{{ t("TID_LB_DOWNLOAD_APP") }}</span>
        </div>
      </a-popover>

      <!-- 服务条款 -->
      <div class="tab-item" @click="goTo('tnc')" v-show="false">
        <img src="@/assets/nav-bar/tnc.png" alt="" />
        <span>{{ t("TID_LB_TNC") }}</span>
      </div>
    </div>

    <template #footer>
      <div style="height: max-content;display: flex;flex-direction: column;gap: 0.5rem">
        <span style="font-size: 1.1875rem;font-weight: bold">{{ t("TID_LB_JOIN_OUR_COMMUNITY") }}</span>
        <div class="social-media-logo" style="margin: 0;padding-left: 2rem">
          <img src="@/assets/common/facebook.png" alt="facebook" @click="shareOnFacebook(userInfo.uuid)" />
          <img src="@/assets/common/telegram.png" alt="telegram" @click="shareOnTelegram(userInfo?.uuid)" />
          <img src="@/assets/common/whatsapp.png" alt="whatsapp" @click="shareOnWhatsApp(userInfo?.uuid)" />
        </div>
      </div>

      <div style="display: flex;flex-direction: column;gap: 0.5rem" v-show="false">
        <!-- 访问帮助中心 -->
        <div style="display: flex;flex-direction: row;gap: 0.5rem;vertical-align: middle;">
          <img src="@/assets/nav-bar/icon_visitar.png" alt="" style="width: 2.5rem" />
          <span style="font-size: 1.1875rem">{{ t("TID_VISIT_HELP_CENTER") }}</span>
        </div>

        <!-- 报告问题 -->
        <div style="display: flex;flex-direction: row;gap: 0.5rem;">
          <img src="@/assets/nav-bar/icon_Relatar.png" alt="" style="width: 2.5rem" />
          <span style="font-size: 1.1875rem;vertical-align: middle">{{ t("TID_REPORT_A_PROBLEM") }}</span>
        </div>
      </div>
    </template>

  </a-drawer>

  <OnlineCustomer :open="isPopoverOpen" :close="() => isPopoverOpen = false"/>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import musicOnImage from "@/assets/nav-bar/music_on.png";
import musicOffImage from "@/assets/nav-bar/music_off.png";
import { shareOnFacebook, shareOnTelegram, shareOnWhatsApp } from "@/lib/utils";
import { useStore } from "vuex";
import { computed, defineProps, ref } from "vue";
// import {UserStore} from "@/lib/UserStore";
import OnlineCustomer from "@/components/OnlineCustomer";

const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const props = defineProps({
  open: Boolean,
  close: Function
})

const activeKey = ref(["1"]);
// const requestLoading = ref(false);

const showPopover = ref(false);
const showIosModal = ref(false);
const isPopoverOpen = ref(false);

const store = useStore();
const token = computed(() => store.state.token);
const isAudioPlaying = computed(() => store.state.isAudioPlaying);
// const apiBaseUrl = computed(() => store.state.apiBaseUrl);
const userInfo = computed(() => store.state.userInfo);

const goTo = (page) => {
  if (token.value === "") {
    if ([
      "rebate",
      "transaction-history",
      "casino-bet-history",
      "esports-bet-history",
      "deposit_100",
      "promotion",
      "change_password",
      "cashback_25_page",
      "receive_50_for_free_page"
    ].includes(page)) {
      store.commit("setCurrentView", "login-register");
      return;
    }
  }
  store.commit("setCurrentView", page);
};

const onMenuClose = () => {
  props.close();
}

const switchMusic = () => {
  store.commit("setAudioPlaying", !isAudioPlaying.value);
};

const gotoChangePwd = (type) => {
  store.commit("setChangedPwdType", type);
  store.commit("setCurrentView", 'change-password');
}

const switchLocale = () => {
  if (locale.value === "en") {
    store.commit("setLanguage", "vn");
  } else {
    store.commit("setLanguage", "en");
  }
};

// const logout = async () => {
//   try {
//     props.close();
//     requestLoading.value = true;
//     const res = await netApi.userLogout({});
//
//     if (res) {
//       window.location.reload();
//       store.commit("setToken", "");
//       store.commit("setUserInfo", "");
//       store.commit("clearHistoryStack");
//       store.commit("setDepositOrWithdraw", "deposit");
//       store.commit("setCurrentView", "casino");
//       store.commit("setSpinValue", 0);
//       store.commit("setPrevApiBaseUrl", apiBaseUrl.value);
//       store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());
//     }
//   } catch (e) {
//     console.log(e);
//   } finally {
//     requestLoading.value = false;
//   }
// };

// const openTelegramLink = () => {
//   window.open(officialCS.value, "_blank");
// };

const handleIos = () => {
  console.log("hello");
  showPopover.value = false;
  showIosModal.value = true;
  console.log(showIosModal.value);
};

const downloadApp = () => {
  // Specify the path to your file in the public directory
  const filePath = "/0038.apk"; // Adjust the path if you place it in a subdirectory within public

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = filePath;
  link.download = filePath.split("/").pop(); // This extracts the filename

  // Append to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up by removing the link
  document.body.removeChild(link);
};
</script>

<style lang="scss">
.menu-drawer {
  gap: 1.125rem;

  .banner-img-section {
    width: 100%;
    @include flex(row, space-around, center);
    flex-wrap: wrap;
    background-color: #000;
    gap: 0.625rem;
    padding: 0.9375rem 0;

    img {
      width: calc(100% - 1.875rem);
      border-radius: 0.3125rem;
      cursor: pointer;
    }
  }

  .tab-section {
    @include flex(column, center, center);
    width: 100%;
    margin: 1rem 0;
    gap: 0.3rem;
    background-color: #000;

    .tab-item {
      font-family: "Poppins", sans-serif !important;
      @include flex(row, flex-start, center);
      font-size: 1.4375rem;
      box-sizing: border-box;
      cursor: pointer;
      border-bottom: 0.0625rem solid #4e4e4e;
      height: 4.75rem;
      padding: 0;
      margin: 0;

      img {
        width: 2.8125rem;
      }
    }

    .ant-collapse {
      &-header {
        padding: 0 !important;
        margin: 0;

        &-text {
          height: 4.75rem;
          gap: .5rem;
        }
      }

      &-expand-icon {
        height: 4.75rem !important;
      }
    }

    .menu-collapse {
      &-title {
        color: #fff;
        font-size: 1.4375rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        padding: 0;
        margin: 0;
      }

      &-content {
        @include flex(column, center, flex-start);
        gap: 1rem;
        padding: 0 1rem;

        .menu-collapse-item {
          @include flex(row, space-between, stretch);
          width: 100%;
          gap: 0.5rem;
          cursor: pointer;

          .left {
            @include flex(row, center, center);
            gap: 0.5rem;
          }

          .right {
            @include flex(row, center, center);
            color: #e71e62;
            width: max-content;
            font-weight: bold;
          }

          border-bottom: 0.0625rem solid #656666;
        }
      }

      &-item {
        &-icon {
          width: 2.8125rem;
        }

        &-text {
          font-size: 1.4375rem;
        }
      }
    }
  }
}
</style>