<template>
  <section class="fav-game-container">
    <PageHeader title="TID_LB_FAVOURITE_GAME" :back="onBack" />

    <div class="fav-game-inner">
      <div class="game-list-container-content">
        <div class="game-list-container-content-layout">
          <GameItem class="game-list-container-content-item" v-for="(game, key) of favouriteGameList" :key="key"
            :game="game" />
        </div>
      </div>
      <section class="no-results-section" v-if="favouriteGameList.length <= 0 && !requestLoading">
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Loading from "@/components/Loading";
import PageHeader from "@/components/PageHeader";
import { GameCtrl } from "@/ctrl/GameCtrl";
import GameItem from "@/components/views/Game/Item";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const requestLoading = ref(false);

const favouriteGameList = computed(() => GameCtrl.collectsList.value);

// const dataList = ref([]);
const onBack = () => {
  store.commit("goBack");
};
</script>

<style lang="scss">
.fav-game-container {
  @include flex(column, center, center);

  .fav-game-inner {
    width: 100%;
    box-sizing: border-box;
    @include flex(column, center, center);
    padding: 1rem;
    max-width: 600px;

    .game-list-section {
      margin: 1rem 0;
      @include flex(row, flex-start, center);
      width: 100%;
      box-sizing: border-box;
      flex-wrap: wrap;
      gap: 0.5rem;

      .game-item {
        flex: 0 0 calc((100% - 2 * 0.5rem) / 3); // Width for three items per row with gaps
        border-radius: 10px;
        border: 1px solid #463f04;
        height: 180px;
        box-sizing: border-box;
        z-index: 5;
        @include flex(row, center, center);
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .circle {
          width: 50px;
          height: 50px;
          background-color: #000;
          opacity: 0.8;
          border-radius: 50%;
          @include flex(row, center, center);
          position: absolute;
          top: -20px;
          right: -20px;
          cursor: pointer;
          z-index: 100;
        }

        .fav-icon {
          width: 13px;
          height: 13px;
          position: absolute;
          top: 6px;
          right: 6px;
          cursor: pointer;
          z-index: 100;
        }

        .background {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .game-list-container {
      @include flex(row, center, center);
      padding: 1rem;
      gap: 1rem;
      background-color: black;
      box-sizing: border-box;
      height: 100%;

      &-platform {
        @include flex(column, flex-start, center);
        overflow-x: hidden;
        overflow-y: scroll;
        height: calc(100vh - 11.5rem);
        gap: .5rem;

        &-item {
          flex: 0 0 auto;
          background-color: #545454;
          width: 7rem;
          height: 4.8125rem;
          border-radius: .5rem;
          box-sizing: border-box;

          @include flex(column, center, center);

          &-selected {
            background-color: #e71e62;
          }

          img {
            height: 2.8333333333rem;
          }
        }
      }

      &-content {
        width: 100%;
        height: calc(100vh - 6.375rem);
        overflow-x: hidden;
        overflow-y: scroll;
        flex-grow: 1;

        &-layout {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
        }

        &-item {
          width: calc((100% - 2rem) / 3);
        }
      }
    }
  }
}
</style>
