<template>
  <div class="home-wrapper" v-show="!requestLoading">
    <div class="home-inner">
      <!-- 顶部Banner信息部分 -->
      <section class="img-section">
        <a-carousel :draggable="true" autoplay :autoplaySpeed="3000" v-show="!requestLoading">
          <div v-for="(i, key) in banner" :key="key" @click="bannerJump(i, $event)"
            @mousedown="handleDragStart" @mouseup="handleDragEnd" 
            @dragstart="handleDragStart" @dragend="handleDragEnd"
          >
            <img :src="i.iconurl" alt="banner" />
          </div>
        </a-carousel>

        <!-- 活动小图 -->
        <div class="static-img">
          <img src="@/assets/landing/small_1.jpg" alt="cashback" @click="goTo('deposit-withdraw')" />
          <img src="@/assets/landing/small_2.jpg" alt="cashback" @click="goTo('receive_50_for_free_page')" />
          <img src="@/assets/landing/small_3.jpg" alt="cashback" @click="goTo('main_affiliate_page')" />
        </div>
      </section>

      <!-- 跑马灯 -->
      <section class="announcement-section">
        <img src="@/assets/landing/speaker.png" v-if="orientation === 'horizontal'" />
        <img src="@/assets/landing/speaker.png" v-else />
        <Vue3Marquee :duration="12" v-if="orientation === 'horizontal'" style="height: 2.8125rem;">
          <div class="text text--horizontal" v-for="(msg, index) in annoucementMessages" :key="index">
            {{ msg }}
          </div>
        </Vue3Marquee>

        <a-carousel autoplay vertical :autoplaySpeed="1800" :verticalSwiping="true" :dots="false" style="height: 2.8125rem;" v-else>
          <div class="text text--vertical" v-for="(msg, index) in annoucementMessages" :key="index">
            {{ msg }}
          </div>
        </a-carousel>
      </section>

      <section class="search-section">
        <div class="search-bar" @click="searchGame">
          <SearchOutlined style="font-size: 2rem" />
          <span>{{ t("TID_LB_SEARCH_HINT") }}</span>
        </div>

        <div class="favourite" @click="goTo('favourite-games')">
          <img src="@/assets/landing/icon_favourite.png" alt="favourite" />
        </div>
      </section>

      <div class="games-section" v-show="!requestLoading">
        <section class="category-section">
          <div class="category-item" v-for="(gametype, key) in categories" :key="key"
            @click="searchGameWithCategory(gametype)">
            <div class="category-item-hot" v-if="GameCtrl.checkNewGameType(gametype)">
              <img src="@/assets/common/hot.png" />
            </div>
            <span>
              {{ t(`${GameCtrl.getGameTypeName(gametype)}`) }}
            </span>
          </div>
        </section>

        <!-- <GameContainer v-for="(game, key) in categories" :key="key" :info="game" /> -->
        <GameContainer type="hot" />
        <GameContainer type="pgslot" />
        <GameContainer type="jili" />
        <GameContainer type="new" />
        <!-- <GameContainer type="live" />
        <GameContainer type="egame" /> -->

        <div>
          <img src="@/assets/common/hezuo.png" alt="" :style="{
            width: '37.5rem'
          }">
          <div :style="{
            width: 0,
            height: 0,
            position: 'relative',
            float: 'inline-start',
          }">
            <div :style="{
              width: '37.5rem',
              color: '#727576',
              fontSize: '1.875rem',
              textAlign: 'center',
            }">
              <span>{{ t('TID_GAME_PROVIDER') }}</span>
            </div>
          </div>
        </div>

        <GameContainer type="all" />
      </div>

      <div>
        <a-row style="text-align: center;justify-content: center; margin: 0.5rem 0;">
          <!-- 关于我们 -->
          <a-col :span="7">
            <div style="text-decoration: underline; font-size: 1.4375rem;"  @click="openTelegramLink">{{ t('TID_ABOUT_US') }}</div>
          </a-col>
          <a-col>
            <div style="font-size: 1.4375rem;">●</div>
          </a-col>
          <!-- 联系我们 -->
          <a-col :span="7">
            <div style="text-decoration: underline; font-size: 1.4375rem;"  @click="openTelegramLink">{{ t('TID_CONTACT_US') }}</div>
          </a-col>
          <a-col>
            <div style="font-size: 1.4375rem;">●</div>
          </a-col>
          <!-- 帮助 -->
          <a-col :span="7">
            <div style="text-decoration: underline; font-size: 1.4375rem;"  @click="openTelegramLink">{{ t('TID_HOW_TO_PLAY') }}</div>
          </a-col>
        </a-row>
        <div style="text-align: center; justify-content: center; padding-top: .625rem;">
          <img src="@/assets/common/icon_downkefu1.png" style="height: 5rem;padding-bottom: 0;padding-right: 1.4rem;"  @click="openTelegramLink"/>
          <img src="@/assets/common/icon_downkefu2.png" style="height: 5rem;padding-bottom: 0;padding-left: 1.4rem;"  @click="openTelegramLink"/>
          <div style="font-size: 1.3rem;" @click="openTelegramLink">{{ t('TID_CAN_I_HELP')}}</div>
        </div>
        <div style="text-align: center;justify-content: center;color: #8c8c8c;">
          <br />
          <!-- 反洗钱政策 -->
          <span style="padding-right: .625rem; font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC1') }}</span>
          <!-- 条款和条件 -->
          <span style="padding-left: .625rem; font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC2') }}</span><br />
          <!-- KYC 政策 -->
          <span style="padding-right: .625rem; font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC3') }}</span>
          <!-- 自我排除政策 -->
          <span style="padding-left: .625rem; font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC4') }}</span><br />
          <!-- 未成年人游戏政策（18+） -->
          <span style="font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC5') }}</span><br />
          <!-- 负责任的游戏政策 -->
          <span style="padding-right: .625rem; font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC6') }}</span>
          <!-- 运动规则 -->
          <span style="padding-left: .625rem; font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC7') }}</span><br />
        </div>
        <div style="text-align: center; justify-content: center; color: #8c8c8c;">
          <br />
          <span style="font-size: 1.3rem;">{{ t('TID_DISCLAIMERS_DEC8') }}</span><br />
          <hr style="border: 0.1rem solid #8c8c8c; width: 90%;" />
        </div>
        <div style="text-align: center; justify-content: center; margin: 1rem 1rem; color: #8c8c8c; line-height: 1.2rem;">
          <span style="font-size: 1.0rem;">{{ t('TID_DISCLAIMERS_DEC9') }}</span>
          <span style="font-size: 1.1rem; color: #fff;">{{ 'GLH-OCCHKTW0705152022' }}</span>
        </div>
        <div style="font-size: 0.8rem; padding: 0 1rem; color: #8c8c8c">
          <span style="font-size: 1.0rem;">{{ t('TID_DISCLAIMERS_DEC10') }}</span><br />
          <span style="font-size: 1.0rem;">{{ t('TID_DISCLAIMERS_DEC11') }}</span><br />
          <span style="font-size: 1.0rem;">{{ t('TID_DISCLAIMERS_DEC12') }}</span>
        </div>
      </div>
      <br/>
      <br/>
      <div @click="backTop">
        <div :style="{
          textAlign: 'center',
          width: '3.125rem',
          backgroundColor: '#3c3c3c',
          borderRadius: '.5rem',
          padding: '.5rem',
          margin: 'auto',
        }">
          <p style="margin: 0;color: #e71e62;">▲</p>
          <p style="margin: 0;">TOP</p>
        </div>
      </div>
    </div>

    <div class="floating-icons-section" v-if="!requestLoading">
      <!-- 七日签到 -->
      <img src="@/assets/landing/7_days_floating.gif" class="seven-days-gif" alt="7-days"
        @click="goTo('7_days_check_in_bonus_page')" />

      <!-- 联系客服 -->
      <img src="@/assets/common/support.png" class="support-icon" alt="support"
        @click="onlineCustomerOpen = true" />
      <a-popover trigger="click" placement="left" @visibleChange="handlePopoverToggle" v-if="false">
        <template #content>
          <div class="download-row" style="border-bottom: 0.0625rem solid #252525" @click="openTelegramLink">
            <img src="@/assets/common/telegram.png" />
            <div class="download-with">
              <span>{{ t("TID_LB_CHAT_NOW") }}</span>
            </div>
          </div>
          <div class="download-row" @click="copyToClipboard(officialCS)">
            <img src="@/assets/common/copy_link.png" />
            <div class="download-with">
              <span>{{ t("TID_LB_COPY_LINK") }}</span>
            </div>
          </div>
        </template>
        <img src="@/assets/common/support.png" class="support-icon" alt="support" />
      </a-popover>
    </div>
  </div>

  <!-- 联盟奖励对话框 -->
  <a-modal :open="showUnclaimModal" centered @ok="claimCom" :closable="false" :maskClosable="true"
    :cancelButtonProps="{ style: { display: 'none' } }" okText="Claim Commission" :okButtonProps="{
      style: {
        width: '100%',
        height: '3.125rem',
        fontWeight: 'bold',
        backgroundColor: '#FF8800',
        color: '#fff',
      },
    }">
    <div class="unclaim-com-modal-content">
      <span style="font-size: 1rem; text-decoration: underline; font-weight: bold">
        Affiliate Bonus</span>
      <span>
        You have an Affiliate bonus waiting for you to redeem. Click the button
        below to claim your bonuses now.
      </span>

      <img src="@/assets/common/close.png" alt="close" class="unclaim-com-modal-close"
        @click="showUnclaimModal = false" />
    </div>
  </a-modal>

  <!-- 弹窗公告 -->
  <a-modal :open="showPopupBanner" centered :closable="false" :maskClosable="true"
    :cancelButtonProps="{ style: { display: 'none' } }" :okButtonProps="{
      style: {
        display: 'none',
      },
    }" class="landing-popup-modal" v-if="appInfo?.multi_popup_banner">
    <div class="landing-popup-modal-content">
      <img :src="appInfo?.multi_popup_banner[0]?.image" alt="banner" class="landing-popup-modal-bg"
        style="cursor: pointer" @click="goTo('deposit-withdraw')" />

      <img src="@/assets/common/close.png" alt="close" class="landing-popup-modal-close"
        @click="showPopupBanner = false" />
    </div>
  </a-modal>

  <Loading v-if="requestLoading || fetchingGame" />
  <PopupAd :popupad="popupad" :idx="popupadIdx" :next="() => popupadIdx += 1" v-if="popupadIdx < popupad.length"/>
  <OnlineCustomer :open="onlineCustomerOpen" :close="() => onlineCustomerOpen = false"/>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { netApi } from "@/api";
import { SearchOutlined } from "@ant-design/icons-vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Loading from "@/components/Loading";
import { copyToClipboard } from "@/lib/utils";
import { CfgAnalyze } from "@/lib/CfgAnalyze";
import { GameCtrl } from "@/ctrl/GameCtrl";
import GameContainer from "@/components/views/Game/Container";
import PopupAd from "@/components/PopupAd";
import OnlineCustomer from "@/components/OnlineCustomer";
import { ENoticeType } from "@/lib/Consts";
import { GlobalCtrl } from "@/ctrl/GlobalCtrl";

const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

// 滚动图
const banner = ref([]);
// 弹窗广告
const popupad = ref([]);
const popupadIdx = ref(0);

const store = useStore();

const orientation = ref("horizontal");
const annoucementMessages = ref([]);
const categories = ref([]);

const requestLoading = ref(false);
const fetchingGame = ref(false);

// 在线客服
const onlineCustomerOpen = ref(false);
// 联盟奖励
const showUnclaimModal = ref(false);
// 弹窗公告
const showPopupBanner = ref(false);
const isPopoverOpen = ref(false);

const token = computed(() => store.state.token);

const appInfo = computed(() => store.state.appInfo);

// 频道号
const officialCS = ref(CfgAnalyze.getOfficialCS());

// Banner拖动
// 拖动状态
const isDragging = ref(false);
// 拖动阈值，防止误触发
const dragThreshold = ref(5);
const startX = ref(0);
const startY = ref(0);

// 7 days right icon when scroll need go in go out
const openTelegramLink = () => {
  window.open(officialCS.value, "_blank");
};

const generateAnnoucementMessages = () => {
  const number = Math.floor(Math.random() * 900 + 100) // Generates a number from 100 to 999
    .toString()
    .padStart(3, "0");
  const amount = Math.floor(Math.random() * (1000 - 100) + 100) * 10; // Generates an amount in multiples of 10 from 1000 to 10000
  return `84*******${number} ${" " + t("TID_LB_HAS_WON") + " ₫"} ${amount}`;
};

const toggleOrientation = () => {
  orientation.value = orientation.value === "horizontal" ? "vertical" : "horizontal";
  annoucementMessages.value = []; // Clear existing messages
  for (let i = 0; i < 5; i++) {
    annoucementMessages.value.push(generateAnnoucementMessages());
  }
};

setInterval(toggleOrientation, 8000);

const claimCom = () => {
  showUnclaimModal.value = false;
  goTo("profile");
};

const toggleBodyScroll = (disableScroll) => {
  if (disableScroll) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }
};

const handlePopoverToggle = () => {
  isPopoverOpen.value = !isPopoverOpen.value;
};

const handleDragStart = (event) => {
  isDragging.value = false;
  startX.value = event.clientX;
  startY.value = event.clientY;
}

const handleDragEnd = (event) => {
  // 计算位移
  const deltaX = Math.abs(event.clientX - startX.value);
  const deltaY = Math.abs(event.clientY - startY.value);
  // 如果X或Y轴的位移超过阈值，视为拖动
  if (deltaX > dragThreshold.value || deltaY > dragThreshold.value) {
    isDragging.value = true;
  }
}

watch(isPopoverOpen, (newValue) => {
  toggleBodyScroll(newValue);
});

const searchGame = () => {
  store.commit("setSelectedGameCategory", 0);
  store.commit("setSelectedGamePlatform", 0);

  store.commit("setCurrentView", "game-list");
}

const goTo = (page) => {
  showPopupBanner.value = false;
  if (page === "login-register") {
    if (token.value === "") {
      store.commit("setCurrentView", page);
    }
    return;
  }

  if (["deposit-withdraw", "receive_50_for_free_page", "main_affiliate_page"].includes(page)) {
    if (token.value === "") {
      store.commit("setCurrentView", "login-register");
      return;
    } 
  }

  if (page === "deposit-withdraw") {
    store.commit("setDepositOrWithdraw", "deposit");
  }

  store.commit("setCurrentView", page);
};

const searchGameWithCategory = (categoryId) => {
  store.commit("setSelectedGameCategory", categoryId);

  store.commit("setCurrentView", "game-list");
};

onMounted(async () => {
  for (let i = 0; i < 5; i++) {
    annoucementMessages.value.push(generateAnnoucementMessages());
  }

  try {
    requestLoading.value = true;

    const resp = await netApi.getActivityConf({ type: 1 })
    // 滚动图
    banner.value = resp.result[ENoticeType.ENotice_RollPic].filter(({ status }) => status === 1);
    // 弹出广告
    popupad.value = resp.result[ENoticeType.ENotice_Notice].filter(({ status }) => status === 1);

    // 获取游戏数据
    GameCtrl.reqGameConf(async () => {
      initGameType();
      initGameList();
    })

    // const appRes = await reqApi.getAppSettings({
    //   language: locale.value,
    //   version: "3.6.2",
    // });
    // store.commit("setAppInfo", appRes);
    // showPopupBanner.value = true;

    // if (token.value !== "") {
    //   const downlineRes = await reqApi.getAgentDownlineDashboard({
    //     language: locale.value,
    //     version: "3.6.2",
    //   });
    //   if (downlineRes) {
    //     if (parseFloat(downlineRes.total_unclaim_commission) > 0) {
    //       showUnclaimModal.value = true;
    //     }
    //   }
    // }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
});

// 游戏类型
const initGameType = () => {
  categories.value = GameCtrl.getGameTypeCfgList()
    // .map(platform => {
    //   const games = GameCtrl.getSubGameTypeCfgListFromPlatform(platform)
    //     .map(gametype => [platform, gametype])
    //   return games.length > 0 ? platform : undefined;
    // })
    // .filter(platform => !!platform)
}

const initGameList = () => {

}

const backTop = () => {
  scrollTo(0, 0)
}

// 顶部banner跳转
const bannerJump = (data, event) => {
  // 如果在拖动，阻止 click 事件
  if (isDragging.value) {
    // 阻止click事件的默认行为
    event.preventDefault();
    // 阻止事件冒泡
    event.stopPropagation();
    return;
  }
  GlobalCtrl.jumpToAnyWhere(data);
}
</script>

<style lang="scss" scoped>
.home-wrapper {
  width: 100%;
  @include flex(column, center, center);
  padding-bottom: 8rem;
  position: relative;

  .loading-skeleton {
    @include flex(row, center, center);
  }

  .home-inner {
    // width: 80%;
    max-width: 600px;

    .img-section {
      img {
        border-radius: .625rem;
        cursor: pointer;
      }

      .static-img {
        @include flex(row, space-between, center);
        margin-top: 0.5rem;

        img {
          width: 32.5%;
          height: auto;
        }
      }

      .ant-carousel :deep(.slick-slide) {
        text-align: center;
        line-height: 7.5rem; // Adjust line-height to your carousel height
        background: #000;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      @media (max-width: 600px) {
        padding: 0 0.5rem;
      }
    }

    .announcement-section {
      background-color: #191919;
      padding: 0.5rem 0.5rem;
      margin: 1rem 0;
      @include flex(row, space-between, center);
      gap: .5rem;

      .ant-carousel-vertical {
        @include flex(row, center, center);
      }

      img {
        width: 1.8rem;
        height: 1.8rem;
      }

      .text {
        font-size: 1.625rem;
        font-weight: bold;

        &--horizontal {
          color: #ffffff;
          padding-right: 1.5rem;
        }

        &--vertical {
          color: #ffffff;
        }
      }
    }

    .search-section {
      @include flex(row, center, stretch);
      gap: 1rem;

      .search-bar {
        background-color: #333;
        border-color: #191919;
        border-width: 0.125rem;
        color: #a5a5a4;
        padding: 0 1rem;
        font-size: 1.625rem;
        @include flex(row, flex-start, center);
        border-radius: 2rem;
        gap: 1rem;
        flex-grow: 1;
        height: 3.25rem;
        cursor: pointer;
      }

      .favourite {
        @include flex(row, center, center);

        width: 4.4rem;

        img {
          height: 3.2666666667rem;
        }
      }
    }

    .category-section {
      @include flex(row, flex-start, center);
      gap: 0.5rem; // Increase the gap between items for spacing
      overflow-x: auto; // Allow horizontal scrolling
      overflow-y: hidden;
      scrollbar-width: none; // Firefox
      -ms-overflow-style: none; // IE and Edge
      width: 100%;
      box-sizing: border-box;
      margin-top: .5rem;

      // Hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }

      .category-item {
        @include flex(row, center, center);
        cursor: pointer;
        background-color: #545454;
        color: #fff;
        margin: .5rem;
        font-size: 1.625rem;
        white-space: nowrap;
        height: 4rem;
        padding: 0 3rem;
        border-radius: 2rem;

        &-hot {
          width: 0;
          height: 0;

          img {
            width: 3.5rem;
            position: relative;
            top: -2.5rem;
            left: -3.5rem;
          }
        }
      }
    }

    .games-section {
      @include flex(column, center, center);
      gap: .5rem !important;
    }

    .game-list-section {
      position: relative;
      padding: 0.5rem 0;
      margin-bottom: 0;
      width: 100%;
      box-sizing: border-box;

      @mixin title-section {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
        padding: 0 0.5rem 0.5rem 0.5rem;
        position: relative;
        min-width: 430px;
        height: auto;
        background-image: url("@/assets/landing/game-tab-bg1.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        box-sizing: border-box;

        .popular-icon {
          width: auto;
          height: 2.6875rem;
        }

        .title {
          font-weight: bold;
          font-size: 1rem;
          color: #fff300;
        }

        .all-btn {
          position: absolute;
          right: 2rem;
          @include flex(row, center, center);
          gap: 0.6rem;
          padding: 0.5rem;
          cursor: pointer;
          font-weight: bold;

          img {
            width: auto;
            height: 1.5rem;
          }
        }

        @media (max-width: 600px) {
          min-width: 300px;
        }
      }

      .title-section-1 {
        @include title-section;
      }

      .title-section-2 {
        @include title-section;
        background-image: url("@/assets/landing/game-tab-bg2.png");

        .title {
          color: #e71e62;
        }
      }

      .game-list {
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        background-color: #121212;
        gap: 0.5rem;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem;

        .game-item {
          flex: 0 0 calc((100% - 2 * 0.5rem) / 3);
          max-width: calc((100% - 2 * 0.5rem) / 3);
          cursor: pointer;
          border-radius: .625rem;
          box-sizing: border-box;
          height: auto;
        }
      }
    }

    .provider-list {
      @include flex(row, flex-start, stretch);
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.5rem;
      gap: 0.5rem;
      margin-bottom: 0.5rem;

      .title-section {
        @include flex(row, flex-start, center);
        gap: 0.5rem;
        padding: 0.5rem;
        height: auto;
        width: 100%;
        box-sizing: border-box;

        .popular-icon {
          width: auto;
          height: 1.5625rem;
        }

        .title {
          font-weight: bold;
          font-size: 1.1rem;
        }
      }

      .provider-item {
        flex: 0 0 calc((100% - 2 * 0.5rem) / 3); // Width for three items per row with gaps
        border-radius: .625rem;

        @include flex(row, center, center);

        box-sizing: border-box;
        border-radius: .625rem;
        background-color: #3a3a3a;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    @media (max-width: 600px) {
      width: 100%;
      padding: 0;
    }
  }

  @media (max-width: 600px) {}
}

.floating-icons-section {
  position: fixed;
  right: 0;
  bottom: 8.75rem;
  width: max-content;
  height: max-content;
  @include flex(column, flex-start, flex-end);
  z-index: 1;
  gap: 0.5rem;

  .seven-days-gif {
    height: 6.25rem;
    width: auto;
    cursor: pointer;
  }

  .support-icon {
    width: 3.125rem;
    height: auto;
    margin-right: .625rem;
    cursor: pointer;
  }
}

.unclaim-com-modal-content {
  background-color: #1c1c1c;
  gap: 1rem;
  @include flex(column, center, center);
  padding: 0.8rem;
  border-radius: .5rem;
  position: relative;
}

.unclaim-com-modal-close {
  position: absolute;
  bottom: -9.375rem;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
}
</style>
