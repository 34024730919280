/**
 * 用户数据模块
 */

import { EUserType } from "@/lib/Consts";
import { DataIns } from "@/lib/DataIns";
import { GFunc } from "@/lib/GFunc";
import store from "@/store";
import { computed } from "vue";

// // 钱包数据
// const WalletInfo = {
//     accountName,
//     cardNumber,
//     bankName,
//     branchName,
//     bankCode
// }

// // PIX数据
// const PixInfo = {
//     cpf,        // 个人税号
//     email,      // 邮箱
//     pixname,    // 姓名
//     telefone,   // 电话号码
//     chavepix,   // pix钥匙
// }

// // USDT兑换比例
// const UsdtExchangeCfg = {
//     cashToUsdt,
//     usdtToCash
// }

const userInfo = computed(() => store.state.userInfo);

class _UserCtrl_ {
    isLogin = false;    // 是否已登录
    vipLevel = 0;       // VIP等级
    date = "";          // 日期
    walletInfo = null;  // 钱包银行卡信息
    pixInfo = null;     // PIX信息
    usdtExchangeCfg = null; // Usdt兑换比例
    bankInfo = null;    // 银行卡信息

    differenceTime = 0; // 客户端与服务器时间差
    isAutoOpenAgentConsulting = false;  // 是否自动弹出过代理公告
    isAutoOpenSignin = false;  // 是否自动弹出签到
    isAutoOpenNewbie = false;  // 是否自动弹出新手任务
    rechargeToday = false;  // 今日是否有充值 默认有充值
    completeActivitys = []; // 记录已完成的活动模块 1: 新手任务活动
    isBind = false;     // 是否绑定账号
    thirdLogin = false; // 是否三方登录

    constructor() {
        GFunc.log("UserCtrl constructor")
        // this.initCtrl();
        this.initEvents();
    }

    initCtrl() {
        GFunc.log("UserCtrl initCtrl")
    }

    initEvents() {
    }

    loadLocalData() {
        // 钱包数据
        this.walletInfo = {
            accountName: "",
            cardNumber: "",
            bankName: "",
            branchName: "",
            bankCode: "",
        }
        // PIX数据
        this.pixInfo = {
            cpf: "",        // 个人税号
            email: "",      // 邮箱
            pixname: "",    // 姓名
            telefone: "",   // 电话号码
            chavepix: "",   // pix钥匙
        }
        // USDT兑换比例
        this.usdtExchangeCfg = {
            cashToUsdt: 1,
            usdtToCash: 1
        }
        // 银行卡信息
        this.bankInfo = {
            account: "",
            cardNo: "",
            bankID: "",
        }

        // 初始化每日存档
        let dayString = new Date().toDateString();
        if (this.date != dayString) {
            this.date = dayString;
        }

        // 初始化临时缓存数据
        this.isAutoOpenAgentConsulting = false; // 是否自动弹出过代理公告
        this.isAutoOpenSignin = false;  // 是否自动弹出签到
        this.isAutoOpenNewbie = false;  // 是否自动弹出新手任务

        // this.cleanLocalData();
    }

    saveLocalData() {
        if (!userInfo.value) {
            GFunc.log("UserCtrl data not init!");
            return;
        }
        store.commit("setUserInfo", userInfo.value);
    }

    cleanLocalData() {
        this.isLogin = false;
        this.vipLevel = 0;
        this.differenceTime = 0;
        this.rechargeToday = false;
        this.isBind = false;
        this.thirdLogin = false;
    }

    /* 用户token */ /* tokenInfo */
    getUserToken() {
        return DataIns.checkstring(store.state.token);
    }

    /* 用户ID */ /* userid */
    getUserId() {
        return DataIns.checkint(userInfo.value?.id);
    }

    /* 虚拟用户ID */ /* vuserid */
    getVUserId() {
        return DataIns.checkint(userInfo.value?.showid);
    }
    setVUserId(showid) {
        if (!userInfo.value) { return; }
        userInfo.value.showid = DataIns.checkint(showid);
        this.saveLocalData();
    }

    /* 玩家昵称 */ /* nickName */
    getNickName() {
        return DataIns.checkstring(userInfo.value?.nickname) || `game${GFunc.getRandStr(8)}`;
    }
    setNickName(nickName) {
        if (!userInfo.value) { return; }
        userInfo.value.nickname = DataIns.checkstring(nickName);
        this.saveLocalData();
    }

    /* 邀请码 */ /* uuid */
    getUuid() {
        return DataIns.checkstring(userInfo.value?.uuid);
    }

    /* 账号名 */ /* username */
    getUserName() {
        return DataIns.checkstring(userInfo.value?.username);
    }
    setUserName(suername) {
        if (!userInfo.value) { return; }
        userInfo.value.username = DataIns.checkstring(suername);
        this.saveLocalData();
    }

    /* 头像索引或地址 */ /* avatarIdx */
    getAvatarIdx() {
        return DataIns.checkstring(userInfo.value?.avatar) || GFunc.getRandAvatarIdx();
    }
    setAvatarIdx(idx) {
        if (!userInfo.value) { return; }
        userInfo.value.avatar = `${DataIns.checkint(idx)}`;
        this.saveLocalData();
    }

    /* 是否已登录 */ /* isLogin */
    getIsLogin() {
        // return this.isLogin;
        // TODO token 调整为永久，直接使用 token 判断
        return !!this.getUserToken();
    }
    setIsLogin(status) {
        this.isLogin = status;
    }

    /* VIP等级 */ /* vipLevel */
    getVipLevel() {
        return this.vipLevel;
    }
    setVipLevel(lv) {
        this.vipLevel = lv;
    }

    /* 账号类型 1 普通账号 2 内部账号 3 代理账号 */ /* userType */
    getUserType() {
        return DataIns.checkint(userInfo.value?.type) || EUserType.Normal;
    }
    setUserType(type) {
        if (!userInfo.value) { return; }
        userInfo.value.type = DataIns.checkint(type) || EUserType.Normal;
        this.saveLocalData();
    }

    /* 金币 */ /* coin */
    getCoin() {
        return DataIns.checkint(userInfo.value?.goldcoin);
    }
    setCoin(coin) {
        if (!userInfo.value) { return; }
        userInfo.value.goldcoin = DataIns.checkint(coin);
        this.saveLocalData();
    }
    addCoin(coin) {
        if (!userInfo.value) { return; }
        userInfo.value.goldcoin += DataIns.checkint(coin);
        this.saveLocalData();
    }

    /* 钻石 */ /* diamond */
    getDiamond() {
        return DataIns.checkint(userInfo.value?.diamond);
    }
    setDiamond(diamond) {
        if (!userInfo.value) { return; }
        userInfo.value.diamond = DataIns.checkint(diamond);
        this.saveLocalData();
    }
    addDiamond(diamond) {
        if (!userInfo.value) { return; }
        userInfo.value.diamond += DataIns.checkint(diamond);
        this.saveLocalData();
    }

    /* 玩家可提现金额 */ /* userMoney */
    getUserMoney() {
        return DataIns.checknumber(userInfo.value?.currentmoney);
    }
    setUserMoney(money) {
        if (!userInfo.value) { return; }
        userInfo.value.currentmoney = DataIns.checknumber(money);
        this.saveLocalData();
    }
    addUserMoney(money) {
        if (!userInfo.value) { return; }
        userInfo.value.currentmoney += DataIns.checknumber(money);
        this.saveLocalData();
    }

    /* 奖金钱包金额 */ /* userBonusMoney */
    getUserBonusMoney() {
        return DataIns.checknumber(userInfo.value?.bonus);
    }
    setUserBonusMoney(money) {
        if (!userInfo.value) { return; }
        userInfo.value.bonus = DataIns.checknumber(money);
        this.saveLocalData();
    }
    addUserBonusMoney(money) {
        if (!userInfo.value) { return; }
        userInfo.value.bonus += DataIns.checknumber(money);
        this.saveLocalData();
    }

    /* 玩家在途金额 */ /* lockMoney */
    getLockMoney() {
        return DataIns.checknumber(userInfo.value?.lockmoney);
    }
    setLockMoney(lockmoney) {
        if (!userInfo.value) { return; }
        userInfo.value.lockmoney = DataIns.checknumber(lockmoney);
        this.saveLocalData();
    }
    addLockMoney(lockmoney) {
        if (!userInfo.value) { return; }
        userInfo.value.lockmoney += DataIns.checknumber(lockmoney);
        this.saveLocalData();
    }

    /* 当前打码量 */ /* playMoney */
    getPlaylMoney() {
        return DataIns.checknumber(userInfo.value?.playmoney);
    }
    setPlayMoney(playmoney) {
        if (!userInfo.value) { return; }
        userInfo.value.playmoney = DataIns.checknumber(playmoney);
        this.saveLocalData();
    }
    addPlayMoney(playmoney) {
        if (!userInfo.value) { return; }
        userInfo.value.playmoney += DataIns.checknumber(playmoney);
        this.saveLocalData();
    }

    /* 玩家需要打码量 */ /* */
    setNeedPlayMoney(money) {
        if (!userInfo.value) { return; }
        userInfo.value.needplaymoney = DataIns.checknumber(money);
        this.saveLocalData();
    }

    /* 玩家需要打码量[操作增减打码量] */ /* */
    setOpNeedPlayMoney(money) {
        if (!userInfo.value) { return; }
        userInfo.value.opneedplaymoney = DataIns.checknumber(money);
        this.saveLocalData();
    }

    /* 玩家需要打码量总额 */ /* needPlayMoneyTotal */
    getNeedPlayMoneyTotal() {
        let needPlayMoney = DataIns.checknumber(userInfo.value?.needplaymoney);
        let opNeedPlayMoney = DataIns.checknumber(userInfo.value?.opneedplaymoney);
        return needPlayMoney + opNeedPlayMoney;
    }

    /* 提现金额 */ /* withdrawalMoney */
    getWithdrawalMoney() {
        return DataIns.checknumber(userInfo.value?.withdrawalMoney);
    }
    setWithdrawalMoney(withdrawalMoney) {
        if (!userInfo.value) { return; }
        userInfo.value.withdrawalMoney = DataIns.checknumber(withdrawalMoney);
        this.saveLocalData();
    }
    addWithdrawalMoney(withdrawalMoney) {
        if (!userInfo.value) { return; }
        userInfo.value.withdrawalMoney += DataIns.checknumber(withdrawalMoney);
        this.saveLocalData();
    }

    /* usdt钱包地址 */ /* usdtAddr */
    getUsdtAddr() {
        return DataIns.checkstring(userInfo.value?.addr);
    }
    setUsdtAddr(addr) {
        if (!userInfo.value) { return; }
        userInfo.value.addr = DataIns.checkstring(addr);
        this.saveLocalData();
    }

    /* 提现密码 */ /* walletPassword */
    getWalletPassword() {
        return DataIns.checkstring(userInfo.value?.wpassword) ? "*" : "";
    }
    setWalletPassword(pwd) {
        if (!userInfo.value) { return; }
        userInfo.value.wpassword = DataIns.checkstring(pwd);
        this.saveLocalData();
    }

    /* 提现状态 1正常 3 禁止提现 */ /* walletStatus */
    getWalletStatus() {
        return DataIns.checkint(userInfo.value?.wstatus);
    }
    setWalletStatus(status) {
        if (!userInfo.value) { return; }
        userInfo.value.wstatus = DataIns.checkint(status);
        this.saveLocalData();
    }

    /* 提现VIP等级 -1表示未设置 */ /* walletVipLevel */
    getWalletVipLevel() {
        return DataIns.checkint(userInfo.value?.wvip);
    }
    setWalletVipLevel(lv) {
        if (!userInfo.value) { return; }
        userInfo.value.wvip = DataIns.checkint(lv);
        this.saveLocalData();
    }

    /* 提现次数 */ /* withdrawalTimes */
    getWithdrawalTimes() {
        return DataIns.checkint(userInfo.value?.withdrawalCount);
    }
    setWithdrawalTimes(times) {
        if (!userInfo.value) { return; }
        userInfo.value.withdrawalCount = DataIns.checkint(times);
        this.saveLocalData();
    }

    /* 客户端与服务器时间差 */ /* differenceTime */
    getDifferenceTime() {
        return DataIns.checkint(this.differenceTime);
    }
    setDifferenceTime(value) {
        this.differenceTime = DataIns.checkint(value);
    }

    /* 累计充值（非实时） */ /* totalRecharge */
    getTotalRecharge() {
        return DataIns.checknumber(userInfo.value?.total_recharge);
    }
    setTotalRecharge(money) {
        if (!userInfo.value) { return; }
        userInfo.value.total_recharge = DataIns.checknumber(money);
        this.saveLocalData();
    }

    /* 累计提现 */ /* totalWithdrawal */
    getTotalWithdrawal() {
        return DataIns.checknumber(userInfo.value?.total_withdrawal);
    }
    setTotalWithdrawal(money) {
        if (!userInfo.value) { return; }
        userInfo.value.total_withdrawal = DataIns.checknumber(money);
        this.saveLocalData();
    }

    /* vip等级奖励领取状态 位运算 2的n次方 vip1=2 vip2=4 vip3=8 */ /* vipAwards */
    getVipAwards() {
        return DataIns.checkint(userInfo.value?.vipawardlocation);
    }
    setVipAwards(awards) {
        if (!userInfo.value) { return; }
        userInfo.value.vipawardlocation = DataIns.checkint(awards);
        this.saveLocalData();
    }

    /* 邀请人数 有效邀请人数 */ /* inviteAmounts */
    getInviteAmounts() {
        return DataIns.checkint(userInfo.value?.inviteAmounts);
    }
    setInviteAmounts(inviteAmounts) {
        if (!userInfo.value) { return; }
        userInfo.value.inviteAmounts = DataIns.checkint(inviteAmounts);
        this.saveLocalData();
    }

    /* 今日是否有充值 默认有充值 */ /* rechargeToday */
    getRechargeToday() {
        return this.rechargeToday;
    }
    setRechargeToday(status) {
        this.rechargeToday = DataIns.checkbool(status);
    }

    /* 是否绑定账号 */ /* isBind */
    getIsBind() {
        return this.isBind;
    }
    setIsBind(status) {
        this.isBind = status;
    }

    /* 是否三方登录 */ /* thirdLogin */
    getThirdLogin() {
        return this.thirdLogin;
    }
    setThirdLogin(status) {
        this.thirdLogin = status;
    }

    /*  */

    /*  */

    /*  */

    /*  */

    /*  */

    /*  */

    /*  */

    // 获取活动是否已完成
    isActivityComplete(id) {
        return this.completeActivitys.indexOf(id) >= 0;
    }

    // 记录活动为已完成
    setActivityComplete(id) {
        if (this.isActivityComplete(id)) {
            return;
        }
        this.completeActivitys.push(id);
    }

    // 自动弹出公告
    getIsAutoOpenAgentConsulting() {
        return this.isAutoOpenAgentConsulting;
    }
    setIsAutoOpenAgentConsulting(state) {
        this.isAutoOpenAgentConsulting = state;
    }

    // 自动弹出签到
    getIsAutoOpenSignin() {
        return this.isAutoOpenSignin;
    }
    setIsAutoOpenSignin(state) {
        this.isAutoOpenSignin = state;
    }

    // 自动弹出新手任务
    getIsAutoOpenNewbie() {
        return this.isAutoOpenNewbie;
    }
    setIsAutoOpenNewbie(state) {
        this.isAutoOpenNewbie = state;
    }

    // 是否已经绑定银行卡
    hadPlayerBindBankCard() {
        return this.walletInfo && this.walletInfo.cardNumber || false;
    }

    // 是否已经绑定USDT钱包
    hadPlayerBindUsdt() {
        return this.getUsdtAddr();
    }

    // 是否已经绑定PIX
    hadPlayerBindPix() {
        return this.pixInfo && this.pixInfo.cpf || false;
    }

    // 是否已经充值
    hadRecharge() {
        return this.getTotalRecharge() > 0;
    }

    // 是否虚拟账号
    internalAccount() {
        return this.getUserType() == EUserType.Internal;
    }
}

const UserCtrl = new _UserCtrl_();

export { UserCtrl }

// DEBUG
window.UserCtrl = UserCtrl;
