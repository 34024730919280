<template>
  <section class="in-game-banner">
    <img src="@/assets/common/close.png" alt="close" @click="exitGame" />
  </section>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { useStore } from "vuex";

const store = useStore();

const exitGame = () => {
  store.commit("goBack");
};
</script>

<style lang="scss">
.in-game-banner {
  box-sizing: border-box;
  width: 100%;
  background-color: #000;
  position: sticky;
  top: 0;
  @include flex(row, flex-start, center);
  padding: 0.5rem;
  height: 6vh;
  img {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
}
</style>
