<template>
  <!-- Display the HTML content using v-html -->
  <div class="tnc-top-section">
    <img
      src="@/assets/common/close.png"
      alt="close"
      class="tnc-top-close"
      @click="onBack"
    />
  </div>
  <div style="background-color: #fff; padding: 1rem 0.5rem 8rem 0.5rem">
    <div v-html="termsContent"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

// Reference to store the terms and conditions content
const termsContent = ref("");
const store = useStore();

const onBack = () => {
  store.commit("goBack");
};

// Function to fetch the HTML file from the `public` folder
async function fetchTermsContent() {
  try {
    const response = await fetch("/0038_tnc.html"); // Adjust path as needed
    if (response.ok) {
      termsContent.value = await response.text();
    } else {
      console.error(`Failed to fetch terms file: ${response.status}`);
    }
  } catch (error) {
    console.error(`Error fetching terms file: ${error}`);
  }
}

// Fetch the terms content once the component mounts
onMounted(fetchTermsContent);
</script>

<style lang="scss">
.tnc-top-section {
  width: 100%;
  box-sizing: border-box;
  @include flex(row, flex-start, center);
  padding: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #000;
  .tnc-top-close {
    width: 35px;
    height: 35px;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
