<template>
  <section class="rebate-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_REBATE") }}</span>

      <img
        src="@/assets/common/back.png"
        alt="back"
        class="back-btn"
        style="opacity: 0"
      />
    </div>

    <div class="rebate-inner">
      <div class="wallet-section">
        <span class="wallet-section-title">₫ {{ userInfo.wallet }}</span>
        <span class="wallet-section-desc">{{ t("TID_LB_WALLET") }}</span>
      </div>

      <div class="table-header">
        <span>{{ t("TID_LB_DATE") }}</span>
        <span>{{ t("TID_LB_REBATE") }}</span>
        <span>{{ t("TID_LB_STATUS") }}</span>
      </div>
      <section class="rebate-section" v-if="!requestLoading">
        <div class="rebate-item" v-for="(item, key) in dataList" :key="key">
          <div class="rebate-item-date">
            {{ item.date }}
          </div>

          <div class="rebate-item-amount">
            {{ "₫ " + item.reserve_amount }}
          </div>

          <div class="rebate-item-status grey" v-if="item.status === 2">
            {{ t("TID_LB_CLAIMED") }}
          </div>

          <div
            class="rebate-item-status red"
            v-else
            @click="openClaimModal(item)"
            style="cursor: pointer"
          >
            {{ t("TID_LB_UNCLAIMED") }}
            <CaretRightOutlined style="color: #fff; margin-left: 0.2rem" />
          </div>
        </div>
        <div ref="sentinel" class="sentinel">
          <a-spin
            v-if="fetchingMoreData"
            style="color: #ffeb02"
            size="medium"
          />
          <span v-else-if="!fetchingMoreData && dataList.length > 0">
            {{ t("TID_LB_LOAD_NO_DATA") }}</span
          >
        </div>
      </section>

      <section class="skeletons" v-else>
        <div class="skeletons-item" v-for="(i, key) in 10" :key="key"></div>
      </section>

      <section
        class="no-results-section"
        v-if="dataList.length <= 0 && !requestLoading"
      >
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>
    </div>
  </section>

  <a-modal
    :open="showClaimModal"
    centered
    :closable="false"
    :maskClosable="true"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        display: 'none',
      },
    }"
  >
    <div class="modal-content">
      {{ t("TID_LB_CLAIM_YOUR_REBATE") }}
    </div>
    <div class="modal-footer">
      <span @click="closeClaimModal">{{ t("TID_LB_CANCEL") }}</span>
      <span style="color: #ffeb02" @click="claimRebate">{{ t("TID_LB_YES") }}</span>
    </div>
  </a-modal>

  <Loading v-if="requestLoading" />
</template>

<script setup>
import { ref, onMounted, onUnmounted, watchEffect, computed } from "vue";

import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import { CaretRightOutlined } from "@ant-design/icons-vue";

const store = useStore();
const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const requestLoading = ref(false);
const fetchingMoreData = ref(false);

const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const sentinel = ref(null);

const showClaimModal = ref(false);
const selectedItem = ref({});

const userInfo = computed(() => store.state.userInfo);

const openClaimModal = (item) => {
  showClaimModal.value = true;
  selectedItem.value = item;
};

const closeClaimModal = () => {
  selectedItem.value = {};
  showClaimModal.value = false;
};

const onBack = () => {
  store.commit("goBack");
};

const fetchData = async (pageNumber) => {
  try {
    if (pageNumber < 2) {
      requestLoading.value = true;
    } else {
      fetchingMoreData.value = true;
    }

    const res = await reqApi.getRebateList({
      language: locale.value,
      page: currentPage.value,
      version: "3.6.2",
    });
    if (res) {
      dataList.value = [...dataList.value, ...res.data];
      currentPage.value = res.current_page;
      lastPage.value = res.last_page;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
    fetchingMoreData.value = false;
  }
};

const updateData = async (pageNumber) => {
  try {
    if (pageNumber < 2) {
      requestLoading.value = true;
    } else {
      fetchingMoreData.value = true;
    }

    const res = await reqApi.getRebateList({
      language: locale.value,
      page: currentPage.value,
      version: "3.6.2",
    });
    if (res) {
      dataList.value = res.data;
      currentPage.value = res.current_page;
      lastPage.value = res.last_page;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
    fetchingMoreData.value = false;
  }
};

const claimRebate = async () => {
  try {
    showClaimModal.value = false;
    requestLoading.value = true;
    const claimRes = await reqApi.claimRebate({
      id: selectedItem.value.id,
      language: locale.value,
      version: "3.6.2",
    });
    if (claimRes) {
      updateData(currentPage.value);
      const profileRes = await reqApi.getMemberProfile({
        restore: null,
        language: locale.value,
        version: "3.6.2",
      });
      if (profileRes) {
        store.commit("setUserInfo", profileRes);
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const observer = new IntersectionObserver(
  ([entry]) => {
    if (entry.isIntersecting && lastPage.value > currentPage.value) {
      currentPage.value += 1;
      fetchData(currentPage.value);
    }
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  }
);

watchEffect(() => {
  if (sentinel.value) {
    observer.observe(sentinel.value);
  }
});

onMounted(() => {
  fetchData(currentPage.value);
});

onUnmounted(() => {
  if (sentinel.value) {
    observer.unobserve(sentinel.value);
  }
});
</script>

<style lang="scss">
.rebate-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    @include flex(row, space-between, center);
    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .rebate-inner {
    width: 100%;
    max-width: 430px;
    box-sizing: border-box;
    @include flex(column, center, center);
    .wallet-section {
      border: 1px solid #463f04;
      width: 80%;
      @include flex(column, center, center);
      padding: 0.5rem 0;
      border-radius: 10px;
      &-title {
        font-weight: bold;
        font-size: 1.3rem;
      }
      &-desc {
        color: #efb677;
        font-size: 0.8rem;
      }
    }
    .table-header {
      background-color: #666;
      width: 100%;
      @include flex(row, space-around, center);
      font-size: 0.9rem;
      padding: 0.2rem 0;
      margin-top: 1rem;
      span {
        flex: 0 0 30%;
        @include flex(row, center, center);
      }
    }

    .rebate-section {
      width: 100%;
      box-sizing: border-box;
      @include flex(column, center, center);
      font-size: 0.9rem;
    }
    .rebate-item {
      width: 100%;
      box-sizing: border-box;
      height: 80px;
      border-bottom: 1px solid #d9d9d9;

      gap: 0.5rem;
      @include flex(row, space-around, center);
      box-sizing: border-box;
      div {
        flex: 0 0 30%;
        @include flex(row, center, center);
      }
      &-amount {
        color: #ffeb02;
      }
    }

    .rebate-item:last-child {
      border-bottom: none;
    }

    @media (max-width: 600px) {
      padding: 1rem 0;
    }
  }
}
.modal-content {
  height: 50px;
  font-family: "Poppins", sans-serif !important;
  @include flex(row, flex-start, center);
  font-size: 1rem;
}
.modal-footer {
  @include flex(row, flex-end, center);
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  font-size: 0.9rem;
  gap: 3rem;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  span {
    cursor: pointer;
  }
}
</style>
