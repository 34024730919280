<template>
  <section class="convert-bonus-container">
    <div class="top-section">
      <img
        src="@/assets/common/back.png"
        alt="back"
        @click="onBack"
        class="back-btn"
      />

      <span class="title">{{ t("TID_LB_BONUS") }}</span>

      <QuestionCircleOutlined
        style="
          margin-left: 0.2rem;
          color: #ff9700;
          font-size: 1.5rem;
          cursor: pointer;
        "
        @click="showDepositModal = true"
      />
    </div>

    <div class="convert-bonus-inner">
      <div class="convert-bonus-wallet-section">
        <div class="convert-bonus-wallet-section-top">
          <div class="convert-bonus-wallet-section-top-inner">
            <span class="title">₫ {{ userInfo.wallet }}</span>
            <span class="desc">{{ t("TID_LB_WALLET") }}</span>
          </div>
          <div class="convert-bonus-wallet-section-top-inner">
            <span class="title">₫ {{ userInfo.bonus }}</span>
            <span class="desc">{{ t("TID_LB_WALLET") }}</span>
          </div>
        </div>

        <a-progress
          class="convert-bonus-progress"
          :percent="
            (parseFloat(userInfo.bonus_turnover) / parseFloat(userInfo.bonus_target_turnover)) * 100
          "
        />

        <div
          class="convert-btn convert-btn-active"
          v-if="isBonusAchieved"
          @click="convertToWallet"
        >
          {{ t("TID_LB_CONVERT_TO_WALLET") }}
        </div>

        <div class="convert-btn convert-btn-disabled" v-else>
          {{ t("TID_LB_CONVERT_TO_WALLET") }}
        </div>
      </div>
      <div class="table-header">
        <span>{{ t("TID_LB_DATE") }}</span>
        <span>{{ t("TID_LB_DESCRIPTION") }}</span>
        <span>{{ t("TID_LB_COMMISSION") }}</span>
      </div>

      <section class="bonus-list-section" v-if="!requestLoading">
        <div class="bonus-list-item" v-for="(item, key) in dataList" :key="key">
          <div class="bonus-list-item-cell">
            {{ moment(item.confirm_date).format("YYYY-MM-DD") }}
          </div>

          <div class="bonus-list-item-cell">
            {{ t(bonusType[item.type]) }}
          </div>

          <div
            class="bonus-list-item-cell"
            style="font-weight: bold; color: #ffeb02"
          >
            {{ item.amount }}
          </div>
        </div>
      </section>

      <section class="skeletons" v-else>
        <div class="skeletons-item" v-for="(i, key) in 10" :key="key"></div>
      </section>

      <section
        class="no-results-section"
        v-if="dataList.length <= 0 && !requestLoading"
        style="margin-top: 3rem"
      >
        <img src="@/assets/common/no_record.png" alt="no-record" />
        <span>{{ t("TID_LB_NO_RESULT_FOUND") }}</span>
      </section>

      <Loading v-if="requestLoading" />
    </div>
    <section class="pagination-section" v-if="!requestLoading">
      <a-pagination
        v-model:current="currentPage"
        :defaultCurrent="1"
        :pageSize="perPage"
        :total="totalItems"
      />
    </section>
  </section>

  <DepositBonusModal
    :showDepositModal="showDepositModal"
    @onCloseModal="showDepositModal = false"
  />
</template>

<script setup>
// import { ref, onMounted, watch, onUnmounted, watchEffect } from "vue";
import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import DepositBonusModal from "@/components/DepositBonusModal";
import moment from "moment";
// import dayjs from "dayjs";

import Loading from "@/components/Loading";
import { reqApi } from "@/api";
import { GFunc } from "@/lib/GFunc";

const store = useStore();
const showDepositModal = ref(false);
const requestLoading = ref(false);

const { locale, t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});
const userInfo = computed(() => store.state.userInfo);

const bonusType = {
  1: "TID_LB_FIRST_DEPOSIT_BONUS",
};
const dataList = ref([]);
const currentPage = ref(1);
const lastPage = ref(0);
const perPage = ref(0);
const totalItems = ref(0);

GFunc.log(store);
GFunc.log(locale);

const onBack = () => {
  store.commit("goBack");
};

const bonusPercentage = computed(() => {
  const bonusTurnover = parseFloat(userInfo.value.bonus_turnover);
  const bonusTargetTurnover = parseFloat(userInfo.value.bonus_target_turnover);
  return bonusTargetTurnover > 0
    ? (bonusTurnover / bonusTargetTurnover) * 100
    : 0;
});

const isBonusAchieved = computed(() => bonusPercentage.value >= 100);

const fetchData = async () => {
  try {
    requestLoading.value = true;

    const res = await reqApi.getBonusList({
      language: locale.value,
      version: "3.6.2",
      page: currentPage.value,
    });

    if (res) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      dataList.value = res.data;
      currentPage.value = res.current_page;
      lastPage.value = res.last_page;
      perPage.value = res.per_page;
      totalItems.value = res.total;
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

const convertToWallet = async () => {
  try {
    requestLoading.value = true;
    const res = await reqApi.transferWallet({
      language: locale.value,
      version: "3.6.2",
    });
    if (res) {
      store.commit("setUserInfo", res);
      currentPage.value = 1;
      fetchData();
    }
  } catch (e) {
    console.log(e);
  } finally {
    requestLoading.value = false;
  }
};

watch(currentPage, () => {
  fetchData();
});

onMounted(() => {
  fetchData();
});
</script>

<style lang="scss">
.convert-bonus-container {
  @include flex(column, center, center);
  box-sizing: border-box;
  .top-section {
    box-sizing: border-box;
    padding: 0.5rem;
    width: 100%;
    @include flex(row, space-between, center);
    .back-btn {
      width: 35px;
      height: 35px;
      cursor: pointer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }
  .convert-bonus-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 430px;
    .convert-bonus-wallet-section {
      border: 1px solid #7c6d08;
      border-bottom: none;
      border-radius: 30px 30px 0 0;
      width: 100%;
      box-sizing: border-box;
      padding: 1rem 0.5rem 1rem 0.5rem;
      &-top {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, space-around, center);
        &-inner {
          @include flex(column, center, center);
          .title {
            font-weight: bold;
            font-size: 1.3rem;
          }
          .desc {
            font-size: 0.9rem;
            color: #f9c07b;
          }
        }
      }
      .convert-btn {
        width: 100%;
        box-sizing: border-box;
        @include flex(row, center, center);
        text-align: center;
        font-weight: bold;
        font-size: 0.9rem;
        border-radius: 8px;
        padding: 0.6rem 0;
        cursor: pointer;
        &-disabled {
          background-color: #9d9d9d;
        }
        &-active {
          background-color: #ffeb02;
          color: #000;
        }
      }
    }
    .table-header {
      background-color: #383838;
      @include flex(row, space-between, center);
      width: 100%;
      box-sizing: border-box;
      padding: 0.3rem 0;
      span {
        width: 30%;
        text-align: center;
        font-size: 0.9rem;
      }
    }
    .bonus-list-section {
      margin-top: 1rem;
      width: 100%;
      box-sizing: border-box;
      @include flex(column, center, center);
      .bonus-list-item {
        @include flex(row, space-between, stretch);
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #252525;
        &-cell {
          width: 30%;
          text-align: center;
          font-size: 0.9rem;
        }
      }
      .bonus-list-item:last-child {
        border-bottom: none;
      }
    }
    @media (max-width: 600px) {
      padding: 0 0.5rem;
    }
  }
}
.convert-bonus-progress {
  .ant-progress-text {
    color: #fff;
  }
}
</style>
