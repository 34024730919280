/**
 * 一些常量的定义
 */

export const Consts = {
  // 头像数量
  userIconAmount: 1,

  // 支付扫码时间 分钟
  scanPayQrCodeLimitTime: 10,

  // 没有更多数据
  noMoreData: -1,

  // 基础版本号
  baseVersion: "1.1.1",
};

// 包类型
export const EPackType = {
  NONE: 100000,
}

/** 用户类型 */
export const EUserType = {
  None: 0,                  // 0 未定义
  Normal: 1,                // 1 普通账号
  Internal: 2,              // 2 内部账号
  Agent: 3,                 // 3 代理账号
}

/** 钱包绑定或提现类型 */
export const EWBindType = {
  EW_None: 0,               // 占位
  Ew_BankCark: 1,           // 银行卡
  Ew_BankUsdt: 2,           // Usdt
  Ew_BankPix: 3,            // Pix
}

/** 我的团队索引 */
export const ETeamChooseIdx = {
  Et_All: 0,                 // 所有记录
  Et_Today: 1,               // 今日记录
  Et_Yesterday: 2,           // 昨日记录
  Et_Week: 3,                // 本周记录
}

/** 导航栏索引 */
export const ENavigationIdx = {
  ENav_None: 0,               // 占位
  ENav_Home: 1,               // 主页
  ENav_Promotion: 2,          // 推广
  ENav_Team: 3,               // 团队
  ENav_Profile: 4,            // 我的
  ENav_Vip: 5,                // Vip
  ENav_Wallet: 6,             // 钱包
  ENav_History: 7,            // 记录
  ENav_Event: 8,              // 活动
  ENav_Support: 9,            // 在线支持，客服
}

/** 登录页面类型 */
export const ELoginType = {
  ELogin_Login: 0,            // 登录
  ELogin_Register: 1,         // 注册
  ELogin_Forgot: 2,           // 找回密码
  ELogin_None: 100,           // 空页面
}

/** 登录模式 */
export const ELoginMode = {
  ELoginMode_Acc: 0,          // 账号登录
  ELoginMode_Phone: 1,        // 手机号登录
}

/** 登录渠道类型 */
export const ELoginChannelType = {
  ELoginChannel_Account: 0,   // 账号
}

/** 通用弹窗类型 */
export const EPopupSelectType = {
  EPopup_Gender: 0,           // 性别
  EPopup_Customer: 1,         // 在线客服
}

/** 历史记录类型 */
export const EHistoryType = {
  EHis_Deposit: 0,            // 充值
  EHis_Withdraw: 1,           // 提现
  EHis_Transaction: 2,        // 交易
  EHis_Game: 3,               // 游戏
  EHis_Bets: 4,               // 投注
}

/** 通用信息界面类型 */
export const EGeneralInfoType = {
  EGen_Agreement: 0,          // 用户注册协议
  EGen_Cert: 1,               // 证书
  EGen_TAC: 2,                // 条款和条件
  EGen_Event: 3,              // 事件
  EGen_FAQs: 4,               // 常见问题解答
  EGen_AboutUs: 5,            // 关于我们
}

/** 修改密码类型 */
export const EPasswordType = {
  EPwd_None: 0,               // 占位
  EPwd_Login: 1,              // 登录密码
  EPwd_Pin: 2,                // 取款密码
}

/** 游戏平台 */
export const EGamePlatform = {
  EGP_None: 0,                // 0.占位
  EGP_COLL: 1,                // 1.自定义-收藏
  EGP_HOT: 2,                 // 2.自定义-热门
  EGP_HIS: 3,                 // 3.自定义-玩过的游戏
  EGP_NEW: 4,                 // 4.自定义-最新或有新增游戏
  EGP_PG: 11,                 // 11.PG (SLOT)
  EGP_PP: 12,                 // 12.PP (SLOT|EGAME)
  EGP_SABA: 13,               // 13.SABA (VIRTUAL)
  EGP_E1SPORT: 14,            // 14.电竞 (ESPORTS)
  EGP_JDB: 15,                // 15.JDB (FH)
  EGP_JILI: 16,               // 16.JILI (FH)
  EGP_DB: 17,                 // 17.多宝
  EGP_BG: 18,                 // 18.Big Gaming
  EGP_EVOLUTION: 19,          // 19.卡牌
  EGP_HOTROAD: 20,            // 20.竞速街机
  EGP_SEXYBCRT: 21,           // 21.性感
  EGP_FC: 22,                 // 22.FC Gaming
  EGP_YL: 23,                 // 23.YL
  EGP_KINGMAKER: 24,          // 24.KINGMAKER
}

/** 游戏类型 */
export const EGameType = {
  EGT_None: 0,                // 0.占位
  EGT_COLL: 1,                // 1.自定义-收藏
  EGT_HOT: 2,                 // 2.自定义-热门
  EGT_HIS: 3,                 // 3.自定义-玩过的游戏
  EGT_NEW: 4,                 // 4.自定义-玩过的游戏
  EGT_SLOT: 11,               // 11.老虎机
  EGT_VIRTUAL: 12,            // 12.虚拟运动
  EGT_ESPORTS: 13,            // 13.电竞
  EGT_EGAME: 14,              // 14.电玩
  EGT_LIVE: 15,               // 15.真人
  EGT_FH: 16,                 // 16.捕鱼
  EGT_HASH: 17,               // 17.哈希
  EGT_TABLE: 18,              // 18.桌面
  EGT_BINGO: 19,              // 19.宾果
  EGT_LOTTO: 20,              // 20.彩票
}

/** 活动公告类型 */
export const ENoticeType = {
  ENotice_None: 0,            // 占位
  ENotice_RollPic: 1,         // 轮播图
  ENotice_Notice: 2,          // 弹窗公告
}

/**
 * 活动类型
 * 请勿调整顺序，勿修改索引号
 */
export const EEventType = {
  EEvent_None: 0,             // 0.占位
  EEvent_Vip: 1,              // 1.vip
  EEvent_Invite: 2,           // 2.拉新奖励
  EEvent_FirstDeposit: 3,     // 3.首充奖励
  EEvent_TGRedemptionCode: 4, // 4.TG兑换码
  EEvent_DividendDay: 5,      // 5.股息日
  EEvent_MemberDay: 6,        // 6.会员日
  EEvent_AmountOfLoss: 7,     // 7.损失金额
  EEvent_LuckyWheel: 8,       // 8.幸运轮盘
  EEvent_CheckIn: 9,          // 9.每日签到
  EEvent_YuEBao: 10,          // 10.存款理财
  EEvent_Envelopes: 11,       // 11.信封红包
  EEvent_DownloadApp: 12,     // 12.下载APP随机送奖金
  EEvent_MonthlyHundred: 13,  // 13.指定日期随机送奖金
  EEvent_MillBonus: 14,       // 14.百万神秘奖金随机送
  EEvent_BetBonus: 15,        // 15.投注奖金返奖
  EEvent_LuckyBet: 16,        // 16.幸运赌注
  EEvent_PatinerBet: 17,      // 17.代理招商
  EEvent_TurnTable:18,        // 18.转盘
  EEvent_WeekRelief:19,       // 19.周返奖
}

/**
 * 活动item布局类型
 */
export const EEventItemLayout = {
  Choose: 0,                // 选择
  Open: 1,                  // 打开
}

/** 跳转类型 */
export const EJumpToType = {
  EJump_Url: 1,             // 外部地址
  EJump_Module: 2,          // APP内部模块
  EJump_Detail: 3,          // APP特定模块详情
  EJump_Channel: 4,         // 频道号地址
}

// 在线客服浮层状态类型
export const EFloatStateType = {
  EFloat_Hide: 0,           // 隐藏
  EFloat_Show: 1,           // 显示
  EFloat_Revert: 99,        // 还原上一次有效状态
}

// 钱包页签索引
export const EWalletIdx = {
  EWallet_None: 0,          // 占位
  EWallet_Recharge: 1,      // 存款
  EWallet_Withdraw: 2,      // 取款
  EWallet_Trans: 3,         // 交易
}

// 提款类型
export const EWithdrawType = {
  EWT_Balance: 0,           // 余额
  EWT_Bonus: 1,             // 奖金
}

// 资金来源
export const EWealthSource = {
  EWS_All: -1,              // 所有
  EWS_Balance: 0,           // 余额
  EWS_Bonus: 1,             // 奖金
}

// 打码类型倍率
export const ECodeRatioType = {
  ECRT_None: 0,              // 占位
  ECRT_Deposit: 1,           // 充值
  ECRT_Bonus: 2,             // 奖金
  ECRT_Commission: 3,        // 邀请奖励
}

// PIX绑定类型
export const EPixBindType = {
  EPBT_CPF: 0,              // CPF
  EPBT_CNPJ: 1,             // CNPJ
  EPBT_PHONE: 2,            // 手机
  EPBT_EMALI: 3,            // 邮箱
}

// 交易记录类型 参考 TransactionTypes
// 与服务端约定，请勿随意修改
export const ETransactionType = {
  ETra_None: 0,
  ETra_RechargeSucc: 1,             // 充值转入
  ETra_RechargeGift: 2,             // 充值赠送
  ETra_WithrawalApply: 3,           // 申请提现
  ETra_WithrawalFailed: 4,          // 提现失败退回
  ETra_CheckInReward: 5,            // 签到奖励
  ETra_VipUpgradeReward: 6,         // VIP升级奖励
  ETra_InviteCommission: 7,         // 邀请佣金
  ETra_TeamCommission: 8,           // 团队佣金
  ETra_ManualReward: 9,             // 后台人工赠送
  ETra_ManualDeduction: 10,         // 后台人工扣除
  ETra_ManualRecharge: 11,          // 后台人工充值
  ETra_ThirdParytDis: 12,           // 游戏变更 需要增加(GamePlatform)，如 (pg)
  ETra_ChestReward: 13,             // 宝箱奖励
  ETra_ReissueInviteCom: 14,        // 补发拉新佣金
  ETra_RedEnvelopeReward: 15,       // 红包奖励
  ETra_ReissueDailyRechargeCom: 16, // 补发每日充值佣金
  ETra_DailyRechargeCom: 17,        // 每日充值佣金
  ETra_BonusRedemption: 18,         // 奖金兑换
  ETra_DividendDayReward: 19,       // 转盘奖励
  ETra_MemberDayReward: 21,         // 周返奖奖励
  ETra_LossAllowance: 22,           // 红包雨奖励
}

// 交易记录类型
export const TransactionTypes = {
  [ETransactionType.ETra_RechargeSucc]: `TID_TRANSACTION_TIP2`,             // 充值赠送
  [ETransactionType.ETra_RechargeGift]: `TID_TRANSACTION_TIP2`,             // 充值赠送
  [ETransactionType.ETra_WithrawalApply]: `TID_TRANSACTION_TIP3`,           // 申请提现
  [ETransactionType.ETra_WithrawalFailed]: `TID_TRANSACTION_TIP1`,          // 提现失败退回
  [ETransactionType.ETra_CheckInReward]: `TID_TRANSACTION_TIP5`,            // 签到奖励
  [ETransactionType.ETra_VipUpgradeReward]: `TID_TRANSACTION_TIP6`,         // VIP升级奖励
  [ETransactionType.ETra_InviteCommission]: `TID_TRANSACTION_TIP7`,         // 邀请佣金
  [ETransactionType.ETra_TeamCommission]: `TID_TRANSACTION_TIP8`,           // 团队佣金
  [ETransactionType.ETra_ManualReward]: `TID_TRANSACTION_TIP9`,             // 后台人工赠送
  [ETransactionType.ETra_ManualDeduction]: `TID_TRANSACTION_TIP10`,         // 后台人工扣除
  [ETransactionType.ETra_ManualRecharge]: `TID_TRANSACTION_TIP11`,          // 后台人工充值
  [ETransactionType.ETra_ThirdParytDis]: `TID_TRANSACTION_TIP12`,           // 游戏变更 需要增加(GamePlatform)，如 (pg)
  [ETransactionType.ETra_ChestReward]: `TID_TRANSACTION_TIP13`,             // 宝箱奖励
  [ETransactionType.ETra_ReissueInviteCom]: `TID_TRANSACTION_TIP14`,        // 补发拉新佣金
  [ETransactionType.ETra_RedEnvelopeReward]: `TID_TRANSACTION_TIP15`,       // 红包奖励
  [ETransactionType.ETra_ReissueDailyRechargeCom]: `TID_TRANSACTION_TIP16`, // 补发每日充值佣金
  [ETransactionType.ETra_DailyRechargeCom]: `TID_TRANSACTION_TIP17`,        // 每日充值佣金
  [ETransactionType.ETra_BonusRedemption]: `TID_TRANSACTION_TIP18`,         // 奖金兑换
  [ETransactionType.ETra_DividendDayReward]: `TID_TRANSACTION_TIP19`,       // 转盘奖励
  [ETransactionType.ETra_MemberDayReward]: `TID_TRANSACTION_TIP21`,         // 周返奖奖励
  [ETransactionType.ETra_LossAllowance]: `TID_TRANSACTION_TIP22`,           // 红包雨奖励
}
