import axios from "axios";
import store from "@/store";
import eventBus from "@/eventBus";
import { encodeReqData, getAppBaseApi, getInternalAccFlag, getUrl, service, setInternalAccFlag } from "./NetAxios";
import { DataIns } from "@/lib/DataIns";
import { EEventType, ENoticeType, EPixBindType, EUserType, EWalletIdx } from "@/lib/Consts";
import { DeviceMgr } from "@/lib/DeviceMgr";
import { computed } from "vue";
import { ObjectIns } from "@/lib/ObjectIns";
import { CfgAnalyze, SwitchModule } from "@/lib/CfgAnalyze";
import { UserCtrl } from "@/ctrl/UserCtrl";
import { CheckinCtrl } from "@/ctrl/CheckinCtrl";
import { RedPacketCtrl } from "@/ctrl/RedPacketCtrl";
import { NoticeCtrl } from "@/ctrl/NoticeCtrl";
import { GameCtrl } from "@/ctrl/GameCtrl";
import { EventCtrl } from "@/ctrl/EventCtrl";
import { KGEvent } from "./KGEvent";
import { GlobalCtrl } from "@/ctrl/GlobalCtrl";
import { VipCtrl } from "@/ctrl/VipCtrl";

/* Default Api requesting */
const postData = async (route, data = {}) => {
  try {
    const response = await axios.post(`${getAppBaseApi()}${route}`, data);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    eventBus.emit("message", {
      type: "error",
      message: error.response.data.message,
    });
    throw error;
  }
};

const postAuthorized = async (route, data = {}) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${store.state.token}`,
      },
    };
    const response = await axios.post(`${getAppBaseApi()}${route}`, data, config);
    return response.data.data;
  } catch (error) {
    if (
      error.response.data.status === "Unauthenticated" ||
      error.response.data.message === "Unauthenticated."
    ) {
      eventBus.emit("show-modal", true);
      return;
    } else if (
      error.response.data.message === "Too Many Requests" ||
      error.response.data.message === "muitos pedidos"
    ) {
      store.commit("setCurrentView", "casino");
      window.location.reload();
    }
    eventBus.emit("message", {
      type: "error",
      message: error.response.data.message,
    });
    throw error;
  }
};

/**
 * API interface
 */
const netApi = {

  /**
   * 账号注册 --LG--
   * @param reqParams 注册参数
   * @param callFunc  成功回调
   * @param failFunc  失败回调
   * reqParams {
   *    "username": "string",         [*]用户账号
   *    "password": "string",         [*]密码
   *    "confirmPassword": "string",  [*]确认密码
   *    "source": "string",           [+]渠道来源
   *    "uuid": "string",             [*]邀请码
   *    "avatar": "string",           [+]头像ID
   *    "phone": "string",            [*]电话号码
   *    "code": "string",             [*]手机验证码
   *    "cpf": "string",              [+]cpf
   * }
   * response 通用
   */
  userRegister: async (reqParams) => {
    let reqDatas = {
      "__isAuth__": true,
      "username": reqParams.username,
      "password": reqParams.password,
      "nextpassword": reqParams.confirmPassword,
      "source": reqParams.source,
      "uuid": reqParams.uuid,
      "avatar": DataIns.checkstring(reqParams.avatar.toString()),
      "phone": DataIns.checkstring(reqParams.phone),
      "code": DataIns.checkstring(reqParams.code),
      "cpf": DataIns.checkstring(reqParams.cpf),
      "vcode": DataIns.checkstring(reqParams.vcode),
    };
    const respDatas = await service({
      url: getUrl("auth/reg"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // store.commit("setUserAccount", DataIns.checkstring(reqParams.username));
    // store.commit("setUserPwd", DataIns.checkstring(reqParams.password));
    return respDatas;
  },

  /**
   * 找回密码 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *    "password": "string",     [*]新密码
   *    "nextpassword": "string", [*]确认密码
   *    "phone": "string",        [*]电话号码
   *    "code": "string",         [*]手机验证码
   * }
   * response 通用
   */
  forgotPassword: async (reqParams) => {
    let reqDatas = {
      "__isAuth__": true,
      "password": reqParams.password,
      "nextpassword": reqParams.nextpassword,
      "phone": DataIns.checkstring(reqParams.phone),
      "code": DataIns.checkstring(reqParams.code),
    };
    const respDatas = await service({
      url: getUrl("auth/findPass"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // 清空记录密码，下次登录需要用户输入
    store.commit("setUserPwd", "");
    return respDatas;
  },

  /**
   * 修改登录密码 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "oldpassword": "string",   [+]旧密码 修改密码必填
   *   "password": "string",      [*]密码
   *   "nextpassword": "string",  [*]确认密码
   * }
   * response 通用
   */
  setLoginPwd: async (reqParams) => {
    let reqDatas = {
      "password": reqParams.password,
      "nextpassword": reqParams.nextpassword,
    };
    let oldpassword = reqParams.oldpassword;
    if (oldpassword) {
      reqDatas["oldpassword"] = oldpassword;
    }
    const respDatas = await service({
      url: getUrl("user/setPass"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // 清空记录密码，下次登录需要用户输入
    store.commit("setUserPwd", "");
    return respDatas;
  },

  /**
   * 获取用户账号类型
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "username": "string", [*]用户账号
   *    "password": "string", [*]密码
   * }
   * response{
   *   "data": {
   *      "type": 1   参考 EUserType
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getUserType: async (reqParams) => {
    let reqDatas = {
      "__ignoreFail__": true,
      "username": reqParams.username,
      "password": reqParams.password
    };
    const respDatas = await service({
      url: getUrl("auth/userType"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    let type = DataIns.checkint(DataIns.checktable(respDatas.data).type);
    setInternalAccFlag(type == EUserType.Internal);
    return respDatas;
  },

  /**
   * 账号登录 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "username": "string", [*]用户账号
   *    "password": "string", [*]密码
   *    "info": "string",     [+]透传信息
   *    "type": 0,            [+]登录模式 0账号 1手机
   * }
   */
  /** response {
   *    "data": {
   *        "rechargeCommissionConf": {           充值返佣配置
   *         "minmoney": 0,                       最低充值金额
   *         "rate": 0                            10 表示10%返佣
   *        },
   *        "commissionRate": {                   代理佣金返佣比例
   *            "level1": 0,
   *            "level2": 0,
   *            "level3": 0
   *        },
   *        "platBaseConf": {
   *            "CommissionMoneyCodeRatio": 0,    邀请奖励打码倍率 0
   *            "RechargeMoneyCodeRatio": 0,      充值打码倍率 1.5
   *            "SendMoneyCodeRatio": 0,          玩家奖金打码倍率 10
   *            "TaxRate": 0,                     税收 0.05
   *            "TodayRechargeFixedCnt": 0,       固额 -日n次 0
   *            "TodayRechargeRangeCnt": 0        一日3次机会(充值范围) 3
   *        },
   *        "player": {
   *            "agent_time": "string",           成为代理时间
   *            "allteamcount": 0,                团队人数(所有下级人数)
   *            "avatar": "string",               *头像
   *            "chavepix": "string",             *PIX:pix钥匙
   *            "cpf": "string",                  *PIX:个人税号
   *            "email": "string",                *PIX:email
   *            "pixname": "string",              *PIX:姓名
   *            "telefone": "string",             *PIX:电话号码
   *            "commissionincome": 0,            佣金收益总额
   *            "create_time": "string",          注册时间
   *            "awardstatus": 0,                 奖励派发状态
   *            "boxawardlocation": 0,            宝箱奖励位置是否领取
   *            "bonus": 0,                       *奖金钱包
   *            "currentmoney": 0,                *余额
   *            "diffmoney": 0,                   充提差 充值-提现
   *            "fristrecharge_time": "string",   首充时间 【"2023-06-07 17:26:48"】
   *            "highpid": 0,                     最高级代理ID
   *            "id": 0,                          *玩家ID
   *            "invitecount": 0,                 *邀请人数(直推人数)
   *            "isdebug": 0,                     是否测试 1测试
   *            "istodayrecharge": 0,             当日充值贡献出拉新佣金
   *            "last_time": "string",            最后登录时间 【"2023-06-12 19:38:25"】
   *            "lastip": "string",               最后登录ip
   *            "level": 0,                       会员等级
   *            "lockmoney": 0,                   锁定余额
   *            "logintype": "string",            登录类型 谷歌登录 google
   *            "needplaymoney": 0,               *需要打码量
   *            "offlinemoney": 0,                人工操作金额
   *            "opneedplaymoney": 0,             *增加或减少打码量
   *            "password": "string",             密码【加密】
   *            "phone": "string",                电话号码
   *            "pid": 0,                         上级ID
   *            "playcount": 0,                   下单次数
   *            "playmoney": 0,                   *流水下单累计金额/当前打码量
   *            "regip": "string",                注册IP
   *            "status": 0,                      状态 1正常 3 禁止登录
   *            "showid": 0,                      *虚拟号显示用户ID
   *            "systemsendmoney": 0,             系统赠送金额
   *            "threeteamcount": 0,              下级人数3级
   *            "total_recharge": 0,              *累计充值
   *            "total_withdrawal": 0,            *累计提现
   *            "username": "string",             *用户账号
   *            "uuid": "string",                 *邀请码
   *            "vipawardlocation": 0,            *vip奖励,根据位计算该等级vip奖励是否领取
   *            "winmoney": 0,                    中奖金额(正加负减)
   *            "withdrawalcount": 0,             *成功提现次数
   *            "wpassword": "string",            *提现密码
   *            "wstatus": 0                      *提现状态 1正常 3 禁止提现
   *            "wvip": 0,                        *提现VIP等级 -1表示未设置
   *        },
   *        "token": "string"                     *验证token
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  userLogin0(reqParams, callFunc, failFunc) {
    this.getUserType(reqParams, () => {
      this.gotoLogin(reqParams, callFunc, failFunc);
    }, () => {
      this.gotoLogin(reqParams, callFunc, failFunc);
    });
  },
  gotoLogin0(reqParams, callFunc, failFunc) {
    let reqDatas = {
      "username": reqParams.username,
      "password": reqParams.password,
      "vcode": DataIns.checkstring(reqParams.vcode),
      "info": JSON.stringify(DeviceMgr.getDeviceInfo()),
      "type": DataIns.checkint(reqParams.type)
    }
    let self = this;
    this.sendRequest("auth/login", reqDatas, (data) => {
      self.loginSuccessCallback(reqDatas, data, callFunc, failFunc);
    }, (data) => {
      failFunc && failFunc();
      self.showLog("Login fail!", data);
    });
  },
  userLogin: async (reqParams) => {
    await netApi.getUserType(reqParams);
    return await netApi.gotoLogin(reqParams);
  },
  gotoLogin: async (reqParams) => {
    let reqDatas = {
      "__isAuth__": true,
      "username": reqParams.username,
      "password": reqParams.password,
      "vcode": DataIns.checkstring(reqParams.vcode),
      "info": JSON.stringify(DeviceMgr.getDeviceInfo()),
      "type": DataIns.checkint(reqParams.type)
    };
    const respDatas = await service({
      url: getUrl("auth/login"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    await netApi.loginSuccessCallback(reqDatas, respDatas);
    return respDatas;
  },

  // 登录成功回调
  loginSuccessCallback: async (reqParams, response) => {
    // 记录用户登录信息
    store.commit("setLoginMode", DataIns.checkint(reqParams.type));
    store.commit("setUserAccount", DataIns.checkstring(reqParams.username));
    store.commit("setUserPwd", DataIns.checkstring(reqParams.password));

    // 记录用户请求登录信息
    let localData = DataIns.checktable(response);
    localData.user = {
      // secret: rich.base64.encode(rich.base64.encode(reqParams.password)),
      thirdLogin: DataIns.checkbool(reqParams.thirdLogin)
    }
    // 是否为虚拟账号标记
    localData.internal = getInternalAccFlag();
    store.commit("setLocalLastUser", localData);
    await netApi.tryLoadLocalData();
    await netApi.autoRequestConfData();
  },

  tryLoadLocalData: async (force, callFunc) => {
    const localData = computed(() => store.state.localLastUser);
    // console.warn("====== token:", localData.value?.token)
    // console.warn("====== force:", force)
    // console.warn("====== callFunc:", callFunc)
    if (!localData.value) {
      callFunc && callFunc();
      return;
    }
    // 记录到数据缓存
    let userData = DataIns.checktable(localData.value?.user);
    let playerData = DataIns.checktable(localData.value?.player);
    // cc.sys.localStorage.setItem("lg_userInfo", JSON.stringify(playerData));
    store.commit("setUserInfo", playerData);

    // 是否为虚拟账号标记
    setInternalAccFlag(DataIns.checkbool(localData.value?.internal));

    // 初始化用户数据
    UserCtrl.initCtrl();
    UserCtrl.loadLocalData();

    // 记录是否三方登录，目前默认三方登录为Google登录
    let thirdLogin = DataIns.checkbool(userData?.thirdLogin);
    UserCtrl.setThirdLogin(thirdLogin);
    store.commit("setToken", localData.value?.token);
    !force && UserCtrl.setIsLogin(true);
    await netApi.saveLocalData(localData, true);
    callFunc && callFunc();
  },

  autoRequestConfData: async (callFunc) => {
    let succCall = () => {
      callFunc && callFunc();
      // 登录成功启动刷新Token定时器
      eventBus.emit(KGEvent.EVENT_START_REFRESH_TOKEN_SCHEDULE);
      // GlobalCtrl.getInstance().gotoUIHome();
    }

    netApi.tryRequestAuthConfData();

    await netApi.tryRequestConfData(succCall);
  },

  tryRequestAuthConfData: async () => {
    // 公告及滚动图
    NoticeCtrl.getNoticeDataByCache(ENoticeType.ENotice_None);
    // 活动
    EventCtrl.getEventDataByCache(EEventType.EEvent_None);
    // 获取游戏配置数据
    GameCtrl.reqGameConf();
  },

  tryRequestConfData: async (callFunc) => {
    // 初始化签到数据
    CheckinCtrl.getSigninDataByCache();
    // 获取推广拉新配置
    // PromotionCtrl.getInstance().getPullConfByCache();
    // 获取红包码
    RedPacketCtrl.initRedPacketCode();
    // 请求Vip数据
    // VipCtrl.getVipDataByCache(() => {
    //     callFunc && callFunc();
    // },);
    callFunc && callFunc();
  },

  saveLocalData: async (localData, resetCache) => {
    let playerData = DataIns.checktable(localData.value?.player);
    // let initConfData = DataIns.checktable(localData.value?.initconf);
    // let usdtExchangeConf = DataIns.checktable(initConfData.usdtExchangeConf);

    // 更新钱包数据
    // UserCtrl.walletInfo.accountName = playerData.accountname;
    // UserCtrl.walletInfo.cardNumber = playerData.cardno;
    // UserCtrl.walletInfo.bankName = playerData.bankname;
    // UserCtrl.walletInfo.branchName = playerData.iscf;
    // UserCtrl.walletInfo.bankCode = playerData.bankcode;
    // 更新PIX数据
    UserCtrl.pixInfo.chavepix = playerData.chavepix;
    UserCtrl.pixInfo.cpf = playerData.cpf;
    UserCtrl.pixInfo.email = playerData.email;
    UserCtrl.pixInfo.pixname = playerData.pixname;
    UserCtrl.pixInfo.telefone = playerData.telefone;
    // USDT兑换比例
    // UserCtrl.usdtExchangeCfg.cashToUsdt = DataIns.checknumber(usdtExchangeConf.cashexchangeUsdt);
    // UserCtrl.usdtExchangeCfg.usdtToCash = DataIns.checknumber(usdtExchangeConf.usdtexchangeCash);
    // 银行卡信息
    let bankinfo = playerData.bankinfo ? JSON.parse(playerData.bankinfo) : {};
    UserCtrl.bankInfo.account = DataIns.checkstring(bankinfo.account);
    UserCtrl.bankInfo.cardNo = DataIns.checkstring(bankinfo.cardNo);
    UserCtrl.bankInfo.bankID = DataIns.checkstring(bankinfo.bankID);

    // 今日是否有充值 默认有充值
    UserCtrl.setRechargeToday(true);

    // 记录是否绑定账号
    // let isBind = DataIns.checkbool(playerData.bind);
    // UserCtrl.setIsBind(isBind);

    // 时间差
    // let nowTime = Date.parse(new Date().toString()) / 1000;
    // let dt = responseData.serverTime - nowTime;
    // UserCtrl.setDifferenceTime(dt);

    // 重置弹窗公告
    resetCache && UserCtrl.setIsAutoOpenAgentConsulting(false);
  },

  /**
   * 获取用户基本信息 --LG--
   * @param callFunc
   * @param failFunc
   * response 同账号登录，但无返回 token
   */
  getUserInfo: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/userInfo"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    await netApi.updateLocalData(respDatas);
    await netApi.saveLocalData(respDatas);
    // 请求Vip数据
    // VipCtrl.getInstance().getVipDataByCache(() => {
    //     callFunc && callFunc();
    // },);
    return respDatas;
  },
  updateLocalData: async (respDatas) => {
    const localData = computed(() => store.state.localLastUser);
    if (!localData.value) {
      return;
    }
    let data = localData.value;
    data = ObjectIns.mergeKeep(data, respDatas);
    store.commit("setLocalLastUser", data);
    store.commit("setUserInfo", data.player);
  },

  /**
   * 退出登录 --LG--
   * @param callFunc
   * response 通用
   */
  userLogout: async (reqParams) => {
    let reqDatas = {
      // "__isAuth__": true,
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/logout"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    // 退出成功，抛出Token过期，并清空用户数据
    GlobalCtrl.cleanUserData();
    // GlobalCtrl.getInstance().gotoUILogin();
    return respDatas;
  },

  /**
   * 修改用户属性 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *    "avatar": "string",   头像
   *    "nickName": "string"  昵称
   * }
   * response 通用
   */
  modifyAttr: async (reqParams) => {
    let reqDatas = {
      "avatar": DataIns.checkstring(reqParams.avatar),      // 头像
      "nickname": DataIns.checkstring(reqParams.nickName)   // 昵称
    };
    const respDatas = await service({
      url: getUrl("user/attr"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    // 保存数据
    if (DataIns.checkstring(reqParams.avatar)) {
      UserCtrl.setAvatarIdx(reqParams.avatar);
    }
    if (DataIns.checkstring(reqParams.nickName)) {
      UserCtrl.setNickName(DataIns.checkstring(reqParams.nickName));
    }
    return respDatas;
  },

  /**
   * 刷新Token --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * response 通用
   */
  refreshToken: async (reqParams) => {
    // 是否静默刷新，是则不弹出登录界面
    let silence = DataIns.checkbool(DataIns.checktable(reqParams).silence);
    let reqDatas = {
      "__force__": silence,
      "__isRefresh__": true,
    };
    const respDatas = await service({
      url: getUrl("user/refresh"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取手机验证码 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "phone": "string"  电话号码
   * }
   */
  getVerifyCode: async (reqParams) => {
    let reqDatas = {
      "phone": reqParams.phone,
    };
    const respDatas = await service({
      url: getUrl("auth/subMessage"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取在线客服 --LG--
   * @param callFunc
   * response {
   *   "data": [
   *     {
   *       "name": "string",
   *       "url": "string"
   *     }
   *   ],
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getCustomer: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("auth/getCustomer"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取游戏列表 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "collects": [                收藏游戏列表 [已剥离，不再下发]
   *       "string"
   *     ],
   *     "games": {                   游戏类型列表 prop: PP|PG
   *       "prop": [
   *         {
   *           "gamekey": "string",   *游戏短名
   *           "gametype": "string",  *游戏类型
   *           "hotnum": 0,           *热门基本数
   *           "iconurl": "string",   *icon地址
   *           "id": 0,               *游戏自增ID
   *           "name": "string",      *游戏名字
   *           "picknum": 0,          *收藏基本数
   *           "rtp": 0,              赔率 92.22%
   *           "sort": 0,             *排序
   *           "status": 0,           *1 开启 0 关闭
   *           "platfrom": "string",  *游戏类型 PP|PG
   *         }
   *       ]
   *     }
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getGameConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    let enterHallDirect = SwitchModule.checkEnterHallDirectSwitch();
    let protocol = enterHallDirect ? "auth/game" : "user/index";
    let __ignore_internal__ = true;
    const respDatas = await service({
      url: getUrl(protocol, __ignore_internal__),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取游戏列表 新接口 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "collects": [                收藏游戏列表 [已剥离，不再下发]
   *       "string"
   *     ],
   *     "games": {                   游戏类型列表 prop: PP_SLOT | JDB_FH
   *       "prop": [
   *         {
   *           "gamekey": "string",   *游戏短名
   *           "gametype": "string",  *游戏类型
   *           "hotnum": 0,           *热门基本数
   *           "iconurl": "string",   *icon地址
   *           "id": 0,               *游戏自增ID
   *           "name": "string",      *游戏名字
   *           "picknum": 0,          *收藏基本数
   *           "rtp": 0,              赔率 92.22%
   *           "sort": 0,             *排序
   *           "status": 0,           *1 开启 0 关闭
   *           "platfrom": "string",  *游戏类型 PP|PG
   *         }
   *       ]
   *     }
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getGameConfNew: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    let __ignore_internal__ = true;
    const respDatas = await service({
      url: getUrl("auth/gameNew", __ignore_internal__),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取收藏列表 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "collects": [    收藏游戏列表
   *       "string"
   *     ]
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getGameCollect: async (reqParams) => {
    let reqDatas = {
      "__force__": SwitchModule.checkEnterHallDirectSwitch(),
      "__isGameCollect__": true,
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/getCollect"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 设置收藏游戏 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   id: 0,         游戏自增ID
   *   type: 0        操作类型 1 收藏 3 取消
   * }
   * response 通用
   */
  setGameCollect: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/setCollect"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取游戏记录 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "info": [0]  游戏记录列表，自增ID
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getGamePlayRecord: async (reqParams) => {
    let reqDatas = {
      "__force__": SwitchModule.checkEnterHallDirectSwitch(),
      "__isGamePlayRecord__": true,
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/getPlayRecord"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 设置游戏记录 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   ids: [0],  游戏自增ID列表
   * }
   * response 通用
   */
  setGamePlayRecord: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/setPlayRecord"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取游戏地址 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {  参数二选一
   *   id: 0,                 游戏自增ID
   *  "gamekey": "string"     游戏短名
   *  "isDesktopLogin": true, 是否桌面登录 不传表示 isMobileLogin == true
   *  "backUrl": "string",    返回商户地址，跳转地址[传入前端web地址]
   * }
   * response {
   *   "data": {
   *     "url": "string"  游戏地址
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getGameAddress: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    let gamekey = DataIns.checkstring(reqParams.gamekey);
    let protocol = gamekey ? "user/enterGame2" : "user/enterGame";
    const respDatas = await service({
      url: getUrl(protocol),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /************************************ 邀请宝箱 Start ************************************/
  /**
   * 获取宝箱配置 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "id": 0,             ID
   *         "level": 0,          等级
   *         "name": "string",    名称
   *         "rechagecnt": 0,     最低充值人数
   *         "sendmoney": 0       赠送金额
   *       }
   *     ]
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getBoxConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("box/conf"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取宝箱奖励 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams{
   *   "level": 0     预领取宝箱的等级
   * }
   * response 通用
   */
  getBoxAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("box/award"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    UserCtrl.addUserMoney(reqParams.sendmoney);
    return respDatas;
  },

  /**
   * 同步宝箱状态 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "boxawardlocation": 0,       宝箱领取位置，根据位计算该等级奖励是否领取
   *     "efficientinvitecount": 0    有效邀请人数（直推充值人数）
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getBoxStatus: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("box/stats"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    UserCtrl.setInviteAmounts(respDatas.efficientinvitecount);
    return respDatas;
  },
  /************************************ 邀请宝箱 End ************************************/

  /************************************ Bet投注 Start ************************************/
  /**
   * 游戏投注统计 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "todayPlayMoney": 0, 今日有效下注金额
   *     "todayWinMoney": 0,  今日派奖金额
   *     "totalPlayMoney": 0, 总有效下注金额
   *     "totalWinMoney": 0   总派奖金额
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getBetStatistics: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/betStats"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 投注每日汇总列表 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "date": "string",  日期 2020-01-01
   *   "lastid": 0,       [移动端] 最后获取ID
   *   "row": 0           每页行数 最大200 默认20
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "create_time": 0,    创建时间
   *         "date": "string",    日
   *         "play_cnt": 0,       个人投注次数
   *         "play_money": 0,     个人投注金额
   *         "play_win_money": 0  个人派奖金额
   *       }
   *     ],
   *     "total": 0               总条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getBetDailyConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/betDaily"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 投注记录 - 游戏历史记录 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "date": "string",      日期 2020-01-01
   *   "gametype": "string",  游戏类型
   *   "lastid": 0,           [移动端] 最后获取ID
   *   "platform": "string",  游戏平台，大写字母:PP||PG
   *   "row": 0               每页行数 最大200 默认20
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "action": "string",      订单状态 bet:投注中, cancelbet:取消投注, settle:完成
   *         "betAmount": 0,          投注金额
   *         "create_time": "string", 创建时间
   *         "gameName": "string",    游戏名称
   *         "platform": "string",    游戏平台名称
   *         "winAmount": 0           盈利金额
   *       }
   *     ],
   *     "total": 0                   总条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getBetRecord: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/betRecord"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ Bet投注 End ************************************/

  /************************************ VIP Start ************************************/
  /**
   * 获取vip配置，生命周期内只获取一次足矣 --LG--
   * @param reqParams 无参
   * @param callFunc
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "body": "string",        签到奖励配置 {"1":5,"2":8,...."7":10}
   *         "id": 0,                 vip索引
   *         "level": 0,              vip等级
   *         "maxdailywithdrawal": 0, 每日最高可提款金额
   *         "maxwithdrawal": 0,      最大提款金额
   *         "minwithdrawal": 0,      最低提款金额
   *         "playmoney": 0,          打码金额
   *         "rate": 0,               手续费率 5.5 表示5.5%
   *         "sendmoney": 0,          升级赠送金额
   *         "totalrecharge": 0,      累计充值金额
   *         "vipname": "string",     VIP等级名称
   *         "withdrawalcount": 0     每日提款次数
   *       }
   *     ]
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getVipConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("vip/conf"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取vip等级奖励 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "level": 0    vip等级
   * }
   * response 通用
   */
  getVipAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("vip/award"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    const sendmoney = VipCtrl.getVipDataByLv(reqParams.level).sendmoney;
    UserCtrl.addUserMoney(sendmoney);
    return respDatas;
  },
  /************************************ VIP End ************************************/

  /************************************ 签到 Start ************************************/
  /**
   * 签到界面 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *    "data": {
   *        "day": 0,         今日第N天可领取
   *        "expiredime": 0,  今日重置时间
   *        "status": 0       1 待领取 3 已领取
   *
   *        "conf": {
   *          "awards": [ 0 ],    每日奖励配置
   *          "minCoding": 0,     用户最低打码量
   *          "minRecharge": 0    用户最低充值
   *        },
   *        "playMoney": 0,       今日打码量
   *        "rechargeMoney": 0,   今日充值金额
   *        "totalMoney": 0       累积领取金额
   *    },
   *    "msg": "string",
   *    "status": 0
   * }
   */
  getCheckinIndex: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/signinIndex"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取签到奖励 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *    "data": {
   *        "addMoney": 0,    奖励金额
   *        "awardTime": 0,   领取时间
   *        "day": 0          领取第N天
   *    },
   *    "msg": "string",
   *    "status": 0
   * }
   */
  getCheckinAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/signinAward"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    UserCtrl.addUserMoney(respDatas.addMoney);
    return respDatas;
  },
  /************************************ 签到 End ************************************/

  /************************************ 团队 Start ************************************/
  /**
   * 获取团队信息 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "start": "string",    开始日期 2020-01-01
   *    "end": "string",      结束时间 2020-01-01
   * }
   */
  /**
   * response {
   *    "data": {
   *       "info": {
   *          "create_time": 0,                   创建时间
   *          "day": 0,                           日 19700101
   *          "id": 0,                            自增ID
   *          "isdebug": 0,                       1测试
   *          "level1_commission_money": 0,       直推Bet佣金金额 (暂时只算bet获取)
   *          "level1_reg_commission_money": 0,   直推拉新佣金金额
   *          "level1_win_money": 0,              直推派奖金额
   *          "level1_fristrecharge_cnt": 0,      1级首充人数
   *          "level1_member": 0,                 1级会员
   *          "level1_play_money": 0,             等级1Bet金额
   *          "level1_recharge_money": 0,         1级充值金额
   *          "level2_fristrecharge_cnt": 0,      2级首充人数
   *          "level2_member": 0,                 2级会员
   *          "level2_play_money": 0,             等级2Bet金额
   *          "level2_recharge_money": 0,         2级充值金额
   *          "level3_fristrecharge_cnt": 0,      3级首充人数
   *          "level3_member": 0,                 3级会员
   *          "level3_play_money": 0,             等级3Bet金额
   *          "level3_recharge_money": 0,         3级充值金额
   *          "month": 0,                         月 197001
   *          "team_cnt": 0,                      团队会员人数
   *          "team_commission_money": 0,         团队佣金收入(是否包含有效拉新佣金，暂时不包含处理)
   *          "team_fristrecharge_member": 0,     团队首充人数
   *          "team_play_cnt": 0,                 团队Bet次数
   *          "team_play_member": 0,              团队Bet人数
   *          "team_play_money": 0,               团队Bet金额
   *          "team_recharge_money": 0,           团队充值金额
   *          "team_win_money": 0,                团队派奖金额
   *          "team_withdrawal_money": 0,         团队提现金额
   *          "userid": 0,                        用户ID
   *          "year": 0,                          年 1970
   *       }
   *    },
   *    "msg": "string",
   *    "status": 0
   * }
   */
  getTeamConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/team"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取团队信息 新版 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   */
  /**
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "day": "string",         年月日
   *         "level1_play_money": 0,  等级1Bet金额
   *         "level2_play_money": 0   等级2Bet金额
   *       }
   *     ],
   *     "statistics": {
   *       "lastweek_level1_play_money": 0,   上周等级1Bet金额
   *       "lastweek_level2_play_money": 0,   上周等级2Bet金额
   *       "total_level1_play_money": 0,      总的等级1Bet金额
   *       "total_level2_play_money": 0       总的等级2Bet金额
   *     }
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getTeamConf2: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/team2"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取团队详情 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "lastid": 0,  最后获取ID
   *    "row": 0,     每页条数 最大200 默认20
   *    "level": 0,   等级
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "commission": 0,         佣金
   *         "create_time": "string", 创建时间
   *         "currentmoney": 0,       余额
   *         "id": 0,                 自增ID
   *         "level": 0,              等级
   *         "playmoney": 0,          Bet金额
   *         "username": "string"     用户ID
   *       }
   *     ],
   *     "total": 0                   条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getTeamDetailsConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/teamInfo"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ 团队 End ************************************/

  /************************************ Activity Start ************************************/
  /**
   * 获取所有活动公告配置 --LG--
   * @param reqParams 无参
   * @param callFunc
   * response {
   *   "data": {
   *     "result": {                  prop: 1 轮播图 2 广告
   *       "prop": [
   *         {
   *           "action": 1,           类型: 1 轮播图 2 广告
   *           "body": "string",      详情数据
   *           "detailurl": "string", 详情图片地址
   *           "iconurl": "string",   icon图片地址
   *           "id": 0,
   *           "status": 0,           1 开启 0 关闭
   *           "title": string,       标题
   *           "type": 0              类型 1 外部地址跳转 2 app内部跳转 3详情页
   *         }
   *       ],
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getActivityConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    let enterHallDirect = SwitchModule.checkEnterHallDirectSwitch();
    let protocol = enterHallDirect ? "auth/banner" : "user/banner";
    let __ignore_internal__ = true;
    const respDatas = await service({
      url: getUrl(protocol, __ignore_internal__),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 所有活动配置 --LG-- --v2_Add-- --pass--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   */
  /**
   * response {
   *   "data": {
   *     "mailer": {                  拉新奖励配置 - 信封
   *       "levels": [ 0, 1, ... ],   用户累计档位人数
   *       "minCoding": 0,            有效用户最低打码量
   *       "minRecharge": 0,          有效用户最低打码量
   *       "money": 0                 每个用户获取金额
   *     },
   *    "rechargeSendConf": {         充值金额配置
   *      "fixedRechargeSendConf": [  固额充值赠送配置
   *        {
   *          "money": 0,             固定金额
   *          "fristgiftRatio": 0,    首充: 1 百分比 2 赠送固额
   *          "fristgiftType": 0,     首充赠送比例或固定值
   *          "repeatgiftRatio": 0,   复充: 1 百分比 2 赠送固额
   *          "repeatgiftType": 0     复充赠送比例或固定值
   *        }
   *      ],
   *      "otherFristgiftRatio": 0,   其他金额首充赠送比例
   *      "otherRepeatgiftRatio": 0,  其他金额复充赠送
   *      "otherFristgiftMoney": 0,   其他金额首充开始赠送金额
   *      "otherRepeatgiftMoney": 0,  其他金额复充开始赠送金额
   *      "minRechaegeMoney": 0,      最低充值金额
   *    },
   *    "signin": {
   *      awards: [1, 3, 9, 15, 17, 27, 47],  签到奖励
   *      minCoding: 1000,            每日最低打码
   *      minRecharge: 50,            每日最低充值
   *    }
   *    "trunTableConf": {            转盘
   *      "isopen": 0,                1 开 0 关
   *      "money": 0,                 邀请可提现金额
   *      "nums": 0                   邀请人数
   *    },
   *     "vip": [                     vip等级配置 同路由 vip/conf
   *       {
   *         "body": "string",        签到奖励配置 {"1":5,"2":8,...."7":10}
   *         "id": 0,                 vip索引
   *         "level": 0,              vip等级
   *         "maxdailywithdrawal": 0, 每日最高可提款金额
   *         "maxwithdrawal": 0,      最大提款金额
   *         "minwithdrawal": 0,      最低提款金额
   *         "playmoney": 0,          打码金额
   *         "rate": 0,               手续费率 5.5 表示5.5%
   *         "sendmoney": 0,          升级赠送金额
   *         "totalrecharge": 0,      累计充值金额
   *         "vipname": "string",     VIP等级名称
   *         "withdrawalcount": 0     每日提款次数
   *       }
   *     ],
   *    "weeklyRelief": {             周返奖配置
   *      "conf": [
   *        {
   *          "playMoney": 0,         游戏金额
   *          "rate": 0               奖金百分比 2.5 表示 2.5%
   *        }
   *      ],
   *      "limitmoney": 0             每周最高返奖金额
   *    }
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getAllEventConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    let __ignore_internal__ = true;
    const respDatas = await service({
      url: getUrl("auth/allConf", __ignore_internal__),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 拉新一级用户列表 --LG-- --v2_Add-- --pass--
   * 参考 user/regPullRecord
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "start": "string",     开始时间 2000-01-01 00:00:00
   *   "end": "string",       结束时间 2000-01-01 00:00:00
   *   "lastid": 0,           [移动端] 最后获取ID
   *   "page": 0,             [PC端] 分页
   *   "playmoney": 0,        有效用户最低打码量
   *   "row": 0,              每页行数 最大200 默认20
   *   "total_recharge": 0,   有效用户总充值
   *   "userid": 0            查询的用户ID
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "create_time": "string", 创建时间
   *         "id": 0,                 用户ID
   *         "playmoney": 0,          总投注
   *         "total_recharge": 0,     总充值
   *         "username": "string"     用户账号
   *       }
   *     ],
   *     "summary": {                 数据汇总，当参数 playmoney 且 total_recharge 都为0时有数据
   *       "fristRechargeMember": 0,  首次充值成员人数
   *       "fristRechargeMoney": 0,   首次充值金额
   *       "repeatRechargeMember": 0, 重复充值成员人数
   *       "repeatRechargeMoney": 0,  重复充值金额
   *       "totalRechargeMember": 0,  总成员人数
   *       "totalRechargeMoney": 0    总充值金额
   *     },
   *     "total": 0
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventRegPullRecord: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/mailerUserList", true),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 信封宝箱状态 --LG-- --v2_Add-- --pass--
   * 参考 user/regPullStats
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "conf": {                    宝箱配置 等同于 active/allConf -> mailer
   *       "levels": [ 0, 1, ... ],   用户累计档位人数
   *       "minCoding": 0,            有效用户最低打码量
   *       "minRecharge": 0,          有效用户最低充值金额
   *       "money": 0                 每个用户获取金额
   *     },
   *
   *     ** 用户数据
   *     "efficientmember": 0,        累计邀请人数
   *     "location": [ 0, 1, ... ],   已领取的档位
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventRegPullStatus: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/mailerStatus", true),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取信封宝箱 --LG-- --v2_Add-- --pass--
   * 参考 user/mailboxAward
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "memberCount": 0 预领取的档位信封人数
   * }
   * response {
   *   "data": {
   *     "memberCount": 0
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventRegPullAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/mailboxAward", true),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 转盘状态
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "trunTableConf": {
   *       "isopen": 0,               1 开 0 关
   *       "money": 0,                邀请可提现金额
   *       "nums": 0                  邀请人数
   *     },
   *
   *     ** 用户数据
   *     ** 剩余次数=(免费次数+邀请可用次数)-(已使用免费次数+已使用邀请次数)
   *     "info": {
   *       "end_time": "string",      活动过期时间
   *       "free_times": 0,           免费次数
   *       "free_usetimes": 0,        已使用免费次数(可使用免费通过时间差计算,一日则有一次免费)
   *       "id": 0,                   转盘编号
   *       "prizes": "string",        奖金配置
   *       "start_time": "string",    活动开始时间
   *       "status": 0,               状态 1奖励已领取，放弃所有转盘次数
   *       "times": 0,                邀请可用次数
   *       "totalmoney": 0,           当前积累金额
   *       "userid": 0,               用户ID
   *       "usetimes": 0              已使用邀请次数
   *     }
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventTrunTableStatus: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/truntableStatus"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 参与转盘活动
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response 通用
   */
  getEventTrunTableOpen: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/truntableOpen"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 抽奖转盘
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "addmoney": 0    中奖金额
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventTrunTableLottery: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/turnTableLottery"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取转盘奖励
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response 通用
   */
  getEventTrunTableAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/turnTableAward"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 周返奖奖励状态
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "conf": [
   *       {
   *         "playMoney": 0,      游戏金额
   *         "rate": 0            奖金百分比 2.5 表示 2.5%
   *       }
   *     ],
   *     "limitmoney": 0,         每周最高返奖金额
   *
   *     ** 用户数据
   *     "bonus": 0,              本次奖金
   *     "isReceive": true,       是否领取本周返奖奖励
   *     "lostMoney": 0,          盈亏金额
   *     "playMoney": 0,          游戏金额
   *     "reliefmoney": 0         累积领取下注返奖金额
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventWeeklyReliefStatus: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/weeklyReliefStatus"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取周返奖奖励
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "money": 0       返奖金额
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getEventWeeklyReliefAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("active/weeklyReliefAward"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ Activity End ************************************/

  /************************************ 钱包 Start ************************************/
  /**
   * 数据查询 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *    "type": string    查询类型，类型如下:
   *                      recharge (额外下发充值次数)
   *                      withdrawal (额外下发提现次数)
   *                      不传则下发其他信息
   * }
   * response {
   *    "data": {
   *        "bonus": 0,           钱包奖金
   *        "expiredime": 0,      今日重置时间
   *        "flushPlayMoney": 0,  刷新累计有效打码
   *        "lockmoney": 0,       冻结金额
   *        "money": 0,           剩余金额
   *        "playMoney": 0,       下注有效金额
   *        "totalRecharge": 0,   累计充值
   *        "needplaymoney": 0,   需要打码量
   *        "opneedplaymoney": 0, 增加或减少打码量
   *        "recharge1Count": 0,  [recharge]充值档：100&&1000 有奖今日剩余次数
   *        "recharge2Count": 0,  [recharge]充值档：35 有奖今日剩余次数
   *        "withdrawalCount": 0, [withdrawal]今日提现次数
   *        "withdrawalMoney": 0, [withdrawal]今日提现金额
   *        "wvip": -1            提现VIP等级
   *    },
   *    "msg": "string",
   *    "status": 0
   * }
   */
  getBalance: async (reqParams) => {
    let idx = DataIns.checkint(reqParams.idx);
    let type = "";
    switch (idx) {
      case EWalletIdx.EWallet_Recharge:
        type = "recharge";
        break;
      case EWalletIdx.EWallet_Withdraw:
        type = "withdrawal";
        break;
    }
    let reqDatas = {
      "__force__": true,
      type: type
    };
    const respDatas = await service({
      url: getUrl("wallte/balance", true),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    let totalRechargeOrig = UserCtrl.getTotalRecharge();
    let totalRechargeCur = DataIns.checknumber(respDatas.totalRecharge);
    if (totalRechargeOrig != totalRechargeCur) {
      // 充值金额有变化
      UserCtrl.setRechargeToday(true);
      // TODO 待处理
      // if (RedPacketCtrl.getInstance().checkCanPop()) {
      //     RedPacketCtrl.getInstance().getRedPacketAward();
      // }
    }
    UserCtrl.setTotalRecharge(totalRechargeCur);
    UserCtrl.setUserMoney(DataIns.checknumber(respDatas.money));
    UserCtrl.setUserBonusMoney(DataIns.checknumber(respDatas.bonus));
    UserCtrl.setLockMoney(DataIns.checknumber(respDatas.lockmoney));
    UserCtrl.setPlayMoney(DataIns.checknumber(respDatas.playMoney));
    UserCtrl.setNeedPlayMoney(DataIns.checknumber(respDatas.needplaymoney));
    UserCtrl.setOpNeedPlayMoney(DataIns.checknumber(respDatas.opneedplaymoney));
    // 提现次数|提现金额
    if (type == "withdrawal") {
      UserCtrl.setWithdrawalTimes(DataIns.checknumber(respDatas.withdrawalCount));
      UserCtrl.setWithdrawalMoney(DataIns.checknumber(respDatas.withdrawalMoney));
    }
    // 提现VIP等级 -1表示未设置
    if (respDatas.wvip != undefined) {
      UserCtrl.setWalletVipLevel(DataIns.checkint(respDatas.wvip));
    }
    return respDatas;
  },

  /**
   * 奖金钱包兑换
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "money": number   兑换金额
   * }
   * response {
   *    "data": {
   *        "bonus": 0,           剩余奖金
   *        "currentmoney": 0,    剩余金额
   *    },
   *    "msg": "string",
   *    "status": 0
   * }
   */
  walletExchange: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("wallte/exchange"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    UserCtrl.setUserMoney(DataIns.checknumber(respDatas.currentmoney));
    UserCtrl.setUserBonusMoney(DataIns.checknumber(respDatas.bonus));
    return respDatas;
  },

  /**
   * 添加pix --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "chavepix": "string",  [+]pix钥匙
   *   "cpf": "string",       [*]个人税号
   *   "email": "string",     [+]邮箱
   *   "pixname": "string",   [+]姓名
   *   "telefone": "string"   [+]电话号码
   * }
   * response 通用
   */
  postPixInfo: async (reqParams) => {
    let reqDatas = {
      "cpf": reqParams.cpf,
      "email": reqParams.email,
      "pixname": reqParams.pixname,
      "telefone": reqParams.telefone,
      "chavepix": reqParams.chavepix,
    };
    const respDatas = await service({
      url: getUrl("wallte/pix"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    UserCtrl.pixInfo.cpf = reqParams.cpf;
    UserCtrl.pixInfo.email = reqParams.email;
    UserCtrl.pixInfo.pixname = reqParams.pixname;
    UserCtrl.pixInfo.telefone = reqParams.telefone;
    UserCtrl.pixInfo.chavepix = reqParams.chavepix;
    return respDatas;
  },

  /**
   * 商城钻石下单 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "amount": 0,           [*]付款金额
   *   "formString": true,    [+]是否返回json串，web跳转支付时使用
   *   "tick": 0,             [+]1 获取奖励
   *   "type": 0,             [+]支付方式 越南: 1 银行 2 zalo
   *   "jumpURL": string,     [+]付款成功后，跳转地址[传入前端web地址]
   * }
   * response {
   *    "data": {
   *        "base64": "string",       二维码base64
   *        "formString": "string",   直接跳转页
   *        "payURL": "string"        支付地址
   *        "qrCode": "string"        二维码
   *        "sn": "string"            订单号
   *    },
   *    "msg": "string",
   *    "status": 0
   * }
   */
  rechargeOrder: async (reqParams) => {
    let reqDatas = {
      "amount": DataIns.checknumber(reqParams.amount),
      "tick": DataIns.checkint(reqParams.tick),
      "type": DataIns.checkint(reqParams.type),
      "jumpURL": DataIns.checkstring(reqParams.jumpURL),
    };
    // TODO 渠道分开处理
    // 没有开启Google支付时，走Web支付
    // 测试服只走下单流程，再后台进行补单
    // const debugChannel = CfgAnalyze.isDebugChannel();
    // if (!ChannelMgr.getGooglePayOn() && !debugChannel) {
    //     this.rechargeOrderByWeb(this.getUrl(protocol), data);
    //     return;
    // },
    const respDatas = await service({
      url: getUrl("wallte/trade"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  // rechargeOrderByWeb(url, reqParams) {
  //     const headers = this.getHeaders();
  //     delete headers["Token"];
  //     reqParams["formString"] = true; // 服务器返回字符串数据
  //     rich.log("** rechargeOrderByWeb reqParams:", this.formatParams(reqParams));
  //     rich.log("** rechargeOrderByWeb headers:", this.formatParams(headers));
  //     let query = rich.base64.encode(`url=${url}&${this.formatParams(headers)}&${this.formatParams(reqParams)}`);
  //     rich.log("try recharge order query:" + query, rich.base64.decode(query))

  //     const webBaseUrl = MaytooSdk.getWebBaseUrl();
  //     rich.log("try recharge order webBaseUrl:" + webBaseUrl)
  //     if (webBaseUrl) {
  //         rich.eventManager.sendEvent(KGEvent.EVENT_CHECK_DIAMOND_STATE, true);
  //         MaytooSdk.openURL(`${webBaseUrl}\\order.html?${query}`);
  //     }
  //     else {
  //         if (SwitchModule.checkPurchaseH5Switch()) {
  //             rich.eventManager.sendEvent(KGEvent.EVENT_CHECK_DIAMOND_STATE, true);
  //             window.open(`${webBaseUrl}\\order.html?${query}`, '_blank');
  //         }
  //         else {
  //             // 页面不存在
  //             rich.alert.show("Page does not exist!");
  //         }
  //     }
  // },

  /**
   * 添加银行卡信息 --New--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "bkAccount": "string",   [*]银行卡账号
   *   "bkCardNo": "string",    [+]银行卡号
   *   "bkBankID": "string",    [*]银行名称ID
   * }
   * response 通用
   */
  postBankInfo: async (reqParams) => {
    let reqDatas = {
      "bankinfo": {
        "account": reqParams.bkAccount,
        "cardNo": reqParams.bkCardNo,
        "bankID": reqParams.bkBankID,
      },
    };
    const respDatas = await service({
      url: getUrl("wallte/setBank"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    UserCtrl.pixInfo.account = reqParams.bkAccount;
    UserCtrl.pixInfo.cardNo = reqParams.bkCardNo;
    UserCtrl.pixInfo.bankID = reqParams.bkBankID;
    return respDatas;
  },

  /**
   * 添加Usdt地址 --New--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "addr": "string"           [*]U地址
   * }
   * response 通用
   */
  postUsdtInfo: async (reqParams) => {
    let reqDatas = {
      "addr": reqParams.addr,
    };
    const respDatas = await service({
      url: getUrl("user/bindusdt"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    UserCtrl.setUsdtAddr(reqParams.addr);
    return respDatas;
  },

  /**
   * 设置提现密码 --New-- --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "oldpassword": "string",   [+]旧密码 修改密码必填
   *   "password": "string",      [*]密码
   *   "nextpassword": "string",  [*]确认密码
   * }
   * response 通用
   */
  setWidthrawalPwd: async (reqParams) => {
    let reqDatas = {
      "password": reqParams.password,
      "nextpassword": reqParams.nextpassword,
    };
    let oldpassword = reqParams.oldpassword;
    if (oldpassword) {
      reqDatas["oldpassword"] = oldpassword;
    }
    const respDatas = await service({
      url: getUrl("wallte/setWpass"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    UserCtrl.setWalletPassword("*");
    return respDatas;
  },

  /**
   * 发起提现 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "money": 0,            提现金额
   *   "password": "string",  提现密码
   *   "type": 0,             提现类型 0 余额 1 奖金
   * }
   * response 通用
   */
  postWithdrawal: async (reqParams) => {
    let reqDatas = {
      "money": reqParams.money,
      "password": reqParams.password,
      "type": reqParams.type,
    };
    const respDatas = await service({
      url: getUrl("wallte/withdrawal"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    // 清空提现VIP等级
    UserCtrl.setWalletVipLevel(-1);
    return respDatas;
  },
  postWithdrawalWithPix: async (reqParams) => {
    let wtype = reqParams.wtype;
    let wtypeStr = "";  // cpf, cnpj, email, phone
    if (wtype != undefined) {
      wtype = DataIns.checkint(wtype);
      switch (wtype) {
        case EPixBindType.EPBT_CPF:
          wtypeStr = "cpf";
          break;
        case EPixBindType.EPBT_CNPJ:
          wtypeStr = "cnpj";
          break;
        case EPixBindType.EPBT_PHONE:
          wtypeStr = "phone";
          break;
        case EPixBindType.EPBT_EMALI:
          wtypeStr = "email";
          break;
      }
    }
    let reqDatas = {
      "money": reqParams.money,
      "password": reqParams.password,
      "type": reqParams.type,
      "wtype": wtypeStr,
      "cpf": reqParams.cpf,
      "email": reqParams.email,
      "pixname": reqParams.pixname,
      "telefone": reqParams.telefone,
      "chavepix": reqParams.chavepix,
      "version": CfgAnalyze.getApiPackVer()   // 0本次额度, 1当日限额
    };
    const respDatas = await service({
      url: getUrl("wallte/withdrawal2"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    // 清空提现VIP等级
    UserCtrl.setWalletVipLevel(-1);
    UserCtrl.pixInfo.cpf = reqParams.cpf;
    UserCtrl.pixInfo.email = reqParams.email;
    UserCtrl.pixInfo.pixname = reqParams.pixname;
    UserCtrl.pixInfo.telefone = reqParams.telefone;
    UserCtrl.pixInfo.chavepix = reqParams.chavepix;
    // 记录最后提现类型
    if (wtype != undefined) {
      // cc.sys.localStorage.setItem(`lg_pixtype_${UserCtrl.username}`, wtype);
      store.commit("setPixType", wtype);
    }
    return respDatas;
  },

  /**
   * 银行卡提现
   * @param {*} reqParams
   * @returns
   * reqParams {
   *   "money": 0,              提现金额
   *   "password": "string",    提现密码
   *   "version": 1,            提现版本，默认传1
   *   "bkAccount": "string",   [*]银行卡账号
   *   "bkCardNo": "string",    [+]银行卡号
   *   "bkBankID": "string",    [*]银行名称ID
   * }
   * response 通用
   */
  postWithdrawalWithBank: async (reqParams) => {
    let reqDatas = {
      "money": DataIns.checknumber(reqParams.money),
      "password": reqParams.password,
      "bankinfo": {
        "account": reqParams.bkAccount,
        "cardNo": reqParams.bkCardNo,
        "bankID": reqParams.bkBankID,
      },
      "version": CfgAnalyze.getApiPackVer()   // 0本次额度, 1当日限额
    };
    const respDatas = await service({
      url: getUrl("wallte/withdrawal3"),
      method: 'post',
      data: encodeReqData(reqDatas)
    })
    // 清空提现VIP等级
    UserCtrl.setWalletVipLevel(-1);
    UserCtrl.pixInfo.account = reqParams.bkAccount;
    UserCtrl.pixInfo.cardNo = reqParams.bkCardNo;
    UserCtrl.pixInfo.bankID = reqParams.bkBankID;
    return respDatas;
  },
  /************************************ 钱包 End ************************************/

  /************************************ 虚拟数据 Start ************************************/
  /**
   * 中奖滚动数据 --LG-- --v2_Add-- --pass--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *    "data": {
   *      "list": [
   *        {
   *          "money1": 0,
   *          "money2": 0,
   *          "multiple": 0,
   *          "userid": 0,
   *          "username": "string"
   *        }
   *      ]
   *    },
   *    "msg": "string",
   *    "status": 0
   *  }
   */
  getUserRewardScrollData: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("auth/scroll"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    // 过滤掉自己
    // let list = DataIns.checkarray(DataIns.checktable(respDatas).list);
    // for (let i = list.length - 1; i >= 0; i--) {
    //     if (UserCtrl.getUserId() === list[i].userid) {
    //         list.splice(i, 1);
    //         break;
    //     }
    // }
    // rich.eventManager.sendEvent(KGEvent.EVENT_USER_REWARD_DATA_RECEIVE, list);
    // return list;
    return respDatas;
  },

  /**
   * 滚动奖池 --LG-- --v2_Add-- --pass--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "info": {
   *       "endscore": 0, 最终数值
   *       "scores": [    递增到最终数值过程值
   *         0
   *       ]
   *     }
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getJackpotScrollData: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("auth/jackpotScroll"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    // TODO 待处理
    // rich.eventManager.sendEvent(KGEvent.EVENT_JACKPOT_REWARD_DATA_RECEIVE, response.data);
    return respDatas;
  },
  /************************************ 虚拟数据 end ************************************/

  /************************************ Record Start ************************************/
  /**
   * 资金记录 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "lastid": 0,           [移动端] 最后获取ID
   *   "row": 0,              每页行数 最大200 默认30
   *   "start": "string",     开始时间 2020-01-01
   *   "end": "string",       结束时间 2020-01-02
   *   "type": 0,             类型 1收入 2支出 0全部
   *   "source": "string",    资金来源 0余额 1奖金 不传全部
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "money": 0,              创建时间
   *         "create_time": "string", 操作金额
   *         "type": "string",        1 充值 ...
   *         // "info": "string",        记录信息，json数据，数据字段与后端约定
   *       }
   *     ],
   *     "total": 0               总条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRecordBonus: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("wallte/record2"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 用户充值订单 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "lastid": 0,       [移动端] 最后获取ID
   *   "row": 0,          每页行数 最大200 默认30
   *   "start": "string", 开始时间 2020-01-01
   *   "end": "string",   结束时间 2020-01-02
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "money": 0,          下单金额
   *         "sn": "string",      平台订单号
   *         "status": 0,         订单状态 0 等待支付 1 支付成功 3 支付失败
   *         "time": "string",    下单时间
   *       }
   *     ],
   *     "total": 0               总条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRecordOrder: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("wallte/rechargeOrder"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 提现订单 --LG--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "lastid": 0,       [移动端] 最后获取ID
   *   "row": 0,          每页行数 最大200 默认30
   *   "start": "string", 开始时间 2020-01-01
   *   "end": "string",   结束时间 2020-01-02
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "money": 0,          下单金额
   *         "sn": "string",      平台订单号
   *         "status": 0,         订单状态 0 待审核 1 审核通过 3 拒绝提现  5 提现中 7 提现成功 9 提现失败
   *         "time": "string",    下单时间
   *       }
   *     ],
   *     "total": 0               总条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRecordWithdrawalOrder: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("wallte/withdrawalOrder"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 任务订单 --New--
   * @param reqParams
   * @param callFunc
   * reqParams {
   *   "isover": "string",    是否下发奖励订单 1(连单最后一单或者随机单) （只会出现在连单的时候, 0则未直接下发奖励连单）
   *   "page": 0,             分页
   *   "row": 0,              每页行数 最大200 默认30
   *   "status": "string"     返还状态 0 未返还 1 返还
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {                            订单信息
   *         "create_time": "string",   创建时间
   *         "id": 0,                   单号ID
   *         "img": "string",           产品图片地址
   *         "incomemoney": 0,          收益金额USDT
   *         "isdebug": 0,              是否测试 1测试
   *         "isover": 0,               1 需立即结算订单 (如果余额不足则立即单子不返现,需玩家充值后领取)
   *         "iswait": 0,               0 正常 1 代办
   *         "productid": 0,            产品ID
   *         "productmoney": 0,         产品价格USDT
   *         "rounds": 0,               第几轮领取
   *         "sn": "string",            订单号
   *         "status": 0,               0 未返现 1返现
   *         "title": "string",         产品标题
   *         "type": 0,                 0 单单 1 连单
   *         "update_time": "string",   最后更改时间
   *         "userid": 0,               用户ID
   *         "username": "string"       用户账号
   *       }
   *      ],
   *      "total": 0               总条数
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRobTaskList: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("record/task"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ Record End ************************************/

  /************************************ 拉新奖励 Start ************************************/
  /**
   * 拉新佣金配置 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": [          奖励配置
   *     {
   *       "max": 0,      最高人数
   *       "min": 0,      最低人数
   *       "money": 0     单人奖励
   *     }
   *   ],
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRegInviteConf: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/getRegInviteAward"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 拉新奖励记录 (只下发实际已经派奖佣金的历史记录) --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "lastid": 0,   最后获取ID
   *   "row": 0       每页行数 最大200 默认20
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "cnt": 0,        新增今日有效充值人数
   *         "day": "string", 日期
   *         "id": 0,         自增ID
   *         "sendmoney": 0,  系统派送拉新佣金
   *         "status": 0      -1 冻结 0 未发放 1 已发放 2 无奖励
   *       }
   *     ]
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRegPullRecord: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/regPullRecord"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 拉新奖励状态值 --LG--
   * @param reqParams 无参
   * @param callFunc
   * @param failFunc
   * response {
   *   "data": {
   *     "conf": {
   *       "levels": [ 0, 1, ... ],   用户累计档位人数
   *       "money": 0                 每个用户获取金额
   *     },
   *     "efficientmember": 0,        累计邀请人数
   *     "lightmember": 0,            累计激活人数
   *     "location": [ 0, 1, ... ],   已领取的档位
   *     "todaycnt": 0,       今日充值人数
   *     "todaymoney": 0,     预计派发注册佣金
   *     "yesterdaycnt": 0,   昨日充值人数
   *     "yesterdaymoney": 0  昨日派发奖励
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRegPullStatus: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/regPullStats"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 领取拉新档位奖励
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "memberCount": 0 预领取的档位信封人数
   * }
   * response {
   *   "data": {
   *     "memberCount": 0
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRegPullAward: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/mailboxAward"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ 拉新奖励 End ************************************/

  /************************************ 充值奖励 Start ************************************/
  /**
   * 充值奖励记录 - 只下发实际已经派奖佣金的历史记录
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *   "lastid": 0,   最后获取ID
   *   "row": 0       每页行数 最大200 默认20
   * }
   * response {
   *   "data": {
   *     "list": [
   *       {
   *         "day": "string", 日期
   *         "id": 0,         自增ID
   *         "sendmoney": 0,  系统派送拉新佣金
   *         "status": 0,     1 表示系统已经派奖
   *         "validmoney": 0  今日充值金额
   *       }
   *     ]
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRechargeMoneyRecord: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/rechargeMoneyRecord"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ 充值奖励 End ************************************/

  /************************************ 红包 Start ************************************/
  /**
   * 领取红包 --LG--
   * @param reqParams
   * @param callFunc
   * @param failFunc
   * reqParams {
   *    "code": "string"   红包码
   *  }
   *  response {
   *    "data": {
   *     "money": 0       领取红包金额
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getRedPacket: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/redpacket/receive"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
  /************************************ 红包 End ************************************/

  /**
   * 图片上传 --New-- --LG--
   * @param reqParams 字节流
   * @param callFunc
   * response{
   *   "url": "string"    图片地址
   * }
   */
  uploadPicture: async (reqParams) => {
    let errorCall = () => {
      // TODO 待处理
      // rich.eventManager.sendEvent(KGEvent.EVENT_IMAGE_BUFFER_RECEIVE, {
      //     code: data
      // });
    }
    let reqDatas = {
      "__errorCall__": errorCall.toString(),
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("picture/binary"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * USDT支付凭证
   * @param reqParams
   * @param callFunc
   * reqParams {
   *    "u": 0,           u数量
   *    "url": "string"   图片地址
   * }
   * response{
   *   "data": {
   *     "formString": "string",  直接跳转页
   *     "payURL": "string",      支付地址
   *     "qrCode": "string",      二维码
   *     "sn": "string"           订单号
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  sumbitUsdtPayment: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/tradeUsdt"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },

  /**
   * 获取充值Usdt地址
   * @param reqParams 无参
   * @param callFunc
   * response {
   *   "data": {
   *     "addr": "string"
   *   },
   *   "msg": "string",
   *   "status": 0
   * }
   */
  getUsdtPaymentAddr: async (reqParams) => {
    let reqDatas = {
      ...reqParams
    };
    const respDatas = await service({
      url: getUrl("user/getUsdtAddr"),
      method: 'post',
      data: encodeReqData(reqDatas)
    });
    return respDatas;
  },
}

/**
 * API interface
 */
const defaultParam = {
  language: "vn",
  version: "3.6.1",
}

const reqApi = {

  userLogin: (data) => postData("/login", { ...defaultParam, ...data }),

  userLogout: (data) => postAuthorized("/disconnect", { ...defaultParam, ...data }),

  userRegister: (data) => postData("/register", { ...defaultParam, ...data }),

  getHomeInfo: (data) => postData("/home_info", { ...defaultParam, ...data }),

  getAppSettings: (data) => postData("/app_settings", { ...defaultParam, ...data }),

  getAllGamesInfo: (data) => postData("/game/get_all_games", { ...defaultParam, ...data }),

  getAllCategorysInfo: (data) => postData("/game/get_all_category", { ...defaultParam, ...data }),

  getAllProvidersInfo: (data) => postData("/game/get_all_provider", { ...defaultParam, ...data }),

  getTestingGameUrl: (data) => postData("/game/testing_game_url", { ...defaultParam, ...data }),

  getTransferGameUrl: (data) => postAuthorized("/game/transfer_and_get_game_url", { ...defaultParam, ...data }),

  getTransferSportGameUrl: (data) => postAuthorized("/game/transfer_and_get_sport_url", { ...defaultParam, ...data }),

  getGameHistory: (data) => postAuthorized("/game/get_game_history", { ...defaultParam, ...data }),

  getMemberProfile: (data) => postAuthorized("/member/profile", { ...defaultParam, ...data }),

  getAvatarList: (data) => postAuthorized("/member/avatar_list", { ...defaultParam, ...data }),

  saveAvatar: (data) => postAuthorized("/member/avatar_save", { ...defaultParam, ...data }),

  changePassword: (data) => postAuthorized("/member/change_password", { ...defaultParam, ...data }),

  getDownlineDepositHistory: (data) => postAuthorized("/member/downline_deposit_history", { ...defaultParam, ...data }),

  getTransactionHistory: (data) => postAuthorized("/member/transaction_history", { ...defaultParam, ...data }),

  getDepositInfo: (data) => postAuthorized("/deposit/info", { ...defaultParam, ...data }),

  getWithdrawInfo: (data) => postAuthorized("/withdraw/info", { ...defaultParam, ...data }),

  submitDeposit: (data) => postAuthorized("/deposit/submit", { ...defaultParam, ...data }),

  submitWithdraw: (data) => postAuthorized("/withdraw/submit", { ...defaultParam, ...data }),

  bindWithdrawWallet: (data) => postAuthorized("/withdraw/bind_wallet", { ...defaultParam, ...data }),

  getRebateList: (data) => postAuthorized("/rebate/list", { ...defaultParam, ...data }),

  claimRebate: (data) => postAuthorized("/rebate/claim", { ...defaultParam, ...data }),

  getBonusList: (data) => postAuthorized("/bonus/list", { ...defaultParam, ...data }),

  transferWallet: (data) => postAuthorized("/bonus/transfer_wallet", { ...defaultParam, ...data }),

  getAgentDownlineDashboard: (data) => postAuthorized("/agent/downline_dashboard", { ...defaultParam, ...data }),

  getAgentDownlineList: (data) => postAuthorized("/agent/downline_list", { ...defaultParam, ...data }),

  getAllAgentRewardList: (data) => postAuthorized("/agent/all_agent_reward", { ...defaultParam, ...data }),

  claimAgentReward: (data) => postAuthorized("/agent/agent_reward_claim", { ...defaultParam, ...data }),

  getCashbackInfo: (data) => postAuthorized("/cashback/info", { ...defaultParam, ...data }),

  claimCashback: (data) => postAuthorized("/cashback/claim", { ...defaultParam, ...data }),

  getPromotionSettings: (data) => postAuthorized("/promotion_settings", { ...defaultParam, ...data }),

  getSevenDayInfo: (data) => postAuthorized("/seven_day/info", { ...defaultParam, ...data }),

  claimSevenDay: (data) => postAuthorized("/seven_day/claim", { ...defaultParam, ...data }),

  getReferralInfo: (data) => postAuthorized("/referral/info", { ...defaultParam, ...data }),

  clickReferral: (data) => postAuthorized("/referral/click", { ...defaultParam, ...data }),

  startReferral: (data) => postAuthorized("/referral/start", { ...defaultParam, ...data }),

  endReferral: (data) => postAuthorized("/referral/end", { ...defaultParam, ...data }),

  claimReferral: (data) => postAuthorized("/referral/claim", { ...defaultParam, ...data }),
}

export { reqApi, netApi }
