/**
 * 红包数据及逻辑模块
 */

import { CfgAnalyze, SwitchModule } from "@/lib/CfgAnalyze";
import { GFunc } from "@/lib/GFunc";
import { UserCtrl } from "./UserCtrl";
import { DataIns } from "@/lib/DataIns";
import { KGEvent } from "@/api/KGEvent";
import eventBus from "@/eventBus";
import { computed } from "vue";
import store from "@/store";
import { netApi } from "@/api";

const ERedPacket = {
    ERP_None: 0,               // 无状态
    ERP_Get: 1,                // 可领取
    ERP_Received: 2,           // 已领取
    ERP_End: 200030,           // 活动结束
    ERP_NotEnough: 200031,     // 领光了
    ERP_NotRecharge: 200032,   // 未充值
    ERP_NotTimes: 200033,      // 今日已参与
}

const rpCodesInfo = computed(() => store.state.rpCodesInfo);

class _RedPacketCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听
    rpCode = "";            // 红包码
    canAutoPop = true;      // 是否可以主动弹出

    constructor() {
        GFunc.log("RedPacketCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("RedPacketCtrl initCtrl");
    }

    initModel() {
        GFunc.log("RedPacketCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
    }

    // 初始化代理数据
    // 不要在这里清空红包码
    initData() {
    }

    // 本地缓存的红包码记录
    /*static*/ getRedPacketCodes() {
        let packtype = CfgAnalyze.getPackType();
        let userid = UserCtrl.getUserId();
        // let rpCodes = JSON.parse(cc.sys.localStorage.getItem(`lg_rpcode_${packtype}_${userid}`)) || {};
        let rpCodes = DataIns.checktable(rpCodesInfo.value[`${packtype}_${userid}`]);
        return rpCodes;
    }

    // 获取未领取的红包码
    /*static*/ getEnableRedPacketCode() {
        let rpCodes = this.getRedPacketCodes();
        let rpCode = "";
        let search = GFunc.getWinLocation("search");
        let keyRPCode = CfgAnalyze.getKeyRPCodeKey();
        // 带有红包码的链接，从链接中获取红包码是否可领取
        if (search && GFunc.getQueryString(keyRPCode)) {
            rpCode = GFunc.getQueryString(keyRPCode);
            let status = DataIns.checkint(rpCodes[rpCode]);
            // 大于1表示红包码不可用
            if (status > ERedPacket.ERP_Get) {
                rpCode = "";
            }
        }
        // 未带红包码的链接，从本地缓存中获取可用的红包码
        else {
            let keys = Object.keys(rpCodes);
            for (let i = 0; i < keys.length; i++) {
                if (rpCodes[keys[i]] == ERedPacket.ERP_Get) {
                    rpCode = keys[i];
                    break;
                }
            }
        }
        return rpCode;
    }

    // 保存红包码到本地缓存
    /*static*/ saveRedPacketCode(status) {
        if (!this.rpCode || !rpCodesInfo.value) {
            return;
        }
        let packtype = CfgAnalyze.getPackType();
        let userid = UserCtrl.getUserId();
        let rpCodes = this.getRedPacketCodes();
        rpCodes[this.rpCode] = status;
        // cc.sys.localStorage.setItem((`lg_rpcode_${packtype}_${userid}`), JSON.stringify(rpCodes));
        rpCodesInfo.value[`${packtype}_${userid}`] = rpCodes;
        store.commit("setRPCodes", DataIns.checktable(rpCodesInfo.value));
    }

    // 设置所有可领取的红包码为参与过
    /*static*/ setRedPacketAgainReceived() {
        let packtype = CfgAnalyze.getPackType();
        let userid = UserCtrl.getUserId();
        let rpCodes = this.getRedPacketCodes();
        let keys = Object.keys(rpCodes);
        for (let i = 0; i < keys.length; i++) {
            if (rpCodes[keys[i]] == ERedPacket.ERP_Get) {
                rpCodes[keys[i]] = ERedPacket.ERP_NotTimes;
            }
        }
        // cc.sys.localStorage.setItem((`lg_rpcode_${packtype}_${userid}`), JSON.stringify(rpCodes));
        rpCodesInfo.value[`${packtype}_${userid}`] = rpCodes;
        store.commit("setRPCodes", DataIns.checktable(rpCodesInfo.value));
    }

    // 清除红包码及状态
    /*static*/ cleanRedPacketCode(status) {
        this.saveRedPacketCode(status);
        if (status === ERedPacket.ERP_Received || status === ERedPacket.ERP_NotTimes) {
            this.setRedPacketAgainReceived();
        }
        this.rpCode = "";
    }

    // 记录红包码，需要在有用户数据的状态下调用
    initRedPacketCode() {
        this.rpCode = this.getEnableRedPacketCode();
        this.saveRedPacketCode(ERedPacket.ERP_Get);
    }

    checkAutoPop() {
        if (!SwitchModule.checkRedPacketSwitch()) {
            return false;
        }
        if (!UserCtrl.getIsLogin()) {
            return false;
        }
        if (!UserCtrl.getRechargeToday()) {
            return false;
        }
        if (!this.canAutoPop) {
            return false;
        }
        if (!this.rpCode) {
            return false;
        }
        return true;
    }

    checkCanPop() {
        if (!SwitchModule.checkRedPacketSwitch()) {
            return false;
        }
        if (!UserCtrl.getIsLogin()) {
            return false;
        }
        if (!UserCtrl.getRechargeToday()) {
            return false;
        }
        if (!this.rpCode) {
            return false;
        }
        return true;
    }

    /**
     * 领取红包
     * @param isPopStack 是否登录自动弹窗
     */
    async getRedPacketAward(isPopStack) {
        let reqParams = {
            code: this.rpCode
        }
        this.canAutoPop = false;
        try {
            const data = await netApi.getRedPacket(reqParams);
            let money = DataIns.checknumber(DataIns.checktable(data).money);
            // TODO 待处理弹窗
            // rich.alert.show(`TID_GET_RED_PACKET_SUCCESS,${GFunc.getCurrencySymbol()} ${money}`, () => {
                this.cleanRedPacketCode(ERedPacket.ERP_Received);
                UserCtrl.addUserMoney(money);
                isPopStack && eventBus.emit(KGEvent.EVENT_AUTO_POP_STACK);
            // });
        } catch (error) {
            const status = DataIns.checkint(error.status);
            if (status === ERedPacket.ERP_End || status === ERedPacket.ERP_NotEnough || status === ERedPacket.ERP_NotTimes) {
                this.cleanRedPacketCode(status);
            }
            // 今日未充值，设置用户充值状态
            else if (status === ERedPacket.ERP_NotRecharge) {
                UserCtrl.setRechargeToday(false);
            }
            isPopStack && eventBus.emit(KGEvent.EVENT_AUTO_POP_STACK);
        } finally {
            /** */
        }
    }
}

const RedPacketCtrl = new _RedPacketCtrl_();

export { RedPacketCtrl }

// DEBUG
window.RedPacketCtrl = RedPacketCtrl;
