<template>
  <a-modal
    :open="showIosModal"
    centered
    :closable="false"
    :maskClosable="true"
    class="ios-modal"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        display: 'none',
      },
    }"
  >
    <div class="ios-modal-content">
      <div class="ios-modal-content-close-icon">
        <img
          src="@/assets/common/close.png"
          alt="close"
          @click="onCloseModal"
        />
      </div>
      <div class="ios-modal-content-inner">
        <img
          src="@/assets/landing/ios_steps.png"
          alt="info"
          class="ios-modal-content-guide-pic"
        />
        <span>{{ t("TID_LB_IOS_STEPS_1") }}</span>
        <br />
        <span>{{ t("TID_LB_IOS_STEPS_2") }}</span>
        <br />
        <span>{{ t("TID_LB_IOS_STEPS_3") }}</span>
      </div>
      <div class="ios-modal-content-footer">
        <div
          class="ios-modal-content-ok-btn"
          @click="downloadApp(appInfo.ios_url)"
        >
          {{ t("TID_LB_DOWNLOAD_NOW") }}
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

// Correct event names and prop handling
defineProps({
  showIosModal: Boolean,
});

const emit = defineEmits(["onClosePopover", "onCloseModal"]);

const { t } = useI18n();
const store = useStore();

const appInfo = computed(() => store.state.appInfo);

const onCloseModal = () => {
  emit("onCloseModal");
};

const downloadApp = () => {
  const filePath = "/0038.mobileconfig";
  const link = document.createElement("a");
  link.href = filePath;
  link.download = filePath.split("/").pop();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  emit("onClosePopover");
};
</script>

<style lang="scss">
.ios-modal {
  .ant-modal-content {
    background-color: #000 !important;
    padding: 0.5rem;

    img {
      width: 100%;
      height: auto;
    }
  }
  &-content {
    &-close-icon {
      width: 100%;

      box-sizing: border-box;
      @include flex(row, flex-end, center);
      margin-bottom: 0.5rem;
      img {
        width: 30px !important;
        cursor: pointer;
        height: 30px !important;
      }
    }
    &-inner {
      width: 100%;
      box-sizing: border-box;
      text-align: left;
      @include flex(column, flex-start, flex-start);
      overflow-y: scroll;
      height: 500px;
    }
    &-footer {
      background-color: #000;
      padding: 1.2rem 0 0 0;
    }
    &-ok-btn {
      background-color: #ffeb02;
      border: 2px solid #ff9700;
      color: #000;
      font-weight: bold;
      @include flex(row, center, center);
      border-radius: 10px;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem 0;
      cursor: pointer;
      font-family: "Poppins", sans-serif;
    }
  }
}
</style>
