/**
 * 活动事件逻辑模块
 */

import { CfgAnalyze, SwitchModule } from "@/lib/CfgAnalyze";
import { EEventType } from "@/lib/Consts";
import { GFunc } from "@/lib/GFunc";
import { UserCtrl } from "./UserCtrl";
import { DataIns } from "@/lib/DataIns";
import { netApi } from "@/api";

class _EventCtrl_ {
    hadEventInit = false;  // 模块是否已经初始化事件监听
    eventData = null;
    lastOpenEventType = EEventType.EEvent_None; // 最后打开的活动分页

    constructor() {
        GFunc.log("EventCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("EventCtrl initCtrl");
    }

    initModel() {
        GFunc.log("EventCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
    }

    // 初始化活动事件数据
    initData() {
        this.eventData = null;
        this.lastOpenEventType = EEventType.EEvent_None;
    }

    getLastOpenEventType() {
        return this.lastOpenEventType;
    }
    setLastOpenEventType(eventType) {
        this.lastOpenEventType = eventType;
    }

    // 本地活动配置是否开启
    checkEventEnable(eventid) {
        let events = CfgAnalyze.getEventSwitch();
        let enable = false;
        for (let idx = 0; idx < events.length; idx++) {
            let cfg = DataIns.checkarray(events[idx]);
            if (DataIns.checkint(cfg[0]) === eventid) {
                enable = !!DataIns.checkint(cfg[1]);
                break;
            }
        }
        return enable;
    }

    // 检查是否弹出首充活动
    checkAutoPopFirstDeposit() {
        // 开关未开启
        if (!SwitchModule.checkPopFirstDepositSwitch()) {
            return false;
        }
        // 活动未开启
        if (!this.checkEventEnable(EEventType.EEvent_FirstDeposit)) {
            return false;
        }
        // 已充值
        if (UserCtrl.getTotalRecharge() > 0) {
            return false;
        }
        // TODO 待处理
        // let isLogin = UserCtrl.getIsLogin();
        // let userid = UserCtrl.getUserId();
        // // 今日不再弹出
        // let fdtoday = cc.sys.localStorage.getItem("lg_first_deposit_reminder_today");
        // fdtoday = fdtoday ? JSON.parse(fdtoday) : {};
        // let fdtodayState = isLogin ? DataIns.checknumber(fdtoday[userid]) : DataIns.checknumber(fdtoday.visitor);
        // if (fdtodayState == DateIns.getTodayEndTime()) {
        //     return false;
        // }
        // // 永久不再弹出
        // let fdforever = cc.sys.localStorage.getItem("lg_first_deposit_reminder_forever");
        // fdforever = fdforever ? JSON.parse(fdforever) : {};
        // let fdforeverState = isLogin ? DataIns.checknumber(fdforever[userid]) : DataIns.checknumber(fdforever.visitor);
        // if (fdforeverState == -1) {
        //     return false;
        // }
        // 可以弹出
        return true;
    }

    // // 根据活动类型显示页面
    // showEventView(uiData) {
    //     let eventType = DataIns.checkint(uiData.eventType);
    //     let uiName = null;
    //     switch (eventType) {
    //         case EEventType.EEvent_Vip:
    //             // uiName = UI.UI_NAME.UIEventVip;
    //             if (!UserCtrl.getIsLogin()) {
    //                 GlobalCtrl.getInstance().gotoUILogin();
    //                 return;
    //             }
    //             GlobalCtrl.getInstance().gotoUIVip();
    //             return;
    //         case EEventType.EEvent_Invite:
    //             uiName = UI.UI_NAME.UIEventInvite;
    //             break;
    //         case EEventType.EEvent_FirstDeposit:
    //             // uiName = UI.UI_NAME.UIEventDeposit;
    //             if (!UserCtrl.getInstance().isLogin) {
    //                 GlobalCtrl.getInstance().gotoUILogin();
    //                 return;
    //             }
    //             GlobalCtrl.getInstance().updateNavigationIdx(ENavigationIdx.ENav_Wallet);
    //             return;
    //         case EEventType.EEvent_TGRedemptionCode:
    //             // uiName = UI.UI_NAME.UIEventTGCode;
    //             GlobalCtrl.getInstance().jumpToAnyWhere({
    //                 type: EJumpToType.EJump_Url,
    //                 body: CfgAnalyze.getOfficialCS()
    //             });
    //             return;
    //     }
    //     GFunc.log(`eventType: ${eventType}, uiName: ${uiName}`);
    //     if (EventCtrl.getInstance().getLastOpenEventType() == eventType) {
    //         return;
    //     }
    //     uiName && EventCtrl.getInstance().getEventDataByCache(eventType, (data) => {
    //         EventCtrl.getInstance().setLastOpenEventType(eventType);
    //         switch (uiData.layout) {
    //             case EEventItemLayout.Choose:
    //                 uiData.choose({ uiName, eventType, data, modelCallFunc: uiData.modelCallFunc });
    //                 break;
    //             case EEventItemLayout.Open:
    //                 UIMgr.getInstance().addUI(UI.UI_NAME.UIEventChoose, UIIndex.UI, { uiName, eventType, data, modelCallFunc: uiData.modelCallFunc });
    //                 break;
    //         }
    //     });
    // }

    // 获取活动事件数据列表
    async getEventDataByCache(eventType, callFunc, failFunc) {
        let isLogin = UserCtrl.getIsLogin();
        if (isLogin) {
            switch (eventType) {
                case EEventType.EEvent_Invite:
                    this.reqEventRegPullStatus(callFunc, failFunc);
                    return;
                case EEventType.EEvent_CheckIn:
                    await this.reqEventCheckinIndex(callFunc, failFunc);
                    return;
            }
        }
        if (!this.eventData) {
            this.getEventDataList(eventType, callFunc, failFunc);
        } else {
            this.getExistEventDataList(eventType, callFunc, failFunc);
        }
    }

    // 获取活动事件数据列表
    /* static */ getEventDataList(eventType, callFunc, failFunc) {
        let self = this;
        this.reqAllEventConfData(() => {
            self.getExistEventDataList(eventType, callFunc, failFunc);
        }, () => {
            failFunc && failFunc();
        });
    }

    // 获取已获取的活动事件数据
    // eslint-disable-next-line no-unused-vars
    /* static */ getExistEventDataList(eventType, callFunc, failFunc) {
        let eventData = DataIns.checktable(this.eventData);
        let eventSumData = null;
        switch (eventType) {
            case EEventType.EEvent_Vip:
                eventSumData = { list: DataIns.checkarray(eventData.vip) };
                break;
            case EEventType.EEvent_Invite:
                eventSumData = { conf: DataIns.checktable(eventData.mailer) };
                break;
            case EEventType.EEvent_FirstDeposit:
                eventSumData = { conf: DataIns.checkarray(eventData.fristrecharge) };
                break;
            case EEventType.EEvent_CheckIn:
                eventSumData = { conf: DataIns.checktable(eventData.signin) };
                break;
            case EEventType.EEvent_TGRedemptionCode:
                return;
        }
        // rich.log("getExistEventDataList eventSumData:", eventSumData)
        callFunc && callFunc(eventSumData);
    }

    // 请求所有活动事件数据
    /* static */ async reqAllEventConfData(callFunc, failFunc) {
        let self = this;
        try {
            const data = await netApi.getAllEventConf({});
            self.eventData = DataIns.checktable(data);
            callFunc && callFunc();
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

    // 拉新奖励状态 - 信封宝箱状态
    /* static */ async reqEventRegPullStatus(callFunc, failFunc) {
        try {
            const data = await netApi.getEventRegPullStatus({});
            callFunc && callFunc(DataIns.checktable(data));
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }
    
    // 每日签到配置
    /* static */ async reqEventCheckinIndex(callFunc, failFunc) {
        try {
            const data = await netApi.getCheckinIndex({});
            callFunc && callFunc(DataIns.checktable(data));
        } catch (e) {
            failFunc && failFunc();
        } finally {
            /** */
        }
    }

}

const EventCtrl = new _EventCtrl_();

export { EventCtrl }

// DEBUG
window.EventCtrl = EventCtrl;
