<template>
  <section class="seven-days-bonus-container">
    <PageHeader :back="onBack" title="TID_LB_SEVEN_DAYS_CHECK_IN_BONUS" />

    <div class="seven-days-bonus-inner">
      <section class="seven-days-bonus">
        <div class="seven-days-bonus-top">
          <span style="color: #fff; font-size: 1.1875rem">
            {{ t("TID_LB_WALLET") + ": " }}
            <span style="color: #f6ff00; font-weight: bold; font-size: 1.1875rem">
              {{ GFunc.formatNumber(userInfo?.currentmoney) }}
            </span>
          </span>

          <div class="seven-days-bonus-days">
            <div v-for="(item, index) in bonusInfo.info.slice(0, -1)" :key="index" class="seven-days-bonus-days-item"
              @click="claimCheckin(item.day)">
              <div :class="[
                'seven-days-bonus-days-item-container',
                bonusInfo.current_day === item.day ? 'seven-days-bonus-days-item-container-selected' : '',
              ]">
                <span>+{{ item.amount }}</span>
                <img src="@/assets/checkin/check_in_completed.png" alt="coin" class="coin"
                  v-if="item.day < bonusInfo.current_day || item.day === bonusInfo.current_day && bonusInfo.sign_status === 3" />
                <img src="@/assets/checkin/jinb1.png" alt="coin" class="coin" v-else />
              </div>
              <span :class="[
                'seven-days-bonus-days-item-text',
                bonusInfo.current_day === item.day ? 'red' : '',
              ]" :style="{
  fontWeight: bonusInfo.current_day === item.day ? 'bold' : 'normal',
}">{{ bonusInfo.current_day === item.day ? t("TID_LB_TODAY") : `Day ${item.day}` }}</span>
            </div>
            <div class="seven-days-bonus-days-item seven-days-bonus-days-item-last"
              @click="claimCheckin(7)">
              <div :class="`seven-days-bonus-days-item-container seven-days-bonus-days-item-container-last
                ${bonusInfo.info[bonusInfo.info.length - 1].day ===
                bonusInfo.current_day &&
                'seven-days-bonus-days-item-container-last-selected'
                }
                `" style="background-color: #e71e62;">
                <!-- <div class="red-quote"> -->
                <span>
                  + {{ bonusInfo.info[bonusInfo.info.length - 1].amount }}
                  <!-- <div class="triangle-down"></div> -->
                </span>
                <img src="@/assets/checkin/check_in_completed.png" alt="coin" class="coin-big" :style="{
                  height: bonusInfo.sign_status === 1 ? '6rem' : '4rem',
                  bottom: bonusInfo.sign_status === 1 ? '2rem' : '0',
                }" v-if="7 <= bonusInfo.current_day && bonusInfo.sign_status === 3" />
                <div v-else class="coin">
                  <img src="@/assets/checkin/jinb2.png" alt="coin" class="coin-big" />
                </div>
              </div>
              <span :class="[
                'seven-days-bonus-days-item-text',
                bonusInfo.info[bonusInfo.info.length - 1].day ===
                  bonusInfo.current_day
                  ? 'red'
                  : '',
              ]" :style="{
  fontWeight: bonusInfo.info[bonusInfo.info.length - 1].day === bonusInfo.current_day ? 'bold' : 'normal',
}">
                {{
                  bonusInfo.info[bonusInfo.info.length - 1].day ===
                  bonusInfo.current_day
                  ? t("TID_LB_TODAY")
                  : "Day " + bonusInfo.info[bonusInfo.info.length - 1].day
                }}
              </span>
            </div>
          </div>
        </div>

        <div class="seven-days-bonus-content">
          <span class="seven-days-bonus-content-title">
            {{ t("TID_LB_SEVEN_DAYS_TITLE") }}
          </span>

          <span v-html="formattedText"></span>
        </div>
      </section>

      <div class="seven-days-bonus-footer">
        <span>{{ t("TID_LB_SEVEN_DAYS_DESCRIPTION_2") }}</span>
        <span>{{ t("TID_LB_SEVEN_DAYS_DESCRIPTION_3") }}</span>
        <span>{{ t("TID_LB_CONTACT_CUSTOMER_SERVICE") }}</span>
        <span class="link" @click="openTelegramLink">{{ officialCS }}</span>
      </div>
    </div>
  </section>

  <a-modal :open="showDepositModal" centered @ok="goToDeposit" :closable="false" :maskClosable="false"
    :okText="t('TID_LB_DEPOSIT_NOW')" class="wheel-spin-won-modal" :cancelButtonProps="{ style: { display: 'none' } }"
    :okButtonProps="{
      style: {
        width: '100%',
        height: '50px',
        fontSize: '1rem',
        marginInlineStart: '0',
        color: '#000',
        fontWeight: 'bold',
        background: 'rgb(255,151,0)',
        background:
          'linear-gradient(180deg, rgba(255,151,0,1) 0%, rgba(255,155,0,1) 11%, rgba(255,164,1,1) 35%, rgba(255,172,1,1) 53%, rgba(255,192,1,1) 74%, rgba(255,195,1,1) 75%, rgba(255,200,1,1) 83%, rgba(255,211,1,1) 91%, rgba(255,214,1,1) 94%, rgba(255,235,2,1) 99%)',
        border: 'none',
      },
    }">
    <div class="deposit-checkin-modal-inner">
      <span style="font-weight: bold">{{ t("TID_LB_OOPS") }}</span>
      <span>{{ t("TID_LB_SEVEN_DAYS_REQUIREMENT") }}</span>

      <img src="@/assets/common/close.png" alt="close" class="close-icon" @click="showDepositModal = false" />
    </div>
  </a-modal>

  <a-modal :open="showClaimSuccessModal" centered @ok="showClaimSuccessModal = false" :closable="false"
    :maskClosable="false" :okText="t('TID_LB_OK')" class="wheel-spin-won-modal"
    :cancelButtonProps="{ style: { display: 'none' } }" :okButtonProps="{
      style: {
        width: '100%',
        height: '50px',
        fontSize: '1rem',
        marginInlineStart: '0',
        color: '#000',
        fontWeight: 'bold',
        background: 'rgb(255,151,0)',
        background:
          'linear-gradient(180deg, rgba(255,151,0,1) 0%, rgba(255,155,0,1) 11%, rgba(255,164,1,1) 35%, rgba(255,172,1,1) 53%, rgba(255,192,1,1) 74%, rgba(255,195,1,1) 75%, rgba(255,200,1,1) 83%, rgba(255,211,1,1) 91%, rgba(255,214,1,1) 94%, rgba(255,235,2,1) 99%)',
        border: 'none',
      },
    }">
    <div class="deposit-checkin-modal-inner">
      <span style="font-weight: bold">{{ t("TID_LB_CONGRATULATIONS_YOU_HAVE_WON") }}</span>
      <span class="won-value">{{ "₫ " + wonValue }}</span>
    </div>
  </a-modal>

  <Loading v-if="requestLoading" />
</template>

<script setup>
// import { ref, onMounted, watch, onUnmounted, watchEffect } from "vue";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
// import dayjs from "dayjs";
// import { reqApi } from "@/api";
import Loading from "@/components/Loading";
import { message } from "ant-design-vue";
import { CfgAnalyze } from "@/lib/CfgAnalyze";
import PageHeader from "@/components/PageHeader";
import { GFunc } from "@/lib/GFunc";
import { CheckinCtrl } from "@/ctrl/CheckinCtrl";
import { UserCtrl } from "@/ctrl/UserCtrl";

const store = useStore();
const { t } = useI18n({
  inheritLocale: true,
  useScope: "global",
});

const requestLoading = ref(false);
const showDepositModal = ref(false);
const bonusInfo = ref({
  check_in: null,
  current_day: 1,
  info: [
    { day: 1, amount: 1 },
    { day: 2, amount: 1 },
    { day: 3, amount: 1 },
    { day: 4, amount: 1 },
    { day: 5, amount: 1 },
    { day: 6, amount: 1 },
    { day: 7, amount: 2 },
  ],
  sign_status: 1,
  minCoding: 0,
  minRecharge: 0,
});
const userInfo = computed(() => store.state.userInfo);
const token = computed(() => store.state.token);

const showClaimSuccessModal = ref(false);
const wonValue = ref(0);

// 频道号
const officialCS = ref(CfgAnalyze.getOfficialCS());

const openTelegramLink = () => {
  window.open(officialCS.value, "_blank");
};

const claimCheckin = async (day) => {
  const { minCoding, minRecharge } = bonusInfo.value;
  if (UserCtrl.getPlaylMoney() < minCoding || UserCtrl.getTotalRecharge() < minRecharge) {
    message.error(t("TID_API_ERROR_CHECKIN_NOT_CONDITION"));
    return;
  }
  if (bonusInfo.value.sign_status === 3) {
    message.warn("Phần thưởng đăng nhập ngày hôm nay đã được thu thập");
    return;
  }
  if (day === 7 && bonusInfo.value.current_day > 7) {
    day = bonusInfo.value.current_day;
  }
  if (day > bonusInfo.value.current_day) {
    message.info(`Hãy quay lại sau ${day - bonusInfo.value.current_day} ngày để nhận giải thưởng của bạn`)
    return;
  }

  await new Promise(resolve => CheckinCtrl.reqSigninAward(resolve));
  await syncCheckinData();
};

const syncCheckinData = async () => {
  await new Promise(resolve => CheckinCtrl.getSigninDataByCache(resolve));
  const { conf, day, status } = CheckinCtrl.data;
  const { awards, minCoding, minRecharge } = conf;
  bonusInfo.value.info = awards.map((amount, i) => {
    return { day: i + 1, amount }
  });
  bonusInfo.value.current_day = day;
  bonusInfo.value.sign_status = status;
  bonusInfo.value.minCoding = minCoding;
  bonusInfo.value.minRecharge = minRecharge;
}

const formattedText = computed(() => {
  return t("TID_LB_SEVEN_DAYS_DESCRIPTION_1", {
    value1: GFunc.formatNumber(bonusInfo.value.minRecharge),
    value2: GFunc.formatNumber(bonusInfo.value.minCoding)
  }).replace(/\n/g, "<br/>");
});

const goToDeposit = () => {
  if (token.value !== "") {
    store.commit("setDepositOrWithdraw", "deposit");
    store.commit("setCurrentView", "deposit-withdraw");
  } else {
    store.commit("setCurrentView", "login-register");
  }
};

const onBack = () => {
  store.commit("goBack");
};

onMounted(async () => {
  syncCheckinData();
});
</script>

<style lang="scss">
.seven-days-bonus-container {
  @include flex(column, center, center);
  box-sizing: border-box;

  .seven-days-bonus-inner {
    width: 100%;
    box-sizing: border-box;
    max-width: 600px;

    .seven-days-bonus {
      &-top {
        background: linear-gradient(#e97f4b, #ffb14b);
        padding: 0.5rem;
        @include flex(column, center, flex-start);
        gap: 1rem;
      }

      &-days {
        @include flex(row, space-between, flex-start);
        width: 100%;

        &-item {
          flex: 0 0 12%;
          @include flex(column, space-between, center);
          gap: 0.5rem;
          cursor: pointer;

          &-container {
            @include flex(column, space-around, center);
            font-size: 1.1875rem;
            background-color: #fde9d1;
            border-radius: 8px;
            box-sizing: border-box;
            height: 100%;
            padding: 0 0 .5rem 0;
            color: #e9804b;
            width: 4.875rem;
            height: 7.5rem;

            &-selected {
              border: 5px solid #e71e62;
              color: #fa2e73;
              font-weight: bold;
              background-color: #fff;
            }

            .red-quote {
              width: 100%;
              box-sizing: border-box;
              background-color: red;
              color: #fff;
              text-align: center;
              border-radius: 10px 10px 0 0;
              position: relative;
            }

            .triangle-down {
              position: absolute;
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid red;
              left: 22px;
            }

            &-last {
              @include flex(column, space-around, center);
              // background-image: url("@/assets/common/prize_bg.png");
              // background-size: cover;
              // background-position: center;
              // background-repeat: no-repeat;
              background-color: #e71e62;
              color: #fff;

              &-selected {
                border: 1px solid red;
              }
            }
          }

          &-last {
            // flex: 0 0 17%;
          }

          &-text {
            width: 4.875rem;
            font-size: 1.1875rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .coin {
            width: auto;
            height: 3rem;

            &-big {
              width: auto;
              height: 6rem;
              position: relative;
              bottom: 2rem;
            }
          }
        }
      }

      &-content {
        @include flex(column, center, center);
        width: 100%;
        box-sizing: border-box;
        font-size: 1.3125rem;
        gap: 0.5rem;
        padding: 1rem 0.5rem;

        &-title {
          font-size: 1.1875rem;
          font-weight: bold;
          color: #feae4b;
          width: 100%;
          text-align: left;
          box-sizing: border-box;
        }
      }

      &-footer {
        width: 100%;
        box-sizing: border-box;
        font-size: 1.3125rem;
        @include flex(column, center, flex-start);
        gap: 1rem;
        margin: 2rem 0;
        padding: 0 .5rem;

        .link {
          text-decoration: underline;
          color: #e71e62;
          cursor: pointer;
        }
      }
    }
  }
}

.deposit-checkin-modal-inner {
  @include flex(column, center, center);
  font-size: 1.2rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: -30px;
    right: -20px;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .won-value {
    font-size: 1.8rem;
    font-weight: bold;
    color: #ff8800;
  }
}
</style>
