<template>
  <section class="payment-qr-section">
    <div class="back-section">
      <img src="@/assets/common/back.png" alt="back" @click="onClose" />
    </div>
    <div class="payment-qr-inner">
      <div class="amount">{{ "₫ " + amount }}</div>

      <img
        :src="paymentInfo.pix_qrcode_base64"
        alt="payment-qr"
        class="payment-qr"
      />

      <div class="copy-btn" @click="copyToClipboard(paymentInfo.pix_qrcode)">
        {{ t("TID_LB_COPY_CODE") }}
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import { copyToClipboard } from "@/lib/utils";

const { t } = useI18n();

const emit = defineEmits(["onClose"]);

defineProps({
  amount: Number,
  paymentInfo: Object,
});

const onClose = () => {
  emit("onClose");
};
</script>

<style lang="scss">
.payment-qr-section {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  @include flex(column, center, center);
  .back-section {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    img {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
  }
  .payment-qr-inner {
    width: 100%;
    height: 100%;
    max-width: 430px;
    box-sizing: border-box;
    @include flex(column, center, center);
    gap: 1rem;
    .payment-qr {
      width: 50%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .amount {
      font-weight: 700;
      font-size: 1.8rem;
      padding: 0;
    }
    .copy-btn {
      width: 80%;
      @include flex(row, center, center);
      background-color: #ffeb02;
      border: 2px solid #ff8800;
      padding: 0.8rem 0;
      color: #000;
      font-weight: 700;
      border-radius: 15px;
      margin-top: 1rem;
      cursor: pointer;
    }

    @media (max-width: 600px) {
      padding: 0 0.5rem;
    }
  }
}
</style>
