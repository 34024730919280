/**
 * 全局数据及逻辑模块
 */

import { netApi } from "@/api";
import { KGEvent } from "@/api/KGEvent";
import eventBus from "@/eventBus";
import { ECodeRatioType, EJumpToType } from "@/lib/Consts";
import { DataIns } from "@/lib/DataIns";
import { GFunc } from "@/lib/GFunc";
import store from "@/store";
import { computed } from "vue";
import { UserCtrl } from "./UserCtrl";

const localData = computed(() => store.state.localLastUser);
const token = computed(() => store.state.token);

class _GlobalCtrl_ {
    hadEventInit = false;   // 模块是否已经初始化事件监听

    constructor() {
        GFunc.log("GlobalCtrl constructor");
        this.initModel();
    }

    initCtrl() {
        GFunc.log("GlobalCtrl initCtrl");
    }

    initModel() {
        GFunc.log("GlobalCtrl initModel")
        if (!this.hadEventInit) {
            this.hadEventInit = true;
            this.initEvents();
        }
    }

    initEvents() {
        // TODO 待处理
    }

    // 初始化代理数据
    initData() {
    }

    // 刷新用户财富数据
    async asyncBalance(callFunc, failFunc) {
        try {
            await netApi.getBalance({});
            callFunc && callFunc();
        } catch (error) {
            failFunc && failFunc();
        } finally {
            /* */
        }
    }

    // 获取代理返佣比例
    getCommissionRate(lv) {
        let rate = 0;
        if (localData.value) {
            let commissionRate = DataIns.checktable(localData.value?.commissionRate);
            rate = DataIns.checknumber(commissionRate[`level${lv}`]);
        }
        return rate;
    }

    // 获取打码倍率
    getCodeRatio(type) {
        let ratio = 1.0;
        if (localData.value) {
            let platBaseConf = DataIns.checktable(localData.value?.platBaseConf);
            switch (type) {
                case ECodeRatioType.ECRT_Deposit:
                    ratio = GFunc.toFixedFloatForce(DataIns.checknumber(platBaseConf.RechargeMoneyCodeRatio) || ratio);
                    break;
                case ECodeRatioType.ECRT_Bonus:
                    ratio = GFunc.toFixedFloatForce(DataIns.checknumber(platBaseConf.SendMoneyCodeRatio) || ratio);
                    break;
                case ECodeRatioType.ECRT_Commission:
                    ratio = GFunc.toFixedFloatForce(DataIns.checknumber(platBaseConf.CommissionMoneyCodeRatio) || ratio);
                    break;
            }
        }
        return ratio;
    }

    // 抛出Token过期，并清空用户数据
    cleanUserData() {
        eventBus.emit(KGEvent.EVENT_TOKEN_TIMEOUT);
        store.commit("setUserInfo", "");
        store.commit("setToken", "");
        store.commit("setUserInfo", "");
        store.commit("clearHistoryStack");
        store.commit("setDepositOrWithdraw", "deposit");
        store.commit("setCurrentView", "casino");
        store.commit("setSpinValue", 0);
        // store.commit("setPrevApiBaseUrl", apiBaseUrl.value);
        // store.commit("setApiBaseUrl", UserStore.getBaseApiUrl());
        UserCtrl.cleanLocalData();
        this.initData();
    }

    // 轮播图或公告点击跳转
    jumpToAnyWhere = ({ type, body }) => {
        if (body === '') return;
        switch (type) {
            case EJumpToType.EJump_Url:
            case EJumpToType.EJump_Channel:
                GFunc.openUrl(body);
                break;
            case EJumpToType.EJump_Module:
                this.jumpToModule(body);
                break;
            case EJumpToType.EJump_Detail:
                break;
        }
    }

    // 跳转APP内部模块
    jumpToModule = (body) => {
        if (token.value === "") {
          store.commit("setCurrentView", "login-register");
          return;
        } 
        if (body === '') return;
        const mapping = {
            "mall": "deposit-withdraw",
            "promotion": "main_affiliate_page",
            "vip": "vip",
            "wheelspin": "receive_50_for_free_page",
            "cashback": "cashback_25_page",
            "checkin": "7_days_check_in_bonus_page",
        }
        let view = DataIns.checkstring(mapping[body]);
        view && store.commit("setCurrentView", view);
    }

}

const GlobalCtrl = new _GlobalCtrl_();

export { GlobalCtrl }

// DEBUG
window.GlobalCtrl = GlobalCtrl;
